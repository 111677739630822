import { Box, IconButton, Menu, MenuItem } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { useState, useEffect } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const ActionsMenu = ({
    deleteNotification = () => console.log('delete-notification'),
    readNotification = () => console.log('read-notification'),
    unreadNotification = () => console.log('mark-as-unread'),
    openInNewTab = () => console.log('open-in-new-tab'),
    initialReadState,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isRead, setIsRead] = useState(initialReadState); // Add this line
    const { classes } = useStyles();

    useEffect(() => {
        setIsRead(initialReadState); // Update isRead when initialReadState changes
    }, [initialReadState]);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const read = () => {
        readNotification();
        setIsRead(true);
        handleClose();
    };

    const unread = () => {
        unreadNotification();
        setIsRead(false);
        handleClose();
    };

    const del = () => {
        deleteNotification();
        handleClose();
    };

    const open = () => {
        openInNewTab();
        handleClose();
    };

    return (
        <>
            <Box>
                <IconButton size="small" onClick={openMenu}>
                    <MoreHorizIcon />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                disableScrollLock
                onClose={handleClose}
            >
                {!isRead && (
                    <MenuItem className={classes.menuItem} onClick={read}>
                        Mark as read
                    </MenuItem>
                )}
                {isRead && (
                    <MenuItem className={classes.menuItem} onClick={unread}>
                        Mark as unread
                    </MenuItem>
                )}
                <MenuItem className={classes.menuItem} onClick={del}>
                    Delete
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={open}>
                    Open in new tab
                </MenuItem>
            </Menu>
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    menuItem: {
        fontSize: '1em',
        fontWeight: 500,
    },
}));
