import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import Picker from 'emoji-picker-react';
import AddIcon from '@mui/icons-material/Add';

const emojis = [
    { id: 'like', symbol: '👍', label: 'Like' },
    { id: 'love', symbol: '❤️', label: 'Love' },
    { id: 'smile', symbol: '😊', label: 'Smile' },
    { id: 'surprise', symbol: '😲', label: 'Surprise' },
];

export const EmojiPicker = ({ onEmojiClick, disabled }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {emojis.map((emoji) => (
                <IconButton
                    key={emoji.id}
                    onClick={() => onEmojiClick(emoji.symbol)}
                    aria-label={emoji.label}
                    disabled={disabled}
                >
                    <Typography variant="body2" component="span">
                        {emoji.symbol}
                    </Typography>
                </IconButton>
            ))}
            <Tooltip title="More reactions">
                <IconButton onClick={handleOpenMenu} disabled={disabled}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <Picker
                        onEmojiClick={(emojiObject) => {
                            onEmojiClick(emojiObject.emoji);
                            handleClose();
                        }}
                        lazyLoadEmojis={true}
                        skinTonesDisabled={true}
                    />
                </MenuItem>
            </Menu>
        </Box>
    );
};
