import { Box, Divider, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ActionsMenu } from './ActionsMenu';
import { useImageLoaded } from '../util';
import ReactHtmlParser from 'react-html-parser';

export const NotificationShortItem = ({
    notification,
    handleRead = () => console.log('handle-read-default'),
    handleUnread = () => console.log('handle-unread-default'),
    deleteNotification = () => console.log('default-read'),
}) => {
    const { classes } = useStyles();
    const [read, setRead] = useState(notification?.read);
    const [imageRef, loaded, onLoad] = useImageLoaded();

    useEffect(() => {
        setRead(notification?.read);
    }, [notification]);

    const notificationRead = () => {
        handleRead(notification._id);
        setRead(true);
    };

    const notificationUnread = () => {
        handleUnread(notification._id);
        setRead(false);
    };

    const delNotification = () => {
        deleteNotification(notification._id);
    };

    const handleClick = () => {
        notificationRead();
        setRead(true);
        if (notification.button_link) {
            window.open(notification.button_link, '_self');
        }
    };

    const openInNewTab = () => {
        if (notification.button_link) {
            window.open(notification.button_link, '_blank');
        }
    };

    return (
        <Box className={classes.notification}>
            <Box
                className={`${!read ? classes.notificationRead : ''} ${
                    classes.notificationBox
                }`}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    flexGrow={1}
                    pl={1}
                    onClick={handleClick}
                >
                    <Typography className={classes.notificationText}>
                        {ReactHtmlParser(notification.notification_text)}
                    </Typography>
                    <Box>
                        <Typography variant="caption">
                            {moment(notification.createdAt).calendar()}
                        </Typography>
                    </Box>
                </Box>

                {notification.notification_image && (
                    <Box onClick={handleClick} ml={2}>
                        <img
                            ref={imageRef}
                            width={loaded ? '75px' : 0}
                            onLoad={onLoad}
                            src={notification.notification_image}
                            height="auto"
                            alt="Notification"
                        />
                    </Box>
                )}
                <Box ml={1}>
                    <ActionsMenu
                        deleteNotification={delNotification}
                        readNotification={notificationRead}
                        unreadNotification={notificationUnread}
                        openInNewTab={openInNewTab}
                        initialReadState={read}
                    />
                </Box>
            </Box>
            <Divider />
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    notification: {
        cursor: 'pointer',
        transition: '0.1s all ease-in',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '105%',
            height: '105%',
            content: '""',
            borderRadius: '4px',
            transition: '0.1s all ease-in',
            backgroundColor: 'rgba(150,150,150,0.2)',
            opacity: 0,
        },
        '&:hover': {
            '&::before': {
                opacity: 1,
            },
        },
    },
    notificationRead: {
        backgroundColor: theme.palette.common.lightGray,
        '&::before': {
            position: 'absolute',
            top: '7px',
            width: '7px',
            height: '7px',
            left: '-8px',
            content: '""',
            borderRadius: '50%',
            backgroundColor: theme.palette.common.lightBlue,
        },
    },
    notificationText: {
        fontSize: 'min(1em, 3vw)',
        fontWeight: '500',
    },
    notificationBox: {
        margin: '15px 0',
        // minWidth: '280px',
        position: 'relative',
        display: 'flex',
        // marginLeft: '8px',
        justifyContent: 'space-between',
    },
}));
