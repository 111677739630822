import { Navigate } from 'react-router-dom';
//import pathMatch from 'path-match';
import { useUser } from '../auth';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { CircularProgress, Loading } from '../ui';

export const PrivateRoleRoute = ({ routeRole, children }) => {
    const {
        isLoading: isLoadingUserDetails,
        user: userDetails,
        userRoles,
    } = useCurrentUser();
    const { user, isLoading } = useUser();

    //const location = useLocation();
    //const { pathname } = location;

    if (isLoading) return <Loading />;

    if (isLoadingUserDetails) return <Loading />;

    if (user && !userDetails) return <Loading />;

    if (!user) return <Navigate to="/login" replace />;

    // if (userDetails && routeRoles?.some((role) => userRoles.has(role)))
    if (userDetails && userRoles.has(routeRole)) return <>{children}</>;

    if (user) return <Navigate to="/profile" replace />;

    // return isLoading || isLoadingUserDetails ? (
    //     <div
    //         style={{
    //             height: '100vh',
    //             display: 'grid',
    //             placeItems: 'center',
    //         }}
    //     >
    //         <CircularProgress color="secondary" />
    //     </div>
    // ) : user && user.is_instructor ? (
    //     <Route {...props} />
    // ) : user ? (
    //     <Navigate to="/profile"  replace />
    // ) : (
    //     <Navigate to="/instructor-login"  replace />
    // );
};
