export default function compareEnrolledCourses(by) {
    switch (by) {
        case 'TITLE':
            return (a, b) => a.title.localeCompare(b.title);
        case 'INSTRUCTOR':
            return (a, b) => {
                const instructorA = a.instructors[0]?.name || '';
                const instructorB = b.instructors[0]?.name || '';
                return instructorA.localeCompare(instructorB);
            };
        case 'RECENT':
            return (a, b) => {
                const dateA = new Date(
                    a.updatedAt || '1900-01-01T00:00:00.000Z'
                );
                const dateB = new Date(
                    b.updatedAt || '1900-01-01T00:00:00.000Z'
                );
                return dateB - dateA;
            };
        case 'PERCENTAGE_COMPLETED':
            return (a, b) => {
                const percentageA = a.percentageCompleted || 0;
                const percentageB = b.percentageCompleted || 0;
                return percentageB - percentageA;
            };
        default:
            return (a, b) => 0;
    }
}
