import { useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '../ui';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { blue } from '@mui/material/colors';
import { sentenceCase } from '../util';
import { ExpandMoreIcon } from '../icons';
import { Fade } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
    root: { position: 'relative' },

    dropDownMenu: {
        position: 'absolute',
        left: '-500%',
        minWidth: 700,
        margin: '0 auto',
        padding: '15px 20px 10px',
        // height: 'fit-content',
        backgroundColor: 'rgba(250,250,250)',
        // animation: '$enter 0.1s ease-out forwards',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
    },
    // '@keyframes enter': {
    //     '0%': {
    //         opacity: 0,
    //     },
    //     '100%': {
    //         opacity: 1,
    //     },
    // },
    // why starweaver component
    sectionHeading: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            width: '90%',
            height: '3px',
            background:
                'linear-gradient(114deg, rgba(21,101,192,1) 0%, rgba(250,250,250,0) 135%)',
            bottom: '-4px',
            left: 0,
            borderRadius: '0px 20px 0px 20px',
        },
    },
    listItem: {
        minHeight: 80,
    },
    link: {
        transistion: ' all 0.2s ease-out',
        textDecoration: 'underline',
        letterSpacing: '1px',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: blue[800],
        },
    },
    container: {
        width: '82%',
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'column',
    },
    dropdownButton: {
        transition: 'all 150ms linear',
        '&:hover': {
            backgroundColor: '#EA7112',
            color: '#ffff',
        },
    },
    dropdownButtonActive: {
        backgroundColor: '#EA7112',
        color: '#ffff',
    },
}));

export const WhyStarweaver = () => {
    const { classes } = useStyles();

    const [openMenu, setOpenMenu] = useState(false);

    const Anchor = ({ children, to, isExternal, className }) =>
        isExternal ? (
            <a href={to} target="_blank" className={className}>
                {children}
            </a>
        ) : (
            <Link to={to} className={className}>
                {children}
            </Link>
        );

    return (
        <Box className={classes.root} onMouseLeave={() => setOpenMenu(false)}>
            <Button
                color="secondary"
                onMouseEnter={() => {
                    setOpenMenu(true);
                }}
                onClick={() => {
                    setOpenMenu(true);
                }}
                endIcon={<ExpandMoreIcon />}
                className={`${classes.dropdownButton} ${
                    openMenu && classes.dropdownButtonActive
                }`}
            >
                Why starweaver
            </Button>

            {openMenu && (
                <Fade in={openMenu}>
                    <Box className={classes.dropDownMenu}>
                        <Grid container item spacing={4} xs={12}>
                            <Grid item xs={6}>
                                <Typography
                                    className={classes.sectionHeading}
                                    variant="h6"
                                    gutterBottom
                                >
                                    {sentenceCase(
                                        'ROLE-BASED AND LIVE EDUCATION'
                                    )}
                                </Typography>
                                {roleBasedAndLiveEducationContent.map(
                                    (item) => (
                                        <Box
                                            mt={2}
                                            className={classes.listItem}
                                        >
                                            <Anchor
                                                to={item.linkTo}
                                                isExternal={item.isExternal}
                                                className={classes.link}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle2"
                                                >
                                                    {item.heading}
                                                </Typography>
                                            </Anchor>
                                            <Box ml={2}>
                                                <Typography variant="caption">
                                                    {item.caption}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    className={classes.sectionHeading}
                                    variant="h6"
                                    gutterBottom
                                >
                                    {sentenceCase(
                                        'TOP QUALITY INSTRUCTORS PUBLISHING NEW CONTENT DAILY'
                                    )}
                                </Typography>
                                {topQaulityInstructorsPublishNewContent.map(
                                    (item) => (
                                        <Box
                                            mt={2}
                                            className={classes.listItem}
                                        >
                                            <Anchor
                                                to={item.linkTo}
                                                isExternal={item.isExternal}
                                                className={classes.link}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle2"
                                                >
                                                    {item.heading}
                                                </Typography>
                                            </Anchor>
                                            <Box ml={2}>
                                                <Typography variant="caption">
                                                    {item.caption}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                )}
                                <Box my={2} />
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            )}
        </Box>
    );
};

const learnByExperienceContent = [
    {
        heading: 'Learn by doing',
        linkTo: '/on-air',
        caption:
            'Hands-on and practical work-related ​activities that guide you in your job.​ ',
    },
    {
        heading: 'Access live instructors and assistants​',
        linkTo: '/on-air',
        caption:
            'Engage directly with leading practitioners, not merely an online library',
    },
    {
        heading: 'Hands-on walk-throughs​',
        linkTo: '/on-air',
        caption:
            'Experience system, tool and platform lab demos, pause those and do it yourself.​',
    },
    {
        heading: 'Connect to peers with similar interests​',
        linkTo: '/how-it-works',
        caption:
            'Great learning happens when you can​ show others, enhancing your knowledge.​',
    },
];

const topQualityContent = [
    {
        heading: 'Structured Journeys & Curriculums​',
        linkTo: '/journeys/all/j',
        caption:
            '​View standard learning paths and create​ a customized journey for your career',
    },
    {
        heading: 'Technical and Business Content​',
        linkTo: '/category/business/courses',
        caption:
            'Cross functional content marries technical  and business skills essential in your career.​',
    },
    {
        heading: 'Practical LivesLabs and GuidedLabs',
        linkTo: '/live-labs',
        caption:
            '​Our experts show you how it’s done not​ getting you engaged and motivated.',
    },
    {
        heading: 'Role- and Skill-Based Content​',
        linkTo: '/journeys/all/j',
        caption:
            ' See how what you are need to know ​relates directly to jobs and skills you want.​​',
    },
];

const roleBasedAndLiveEducationContent = [
    {
        heading: `Find content based on your “Persona”`,
        linkTo: '/job-roles',
        caption:
            'Our Journey builder employs AI and proprietary technologies to surface role-based content just for you. ',
    },
    {
        heading: 'What’s On Air now? Live instructors​',
        linkTo: '/on-air',
        caption:
            'Engage directly with leading practitioners, not merely a boring, static library.​',
    },
    {
        heading: 'Hands-on walk-throughs',
        linkTo: '/live-labs',
        caption:
            '​Get group and team coaching with LiveLabs and hands-on demos. ',
    },
    {
        heading:
            'Great education connects you with peers with similar interests ',
        linkTo: 'https://support.starweaver.com/portal/en/community/recent',
        isExternal: true,
        caption:
            'Real learning happens when you’re confident enough to teach others.​​​',
    },
];

const topQaulityInstructorsPublishNewContent = [
    {
        heading: `Structured Journeys & curriculums`,
        linkTo: '/journeys/all/j',
        caption:
            'Practitioners build learning paths and create​ a customized journeys ',
    },
    {
        heading: 'Technical and business content',
        linkTo: '/category/business/courses',
        caption:
            'Cross functional content marries technical and business skills essential in any career.​',
    },
    {
        heading:
            'Internally use our content curation, streaming and publishing tools',
        linkTo: `/free-enterprise-trial`,
        isExternal: true,
        caption:
            '​Your instructors and content teams can curate, design, publish and stream content for internal use only.',
    },
    {
        heading:
            'Universities and colleges get powerful platform extensions to educate as never before',
        linkTo: '/for-campus/students',
        caption:
            'Your professors and teaching assistants can quickly curate supporting course packages for your students. ​​​',
    },
];
