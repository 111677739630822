import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    Button,
} from '../ui';
import Fade from '@mui/material/Fade';
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './styles';
import { ChevronRightIcon, ChevronLeftIcon } from '../icons';
// import SWCourseCards from '../custom-components/SWCourseCards';
import SWRecomendationCard from '../custom-components/SWRecomendationCard';
import { useTheme } from '@mui/material';

export const TopPicks = ({
    color = '#fafafa',
    heading = 'Here are some top picks for you',
    data,
    showCount = true,
    showViewAll = true,
    launchText = 'Launch',
    // uncommented it if in any case this prop is not filled we can have a default value
    removeText = 'Un-Enroll',
    // negative function
    handleOnClick = (item) => {
        console.log('No On Save Function');
        console.log(item);
    },
    // positive function
    handleLaunch = (item) => {
        console.log('No Default Launch Function');
        console.log(item);
    },
    showActions = false,
    showRemoveBtn,
    isDashboard = false,
}) => {
    const theme = useTheme();
    const isXLDevice = useMediaQuery(theme.breakpoints.up('xl'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const { classes } = useStyles();

    const currentIndex = useRef(0);
    const [displayCourse, setDisplayCourse] = useState(null);
    const [size, setSize] = useState(null);
    const [loaded, setLoaded] = useState(false);
    let navigate = useNavigate();

    function getSize() {
        if (isDashboard) {
            if (isXLDevice) {
                return 2;
            } else if (isLarge) {
                return 2;
            } else if (isMedium) {
                return 2;
            } else if (isSmall) {
                return 2;
            } else {
                return 1;
            }
        } else {
            if (isXLDevice) {
                return 4;
            } else if (isLarge) {
                return 3;
            } else if (isMedium) {
                return 2;
            } else if (isSmall) {
                return 2;
            } else {
                return 1;
            }
        }
    }

    useEffect(() => {
        const getNewSize = getSize();
        setSize(getNewSize);
    }, [isLarge, isMedium, isXLDevice, isSmall]);

    const setNext = () => {
        currentIndex.current += size;
        if (currentIndex.current > data.length - 1) {
            currentIndex.current = 0;
        }
        const newAr = data.slice(
            currentIndex.current,
            currentIndex.current + size
        );
        setDisplayCourse(newAr);
        setLoaded(true);
    };

    const setPrev = () => {
        currentIndex.current -= size;
        if (currentIndex.current < 0) {
            currentIndex.current = data.length - size;
        }
        const newAr = data.slice(
            currentIndex.current,
            currentIndex.current + size
        );
        setDisplayCourse(newAr);
        setLoaded(true);
    };

    useEffect(() => {
        if (size) {
            currentIndex.current = -size;
            setNext();
        }
    }, [size]);

    useEffect(() => {
        if (data && size) {
            currentIndex.current = -size;
            setNext();
        }
    }, [data]);
    const handleClick = (course) => {
        navigate(`/courses/${course.slug}`);
    };

    return (
        <Box my={3}>
            <Typography
                style={{ color: '#000' }}
                className={classes.sectionHeading}
            >
                {heading}{' '}
                {showCount && (
                    <>
                        ({data.length} program{data?.length > 1 ? 's' : ''})
                    </>
                )}
            </Typography>
            {showViewAll && (
                // <Link to="/courses" className={classes.link}>
                <Link to="/category/all/courses" className={classes.link}>
                    view all&nbsp;
                    <ChevronRightIcon size="small" />{' '}
                </Link>
            )}
            <Box
                className={`${
                    isDashboard
                        ? classes.dashboardGridContainer
                        : classes.gridContainer
                }`}
            >
                {displayCourse ? (
                    <>
                        {displayCourse.map((item, index) => {
                            let delay = 0;
                            if (index > 0) {
                                delay = Math.trunc((500 / size) * index);
                            }
                            return (
                                <Fade
                                    in={loaded}
                                    style={{
                                        transitionDelay: loaded
                                            ? `${delay}ms`
                                            : '0ms',
                                    }}
                                    key={'top-picks-' + index}
                                >
                                    <Box key={'top-picks-' + index}>
                                        <SWRecomendationCard
                                            imgSrc={
                                                item.card_image_url ||
                                                item.course_card_image_url
                                            }
                                            courseName={item.name}
                                            instructorName={
                                                item.instructor_names
                                            }
                                            content={item.description}
                                            id={item.id}
                                            showEnrollBtn={item?.showUnEnroll}
                                            item={item}
                                            handleViewBtnClick={handleClick}
                                            showCardActions={false}
                                            showRemoveBtn={showRemoveBtn}
                                            showCloseBtn={false}
                                            handleRemove={handleOnClick}
                                            isDashboard={isDashboard}
                                            instructors={item?.instructors}
                                        />
                                    </Box>
                                </Fade>
                            );
                            // return (
                            //     <Fade
                            //         in={loaded}
                            //         style={{
                            //             transitionDelay: loaded
                            //                 ? `${delay}ms`
                            //                 : '0ms',
                            //         }}
                            //         key={'top-picks-' + index}
                            //     >
                            //         <Grid
                            //             key={'top-picks-' + index}
                            //             item
                            //             xs={12}
                            //             sm={6}
                            //             md={3}
                            //             className={classes.mainBoxWrap}
                            //         >
                            //             <Box
                            //                 className={classes.mainBox}
                            //                 onClick={() => handleClick(item)}
                            //             >
                            //                 <Box
                            //                     component="div"
                            //                     style={{
                            //                         backgroundImage: `url(${
                            //                             item.card_image_url ||
                            //                             item.course_card_image_url
                            //                         })`,
                            //                     }}
                            //                     className={classes.cardImg}
                            //                 >
                            //                     <img
                            //                         src={
                            //                             item.card_image_url ||
                            //                             item.course_card_image_url
                            //                         }
                            //                         width="100%"
                            //                         alt="top"
                            //                     ></img>
                            //                 </Box>
                            //                 <Typography
                            //                     className={classes.cardHeading}
                            //                 >
                            //                     {item.name}
                            //                 </Typography>
                            //                 <Box
                            //                     component="div"
                            //                     className={classes.contentBox}
                            //                 >
                            //                     <Box>
                            //                         {item.instructor_names && (
                            //                             <Typography
                            //                                 style={{
                            //                                     color:
                            //                                         '#BCBCBC',
                            //                                     fontWeight: 500,
                            //                                 }}
                            //                                 variant="body2"
                            //                             >
                            //                                 {
                            //                                     item.instructor_names
                            //                                 }
                            //                             </Typography>
                            //                         )}
                            //                     </Box>
                            //                     <p className={classes.desc}>
                            //                         {item.description.length >
                            //                         150 ? (
                            //                             <>
                            //                                 {`${item.description.substr(
                            //                                     0,
                            //                                     150
                            //                                 )}...`}
                            //                             </>
                            //                         ) : (
                            //                             <>{item.description}</>
                            //                         )}
                            //                     </p>
                            //                 </Box>
                            //                 {showActions && (
                            //                     <Grid
                            //                         container
                            //                         spacing={2}
                            //                         className={classes.cardView}
                            //                         style={{ padding: 5 }}
                            //                     >
                            //                         <Grid
                            //                             item
                            //                             xs={12}
                            //                             sm={12}
                            //                             md={6}
                            //                         >
                            //                             <Button
                            //                                 variant="contained"
                            //                                 color="secondary"
                            //                                 className={
                            //                                     classes.cardBtn
                            //                                 }
                            //                                 onClick={(e) => {
                            //                                     e.stopPropagation();
                            //                                     handleLaunch(
                            //                                         item
                            //                                     );
                            //                                 }}
                            //                             >
                            //                                 {/* this piece of code will help us to change
                            //                                 the text on basis of a condition on a parent component  */}
                            //                                 {/* won't mess up the code eight now but have to make
                            //                                 it so we can use it later */}
                            //                                 {launchText instanceof
                            //                                 Function
                            //                                     ? 'function'
                            //                                     : launchText}
                            //                             </Button>
                            //                         </Grid>
                            //                         {item.hasOwnProperty(
                            //                             'showUnEnroll'
                            //                         ) &&
                            //                         item.showUnEnroll ===
                            //                             false ? (
                            //                             <> </>
                            //                         ) : (
                            //                             <Grid
                            //                                 item
                            //                                 xs={12}
                            //                                 sm={12}
                            //                                 md={6}
                            //                             >
                            //                                 <Button
                            //                                     className={
                            //                                         classes.cardBtn
                            //                                     }
                            //                                     color="secondary"
                            //                                     variant="outlined"
                            //                                     onClick={(
                            //                                         e
                            //                                     ) => {
                            //                                         e.stopPropagation();
                            //                                         handleOnClick(
                            //                                             item
                            //                                         );
                            //                                     }}
                            //                                 >
                            //                                     {removeText}
                            //                                 </Button>
                            //                             </Grid>
                            //                         )}
                            //                     </Grid>
                            //                 )}
                            //             </Box>
                            //         </Grid>
                            //     </Fade>
                            // );
                        })}
                    </>
                ) : (
                    <Box className={classes.load}>
                        <CircularProgress color="secondary" />
                    </Box>
                )}

                <>
                    <IconButton
                        className={`${classes.controlBtn} ${classes.left}`}
                        onClick={() => {
                            if (size >= data.length) {
                                return;
                            }
                            setLoaded(false);
                            setTimeout(() => setPrev(), 500);
                        }}
                        style={{ left: '-12px' }}
                        size="large"
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        className={`${classes.controlBtn} ${classes.right}`}
                        onClick={() => {
                            if (size >= data.length) {
                                return;
                            }
                            setLoaded(false);
                            setTimeout(() => setNext(), 500);
                        }}
                        style={{ right: '-12px' }}
                        size="large"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </>
            </Box>
        </Box>
    );
};
