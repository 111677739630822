import { Box, Typography } from '../ui/index';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

export function PartnerContainer() {
    const { classes } = useStyles();
    return (
        <Box className={classes.root}>
            <Typography className={classes.textHead} variant="subtitle1">
                PARTNER WITH US
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href="https://go.starweaver.com/instructor-signup"
                    // target="_blank"
                    rel="noreferrer"
                >
                    Instructors & Teachers
                </a>
            </Typography>
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/company/affiliate/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Affiliates Program
                </a>
            </Typography> */}
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/partners/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Channel Partners
                </a>
            </Typography> */}
            <Typography className={classes.textStyle} variant="subtitle1">
                {/* <a
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/authors`}
                    href="/books-and-publications"
                    target="_blank"
                    rel="noreferrer"
                >
                    Books, Writing & <br /> Publishing
                </a> */}
                <Link to="/books-and-publications">
                    Books, Writing & <br /> Publishing
                </Link>
            </Typography>
        </Box>
    );
}
