import { coursesSlice } from '../courses/coursesSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
    setAllSavedOnDemand,
    getAllSavedOnDemand,
    getSavedCertifications,
    setSavedCertifications,
} from './Recoil/Selector';

const { getCourses } = coursesSlice;

export const useGetSavedOnDemand = (user) => {
    const course = useSelector(getCourses);
    // IN DB WE HAVE BOTH ON DEMAND AND CERTIFICATION IN ONE ARRAY
    // HERE WE STORE THEM IN DIFFRENT STATES
    // SO IF THE SIZE OF SAVED IN DB === SUN OF(ON DEMAND & CERTIFICATION)
    // THEN RETURN THE CURRENT STATE

    const savedOnDemand = useRecoilValue(getAllSavedOnDemand);
    const setSavedOnDemand = useSetRecoilState(setAllSavedOnDemand);
    const savedCertification = useRecoilValue(getSavedCertifications);
    const setSavedCertification = useSetRecoilState(setSavedCertifications);
    useEffect(() => {
        if (user) {
            if (user.user_save.length === 0) {
                setSavedOnDemand([]);
            } else {
                if (
                    user.user_save.length !==
                    savedOnDemand.length + savedCertification.length
                ) {
                    let foundCertification = [];
                    let foundOnDemand = [];
                    user.user_save.forEach((id) => {
                        for (let i = 0; i < course.length; i++) {
                            if (course[i].id === id) {
                                let newItem = { ...course[i] };
                                newItem.saved = true;
                                if (newItem.collection_ids.includes(248139)) {
                                    foundCertification.push(newItem);
                                } else {
                                    foundOnDemand.push(newItem);
                                }
                            }
                        }
                    });
                    setSavedCertification(foundCertification);
                    setSavedOnDemand(foundOnDemand);
                }
            }
        }
    }, [user]);

    return { savedOnDemand, savedCertification };
};
