import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
//import { useUser } from '../auth/useUser';
//import { useQueryParams } from '../util/useQueryParams';
import { Hero } from '../ui/CustomUI';
import { DomainsTabs } from './DomainsTabs';
import { Box, Divider, Container, Grid } from '../ui';
import { makeStyles } from 'tss-react/mui';
import JourneysList from './JourneysList';
import { journeysRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';

const useStyles = makeStyles()((theme) => ({
    container: {
        minHeight: 500,
    },
    pushFooterDown: {
        height: 1000,
    },
}));

const defaultChannels = [
    //{ name: 'On Air', value: 'on_air' },
    { name: 'Data Science', value: 'data-science' },
    { name: 'Cloud', value: 'cloud' },
    { name: 'Full Stack', value: 'full-stack' },
    { name: 'Cybersecurity', value: 'cybersecurity' },
    { name: 'Agility', value: 'agility' },
    //{ name: 'Devops', value: 'devops' },
    //{ name: 'Networking', value: 'networking' },
    //{ name: 'UI/UX', value: 'ui-ux' },
    { name: 'Finance', value: 'finance' },
    { name: 'Business', value: 'business' },
    //{ name: 'Coming Soon', value: 'coming-soon' },
];

export const JourneysPage = () => {
    const { classes } = useStyles();
    const location = useLocation();
    let navigate = useNavigate();

    const path = location.pathname;
    const pathLastPart = /\/([^\/]+)\/?$/.exec(path)[1];

    const [journeys, setJourneys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingChannels, setIsLoadingChannels] = useState(false);
    const [channels, setChannels] = useState(defaultChannels);

    //let initialTabValue = 0;

    const domain =
        pathLastPart === 'journeys' ? channels[0].value : pathLastPart;
    // if (domain)
    //     initialTabValue = channels.findIndex(({ value }) => value === domain);

    const [value, setValue] = useState(0);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             setIsLoadingChannels(true);
    //             const res = await axios.get(
    //                 `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/journeys/channels?nowprocket=1`
    //             );
    //             console.log(res.data);
    //             // if (res.data === null) {
    //             //     navigate(('/404'));
    //             // }
    //             setChannels(res.data);
    //             setIsLoadingChannels(false);
    //         } catch (err) {
    //             //navigate(('/404'));
    //             console.log(err);
    //             setIsLoadingChannels(false);
    //         }
    //     })();
    // }, []);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                // const res = await axios.get(
                //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/journeys?domain=${domain}`
                // );

                const res = await axios.get(
                    `/api/journeys?domain=${domain}&type=JOURNEY`
                );
                console.log(res.data.journeys);
                if (res.data.journeys === null) {
                    navigate('/404');
                }
                setJourneys(res.data);
                setIsLoading(false);
            } catch (err) {
                navigate('/404');
                console.log(err);
            }
            setIsLoading(false);
        })();
    }, [domain]);

    useEffect(() => {
        let tabIndex = channels.findIndex(({ value }) => value === domain);
        setValue(tabIndex !== -1 ? tabIndex : 0);
    }, [domain, channels.map(({ value }) => value).join()]);

    return <Navigate to="/journeys/all/j" replace />;

    return (
        <>
            <HelmetWrapper route={route} />
            {/* <Hero heroValue={heroValue} /> */}
            {!isLoadingChannels && (
                <DomainsTabs
                    collections={channels}
                    value={value}
                    setValue={setValue}
                />
            )}
            <Divider />
            <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                className={classes.container}
            >
                <JourneysList journeys={journeys} isLoading={isLoading} />
            </Grid>
        </>
    );
};
