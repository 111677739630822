import { useEffect, useState, useMemo } from 'react';
// import { useFetchContainerFiles } from '../util';
// import { COMMENT, setLoadAttachments } from '../slices/commentAttachmentSlice';

import Attachment from './Attachment';
// import { useSelector, useDispatch } from 'react-redux';
// import { Skeleton } from '@mui/material';
import { AzureBlobStorage } from '../util/BlobStorage';
import { VideoPlayerModal } from '../ui';
import { PreviewModal } from '../admin/PreviewModal';
import { getFileTypeByExt } from '../util';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        attachments: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
        },
    };
});

const CONTAINER_NAME = 'chats';

function Attachments({
    /* type, replyId, commentId  */ chatId,
    attachedFileNames,
    deletable,
}) {
    const { classes } = useStyles();
    // const {
    //     fileData,
    //     error: fileFetchErr,
    //     loading: fetchingFiles,
    //     setFileData,
    //     fetchFileData,
    // } = useFetchContainerFiles(
    //     CONTAINER_NAME,
    //     type === COMMENT ? commentId + '/' : `${commentId}/replies/${replyId}/`
    // );
    // const commentAttachments = useSelector((state) => state.commentAttachments);
    // const dispatch = useDispatch();

    const dirName = chatId;
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);

    const files = useMemo(() => {
        if (!attachedFileNames) return [];

        return attachedFileNames.map((name) => {
            const ext = name.split('.').pop();

            return {
                actual: dirName + '/' + name,
                formatted: name,
                extension: ext,
                ...getFileTypeByExt(ext),
            };
        });
    }, [attachedFileNames, dirName]);

    // console.log(files);

    // useEffect(() => {
    //     if (
    //         commentAttachments.loadAttachments.commentId === commentId &&
    //         commentAttachments.loadAttachments.loading
    //     ) {
    //         (async function () {
    //             await fetchFileData(
    //                 CONTAINER_NAME,
    //                 type === COMMENT
    //                     ? commentId + '/'
    //                     : `${commentId}/replies/${replyId}/`
    //             );
    //             dispatch(setLoadAttachments({ commentId: '', loading: false }));
    //         })();
    //     }
    // }, [
    //     commentAttachments.loadAttachments,
    //     commentId,
    //     fetchFileData,
    //     replyId,
    //     type,
    //     dispatch,
    // ]);

    const handleAttachmentClick = async (file) => {
        try {
            if (['IMAGE', 'VIDEO', 'PDF'].includes(file.type)) {
                setCurrentFile(file);
                setShowPreviewModal(true);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setShowPageLoader(false);
        }
    };

    return (
        <div className={classes.attachments}>
            {/* {fetchingFiles && (
                <Skeleton variant="rectangular" width={160} height={160} />
            )} */}

            {showPreviewModal && (
                <PreviewModal
                    containerName={CONTAINER_NAME}
                    currentFile={currentFile}
                    setShowPreviewModal={setShowPreviewModal}
                    showPreviewModal={showPreviewModal}
                />
            )}

            {files.map((file) => {
                return (
                    <Attachment
                        file={file}
                        key={file.actual}
                        containerName={CONTAINER_NAME}
                        // setFileData={setFileData}
                        dirName={dirName}
                        handleAttachmentClick={handleAttachmentClick}
                        showPageLoader={showPageLoader}
                        deletable={deletable}
                    />
                );
            })}
        </div>
    );
}

export default Attachments;
