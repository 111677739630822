import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { enrolledDemandState } from './Recoil/State';

export const useEnrolledOnDemand = (user) => {
    const [loading, setLoading] = useState(false);
    const [enrolledOnDemand, setEnrolledOnDemand] = useRecoilState(
        enrolledDemandState
    );

    useEffect(() => {
        const fetchEnrolledCourses = async () => {
            if (!user || !user.user_save || user.user_save.length === 0) {
                setEnrolledOnDemand([]);
                return;
            }

            setLoading(true);

            try {
                const thinkificProductIds = user.user_save.join(',');

                // Fetch enrolled courses from the first API
                const courseResponse = await axios.get(`/api/courses`, {
                    params: {
                        thinkificProductIds,
                        includeFeedback: '1',
                    },
                });

                let enrolledCourses = courseResponse.data.courses.map(
                    (course) => ({
                        ...course,
                        // productable_id: course.thinkificCourseId,
                        // percentage_completed: 0, // Placeholder, will be updated
                        // updated_at: null, // Placeholder, will be updated
                    })
                );

                // Fetch course progress data from the second API (/api/enrollments/thinkific-user)
                const thinkificUserId = user.user_thinkific_id || 0;
                const thinkificCourseIds = enrolledCourses
                    .map((course) => course.thinkificCourseId)
                    .join(',');

                const progressResponse = await axios.get(
                    `/api/enrollments/thinkific-user`,
                    {
                        params: {
                            thinkificUserId,
                            thinkificCourseIds,
                        },
                    }
                );

                const progressData = new Map(
                    progressResponse.data.map((item) => [
                        parseInt(item.course_id),
                        item,
                    ])
                );

                // Merge the progress data (percentage_completed and updated_at) into the enrolledCourses array
                enrolledCourses = enrolledCourses.map((course) => ({
                    ...course,
                    ...progressData.get(course.thinkificCourseId),
                }));

                setEnrolledOnDemand(enrolledCourses);
            } catch (error) {
                console.error(
                    'Error fetching enrolled courses or course progress:',
                    error
                );
                setEnrolledOnDemand([]);
            } finally {
                setLoading(false);
            }
        };

        fetchEnrolledCourses();
    }, [user, setEnrolledOnDemand]);

    return { enrolledOnDemand, loading };
};
