import { LeaderboardItem } from './LeaderboardItem';
import { useLeaderboards } from './useLeaderboards';
import { Box, Loading } from '../ui';

export const LeaderboardSection = ({ userId, clientId, isAdmin = false }) => {
    const { leaderboards, userRank, isLoading, showUser } = useLeaderboards(
        clientId,
        userId
    );

    return (
        <Box>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Box mb={2}>
                        <LeaderboardItem showHeader={true} isAdmin={isAdmin} />
                    </Box>

                    {leaderboards.map((user, index) => (
                        <LeaderboardItem
                            key={index}
                            isAdmin={isAdmin}
                            user={user}
                            rank={index + 1}
                            isCurrentUser={userRank.rank === index + 1}
                        />
                    ))}
                    {showUser && (
                        <LeaderboardItem
                            isAdmin={isAdmin}
                            user={userRank}
                            isCurrentUser={true}
                            rank={userRank?.rank}
                        />
                    )}
                </>
            )}
        </Box>
    );
};
