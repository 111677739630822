import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { CloseIcon } from '../../../icons';
import { makeStyles } from 'tss-react/mui';
import { blue } from '@mui/material/colors';
import small from '../../../img/small.svg';
import { useState } from 'react';

export const ChristmasOffer = ({
    link,
    onClose = () => console.log('on-close'),
    showToolbar = false,
    heading = '',
    maxWidth = 750,
    dontShowAgainOption = false,
}) => {
    const { classes } = useStyles();
    const [dontShowAgain, setDontShowAgain] = useState(false);

    const showSmallSize = useMediaQuery(`(max-width:${maxWidth}px)`);
    return (
        <Box className={classes.backgroundContainer}>
            <Box className={classes.popupContainer}>
                {showToolbar && (
                    <Toolbar variant="dense" disableGutters>
                        <Box
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <IconButton
                                size={showSmallSize ? 'small' : 'medium'}
                                color="secondary"
                                onClick={() => onClose(dontShowAgain)}
                            >
                                <CloseIcon
                                    fontSize={
                                        showSmallSize ? 'small' : 'default'
                                    }
                                    color="secondary"
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                )}
                <Box
                    style={{
                        position: 'relative',
                    }}
                    display="flex"
                    flexDirection={showSmallSize ? 'column' : 'row'}
                >
                    <Box width={imageWidth} minHeight={158}>
                        <img
                            src={imageUrl}
                            alt="popup"
                            className={`${classes.image} ${
                                showSmallSize ? classes.imageResponsive : ''
                            }`}
                        />
                        {/* <Box className={classes.image} /> */}
                    </Box>
                    <Box
                        pl={showSmallSize ? 0 : 5}
                        mx={showSmallSize ? 'auto' : 0}
                        width="80%"
                        display="flex"
                        flexDirection="column"
                    >
                        <Box>
                            <Typography
                                className={classes.heading}
                                variant="h5"
                                align="center"
                            >
                                GET CERTIFIED!!{' '}
                            </Typography>
                            <Typography
                                style={{
                                    width: '95%',
                                    margin: '0 auto',
                                }}
                                variant="subtitle2"
                                align="center"
                            >
                                Build finance domain skills & have a
                                certification to prove it.{' '}
                            </Typography>
                            <Typography
                                className={classes.secondHeading}
                                variant="h5"
                                align="center"
                            >
                                Only $100 (reduced from $240)
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                align="center"
                                gutterBottom
                            >
                                {heading}
                            </Typography>
                        </Box>
                        {/* coupon box */}
                        <Box
                            display="flex"
                            alignItems="center"
                            mx={'auto'}
                            mt={showSmallSize ? 1 : 1}
                        >
                            <Box
                                style={{
                                    backgroundImage: `url(${small})`,
                                    backgroundSize: 'cover',
                                    width: showSmallSize ? '25px' : '50px',
                                    height: showSmallSize ? '25px' : '50px',
                                    display: showSmallSize ? 'none' : 'block',
                                    zIndex: '4',
                                }}
                            />
                            <Box
                                className={`${classes.couponCode} ${
                                    showSmallSize
                                        ? classes.couponCodeResponsive
                                        : ''
                                }`}
                            >
                                <Typography
                                    variant="h6"
                                    className={
                                        showSmallSize
                                            ? classes.code
                                            : classes.codeLarge
                                    }
                                >
                                    Coupon Code:{' '}
                                    <strong>"BANKING-CERT2022"</strong>
                                </Typography>
                            </Box>
                        </Box>
                        {/* coupon box */}
                        <Box>
                            <Typography
                                variant={
                                    showSmallSize ? 'caption' : 'subtitle2'
                                }
                                component="div"
                                align="center"
                            >
                                (Offer ends on Mar 15, 2022)
                            </Typography>
                        </Box>
                        {dontShowAgainOption && (
                            <Box
                                justifyContent={'center'}
                                display="flex"
                                alignItems="center"
                            >
                                <Checkbox
                                    checked={dontShowAgain}
                                    required
                                    color="primary"
                                    onChange={(e) => {
                                        setDontShowAgain(e.target.checked);
                                    }}
                                />
                                <Typography>Don't show again</Typography>
                            </Box>
                        )}

                        <Box mt={showSmallSize ? 1 : 3} mx="auto">
                            <Button
                                size={showSmallSize ? 'small' : 'medium'}
                                variant="contained"
                                href={link}
                                color="secondary"
                            >
                                Get OFFER
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const imageSmallWidth = 120;
const imageWidth = 170;
const imageUrl =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2Ffbfs-popup-image.png?alt=media&token=03b36a4f-5e93-4bc8-8f1c-a8c8271984c5';

const useStyles = makeStyles()((theme) => ({
    backgroundContainer: {
        backgroundImage:
            'radial-gradient(circle, rgba(48,48,48,1) 0%, rgba(0,0,0,1) 100%)',
        color: '#fafafa',
    },
    popupContainer: {
        background: 'transparent',
        paddingBottom: 30,
        padding: 10,
        margin: 10,
        border: `4px solid #b62223`,
        borderRadius: '3px',
        zIndex: theme.zIndex.drawer + 100,
        // backgroundImage:
        //     'radial-gradient(circle, rgba(248,248,248,1) 0%, rgba(240,240,240,1) 100%)',
        right: 0,
    },
    image: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: 15,
        width: imageWidth,
        margin: '0 auto',
        height: 'auto',
        // backgroundImage: `url(${imageUrl})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
    },
    imageResponsive: {
        width: imageSmallWidth,
        left: '50%',
        transform: 'translateX(-50%)',
        // new
        top: '-5%',
    },
    headingResponsive: {
        fontSize: '1.3em',
    },
    heading: {
        fontWeight: 700,
        fontSize: '2em',
    },
    secondHeading: {
        fontWeight: 500,
        fontSize: '1.6em',
        color: blue[700],
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    couponCode: {
        padding: '3px 5px',
        backgroundColor: '#051E0E',
        position: 'relative',
        color: '#fafafa',
        zIndex: '1',
        '&:before': {
            content: '""',
            zIndex: '-1',
            borderRadius: '50% 0 0 50%',
            position: 'absolute',
            top: 0,
            left: '-30px',
            width: '30px',
            height: '100%',
            backgroundColor: '#051E0E',
        },
    },
    couponCodeResponsive: {
        fontSize: '1em',
        '&:before': {
            display: 'none',
        },
    },
    codeLarge: {
        fontSize: '1.2em',
    },
    code: {
        fontSize: '0.8em',
    },
}));
