//import { coursesSlice } from '../courses/coursesSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { useCurrentUser } from '../my-account/useCurrentUser';
import axios from 'axios';
import {
    /* getPurchasedCourseState,
    setPurchasedCourseState, */
    purchasedJourneysState,
} from './Recoil/State';

/* const { getCourses } = coursesSlice;

const getAllCourse = () => (state) => {
    const courses = getCourses(state);
    return courses;
}; */

export const useGetPurchasedJourneys = (user) => {
    //const course = useSelector(getAllCourse());
    //const { user:  user  } = useCurrentUser();
    const [purchasedJourneys, setPurchasedJourneys] = useRecoilState(
        purchasedJourneysState
    );
    const [loading, setIsLoading] = useState(true);
    //const setPurchasedJourneys = useSetRecoilState(purchasedJourneysState);
    //const [purchasedJourneys, setPurchasedJourneys] = useState([]);

    useEffect(() => {
        //if (purchasedJourneys.length === 0) {
        const getPurchasedJourneysState = async () => {
            const {
                data: { products },
            } = await axios.get(`/api/users/${user._id}/purchased-journeys`);
            //console.log(products);
            setPurchasedJourneys(products);
            setIsLoading(false);
        };
        if (user) {
            getPurchasedJourneysState();
        }
        //}
    }, [user]);

    return { purchasedJourneys, loading };
};
