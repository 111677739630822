import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Hidden,
    Typography,
} from '@mui/material';
import { ChristmasOffer } from './ChristmasOffer';
import {
    CATEGORY_AGILITY_URL,
    CATEGORY_BUSINESS_URL,
    CATEGORY_DATA_SCIENCE_URL,
    CATEGORY_CLOUD_URL,
    CATEGORY_CYBERSECURITY_URL,
    CATEGORY_FULL_STACK_URL,
    CATEGORY_FINANCE_URL,
} from '../../../img';
import { astronaut_id } from '../../../plans/PlanDetails';

export const MultipleOfferPage = ({
    description = [],
    terms = '',
    classes = {},
    showBundleOffers = false,
}) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Typography
                    className={classes.description}
                    variant="h4"
                    gutterBottom
                >
                    {description[0]}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant={classes.terms}>{terms}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box mb={5}>
                    <ChristmasOffer
                        maxWidth={1300}
                        heading="then save $5/month"
                        link={`/checkout?p_id=${astronaut_id}&t=0&coupon=VALENTINE2022`}
                    />
                </Box>
                <Typography
                    className={classes.description}
                    variant="h4"
                    gutterBottom
                >
                    {description[1]}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box mb={5}>
                    <ChristmasOffer
                        maxWidth={1300}
                        heading="then $99 for a year"
                        link={`/checkout?p_id=${astronaut_id}&t=1&coupon=VALENTINE2022`}
                    />
                </Box>
                <Typography
                    className={classes.description}
                    variant="h4"
                    gutterBottom
                >
                    {description[2]}
                </Typography>
            </Grid>
            <Hidden mdDown>
                <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                        With these monthly/annual subscriptions you'll get
                        access to:
                    </Typography>
                    <Box mb={4} />
                    <Grid container justifyContent="center">
                        {OFFERS.map((offer) => (
                            <Grid item xs={12} sm={4} md={3}>
                                <Box
                                    style={{
                                        width: 'fit-content',
                                        margin: '0 auto',
                                    }}
                                >
                                    <Box
                                        display={'flex'}
                                        maxWidth={150}
                                        flexDirection={'column'}
                                    >
                                        <Avatar
                                            src={offer.img}
                                            style={{
                                                margin: '20px auto',
                                                height: 150,
                                                width: 150,
                                            }}
                                        />
                                        <Box mt={2}>
                                            <Typography
                                                align="center"
                                                variant="subtitle2"
                                            >
                                                {offer.text}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Hidden>
            {showBundleOffers && (
                <Grid item xs={12}>
                    <Box my={5}>
                        <Typography variant="h4">
                            Offers on Individual Courses & Bundles
                        </Typography>
                    </Box>
                    <Divider />
                    <Box mt={2}>
                        <Typography variant="h6">
                            Additionally, if you wish to just purchase credits
                            and get going then you can redeem this offer where
                            you'll save 60% on the purchase of 300 credits.
                        </Typography>
                    </Box>
                    {/* <Box mt={2}>
                        <Typography gutterBottom variant="h6">
                            Actual price :
                            <span
                                style={{
                                    textDecoration: 'line-through',
                                }}
                            >
                                $240
                            </span>
                        </Typography>
                        <Typography gutterBottom variant="h6">
                            Offer price : $100
                        </Typography>
                    </Box> */}
                    <Grid container spacing={2} justifyContent="center">
                        {BUNDLES.map(
                            ({
                                name,
                                imgUrl,
                                actualPrice,
                                offerPrice,
                                url,
                            }) => (
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Card
                                        elevation={6}
                                        style={{
                                            marginTop: '20px',
                                        }}
                                    >
                                        <CardMedia
                                            src={imgUrl}
                                            component="img"
                                            height="140"
                                        />
                                        <CardContent>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                >
                                                    {name}
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle2"
                                                >
                                                    Actual price :
                                                    <span
                                                        style={{
                                                            textDecoration:
                                                                'line-through',
                                                        }}
                                                    >
                                                        ${actualPrice}
                                                    </span>
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle2"
                                                >
                                                    Offer price : ${offerPrice}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                href={url}
                                            >
                                                GET credits
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const OFFERS = [
    {
        img:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2FChristmas%20popup%2F700x700.png?alt=media&token=0467b617-4df8-470c-8f00-fbf63d12f188',
        text:
            'Top quality, job & role-specific micro-skills content and classes so you can easily navigate & get into a career and advance quickly.',
    },
    {
        img:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2FChristmas%20popup%2F700x700%20(1).png?alt=media&token=c049a1d3-e3aa-4192-8308-ac982333062c',
        text:
            ' Direct instructor access and live classes and real-time chat, including with peers across the globe. ',
    },
    {
        img:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2FChristmas%20popup%2F700x700%20(2).png?alt=media&token=72c0b51d-35ac-47bb-9546-c749adbaa027',
        text:
            'Community of experts to help you get through your learning journey to help you achieve your goals and much more.',
    },
];

const BUNDLES = [
    {
        imgUrl: CATEGORY_DATA_SCIENCE_URL,
        name: 'Data Science',
        actualPrice: 240,
        offerPrice: 100,
        url: '/credits/checkout?coupon=DS60&credits=300',
    },
    {
        imgUrl: CATEGORY_CLOUD_URL,
        name: 'Cloud',
        actualPrice: 240,
        offerPrice: 100,
        url: '/credits/checkout?coupon=CL60&credits=300',
    },
    {
        imgUrl: CATEGORY_FULL_STACK_URL,
        name: 'Full-Stack',
        actualPrice: 240,
        offerPrice: 100,
        url: '/credits/checkout?coupon=FS60&credits=300',
    },
    {
        imgUrl: CATEGORY_FINANCE_URL,
        name: 'Finance',
        actualPrice: 240,
        offerPrice: 100,
        url: '/credits/checkout?coupon=FI60&credits=300',
    },
    {
        imgUrl: CATEGORY_BUSINESS_URL,
        name: 'Business',
        actualPrice: 240,
        offerPrice: 100,
        url: '/credits/checkout?coupon=BU60&credits=300',
    },
    {
        imgUrl: CATEGORY_CYBERSECURITY_URL,
        name: 'Cybersecurity',
        actualPrice: 240,
        offerPrice: 100,
        url: '/credits/checkout?coupon=CS60&credits=300',
    },
    {
        imgUrl: CATEGORY_AGILITY_URL,
        name: 'Dev-ops & ßAgility',
        actualPrice: 240,
        offerPrice: 100,
        url: '/credits/checkout?coupon=DA60&credits=300',
    },
];
