import { atom } from 'recoil';
//import { astronaut_id } from '../plans/PlanDetails';

export const globalPopUpOpenState = atom({
    key: 'globalPopUpOpenState',
    default: false,
});

export const globalPopUpTitleState = atom({
    key: 'globalPopUpTitleState',
    default: '',
});

export const globalPopUpMessageState = atom({
    key: 'globalPopUpMessageState',
    default: '',
});

export const globalPopUpActionBtnState = atom({
    key: 'globalPopUpActionBtnState',
    default: null,
});

export const globalPopUpActionLinkState = atom({
    key: 'globalPopUpActionLinkState',
    //default: `/checkout?p_id=${astronaut_id}&t=0`,
    default: `/`,
});
