import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';
import { useCurrentUser } from '../my-account/useCurrentUser';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import NoDataImg from './assets/no_data_found.svg';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '80%',
            margin: 'auto',
            marginTop: 90,
            marginBottom: 40,
            minHeight: '50vh',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
            },
        },
        invitationContainer: {
            display: 'flex',
            gap: 10,
            padding: 10,
            marginBottom: 10,
        },
        invitationMsgWrapper: {
            display: 'flex',
            flexDirection: 'column',
        },
        invitationMsg: {
            fontWeight: 'bold',
        },
        viewBtn: {
            width: 'fit-content',
            marginTop: 5,
            textTransform: 'capitalize',
            backgroundColor: 'black',
            color: 'white',
            padding: 8,
            '&:hover': {
                backgroundColor: 'black',
            },
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paperModal: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            maxWidth: '500px',
            width: '100%',
            margin: 6,
            maxHeight: '90vh',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        permissionList: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            rowGap: 8,
            padding: 0,
            marginLeft: 18,
            maxWidth: '90%',
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: 'repeat(1,1fr)',
            },
        },
        addressContainer: {
            display: 'flex',
            gap: '3rem',
            maxWidth: '90%',
            [theme.breakpoints.down('md')]: {
                display: 'block',
            },
        },
        permissionTitle: {
            display: 'flex',
            gap: 5,
            alignItems: 'center',
        },
        btnGroup: {
            textAlign: 'right',
            marginTop: 6,
        },
        acceptBtn: {
            padding: '1rem',
            backgroundColor: 'black',
            color: 'white',
            border: '2px solid black',
            '&:hover': {
                backgroundColor: 'black',
            },
        },
        declineBtn: {
            padding: '1rem',
            backgroundColor: 'white',
            color: 'black',
            border: '2px solid black',
            marginRight: '1rem',
        },
        noPendingText: {
            color: '#929292',
            fontSize: '1.25rem',
            letterSpacing: '0.1rem',
        },
        modalHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        noPendingContainer: {
            marginTop: '10rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        },
    };
});

//no invitation component
const NoInvitations = () => {
    const { classes } = useStyles();
    return (
        <Box className={classes.noPendingContainer}>
            <img src={NoDataImg} alt="no data" height={200} width={200} />
            <Typography /* variant="p" */ className={classes.noPendingText}>
                No pending invitations.
            </Typography>
        </Box>
    );
};

//invitation list
const InvitationList = (props) => {
    const { classes } = useStyles();
    const {
        invitationData,
        setLoading,
        setAlert,
        loading,
        handleSetInvitation,
    } = props;
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <>
            <Paper className={classes.invitationContainer}>
                <div>
                    <InfoIcon />
                </div>
                <div className={classes.invitationMsgWrapper}>
                    <Typography
                        /* variant="p" */ className={classes.invitationMsg}
                    >
                        You have a pending co-instructor invitation for the
                        course: {invitationData.courseName}.
                    </Typography>
                    <Button
                        variant="contained"
                        className={classes.viewBtn}
                        size="small"
                        onClick={handleOpenModal}
                    >
                        View Invitation
                    </Button>
                </div>
            </Paper>
            <InvitationModal
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                invitationData={invitationData}
                setAlert={setAlert}
                setLoading={setLoading}
                loading={loading}
                handleSetInvitation={handleSetInvitation}
            />
        </>
    );
};

const InvitationModal = (props) => {
    const { classes } = useStyles();
    const {
        openModal,
        handleCloseModal,
        invitationData,
        setLoading,
        setAlert,
        loading,
        handleSetInvitation,
    } = props;

    const handleAcceptInvitation = async () => {
        try {
            setLoading(true);
            const payload = {
                invitationId: invitationData._id,
                status: true,
            };

            const axiosRes = await axios.patch(
                '/api/collaborators/change-invite-status',
                payload
            );

            setLoading(false);
            setAlert({
                message: axiosRes.data.message,
                severity: 'success',
                open: true,
            });
            handleSetInvitation(payload.invitationId);
        } catch (e) {
            const errorMsg = e?.response?.data?.message ?? e.message;
            setLoading(false);
            setAlert({
                message: errorMsg,
                severity: 'error',
                open: true,
            });
        }
    };

    const handleDeclineInvitation = async () => {
        try {
            setLoading(true);
            const payload = {
                invitationId: invitationData._id,
                status: false,
            };

            const axiosRes = await axios.patch(
                '/api/collaborators/change-invite-status',
                payload
            );

            setLoading(false);
            setAlert({
                message: axiosRes.data.message,
                severity: 'success',
                open: true,
            });
            handleSetInvitation(payload.invitationId);
        } catch (e) {
            const errorMsg = e?.response?.data?.message ?? e.message;
            setLoading(false);
            setAlert({
                message: errorMsg,
                severity: 'error',
                open: true,
            });
        }
    };

    const closeMoadl = () => {
        handleCloseModal();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <Fade in={openModal}>
                <div className={classes.paperModal}>
                    <div className={classes.modalHeader}>
                        <h2 id="transition-modal-title">
                            Co-instructor invitation
                        </h2>
                        <IconButton size="small" onClick={closeMoadl}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <h3 id="transition-modal-description">Course name</h3>
                    <Typography /* variant="p" */>
                        {invitationData.courseName}
                    </Typography>
                    <div className={classes.addressContainer}>
                        <div>
                            <h3>Owner of the course</h3>
                            <p>{invitationData.from.display_name}</p>
                        </div>
                        <div>
                            <h3>Invite sent by</h3>
                            <p>{invitationData.from.display_name}</p>
                        </div>
                    </div>
                    <div>
                        <h3 className={classes.permissionTitle}>
                            Your permissions <InfoIcon fontSize="small" />
                        </h3>
                        <ul className={classes.permissionList}>
                            {invitationData.visible && (
                                <li>Visible instructor</li>
                            )}
                            {invitationData.manage && <li>Manage course</li>}
                            {invitationData.reviews && <li>Reviews</li>}
                            {invitationData.revenueShare !== 0 && (
                                <li>
                                    Revenue Share: {invitationData.revenueShare}{' '}
                                    %
                                </li>
                            )}
                            {invitationData.qna && <li>Q &#38; A</li>}
                            {invitationData.performance && <li>Performance</li>}
                            {invitationData.assignments && <li>Assignments</li>}
                            {invitationData.captions && <li>Captions</li>}
                        </ul>
                        <div className={classes.btnGroup}>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.declineBtn}
                                onClick={handleDeclineInvitation}
                                disabled={loading}
                            >
                                Decline
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.acceptBtn}
                                onClick={handleAcceptInvitation}
                                disabled={loading}
                            >
                                Accept
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

//main component
const Invitations = () => {
    const { classes } = useStyles();
    const [invitations, setInvitations] = useState([]);
    const { user: userDetails } = useCurrentUser();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        message: '',
        severity: 'success',
        open: false,
    });

    useEffect(() => {
        if (userDetails) {
            const userId = userDetails._id;

            axios
                .get(`/api/collaborators/get-invitations?userId=${userId}`)
                .then((res) => {
                    setInvitations(res.data.invitations);
                })
                .catch((e) => console.log(e));
        }
    }, [userDetails]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ message: '', severity: 'success', open: false });
    };

    const handleSetInvitation = (invitationId) => {
        setInvitations((prev) => {
            return prev.filter((data) => data._id !== invitationId);
        });
    };

    return (
        <div className={classes.root}>
            {alert.message.length > 0 && (
                <Snackbar
                    open={alert.open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <div>
                        <Alert onClose={handleClose} severity={alert.severity}>
                            {alert.message}
                        </Alert>
                    </div>
                </Snackbar>
            )}
            {invitations.length === 0 && <NoInvitations />}
            {invitations.map((data) => {
                return (
                    <InvitationList
                        key={data._id}
                        invitationData={data}
                        setAlert={setAlert}
                        setLoading={setLoading}
                        loading={loading}
                        handleSetInvitation={handleSetInvitation}
                    />
                );
            })}
        </div>
    );
};

export default Invitations;
