import {
    adminUserProfilesRoute,
    // msgSuiteTemplateList,
    createMessageHomePageRoute,
    adminReportingRoute,
    adminEngagementReportingRoute,
    adminProfilerReportingRoute,
    certificationAllRoute,
    communityRoute,
    // coursesRoute,
    categoryAllCoursesRoute,
    // eventsRoute,
    faqsRoute,
    enterpriseRoute,
    howItWorksRoute,
    instructorOnboarding,
    journeyAllLandingRoute,
    liveLabsRoute,
    channelRoute,
    jobRolesRoute,
    /* moonshotsRoute, */
    onAirRoute,
    podcastRoute,
    retroCoursesRoute,
    techbytesRoute,
    testPrepAllLandingRoute,
    universityLandingPageRoute,
    instructorDashboardProfile,
    instructorDashboardProfilePublic,
    instructorChannelConfigurationRoute,
    instructorDashboardIntegrations,
    instructorDashboardAffliateProgram,
    instructorDashboardMSA,
    instructorDashboardContentCreateSelectType,
    instructorDashboardStreamNow,
    // instructorDashboardContentProducer,
    instructorDashboardContentCurate,
    // instructorDashboardContentPersonas,
    instructorDashboardContentBlogs,
    instructorDashboardNetwork,
    instructorDashboardMessaging,
    instructorDashboardAnnouncements,
    instructorDashboardReports,
    instructorScreenTests,
    instructorDashboardPayouts,
    instructorDashboardProgramManager,
    // instructorDashboardNetworkRoleManager,
    instructorDashboardNetworkClients,
    instructorDashboardNetworkFollowers,
    instructorDashboardNetworkInvitations,
    instructorDashboardReportsStudents,
    instructorDashboardReportsUsage,
    instructorDashboardReportsEngagement,
    instructorDashboardReportsFeedback,
    instructorDashboardAdminAndFinance,
    // instructorOpportunityManagementInstructors,
    instructorOpportunityManagementRequirements,
    instructorOpportunityManagementProjectRoute,
    instructorOpportunityManagementFeedRoute,
    adminUserSuspendedRoute,
    teachingCamp,
    instructorLearHow,
    adminsPending,
    adminInstructorsActive,
    adminInstructorsReports,
    adminInstructorsSubmissions,
    teamManagementRoute,
    adminOpportunityManagementRequirementsRoute,
    adminOpportunityManagementProjectsRoute,
    contentAdminRoute,
    contentCurationRoute,
    contentPersonasRoute,
    contentBlogsRoute,
    GuidanceRoute,
    LogisticsTemplateRoute,
    adminProgramManagementRoute,
    adminStardustReportsRoute,
    leaderboardRoute,
    adminMessagingRoute,
    // adminChannelRoute,
    adminOpportunityManagementAnalyticsRoute,
    instructorCourseOutline,
    ClientsPageActiveRoute,
    adminOpportunityManagementFeedRoute,
    ConstellationsRoute,
    collaboratorOpportunityManagementRoute,
    collaboratorOpportunityManagementAnalyticsRoute,
    collaboratorOpportunityManagementFeedRoute,
    instructorDashboardConnect,
    coursesManageRoute,
    onAirManageRoute,
    journeysManageRoute,
    jobRolesManageRoute,
} from './route-definitions';

import {
    analyticsContentExplorerRoute,
    analyticsContentTrackerRoute,
    analyticsKeywordExplorerRoute,
    analyticsTopicAnalyzerRoute,
    analyticsCompetitionExplorerRoute,
} from './route-definitions/analytics';

const removeParams = (navigationItem) => {
    const path = navigationItem.path;
    let newPath = '';
    let paramCheck = false;
    for (let i = 0; i < path.length; i++) {
        const char = path.charAt(i);
        if (!paramCheck) {
            if (char === ':') {
                newPath += 'new/';
                paramCheck = true;
                continue;
            }
            newPath += char;
        } else {
            if (char === '/') {
                paramCheck = false;
            }
        }
    }
    return { ...navigationItem, path: newPath };
};

export const navSections = [
    {
        name: 'DOING',
        items: [
            onAirRoute,
            // liveLabsRoute,
            channelRoute,
            jobRolesRoute,
            // eventsRoute,

            // challengesRoute,
            // datasetsRoute,
            /* moonshotsRoute, */
            // sandboxesRoute,
        ],
    },
    {
        name: 'LEARNING',
        items: [
            // coursesRoute,
            categoryAllCoursesRoute,
            certificationAllRoute,
            journeyAllLandingRoute,
            testPrepAllLandingRoute,
            // retroCoursesRoute,
        ],
    },

    {
        name: 'CONNECTING',
        items: [
            howItWorksRoute,
            communityRoute,
            // starSystemsRoute,
            // constellationsRoute,
            // meetTheGurusRoute,
            techbytesRoute,
            podcastRoute,
            leaderboardRoute,
        ],
    },
    {
        name: 'SUPPORT',
        items: [
            faqsRoute,
            enterpriseRoute,
            universityLandingPageRoute,
            instructorOnboarding,
        ],
    },
];

export const instructorNavSections = [
    {
        name: 'OPPORTUNITY MANAGEMENT',
        items: [
            // instructorOpportunityManagementInstructors,
            instructorOpportunityManagementRequirements,
            instructorOpportunityManagementFeedRoute,
            // instructorOpportunityManagementProjectRoute,
        ],
    },
    {
        name: 'ANALYTICS',
        items: [
            analyticsContentExplorerRoute,
            analyticsContentTrackerRoute,
            analyticsKeywordExplorerRoute,
            analyticsTopicAnalyzerRoute,
            analyticsCompetitionExplorerRoute,
        ],
    },
    {
        name: 'CONTENT PRODUCER',
        items: [
            instructorDashboardContentCreateSelectType,
            instructorDashboardStreamNow,
            // instructorDashboardContentProducer,
            instructorDashboardContentCurate,
            instructorCourseOutline,
            // instructorDashboardContentPersonas,
            instructorDashboardContentBlogs,
            ConstellationsRoute,
        ],
    },
    /*{
        name: 'NETWORK/COMMUNITY',
        items: [
            // instructorDashboardNetworkRoleManager,
            instructorDashboardNetworkClients,
            instructorDashboardNetworkFollowers,
            instructorDashboardNetworkInvitations,
        ],
    },*/
    {
        name: 'MESSAGING SUITE',
        items: [
            instructorDashboardMessaging,
            //instructorDashboardChatGroups,
            instructorDashboardAnnouncements,
        ],
    },
    {
        name: 'REPORTS',
        items: [
            instructorDashboardReportsStudents,
            instructorDashboardReportsUsage,
            instructorDashboardReportsEngagement,
            instructorDashboardReportsFeedback,
        ],
    },
    {
        name: 'SETTINGS',
        items: [
            // instructorDashboardProfile,
            instructorDashboardProfilePublic,
            instructorChannelConfigurationRoute,
            instructorScreenTests,
            instructorDashboardAdminAndFinance,
            // instructorDashboardPayouts,
            instructorDashboardIntegrations,
            instructorDashboardAffliateProgram,
            // instructorDashboardMSA,
        ],
    },
    // {
    //     name: 'Integrations',
    //     items: [instructorDashboardPayouts],
    // },
    {
        name: 'HELP & SUPPORT',
        items: [
            instructorLearHow,
            // instructorDashboardProgramManager,
            instructorDashboardConnect,
            teachingCamp,
        ],
    },
];

export const adminSections = [
    {
        name: 'OPPORTUNITY MANAGEMENT',
        items: [
            adminOpportunityManagementRequirementsRoute,
            // adminOpportunityManagementProjectsRoute,
            adminOpportunityManagementAnalyticsRoute,
            adminOpportunityManagementFeedRoute,
        ],
    },
    {
        name: 'ANALYTICS',
        items: [
            analyticsContentExplorerRoute,
            analyticsContentTrackerRoute,
            analyticsKeywordExplorerRoute,
            analyticsTopicAnalyzerRoute,
            analyticsCompetitionExplorerRoute,
        ],
    },
    {
        name: 'CONTENT MANAGEMENT',
        items: [
            //contentAssignAndManageRoute,
            // contentAdminRoute,
            // removeParams(contentCurationRoute),
            // contentPersonasRoute,
            coursesManageRoute,
            onAirManageRoute,
            journeysManageRoute,
            jobRolesManageRoute,
            ConstellationsRoute,
            contentBlogsRoute,
            GuidanceRoute,
            LogisticsTemplateRoute,
        ],
    },
    {
        name: 'INSTRUCTOR/SME MANAGEMENT',
        items: [
            adminInstructorsActive,
            // adminInstructorsReports,
            adminInstructorsSubmissions,
            // adminChannelRoute,
        ],
    },
    {
        name: 'USER & TEAM MANAGEMENT',
        items: [
            adminUserProfilesRoute,
            adminUserSuspendedRoute,
            teamManagementRoute,
            adminsPending,
        ],
    },
    {
        name: 'CLIENT MANAGEMENT',
        items: [ClientsPageActiveRoute],
    },
    {
        name: 'MESSAGING SUITE',
        items: [
            createMessageHomePageRoute,
            // msgSuiteTemplateList,
            adminMessagingRoute,
        ],
    },
    {
        name: 'REPORTS',
        items: [
            adminReportingRoute,
            adminEngagementReportingRoute,
            adminProfilerReportingRoute,
            adminStardustReportsRoute,
        ],
    },
    // {
    //     name: 'Settings',
    //     items: [],
    // },
    {
        name: 'HELP AND SUPPORT',
        items: [adminProgramManagementRoute, communityRoute],
    },
];

export const roleNavSection = (routeRole) => [
    {
        name: 'OPPORTUNITY MANAGEMENT',
        items: [
            collaboratorOpportunityManagementRoute,
            collaboratorOpportunityManagementAnalyticsRoute,
            collaboratorOpportunityManagementFeedRoute,
        ],
    },

    {
        name: 'HELP AND SUPPORT',
        items: [communityRoute],
    },
];

export const instructorNavSectionsOld = [
    //instructorDashboardHome,
    instructorDashboardProfile,
    // instructorDashboardContentProducer,
    instructorDashboardNetwork,
    instructorDashboardMessaging,
    instructorDashboardReports,
    instructorDashboardPayouts,
    instructorDashboardProgramManager,
];
