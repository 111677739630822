import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useUser } from '../auth/useUser';
import {
    getPrivateCourseState,
    setPrivateCourseState,
} from './Recoil/Selector';
import axios from 'axios';
import { privateEmailDomainsAndBundleNames } from '../courses';

export const useGetPrivateClasses = (user, showCourses = false) => {
    const { user: firebaseUser } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const privateCourse = useRecoilValue(getPrivateCourseState);
    const setPrivateCourse = useSetRecoilState(setPrivateCourseState);

    const getPrivateCourse = useCallback(
        async (domain) => {
            if (firebaseUser) {
                setIsLoading(true);
                try {
                    const { data } = await axios.get(
                        `/api/content/courses/private-bundle?bundleName=${privateEmailDomainsAndBundleNames[domain]}`
                    );
                    setPrivateCourse(data);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
            }
        },
        [setPrivateCourse]
    );

    useEffect(() => {
        if (user) {
            setIsLoading(true);
            const domain = user.user_email.split('@').pop();
            if (
                Object.keys(privateEmailDomainsAndBundleNames).includes(domain)
            ) {
                getPrivateCourse(domain);
            }
            setIsLoading(false);
        }
    }, [user, getPrivateCourse, setPrivateCourse]);

    const hasEnrolledCourses = useMemo(() => {
        return user?.user_save.length > 0;
    }, [user]);

    return {
        privateCourse: hasEnrolledCourses || showCourses ? privateCourse : [],
        isLoading,
    };
};
