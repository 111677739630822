import { useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import axios from 'axios';
import { Box, Dialog, Grid, Tooltip, Typography } from '../ui';
import { getUser } from '../my-account/Recoil/Selector';
import { mobileOtpVerificationDialogOpenState } from './recoil';
//import { useStyles } from './Styles';
import { MobileOtpVerification } from '../verify-mobile-number/MobileOtpVerification';
import { makeStyles } from 'tss-react/mui';

import { useImageDimensions } from '../landing-page/useImageDimensions';

import logo from '../starweaver-logo-v2.png';
import road from '../img/road.svg';

const useStyles = makeStyles()((theme) => ({
    root: {
        paddingTop: 60,
        paddingLeft: 100,
        [theme.breakpoints.only('md')]: {
            paddingLeft: 50,
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: 20,
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
        },
    },

    bannerContainer: {
        minHeight: '600px',
        // width: '100%',

        [theme.breakpoints.only('md')]: {
            minHeight: '500px',
        },
        [theme.breakpoints.only('sm')]: {
            minHeight: '300px',
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: '400px',
        },
    },

    bannerImageContainer: {
        //width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            display: 'none',
        },

        // backgroundColor: '#c5c5c5',ß
        overflow: 'hidden',
    },
    bannerContent: {
        justifyContent: 'center',
        height: '100%',
    },
    road: {
        //...positionAbsoluteBoilerPlate,
        position: 'absolute',
        //content: '""',
        //width: '100%',
        height: '100%',
        backgroundImage: `url(${road})`,
        top: 0,
        right: 0,
        //zIndex: '-2',
    },

    // tooltip: {
    //     '&:hover': {
    //         '&$marker1': {
    //             transform: 'scale(1.2)',
    //             animation:
    //                 '$bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) infinite',
    //         },
    //     },
    // },
    marker1: {
        position: 'absolute',
        height: '81px',
        width: '50px',
        backgroundRepeat: 'no-repeat',
        filter: 'drop-shadow(0px 15px 6px black)',
        //zIndex: '0',
    },
}));

export const MobileOtpVerificationDialog = () => {
    //const [loading, setLoading] = useState(false);
    const { classes } = useStyles();

    const {
        containerRef,
        imageWidth,
        positionMarkersOnWidth,
    } = useImageDimensions();

    const user = useRecoilValue(getUser);

    const [open, setOpen] = useRecoilState(
        mobileOtpVerificationDialogOpenState
    );

    const [mobileNumber, setMobileNumber] = useState(user?.user_phone);
    const noMobileNumber = !user?.user_phone;
    const initialMobileVerificationStatus = noMobileNumber ? '' : 'approved';
    const [mobileVerificationStatus, setMobileVerificationStatus] = useState(
        '' //  initialMobileVerificationStatus
    );

    const onClose = () => {
        setOpen(false);
    };

    const savePhone = async (phone) => {
        try {
            await axios.put(`/api/users/phone/${user._id}`, {
                user_phone: phone,
            });
            onClose();
        } catch (error) {
            console.log({ error });
        }
    };

    return (
        <Dialog
            //fullWidth
            fullScreen
            disableScrollLock
            // disableBackdropClick={true}
            open={open}
            onClose={onClose}
        >
            <Box className={classes.root}>
                <Box className={classes.logo}>
                    <img src={logo} alt="Starweaver logo" width={200} />
                </Box>

                <Grid className={classes.bannerContainer} container>
                    <Grid item xs={12} sm={6} md={6} lg={5}>
                        <Box
                            //pl={3}
                            display="flex"
                            flexDirection="column"
                            className={classes.bannerContent}
                        >
                            <MobileOtpVerification
                                mobile_number={mobileNumber}
                                setMobileNumber={setMobileNumber}
                                mobileVerificationStatus={
                                    mobileVerificationStatus
                                }
                                setMobileVerificationStatus={
                                    setMobileVerificationStatus
                                }
                                savePhone={savePhone}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={7}>
                        <Box className={classes.bannerImageContainer}>
                            <Box
                                ref={containerRef}
                                style={{ width: imageWidth }}
                                className={classes.road}
                            />
                            {positionMarkersOnWidth.map(
                                ({ markerPosition, data }, index) => (
                                    <Box
                                        style={{
                                            ...markerPosition,
                                        }}
                                        component="a"
                                        className={classes.marker1}
                                    />
                                )
                            )}
                               
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};
