import { Dialog } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { useCurrentUser } from '../../my-account/useCurrentUser';
import { offers } from '../../util/offerDetails';
import { useRecoilState } from 'recoil';
import {
    offerPopUpPermanentClosedState,
    offerTimeoutTimeState,
} from '../../billing/recoil';
import { ChristmasOffer } from './offers/';
import moment from 'moment';

const offersUrl = offers.map((offer) => `/sale/${offer.url}`);
const offersUrlWithSlash = offers.map((offer) => `/sale/${offer.url}/`);

const excludeFromPages = [
    '/login',
    '/signup',
    '/checkout',
    '/credits/checkout',
    '/student-onboarding',
    '/checkout/success',
    '/checkout/success/',
    '/checkout/canceled',
    ...offersUrl,
    ...offersUrlWithSlash,
];
const offerPageUrl = '/sale/banking-cert2022/';

export const DialogOfferPopup = ({ hasVisitedFirstTime = false }) => {
    const [show, setShow] = useState(false);
    const { user } = useCurrentUser();

    const [isPermanentlyClosed, setIsPermanentlyClosed] = useRecoilState(
        offerPopUpPermanentClosedState
    );

    const [timeoutTime, setTimeoutTime] = useRecoilState(offerTimeoutTimeState);

    const openPopUp = useCallback(() => {
        if (!user || user?.user_subscription_id === '') {
            const savedTime = localStorage.getItem('closePermanently');
            const toShow = savedTime
                ? moment().isAfter(moment(savedTime))
                : true;
            const doNotOpen = excludeFromPages.includes(
                window.location.pathname
            );
            if (
                toShow &&
                !show &&
                !hasVisitedFirstTime &&
                !doNotOpen &&
                !isPermanentlyClosed
            ) {
                setShow(true);
            }
        } else {
            console.log('sub');
        }
    }, [user, hasVisitedFirstTime, show, isPermanentlyClosed]);

    useEffect(() => {
        const openTimeout = setTimeout(() => openPopUp(), timeoutTime);
        return () => {
            clearTimeout(openTimeout);
        };
    }, [openPopUp, timeoutTime]);

    const onClose = (dontShowAgain = false) => {
        setShow(false);
        const newTime = timeoutTime + 4 * 60 * 1000;
        setTimeoutTime(newTime);
        if (dontShowAgain) {
            console.log(`don't show again`);
            setIsPermanentlyClosed(true);
            localStorage.setItem('closePermanently', moment().add(1, 'day'));
        }
    };

    return (
        <Dialog open={show} onClose={onClose} /* disableBackdropClick */>
            <ChristmasOffer
                link={offerPageUrl}
                showToolbar={true}
                onClose={onClose}
                dontShowAgainOption={true}
            />
        </Dialog>
    );
};
