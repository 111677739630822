import { useStyles } from './Styles';
import { useState, useEffect } from 'react';
import { useUser } from '../auth/useUser';
import { userTrack } from '../util/tracking';
import { useCurrentUser } from '../my-account/useCurrentUser';
import {
    Box,
    Button,
    CircularProgress,
    CustomSnackbar,
    Tooltip,
    Typography,
} from '../ui';
import { useNavigate } from 'react-router-dom';
import { LoginModal } from '../auth/LoginModal';
import { EnrollDialog } from './EnrollDialog';
import { useGetPurchasedCourse } from '../dashboard';
import { useGetAllCourseIdsInUserJourneys } from '../journeys';
import axios from 'axios';
import { saveRedirectDestination } from '../billing/recoil';
import { useSetRecoilState } from 'recoil';
import { SubscribeDialog } from './SubscribeDialog';
import moment from 'moment';
import { EnrollFreePopupInst } from './EnrollFreePopupInst';
import { PlayArrow } from '@mui/icons-material';
import { alertState, severity } from '../app/recoil';

function AccessContainer({
    course,
    courseId,
    domain,
    time,
    credits,
    courseTag,
    showAccessBtn = false,
}) {
    const { classes } = useStyles();
    const setAlert = useSetRecoilState(alertState);
    const [isLoading, setIsLoading] = useState(false);
    const saveDestination = useSetRecoilState(saveRedirectDestination);
    let navigate = useNavigate();
    const { user: userDetails } = useCurrentUser();
    const { user } = useUser();
    const [openEnroll, setOpenEnroll] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [savedCourse, setSavedCourse] = useState([]);
    const [message, setMessage] = useState('');
    const [accessTip, setAccessTip] = useState('');

    const { purchasedCourses } = useGetPurchasedCourse();
    const [openTrialUserDialog, setOpenTrialUserDialog] = useState(false);
    var today = new Date();
    // tomorrow.setDate(tomorrow.getDate() + 1);
    const currentDate = moment(today).format('MMM D');

    const [isEnrollPopupOpen, setisEnrollPopupOpen] = useState(false);

    const {
        onDemandCourseIds: onDemandCourseIdsInUserJourneys,
        isLoading: isLoadingCourseIdsInUserJourneys,
    } = useGetAllCourseIdsInUserJourneys(userDetails);
    //checking is it test prep or not
    const isTestPrep = () => {
        if (course.collection_ids && course.collection_ids.includes(636641)) {
            return true;
        }
        return false;
    };

    //console.log(isTestPrep());
    //console.log(course.collection_ids);

    const onCloseTrialUserDialog = () => {
        setOpenTrialUserDialog(false);
    };

    const goToCourse = async (slug) => {
        if (courseTag === 'UPCOMING') return;
        try {
            setIsLoading(true);
            const authtoken = await user.getIdToken();
            //console.log(authtoken);
            await axios.post(`/api/users/thinkific/enroll`, {
                courseId,
                userId: userDetails.user_thinkific_id,
            });

            const response = await axios.get(
                `/api/sso/${encodeURIComponent(slug)}/thinkific?clientId=${
                    userDetails.client_id
                }`,
                {
                    headers: {
                        authtoken,
                        firstName: userDetails.user_firstName,
                        lastName: userDetails.user_lastName,
                    },
                }
            );
            const ssoUrl = response.data;
            window.open(ssoUrl, '_blank');
            setIsLoading(false);
        } catch (e) {
            console.log(e.message);
            setIsLoading(false);
            console.log(e);
        }
    };

    const onSaveCourse = async (courseProductID) => {
        if (savedCourse.includes(courseProductID)) {
            /* try {
                const response = await axios.put(
                    `api/users/saveUnSaveCourse/${userDetails._id}`,
                    { id: courseProductID, action: 'unsave' }
                );
                const unSavedCourses = response.data.user.user_save;
                const UniUnSavedCourses = [...new Set(unSavedCourses)];

                setSavedCourse(UniUnSavedCourses);
            } catch (err) {
                alert(err.message);
            } */
        } else {
            try {
                setIsLoading(true);
                const newCourseObject = { ...course, domain, time };
                const response = await axios.put(
                    `/api/users/saveUnSaveCourse/${userDetails._id}`,
                    {
                        id: courseProductID,
                        action: 'save',
                        course: newCourseObject,
                        isTestPrep: isTestPrep(),
                    }
                );
                const savedCourses = response.data.user.user_save;
                const UniSavedCourses = [...new Set(savedCourses)];
                const message = `You are enrolled for ${
                    course?.name ?? course?.title
                }`;
                /* setMessage(
                    `You are enrolled for ${course?.name ?? course?.title}`
                ); */
                setAlert({ show: true, message, severity: severity.SUCCESS });
                setSavedCourse(UniSavedCourses);
                trackThisCourse();
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                alert(err.message);
            }
        }
    };

    const trackThisCourse = () => {
        const trackingObject = {
            is_user_registered: localStorage.getItem('userId') ? true : false,
            user: localStorage.getItem('userId'),
            page_slug: window.location.pathname,
            activity_type: 'CLICK',
            activity_value: course?.name ?? course?.title,
            course_id: course.id,
        };
        userTrack('click-enroll-on-demand', trackingObject);
    };

    const purchasedCourseCheck = (id) => {
        const isCoursePresent = purchasedCourses.filter(
            (item) => item.id === id
        );
        if (isCoursePresent.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const isCourseInUserJourneys = onDemandCourseIdsInUserJourneys.find(
        (id) => courseId == id
    )
        ? true
        : false;

    // const isCourseInUserJourneysCheck = (courseId) => {
    //     const foundCourseId = onDemandCourseIdsInUserJourneys.find(
    //         (id) => courseId == id
    //     );
    //     if (foundCourseId) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // };

    useEffect(() => {
        if (userDetails) {
            setSavedCourse(userDetails.user_save || []);

            if (
                userDetails.user_subscription_id === '' &&
                savedCourse.includes(course.id)
            )
                setAccessTip(
                    'You have not lost tracking of your progress and need to upgrade to continue.'
                );
        }
    }, [userDetails]);

    const handleAccess = async () => {
        if (
            isTestPrep() &&
            userDetails?.user_subscription_status === 'trialing'
        ) {
            setOpenTrialUserDialog(true);
            return;
        }

        if (isCourseInUserJourneys) {
            goToCourse(course.slug);
            return;
        }

        const isPurchased = purchasedCourseCheck(course.id);
        if (userDetails.user_subscription_id === '') {
            //Simply go to pricing page in go in Nextjs.
            if (isPurchased) {
                goToCourse(course.slug);
            } else {
                setOpenEnroll(true);
            }
        } else {
            if (!savedCourse.includes(course.id)) {
                if (isPurchased) {
                    goToCourse(course.slug);
                } else {
                    await onSaveCourse(course.id);
                    goToCourse(course.slug);
                }
            } else {
                goToCourse(course.slug);
            }
        }
    };

    const onLoginModalClose = () => {
        setIsLoginModalOpen(false);
    };

    const handleSubscribe = () => {
        setisEnrollPopupOpen(false);
        saveDestination(window.location.pathname);
        if (userDetails) {
            navigate('/pricing');
        } else {
            setisEnrollPopupOpen(true);
        }
        //window.open('/pricing', '_blank');
        //  navigate(('/pricing'));
    };

    const handleGetClass = () => {
        if (isCourseInUserJourneys) {
            goToCourse(course.slug);
            return;
        }

        if (purchasedCourseCheck(course.id)) {
            goToCourse(course.slug);
            return;
        }
        if (user) {
            saveDestination(window.location.pathname);
            setOpenEnroll(true);
        } else {
            saveDestination(window.location.pathname);
            //window.open('/credits/checkout', '_blank');
            navigate(`/credits/checkout?credits=${credits || 10}`);
        }
    };

    return (
        <>
            {isLoading || isLoadingCourseIdsInUserJourneys ? (
                <Box display="grid" justifyContent="center">
                    <CircularProgress color="secondary" size="1.9em" />
                </Box>
            ) : userDetails && userDetails.user_subscription_id !== '' ? (
                <Box display="grid" justifyContent="center">
                    <Tooltip
                        title={
                            courseTag === 'UPCOMING'
                                ? 'This is an upcoming course'
                                : savedCourse.includes(course.id)
                                ? 'Click to start course'
                                : ''
                        }
                    >
                        {purchasedCourseCheck(course.id) ||
                        savedCourse.includes(course.id) ||
                        isCourseInUserJourneys ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.Button1}
                                disabled={isLoading || courseTag === 'UPCOMING'}
                                onClick={handleAccess}
                                endIcon={
                                    courseTag !== 'UPCOMING' && <PlayArrow />
                                }
                                style={{ minWidth: '10.375rem' }}
                            >
                                {courseTag === 'UPCOMING'
                                    ? 'UPCOMING'
                                    : 'LAUNCH'}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.Button1}
                                disabled={isLoading}
                                onClick={handleAccess}
                                style={{ minWidth: '10.375rem' }}
                            >
                                ENROLL
                            </Button>
                        )}
                    </Tooltip>
                </Box>
            ) : !userDetails ? (
                <Box className={classes.notSubscribedContainer}>
                    <Box
                        ml={8}
                        className={classes.enrollSubscribe}
                        display="flex"
                        flexDirection="column"
                    >
                        <div
                            className={classes.enrollFreeTxt}
                            onClick={handleSubscribe}
                        >
                            {showAccessBtn ? 'Enroll Now' : 'Enroll for Free'}
                            {!showAccessBtn && (
                                <div className={classes.btnDate}>
                                    Starts {currentDate}
                                </div>
                            )}
                        </div>
                    </Box>
                </Box>
            ) : (
                <Box className={classes.notSubscribedContainer}>
                    <Box
                        className={classes.getClass}
                        display="flex"
                        flexDirection="column"
                    >
                        <Button
                            onClick={handleGetClass}
                            variant="contained"
                            color="secondary"
                            className={classes.accessBtn}
                            style={{ minWidth: '10.375rem' }}
                            endIcon={courseTag !== 'UPCOMING' && <PlayArrow />}
                        >
                            {purchasedCourseCheck(course.id) ||
                            isCourseInUserJourneys
                                ? 'Launch'
                                : 'Get class'}
                        </Button>
                        {!purchasedCourseCheck(course.id) &&
                            !savedCourse.includes(course.id) &&
                            !isCourseInUserJourneys && (
                                <Typography
                                    className={classes.subtitleAccessContainer}
                                    style={{ color: 'white' }}
                                >
                                    {/* need to make it dynamic */}
                                    {credits} credits
                                </Typography>
                            )}
                    </Box>
                    <Box
                        ml={3}
                        className={classes.subscribe}
                        display="flex"
                        flexDirection="column"
                    >
                        <Button
                            onClick={handleSubscribe}
                            variant="contained"
                            color="secondary"
                            className={classes.accessBtn}
                            style={{ minWidth: '10.375rem' }}
                        >
                            Start My Free 5 Day Trial
                        </Button>
                        <div
                            className={classes.subtitleAccessContainer}
                            style={{ color: 'white' }}
                        >
                            +1000s hours <br /> immersive education
                        </div>
                    </Box>
                </Box>
            )}
            <CustomSnackbar
                type="success"
                message={message}
                setMessage={setMessage}
            />
            <LoginModal
                open={isLoginModalOpen}
                onRequestClose={onLoginModalClose}
                signUpLink="/pricing"
            />
            <SubscribeDialog
                onClose={onCloseTrialUserDialog}
                open={openTrialUserDialog}
                //handleRedirect={goToCourse}
                //slug={course.slug}
            />
            {!userDetails && isEnrollPopupOpen && (
                <EnrollFreePopupInst
                    open={isEnrollPopupOpen}
                    onRequestClose={() => {
                        setisEnrollPopupOpen(false);
                    }}
                    getClass={handleGetClass}
                    courseCost={credits}
                />
            )}
            <EnrollDialog
                open={openEnroll}
                setOpen={setOpenEnroll}
                track={trackThisCourse}
                course={course}
                user={userDetails}
                courseCost={credits}
            />
        </>
    );
}

export default AccessContainer;
