import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useUser } from '../auth/useUser';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { userState, startdustState } from './Recoil/State';
// import { MANAGER } from '../auth/roles';
//import { useNavigate } from 'react-router-dom';

// if no users is present make a call else, give the user
// it reduces the call time
export const useCurrentUser = () => {
    // const [fetchedUser, setFetchedUser] = useState();
    const [user, setUser] = useRecoilState(userState);
    // const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingStardust, setLoadingStardust] = useState(false);
    const [stardustDetails, setStardustDetails] = useRecoilState(
        startdustState
    );
    const { user: firebaseUser, isLoading: isFirebaseUserLoading } = useUser();
    const fetchingStardust = useRef(false);

    // const userRoles = new Set(user?.roles);

    const userRoles = useMemo(() => new Set(user?.roles), [user]);

    // const isManager = useMemo(() => userRoles.has(MANAGER), [userRoles]);

    //let navigate = useNavigate();

    // useEffect(() => {
    //     const isProd = process.env.REACT_APP_ENVIRONMENT !== 'development';

    //     if (isProd && user.user_phone === '') {
    //         navigate(('/verify-mobile'));
    //     }
    // }, [user, navigate]);

    // const fetchStardustDetails = useCallback(async (userId) => {
    //     setLoadingStardust(true);
    //     const { data } = await axios.get(`/api/users/stardust/${userId}`);
    //     setStardustDetails(data.stardust);
    //     setLoadingStardust(false);
    // }, []);

    useEffect(() => {
        let cancel = () => {};
        (async () => {
            // console.log( !fetchingStardust.current && user && stardustDetails.userId != user._id);
            if (
                !fetchingStardust.current &&
                user &&
                stardustDetails.userId != user._id
            ) {
                // fetchStardustDetails(user._id);
                fetchingStardust.current = true;
                setLoadingStardust(true);
                const { data } = await axios.get(
                    `/api/users/stardust/${user._id}?testquery=2`,
                    {
                        cancelToken: new axios.CancelToken((c) => (cancel = c)),
                    }
                );
                setStardustDetails(data.stardust);
                setLoadingStardust(false);
                fetchingStardust.current = false;
            }
            return () => cancel();
        })();
    }, [/* fetchStardustDetails, */ user]);

    useEffect(() => {
        let cancel = () => {};

        (async () => {
            if (isFirebaseUserLoading) return;
            if (!user && firebaseUser) {
                setIsLoading(true);
                const res = await axios.get(
                    `/api/users/${firebaseUser.email}?updateLastAccessed=true&testquery=1`,
                    { cancelToken: new axios.CancelToken((c) => (cancel = c)) }
                );
                // .then((res) => {

                // })
                // .catch((error) => console.log(error));
                // setFetchedUser(res.data.user);
                setUser(res.data.user);
                setIsLoading(false);
            } else {
                if (!firebaseUser) {
                    // setFetchedUser(null);
                    setUser(null);
                } else {
                    // setFetchedUser(user);
                }
                setIsLoading(false);
            }
            window.localStorage.setItem(
                'userId',
                firebaseUser && user ? user._id : null
            );
        })();

        return () => cancel();
    }, [/* user, */ firebaseUser, isFirebaseUserLoading /* , setUser */]);

    return {
        user /* : fetchedUser */,
        setUser /* : () => {} */,
        isLoading,
        stardustDetails,
        loadingStardust,
        userRoles,
        // isManager,
    };
};
