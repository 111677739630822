import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { CustomInput } from './MultipleSelectCheckbox';

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        // minWidth: 120,
        // maxWidth: 300,
    },
    label: {
        zIndex: 1000,
        cursor: 'pointer',
        marginLeft: 10,
        marginTop: -10,
        padding: 3,
        backgroundColor: '#fafafa',
        // '&:focus': {
        //     color: theme.palette.secondary.main,
        // },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};

export function MultipleSelectChip({
    label,
    options,
    selection,
    setSelection,
}) {
    const { classes } = useStyles();
    const theme = useTheme();

    const selectRef = useRef();

    const handleChange = (event) => {
        setSelection(event.target.value);
    };

    return (
        <div>
            <FormControl className={classes.formControl}>
                {label && (
                    <InputLabel
                        className={classes.label}
                        style={{
                            color: '#424242',
                        }}
                        onClick={() => {
                            selectRef.current?.click();
                        }}
                    >
                        {label}
                    </InputLabel>
                )}

                <Select
                    // labelId="demo-mutiple-chip-label"
                    // id="demo-mutiple-chip"
                    multiple
                    value={selection}
                    onChange={handleChange}
                    input={
                        <CustomInput
                        /* id="select-multiple-chip" */
                        />
                    }
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip
                                    key={value}
                                    label={value}
                                    className={classes.chip}
                                />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                    variant="outlined"
                    color="secondary"
                    ref={selectRef}
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox
                                checked={selection.indexOf(option) !== -1}
                            />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
