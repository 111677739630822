import { Navigate, Route, useLocation } from 'react-router-dom';
import pathMatch from 'path-match';
import { useUser } from '../auth';
import { CircularProgress } from '../ui';

const route = pathMatch({
    sensitive: false,
    strict: false,
    end: false,
});

const ignorePaths = ['/login', '/signup'];
const shouldRedirectAfterAuth = (pathname) =>
    !ignorePaths.some((path) => {
        const matches = route(path);
        return matches(pathname);
    });

export const PrivateRoute = ({ children }) => {
    const { isLoading, user } = useUser();
    const location = useLocation();
    const { pathname, search } = location;
    return isLoading ? (
        // IT JUST CENTERED THE LOADER NO NEED TO HAVE A SEPRATE CLASS SO I HAVE USE INLINE STYLE
        <div
            style={{
                height: '100vh',
                display: 'grid',
                placeItems: 'center',
            }}
        >
            <CircularProgress color="secondary" />
        </div>
    ) : user ? (
        <>{children}</>
    ) : shouldRedirectAfterAuth(pathname) ? (
        pathname === 'reset-password' ? (
            <Navigate to="reset-password" replace />
        ) : (
            <Navigate
                to={`/login?dest=${encodeURI(pathname + search)}`}
                replace
            />
        )
    ) : (
        <Navigate to="/login" replace />
    );
};

export default PrivateRoute;
