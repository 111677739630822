import { TextField } from '..';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from 'tss-react/mui';
// LATER MOVE IT HERE
import { COUNTRY_CODES as countries } from '../../my-account/utils';

// ISO 3166-1 alpha-2

const useStyles = makeStyles()((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    input: {
        WebkitAppearance: 'none',
        margin: '0',
    },
}));

export const CountryCodeSelect = ({ selected, setSelected }) => {
    const { classes } = useStyles();
    const defaultOption = countries.filter(
        (country) => country.label === selected
    );

    return (
        <Autocomplete
            id="country-select-demo"
            style={{ width: 150 }}
            options={countries}
            autoComplete="country"
            onChange={(e, option) => {
                option && setSelected(option.phone);
            }}
            value={selected !== '' ? defaultOption[0] : null}
            classes={{
                option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.phone}
            renderOption={(option) => (
                <>
                    {/* <span>{countryToFlag(option.code)}</span> */}
                    {option.label} +{option.phone}
                </>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Country Code"
                    autoComplete="country"
                    inputProps={{
                        ...params.inputProps,
                        className: classes.input,
                        autoComplete: 'country', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};
