import {
    PeopleAltIcon,
    BuildIcon,
    CastForEducationIcon,
    DynamicFeedIcon,
    CardMembershipIcon,
    DeviceHubIcon,
    DeveloperModeIcon,
    // RepeatIcon,
    // SettingsInputAntennaIcon,
    CloudIcon,
    DescriptionIcon,
    AssignmentIcon,
} from '../icons';

export const features = [
    {
        CardIcon: PeopleAltIcon,
        title: 'Data Science',
        body:
            'Machine Learning, Data Visualization, Classification, Naïve Bayes, Big Data Analytics, and much more…',
        linkTo: `/search?q=Data science`,
    },
    {
        CardIcon: CloudIcon,
        title: 'Cloud',
        body:
            'AWS, Azure, Firebase/Google Cloud, On-Prem-to-Cloud Migration, Microservices, IoT',
        linkTo: `/search?q=Cloud`,
    },
    {
        CardIcon: DeveloperModeIcon,
        title: 'Full Stack',
        body:
            'UI/UX, MongoDB, Test Driven Development, Git, Django, NodeJS, and much more…',
        linkTo: `/search?q=Full stack`,
    },
    {
        CardIcon: DeviceHubIcon,
        title: 'Agility & Stability',
        body:
            'Scrum, DevOps, ITIL, TOGAF, Scaled Agile Framework, and much more…',
        linkTo: `/search?q=Agility`,
    },
    {
        CardIcon: AssignmentIcon,
        title: 'Finance',
        body:
            'Banking, Risk, Credit Analysis, Python and Finance, Data Science and Finance, Real Estate, Mortgages, Yield Curves',
        linkTo: `/search?q=Finance`,
    },
    {
        CardIcon: AssignmentIcon,
        title: 'Business',
        body:
            'Sales, Customer Support, Strategy, Leadership, Team Work, Team Building, Life Skills, Writing Skills, Planning and Time Management',
        linkTo: `/search?q=Business`,
    },
];
export const pros = [
    {
        CardIcon: DescriptionIcon,
        title: 'Competency & Job-role based',
        body: 'Tap our database of knowledge, skills and behaviors.',
        linkTo: '/category/all/courses',
    },
    // {
    //     CardIcon: SettingsInputAntennaIcon,
    //     title: 'Social (peer-to-peer) learning',
    //     body: 'Get inspired through peer "Inspiration" posts.',
    // },
    // {
    //     CardIcon: RepeatIcon,
    //     title: 'Atomic habit formation',
    //     body:
    //         'Small, meaningful and measurable habit changes work to improve your everyday performance.',
    // },
    {
        CardIcon: CastForEducationIcon,
        title: 'Live Instruction',
        body: 'Learn from world-class experts and instructors.',
        linkTo: '/on-air',
    },
    {
        CardIcon: PeopleAltIcon,
        title: 'Live Team Channels',
        body: 'Communicate through discussion forums',
        linkTo: 'https://support.starweaver.com/portal/en/home',
    },
    {
        CardIcon: BuildIcon,
        title: 'Cognitive behavioral approach',
        body: 'Practical tools to help you learn by practice.',
        linkTo: '/category/all/courses',
    },
    {
        CardIcon: DynamicFeedIcon,
        title: 'Huge, modular content base',
        body: 'Great Content from top class instructor',
        linkTo: '/category/all/courses',
    },
    {
        CardIcon: CardMembershipIcon,
        title: 'Certifications',
        body: 'Learn from world-class experts and instructors.',
        linkTo: '/certifications/all/courses',
    },
    {
        CardIcon: AssignmentIcon,
        title: 'Quizzes, Tests & Labs',
        body: 'Practical tools to help you learn by practice.',
        linkTo: '/category/all/courses',
    },
];

// HERO SECTION
export const heroValue = {
    heading: 'Watch Live Classes Here',
    subtitle: 'Learn from Leading Experts | Learn by Doing',
};

// FIRST HEADING
export const headingOne = {
    title: 'Make Genius Happen™',
    subtitle: 'We help uncover and develop your talent',
    body:
        'Everyone has a unique genius. Yet, most live their lives without realizing their genius. We realize that those people don’t often receive the guidance and tools they need. We build tools that uncover, focus and sharpen your genius.',
};

// SECOND HEADING
export const training = {
    title: 'Worldclass Training and Instruction',
    subtitle: 'Deep Domain Knowledge among domains',
    body:
        'Our subject matter experts and instructors are the best of the best, and supported by teams of PhDs, Masters experts and other domain professionals to engage students completely',
};

// THIRD HEADING
export const headingThree = {
    title: 'WHY STARWEAVER?',
    subtitle:
        '10% course completion rates mean your employees are not trained!',
    body:
        'Online libraries are accessible at very low cost but data shows self-paced courses just don’t work to train professionals.Through artificial (and human) intelligence our platform delivers meaningful and measurable behavior change and results.',
};

// FOURTH HEADING
export const headingFour = {
    title: 'PEOPLE ARE YOUR MOST IMPORTANT ASSETS!®',
    subtitle: 'Integrated Tools In Action',
    body:
        'Experience the power of our approach in action. Learn how we deploy data science to deliver personalized training which changes the habits and boosts the performance of your people.',
};
