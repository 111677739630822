import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { PeopleAltIcon } from '../icons';

export const visibilities = {
    STARWEAVER_TEAM: {
        visibility: 'Starweaver team',
        icon: <VerifiedUserIcon />,
    },
    STARWEAVER_TEAM_AND_SMES: {
        visibility: 'Starweaver team & SMEs',
        icon: <PeopleAltIcon />,
    },
    CLIENT: {
        visibility: 'Client',
        icon: <BusinessIcon />,
    },
    ADMINS: {
        visibility: 'Admins',
        icon: <GroupIcon />,
    },
    EVERYONE: {
        visibility: 'Everyone',
        icon: <PublicIcon />,
    },
    SELECTED_USERS: {
        visibility: 'Selected users',
        icon: <PersonAddIcon />,
    },
    ONLY_ME: {
        visibility: 'Only me',
        icon: <LockIcon />,
    },
};
