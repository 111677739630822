import {
    Box,
    Button,
    Container,
    CircularProgress,
    // Grid,
    Icon,
    Loading,
    NavigationTab,
    Typography,
} from '../ui';
import { useMemo, useState } from 'react';
// import { CourseCard } from './CourseCard';
import { AddIcon } from '../icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './styles';
import { FlareRoundedIcon } from '../icons/';
import SWCourseCards from '../custom-components/SWCourseCards';
import { useTheme } from '@mui/material';

export const FeaturedCourses = ({ isLoading, tabs, maxWidth = 'xl' }) => {
    const [value, setValue] = useState(0);
    const { classes } = useStyles();

    const theme = useTheme();
    const isSMDevice = useMediaQuery(theme.breakpoints.up('sm'));
    const isMDDevice = useMediaQuery(theme.breakpoints.up('md'));
    const isLGDevice = useMediaQuery(theme.breakpoints.up('lg'));

    console.log('viewport : ', isSMDevice, isMDDevice, isLGDevice);

    const tabValues = useMemo(() => {
        const collections = [];
        const values = [];
        const redirect = [];
        if (!isLoading && Object.keys(tabs)) {
            Object.keys(tabs).forEach((key) => {
                const collectionObject = {
                    name: key,
                };
                collections.push(collectionObject);
                values.push(tabs[key].courses);
                redirect.push(tabs[key].redirect);
            });
        }
        return {
            collections,
            values,
            redirect,
        };
    }, [isLoading, tabs]);

    // greater than or equal to large
    /*  const gteLarge = useMediaQuery('(min-width:1280px)');
    // greater than or equal to mid
    const gteMid = useMediaQuery('(min-width:960px)');
    // greater than or equal to small
    const gteSmall = useMediaQuery('(min-width:960px)'); */

    const size = useMemo(() => {
        if (isLGDevice) {
            return 4;
        } else if (isMDDevice) {
            return 3;
        } else if (isSMDevice) {
            return 2;
        } else {
            return 1;
        }
    }, [isMDDevice, isSMDevice, isLGDevice]);

    return (
        <Box my={3}>
            <Container maxWidth={maxWidth}>
                <Typography
                    align="center"
                    className={` ${classes.sectionHeading} ${classes.colorBlue}`}
                    variant="h3"
                >
                    <Box className={classes.quotes}>
                        <Icon style={{ fontSize: '45px' }}>
                            <FlareRoundedIcon
                                style={{ fontSize: '45px' }}
                                color="secondary"
                            />
                        </Icon>
                    </Box>
                    Extensive on demand content backed by real mentors{' '}
                </Typography>
            </Container>
            {isLoading && (
                <Box
                    height="500px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress color="secondary" />
                </Box>
            )}

            {!tabValues.collections.length > 0 && <Loading height="200px" />}

            {/* Can be made into a component that can be reused */}
            {tabValues.collections.length > 0 && (
                <>
                    <Container maxWidth={maxWidth}>
                        <Box className={classes.navigationTab} mt={2}>
                            <NavigationTab
                                collections={tabValues.collections}
                                value={value}
                                setValue={setValue}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" my={2}>
                            <Box className={classes.cardContainer}>
                                {tabValues.values[value]
                                    .slice(0, size)
                                    .map((course, index) => (
                                        <Box key={index} className="card-item">
                                            {/* <CourseCard course={course} /> */}
                                            <SWCourseCards
                                                course={course}
                                                domain=""
                                            />
                                        </Box>
                                    ))}
                            </Box>
                            <Box mx="auto" mt={4}>
                                <Button
                                    href={tabValues.redirect[value]}
                                    color="secondary"
                                    variant="outlined"
                                    endIcon={<AddIcon />}
                                >
                                    see more
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </>
            )}
        </Box>
    );
};

export default FeaturedCourses;
