import { Box, Grid, Modal, Typography, Link } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import * as React from 'react';
import { UseState, UseDispatch, vars, t } from '../index';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    refetchCoupons,
    setCards,
    clearApiString,
    toggleRefetch,
} from '../Helpers/Store';
import CouponCard from './CouponCard';
import axios from 'axios';
import { useUser } from '../../auth/useUser';

import { LoginModal } from '../../auth/LoginModal';
import { useCurrentUser } from '../../my-account/useCurrentUser';
import CouponSearch from '../Main/CouponSearch';

const CouponLogic = ({ apiList }) => {
    // for that, check which radios are clicked by user. ( grab mainOptions and subOptions )
    // Then, arrange heirarchy of radio-clicked (keywords clicked), as per the api(s) in tom's site.
    // insert those keywords into a string. GET request on this new string.
    // returned object contains whatever needs to be filtered. See if sorting is required.

    const [checkdb_again, setCheckdb_again] = React.useState(true); // checks db to get list of coupons user has already clicked
    const [userCoupons_fetched, setUserCoupons_fetched] = React.useState([]); // this will hold fresh data from mongodb about all coupons clicked by users

    const [activateLink, setActivateLink] = React.useState(true); // activated click to new tab, for coupon card
    const { classes } = useStyles();

    const state = useSelector((state) => state.coupons);
    const dispatch = useDispatch();
    const { user: userDetails } = useCurrentUser();
    const { isLoading } = useUser();
    const [enablePagination, setenablePagination] = useState(false);
    const [totalPageNumber, setTotalPageNumber] = useState(1);

    const [enableCouponSearch, setenableCouponSearch] = useState(false);
    var [couponSearchTxt, setcouponSearchTxt] = useState('');

    const [promptLogin, setPromptLogin] = useState(false);

    // if "checkdb_again" is true, that means react needs to update fresh data about user from mongo
    React.useEffect(() => {
        if (checkdb_again && userDetails) {
            (async () => {
                try {
                    const { data, status } = await axios.post(
                        '/api/udemy-coupon-codes/getUserCoupons',
                        {
                            userID: userDetails._id,
                        }
                    );
                    if (status === 200 && data !== false) {
                        setUserCoupons_fetched(data);
                    } else {
                        setUserCoupons_fetched([]); // clears the array. Any loop for this won't take effect if this is empty.
                    }
                    setCheckdb_again(false); // this line will avoid self calling of this useEffect
                } catch (error) {
                    console.log({ error });
                }
            })();
        }
    }, [checkdb_again, userDetails]);

    const [pagination, setPagination] = useState({
        on_page: 1,
        total_pages: 0,
        records_filtered: 0,
    });
    const displayPaginationNumber = () => {
        const { on_page, total_pages } = pagination;
        let end = on_page * 12;
        let start = end - 11;
        const combine = start + '-' + end;
        return combine;
    };
    const searchText = (searchTxt) => {
        dispatch(toggleRefetch(true));
        setcouponSearchTxt(searchTxt);
        setenableCouponSearch(true);
        apiCall();
    };
    // const [cards, setCards] = useState([]); // holds all fetched cards from axios

    useEffect(() => {
        if (state.cards?.length == 0) {
            setenableCouponSearch(false);
        }
    });
    // ---------------------------code block start ------------------------- create api string------------------
    const elem_to_api = React.useCallback(
        (btn, subOption) => {
            let txt = '';
            switch (btn) {
                case 'Ratings':
                    switch (subOption) {
                        case 'All':
                            return null;
                        case '4.5 & above':
                            return 'rating=4.5';
                        case '4.0 & above':
                            return 'rating=4';
                        case '3.5 & above':
                            return 'rating=3.5';
                        case '3.0 & above':
                            return 'rating=3';
                        default:
                            return null;
                    }
                case 'Discount':
                    switch (subOption) {
                        case '>50%':
                            return '50';
                        case '100%':
                            return '100';
                        default:
                            return null;
                    }
                case 'Categories':
                    let dontReturn = false;
                    state.Categories.forEach((text, i) => {
                        if (text === 'All Categories') {
                            dontReturn = true;
                            return;
                        }
                        txt = txt + (i !== 0 ? '|' : '') + text;
                    });
                    if (dontReturn) {
                        return;
                    }
                    return `cat=${txt}`;
                case 'Sub Categories':
                    let text = '';
                    state['Sub Categories'].forEach((subCat, i) => {
                        const list = apiList?.filter((api_obj, i) => {
                            return api_obj.Subcategory === subCat;
                        });
                        list?.forEach((id, i) => {
                            text =
                                text + (i !== 0 ? '|' : '') + id.SubcategoryId;
                        });
                    });
                    txt = txt + text;
                    return `subcat=${txt}`;
                case 'Level':
                    switch (state.Level[0]) {
                        case 'All Levels':
                            return '';
                        case 'Core':
                            return 'level=Beginner';
                        case 'Intermediate':
                            return 'level=Intermediate';
                        case 'Advanced':
                            return 'level=Expert';
                        default:
                            return '';
                    }
                default:
                    return null;
            }
        },
        [apiList, state]
    );

    const createApi = React.useCallback(() => {
        const {
            apis: {
                start,
                featured,
                sortDir,
                defaultLength,
                discount50,
                discount100,
            },
        } = vars;

        let apiString = `${start}&${featured}&${sortDir}&${defaultLength}`;

        // pagination
        const pageNo =
            enableCouponSearch && couponSearchTxt.length != 0
                ? 1
                : pagination.on_page;
        const pageString = `page=${pageNo}`;
        apiString = `${apiString}&${pageString}`;

        if (state.Ratings.length > 0) {
            let tempString = state.Ratings.map((subOption, i) => {
                return elem_to_api('Ratings', subOption);
            });
            apiString = `${apiString}${
                tempString.length > 0 ? `&${tempString}` : null
            }`;
        }

        if (state['Discount'].length > 0) {
            let tempString_arr = state['Discount'].map((subOption, i) => {
                return elem_to_api('Discount', subOption);
            });
            let tempString = '';
            tempString_arr.forEach((str, i) => {
                tempString = tempString + (i === 1 ? '|' : '') + str;
            });
            apiString = `${apiString}&discount=${tempString}`;
        }
        if (state['Sub Categories'].length > 0) {
            let tempString = elem_to_api('Sub Categories', null);
            if (tempString) {
                apiString = `${apiString}${`&${tempString}`}`;
            }
            // console.log('Clicked', apiString, tempString);
        }
        if (state.Categories.length > 0) {
            // inside CreateApi
            let tempString = elem_to_api('Categories', null);
            if (tempString) {
                apiString = `${apiString}${`&${tempString}`}`;
            }
        }
        if (state.Level.length > 0) {
            let tempString = /* 'level=' +  */ elem_to_api('Level', null);
            apiString = `${apiString}&${tempString}`;
        }
        if (state.Language.length > 0) {
            let tempString = '';
            if (state.Language[0] !== 'All Languages') {
                state.Language.forEach((lang, i) => {
                    tempString = tempString + (i !== 0 ? '|' : '') + lang;
                });
                let lang = 'language=' + tempString + '&';
                apiString = apiString + '&' + lang;
            } else {
                apiString = apiString + '&language=&';
            }
        }
        if (couponSearchTxt.length != 0) {
            apiString = apiString + 'inkw=' + couponSearchTxt;
        }

        // ----------------------------------------------------------------
        // dispatch(refetchCoupons(apiString));

        // dispatch({ type: 'refetch-coupons', payload: apiString });
        return apiString; // return here, is not needed if this function is never called outside useEffect.
        // Calling this function outside useEffect simply checks active-buttons in state and makes returns a plain string api, that needs to be called via axios.
    }, [dispatch, elem_to_api, pagination.on_page, state]);

    const apiCall = async () => {
        const apiString = createApi();
        console.log(apiString);
        try {
            const fetched = await axios.get(apiString);
            if (!fetched) {
                return;
            }

            setCards([]);
            const { data, status } = fetched;

            const { pages, recordsFiltered, results } = data;

            // set paginatio data
            if (status === 200 && data) {
                if (pages === 0 || recordsFiltered === 0) {
                    // console.log('REALLY NOTHING RETURNED');
                    pagination.total_pages = pages;
                    dispatch(toggleRefetch(false));
                    setenablePagination(true);
                    setenableCouponSearch(false);

                    dispatch(setCards([]));
                    setPagination({
                        on_page: 1,
                        total_pages: 0,
                        records_filtered: 0,
                    });
                } else {
                    // console.log('RETURNED');
                    // console.log({ results });
                    dispatch(setCards(results));
                    setenablePagination(true);
                    pagination.records_filtered = recordsFiltered;
                    pagination.total_pages = pages;
                    setTotalPageNumber(recordsFiltered);
                    // setPagination({
                    //     on_page: pagination.on_page,
                    //     total_pages: pages,
                    //     records_filtered: recordsFiltered,
                    // });

                    // if (pagination.total_pages === 0) {
                    // }
                }
            } else {
                console.log('STATUS IS = ', status, data);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    // const {data,isLoading,error,refetch}

    // onMount, check if apiString in State is true. If not, refetch is needed.

    useEffect(() => {
        if (state.refetch === true) {
            (async () => {
                try {
                    await apiCall();
                    dispatch(toggleRefetch(false));
                } catch (error) {
                    console.log({ error });
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, state.refetch]);

    useEffect(() => {
        apiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // ---------------------------code block end------------------------------------------------------------------------

    const isCardActivated = (card) => {
        // cross verifies with database about the list of cards the user has activated/clicked
        if (!userDetails) {
            return false;
        }
        const exists = userCoupons_fetched.some((coupon) => {
            if (
                coupon.couponCode === card.CouponCode &&
                coupon.couponID === card.CouponId
            ) {
                return true;
            } else return false;
        });
        if (exists) {
            return true;
        } else {
            return false;
        }
    };

    const turnPage = (action) => {
        let currentPage = pagination.on_page;
        const totalPages = pagination.total_pages;
        switch (action) {
            case 'previous':
                if (currentPage > 1) {
                    currentPage -= 1;
                }
                break;
            case 'next':
                if (currentPage < totalPages) {
                    currentPage += 1;
                }
                break;
            default:
                break;
        }
        pagination.total_pages = totalPages;
        pagination.on_page = currentPage;
        // setPagination({
        //     ...pagination,
        //     on_page: currentPage,
        //     total_pages: totalPages,
        // });
        // // dispatch(clearApiString());
        apiCall();
    };

    const [modalOpen, setModalOpen] = useState(false);

    // 24hr check for coupons
    //const [ranOnce, setRanOnce] = useState(false);
    // useEffect(() => {
    //     if (userDetails && !ranOnce) {
    //         (async () => {

    //             const { data, status } = await axios.post(
    //                 '/api/udemy-coupon-codes/checkIf24hrsLimitExceeded',
    //                 {
    //                     userID: userDetails._id,
    //                 }
    //             );
    //             if (status === 200 && data && data.total > 1) {
    //                 setActivateLink(false);

    //                 setRanOnce(true);
    //             } else {

    //             }
    //         })();
    //     }
    // }, [ranOnce, userDetails]);

    return (
        <>
            <Modal open={modalOpen} style={{ border: '4px solid red' }}>
                <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    style={{
                        // border: '6px solid blue',
                        height: '100%',
                        overflow: 'hidden',
                        // background: 'white',
                    }}
                >
                    <Grid
                        item
                        container
                        style={{
                            background: 'white',
                            maxWidth: '50vw',
                            margin: 'auto',
                            padding: '3rem',
                            gap: '2rem',
                            borderRadius: 8,
                            // border: '2px solid red',
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            onClick={() => setModalOpen(false)}
                            style={{
                                // backgroundColor: 'pink',
                                cursor: 'pointer',
                                textAlign: 'center',
                            }}
                        >
                            <Typography style={{ fontSize: '24px' }}>
                                You have reached your coupon limit for 24 hours!
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            item
                            style={{
                                // border: '3px solid green',
                                textAlign: 'center',
                            }}
                        >
                            <Typography style={{ fontSize: '1rem' }}>
                                Astronaut and Commander plans have unlimited
                                coupon access!
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            justifyContent="center"
                            style={{ gap: '0.9rem' }}
                        >
                            <Grid
                                item
                                xs={2}
                                style={{
                                    border: '2px solid orange',
                                    borderRadius: 5,
                                }}
                            >
                                <Typography
                                    color="secondary"
                                    align="center"
                                    style={{
                                        padding: '0.5rem',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setModalOpen(false);
                                    }}
                                >
                                    Close
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                style={{
                                    border: '2px solid orange',
                                    borderRadius: 5,
                                }}
                            >
                                <Typography
                                    color="secondary"
                                    align="center"
                                    style={{
                                        padding: '0.5rem',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Link href="/pricing" color="secondary">
                                        Upgrade
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <Grid container direction="row" spacing={2}>
                <Grid
                    container
                    direction="column"
                    xs={12}
                    style={{
                        padding: '8px',
                        flexFlow: 'column',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <CouponSearch onClick={searchText} />
                </Grid>
                {state.cards?.length > 0 &&
                    state.cards.map((card, i) => {
                        return (
                            <Grid md={4} sm={6} item key={i}>
                                <CouponCard
                                    setPromptLogin={setPromptLogin}
                                    setModalOpen={setModalOpen}
                                    card={card}
                                    setCheckdb_again={setCheckdb_again}
                                    activated={isCardActivated(card)}
                                    activateLink={activateLink}
                                    setActivateLink={setActivateLink}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
            {/* {state.cards.length < 1
            ? 'Loading... please wait!'
            : !userDetails &&
              promptLogin && (
                  <LoginModal
                      extraFunctions={() => {
                          setPromptLogin(false);
                      }}
                      open={!userDetails}
                      title={'Access Coupons and more.'}
                  />
              )}
*/}
            {state.refetch ? (
                'Loading... please wait!'
            ) : state.cards.length < 1 ? (
                couponSearchTxt.length != 0 && state.cards.length < 1 ? (
                    <div>
                        <div className={classes.makeSuggestion}>
                            <div style={{ marginBottom: '5px' }}>
                                <strong>Search suggestions:</strong>
                            </div>
                            <div className={classes.suggestionItem}>
                                Make sure keywords are spelled correctly.
                            </div>
                            <div className={classes.suggestionItem}>
                                Try rephrasing or using synonyms.
                            </div>
                            <div className={classes.suggestionItem}>
                                Use less specific keywords.
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid container justifyContent="center" direction="row">
                        No coupons available for this combination
                    </Grid>
                )
            ) : (
                !userDetails &&
                promptLogin && (
                    <LoginModal
                        onRequestClose={() => {
                            setPromptLogin(false);
                        }}
                        open={!userDetails}
                        title={'Access Coupons and more.'}
                        signUpLink="/couponsignup"
                    />
                )
            )}

            {/* {pagination.total_pages === 0 &&
            'No coupons available for this combination'} */}

            {enablePagination && couponSearchTxt.length == 0 && (
                <Grid
                    container
                    justifyContent="flex-end"
                    direction="row"
                    style={{
                        margin: '2rem 0 0',
                        gap: '0.5rem',
                        // border: '2px solid green',
                    }}
                    onClick={() => console.log({ state })}
                >
                    <Grid
                        item
                        // container
                        xs={6}
                        sm={3}
                        container
                        alignContent="center"
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{
                            // border: '2px solid',
                            textAlign: 'right',
                            color: 'grey',
                        }}
                    >
                        {displayPaginationNumber()} of {totalPageNumber} items
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        xs={1}
                        style={{ maxWidth: '3rem', marginTop: '5px' }}
                    >
                        <Grid
                            item
                            xs={6}
                            style={{ cursor: 'pointer' }}
                            onClick={() => turnPage('previous')}
                        >
                            <ArrowBackIosIcon fontSize="small" />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{ cursor: 'pointer' }}
                            onClick={() => turnPage('next')}
                        >
                            <ArrowForwardIosIcon fontSize="small" />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default CouponLogic;

const useStyles = makeStyles()((theme) => ({
    searchBar: { marginTop: 100 },
    makeSuggestion: {
        marginTop: '20px',
        fontSize: '1.3rem',
        marginBottom: '10px',
    },
    suggestionItem: {
        fontSize: '1.1rem',
        lineHeight: '1.60',
    },
}));
