import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Divider, Grid, Typography } from '../ui';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { blue } from '@mui/material/colors';
import { sentenceCase } from '../util';
import { ExpandMoreIcon } from '../icons';
import { Fade } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
    root: { position: 'relative' },

    dropDownMenu: {
        position: 'absolute',
        // left: '-50%',
        transform: 'translateX(-38%)',
        minWidth: 500,
        margin: '0 auto',
        padding: '15px 20px 10px',
        // height: 'fit-content',
        backgroundColor: 'rgba(250,250,250)',
        // animation: '$enter 0.1s ease-out forwards',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
    },
    // '@keyframes enter': {
    //     '0%': {
    //         opacity: 0,
    //     },
    //     '100%': {
    //         opacity: 1,
    //     },
    // },
    // why starweaver component
    sectionHeading: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            width: '90%',
            height: '3px',
            background:
                'linear-gradient(114deg, rgba(21,101,192,1) 0%, rgba(250,250,250,0) 135%)',
            bottom: '-4px',
            left: 0,
            borderRadius: '0px 20px 0px 20px',
        },
    },
    listItem: {
        // minHeight: 80,
    },
    link: {
        transistion: ' all 0.2s ease-out',
        textDecoration: 'underline',
        letterSpacing: '1px',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: blue[800],
        },
    },
    container: {
        width: '82%',
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'column',
    },
    dropdownButton: {
        transition: 'all 150ms linear',
        '&:hover': {
            backgroundColor: '#EA7112',
            color: '#ffff',
        },
    },
    dropdownButtonActive: {
        backgroundColor: '#EA7112',
        color: '#ffff',
    },
}));

export const DomainDropDown = ({ label, domain }) => {
    const { classes } = useStyles();

    const [openMenu, setOpenMenu] = useState(false);

    const [jobRoles, setJobRoles] = useState([]);

    const [techbytes, setTechbytes] = useState([]);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             // setIsLoading(true);

    //             if (hardCodedJobRoles[domain]) {
    //                 setJobRoles(hardCodedJobRoles[domain]);
    //                 return;
    //             }

    //             const res = await axios.get(
    //                 `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/job-roles/navbar?domain=${domain}`
    //             );

    //             setJobRoles(res.data);
    //             // setIsLoading(false);
    //         } catch (error) {}
    //     })();
    // }, [domain]);

    useEffect(() => {
        (async () => {
            try {
                // setIsLoading(true);
                if (hardCodedTechbytes[domain]) {
                    setTechbytes(hardCodedTechbytes[domain]);
                    return;
                }

                const res = await axios.get(
                    `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/techbytes/navbar?domain=${domain}`
                );

                setTechbytes(res.data);
                // setIsLoading(false);
            } catch (error) {}
        })();
    }, [domain]);

    const Anchor = ({ children, to, isExternal, className }) =>
        isExternal ? (
            <a href={to} target="_blank" rel="noreferrer" className={className}>
                {children}
            </a>
        ) : (
            <Link to={to} className={className}>
                {children}
            </Link>
        );

    return (
        <Box className={classes.root} onMouseLeave={() => setOpenMenu(false)}>
            <Button
                color="secondary"
                onMouseEnter={() => {
                    setOpenMenu(true);
                }}
                onClick={() => {
                    setOpenMenu(true);
                }}
                endIcon={<ExpandMoreIcon />}
                className={`${classes.dropdownButton} ${
                    openMenu && classes.dropdownButtonActive
                }`}
            >
                {label}
            </Button>

            {openMenu && (
                <Fade in={openMenu}>
                    <Box className={classes.dropDownMenu}>
                        <Grid container item spacing={4} xs={12}>
                            <Grid item xs={6}>
                                <Typography
                                    className={classes.sectionHeading}
                                    variant="h6"
                                    gutterBottom
                                >
                                    {sentenceCase('BUILD YOUR CAREER')}
                                </Typography>
                                {jobRoles.map((item) => (
                                    <Box mt={2} className={classes.listItem}>
                                        <Anchor
                                            to={`/job-roles/${item.slug}`}
                                            isExternal={item.isExternal}
                                            className={classes.link}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="subtitle2"
                                            >
                                                {item.title}
                                            </Typography>
                                        </Anchor>
                                    </Box>
                                ))}
                            </Grid>

                            <Grid item xs={6}>
                                <Typography
                                    className={classes.sectionHeading}
                                    variant="h6"
                                    gutterBottom
                                >
                                    {sentenceCase('Learn key skills')}
                                </Typography>
                                {techbytes.map((item) => (
                                    <Box mt={2} className={classes.listItem}>
                                        <Anchor
                                            to={`/techbytes-online-technology-blog/${item.slug}`}
                                            isExternal={item.isExternal}
                                            className={classes.link}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="subtitle2"
                                            >
                                                {item.title}
                                            </Typography>
                                        </Anchor>
                                    </Box>
                                ))}
                            </Grid>

                            {/* <Grid item xs={6}>
                                <Typography
                                    className={classes.sectionHeading}
                                    variant="h6"
                                    gutterBottom
                                >
                                    {sentenceCase(
                                        'TOP QUALITY INSTRUCTORS PUBLISHING NEW CONTENT DAILY'
                                    )}
                                </Typography>
                                {topQaulityInstructorsPublishNewContent.map(
                                    (item) => (
                                        <Box
                                            mt={2}
                                            className={classes.listItem}
                                        >
                                            <Anchor
                                                to={item.linkTo}
                                                isExternal={item.isExternal}
                                                className={classes.link}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="subtitle2"
                                                >
                                                    {item.heading}
                                                </Typography>
                                            </Anchor>
                                            <Box ml={2}>
                                                <Typography variant="caption">
                                                    {item.caption}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                )}
                                <Box my={2} />
                            </Grid> */}
                        </Grid>
                    </Box>
                </Fade>
            )}
        </Box>
    );
};

const hardCodedJobRoles = {
    business2: [
        { slug: 'product-owner', title: 'Product Owner' },
        { slug: 'product-manager', title: 'Product Manager' },
        {
            slug: 'information-technology-project-managers',
            title: 'Scrum Master',
        },
        { slug: 'automation-architect', title: 'Automation Architect' },
        { slug: 'devops-engineer', title: 'DevOps Engineer' },
    ],
};
const hardCodedTechbytes = {
    business2: [
        {
            slug:
                'a-complete-beginners-guide-to-data-visualization-in-python-js-techbytes',
            title: 'Visualize data',
        },
        {
            slug: '11-data-science-myths-you-should-avoid-at-all-costs',
            title: 'Apply Python at work',
        },
        {
            slug: 'supply-chain-analytics-what-it-is-why-it-matters',
            title: 'Use analytics for supplychains',
        },
        {
            slug:
                'how-agile-organizations-work-through-transformation-management',
            title: 'Digital transformation skills',
        },
        {
            slug: 'devops-vs-agile-everything-you-need-to-know',
            title: 'DevOps and Agile',
        },
        {
            slug:
                'why-product-management-for-data-science-provides-better-results-for-companies',
            title: 'Product management in data science',
        },
    ],
};

const roleBasedAndLiveEducationContent = [
    {
        heading: `Find content based on your “Persona”`,
        linkTo: '/job-roles',
        caption:
            'Our Journey builder employs AI and proprietary technologies to surface role-based content just for you. ',
    },
    {
        heading: 'What’s On Air now? Live instructors​',
        linkTo: '/on-air',
        caption:
            'Engage directly with leading practitioners, not merely a boring, static library.​',
    },
    {
        heading: 'Hands-on walk-throughs',
        linkTo: '/live-labs',
        caption:
            '​Get group and team coaching with LiveLabs and hands-on demos. ',
    },
    {
        heading:
            'Great education connects you with peers with similar interests ',
        linkTo: 'https://support.starweaver.com/portal/en/community/recent',
        isExternal: true,
        caption:
            'Real learning happens when you’re confident enough to teach others.​​​',
    },
];

const topQaulityInstructorsPublishNewContent = [
    {
        heading: `Structured Journeys & curriculums`,
        linkTo: '/journeys/all/j',
        caption:
            'Practitioners build learning paths and create​ a customized journeys ',
    },
    {
        heading: 'Technical and business content',
        linkTo: '/category/business/courses',
        caption:
            'Cross functional content marries technical and business skills essential in any career.​',
    },
    {
        heading:
            'Internally use our content curation, streaming and publishing tools',
        linkTo: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial`,
        isExternal: true,
        caption:
            '​Your instructors and content teams can curate, design, publish and stream content for internal use only.',
    },
    {
        heading:
            'Universities and colleges get powerful platform extensions to educate as never before',
        linkTo: '/for-campus/students',
        caption:
            'Your professors and teaching assistants can quickly curate supporting course packages for your students. ​​​',
    },
];
