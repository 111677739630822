import { selector, selectorFamily } from 'recoil';
import { userState, verifiedEmail, userIsAdminState } from './State';
import axios from 'axios';
import {
    clientDetailsState,
    disabledUsersState,
    usersState,
} from '../../admin/recoil';

export const getUser = selector({
    key: 'getUser',
    get: ({ get }) => {
        const user = get(userState);
        return user;
    },
});

export const setVerifiedEmail = selector({
    key: 'setVerifiedEmail',
    set: ({ set }, value) => {
        set(verifiedEmail, value);
    },
});

export const getVerifiedEmail = selector({
    key: 'getVerifiedEmail',
    get: ({ get }) => {
        return get(verifiedEmail);
    },
});

export const giveUser = selectorFamily({
    key: 'giveUser',
    get: (email) => async ({ get }) => {
        if (get(userState) === null) {
            const res = await axios.get(`/api/users/${email}`);
            // CHECK IF USER EXIST OR NOT
            if (res.data.user) {
                // CHECK IF USER HAS MADE A WP DB CALL SO IT WOULD CHANCE IT's ID FIELD IN MONGO DB
                // SO WE CHECK IF THE ID IS THE DEFAULT ONE OR NOT
                if (res.data.user.ID === 9999) {
                    const WP_DATA = await axios.get(
                        `/api/users/${email}/wordpress`
                    );
                    // NOW CHECK IF THE USER EXIST IN WP OR NOT
                    if (WP_DATA.data.user !== null) {
                        // IF WP DATA EXIST MERGE IT IN THE MONGO DB
                        const newUser = { ...WP_DATA.data.user };
                        const putRes = await axios.put(
                            `/api/users/${res.data.user._id}`,
                            newUser
                        );
                        return putRes;
                    } else {
                        // IF DATA DOSEN'T EXSIT CHANGE ID TO 0
                        console.log(res.data.user._id);
                        const putRes = await axios.put(
                            `/api/users/${res.data.user._id}`,
                            { ID: 0 }
                        );
                        console.log(putRes);
                        return putRes;
                    }
                } else {
                    return res;
                }
            } else {
                // IF THERE IS NO USER MAKE A USER THERE
                const addUser = axios.post(`/api/users`, {
                    user_email: email,
                });
                return addUser;
            }
        }
        return get(userState);
    },
});

export const setUser = selector({
    key: 'setUser',
    set: ({ set }, user) => {
        if (user.hasOwnProperty('ID')) {
            let newUser = {
                ...user,
            };
            if (!newUser.hasOwnProperty('isBetaUser')) {
                newUser.isBetaUser = false;
            }
            if (!newUser.hasOwnProperty('isInterested')) {
                newUser.isInterested = false;
            }
            newUser.closedDialog = false;
            set(userState, newUser);
        }
    },
});

export const resetUser = selector({
    key: 'resetUser',
    set: ({ set, get }) => {
        localStorage.removeItem('userId');
        set(userState, null);
        set(userIsAdminState, false);
        set(usersState, {});
        set(clientDetailsState, {});
        set(disabledUsersState, []);
        console.log('reset');
    },
});

export const setInterestedFalse = selector({
    key: 'setInterested',
    set: ({ get, set }) => {
        let newUser = {
            ...get(userState),
        };
        newUser.isInterested = false;
        set(userState, newUser);
    },
});

export const setToBetaUser = selector({
    key: 'setToBetaUser',
    set: ({ get, set }) => {
        let newUser = {
            ...get(userState),
        };
        newUser.isBetaUser = true;
        set(userState, newUser);
    },
});

// SET SIGN IN VALUES
export const setSignInValues = selector({
    key: 'setSignInValues',
    set: ({ get, set }, signInValues) => {
        // // HASH THE PASSWORD HERE
        // newUser.user_pass = signInValues.password;
        set(userState, signInValues);
    },
});

export const setPersonalInfo = selector({
    key: 'setPersonalInfo',
    set: ({ get, set }, personalInfoValues) => {
        set(userState, personalInfoValues);
    },
});

export const setNotification = selector({
    key: 'setNotification',
    set: ({ get, set }, user_notifications) => {
        let newUser = {
            ...get(userState),
        };
        newUser.user_notifications = user_notifications;
        set(userState, newUser);
    },
});

// SET IMAGE URL
export const setUserUrl = selector({
    key: 'setUserUrl',
    set: ({ get, set }, newValues) => {
        // let newUser = {
        //     ...get(userState),
        // };
        // newUser.user_url = url;
        set(userState, newValues);
        // set(userState, newUser);
    },
});
// update user RSVPs
export const updateUserRsvpSelector = selector({
    key: 'updateUserRsvpSelector',
    set: ({ get, set }, rsvps) => {
        let newUser = {
            ...get(userState),
        };
        newUser.user_rsvps_onair = rsvps;
        set(userState, newUser);
    },
});
// update in saved courses
export const updateSavedCoursesSelector = selector({
    key: 'updateSavedCoursesSelector',
    set: ({ get, set }, savedCourses) => {
        let newUser = {
            ...get(userState),
        };
        newUser.user_save = savedCourses;
        set(userState, newUser);
    },
});

// add courseid to saved_course in user
export const addCourseToUser = selector({
    key: 'addCourseToUser',
    set: ({ set, get }, { action, payload }) => {
        let newUser = {
            ...get(userState),
        };
        if (action === 'ADD') {
            // if id is already there
            if (
                newUser.saved_courses &&
                newUser.saved_courses.some((id) => id === payload)
            ) {
                console.log('aldready there');
                return;
            }
            // if id is already there
            newUser.saved_courses = newUser.saved_courses
                ? [...newUser.saved_courses, payload]
                : [payload];
        } else if (action === 'REMOVE') {
            newUser.saved_courses = newUser.saved_courses
                ? newUser.saved_courses.filter((ids) => ids !== payload)
                : [];
            console.log('removed');
        } else {
            console.log('no-action');
        }
        set(userState, newUser);
    },
});

// add to user_save
export const addEnrolledCourse = selector({
    key: 'addEnrolledCourse',
    set: ({ set, get }, { action, payload }) => {
        let newUser = {
            ...get(userState),
        };
        if (action === 'ADD') {
            // if id is already there
            if (
                newUser.user_save &&
                newUser.user_save.some((id) => id === payload)
            ) {
                console.log('aldready there');
                return;
            }
            // if id is already there
            newUser.user_save = newUser.user_save
                ? [...newUser.user_save, payload]
                : [payload];
        } else {
            console.log('no-action');
        }
        set(userState, newUser);
    },
});
