import { makeStyles } from 'tss-react/mui';
import backgroundImage from '../img/bgCheck.svg';
import cancelImage from '../img/cancel.svg';
import successImage from '../img/paymentSuccessful.svg';
import successBg from '../img/successBg.svg';
import check from '../img/check.svg';

import { yellow, green, blue, grey } from '@mui/material/colors';

export const useStyles = makeStyles()((theme) => ({
    successText: {
        color: green[800],
        fontWeight: 700,
    },
    colorBlue: {
        color: blue[800],
    },
    errorText: {
        color: 'red',
        fontWeight: 700,
    },
    // Personal info
    radio: {
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
    },
    item: {
        flexBasis: '33%',
    },
    dob: {
        width: 'fit-content',
    },

    // -------------
    couponBox: {
        transition: 'all 0.2s ease-in',
        animation: '$animate 100ms linear forwards',
    },
    '@keyframes animate': {
        '0%': {
            opacity: 0,
            transform: 'scale(0.5)',
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 1,
        },
    },
    productsPageContainer: {
        borderRadius: '5px',
        color: '#444',
        backgroundColor: 'rgba(200,200,200,0.3)',
        backdropFilter: 'blur(35px)',
        padding: '30px',
        // [theme.breakpoints.up('md')]: {
        //     marginTop:2,
        // },
    },
    sectionBillingHeading: {
        color: grey[900],
        fontSize: '1.3em',
    },
    productInfoContainer: {
        display: 'flex',
        flexFlow: 'row',
        marginBottom: '5px',
    },
    productInfoLabel: {
        flexBasis: '65%',
    },
    strike: {
        textDecoration: 'line-through',
        color: '#999',
    },
    final: {
        fontSize: '1.4em',
        fontWeight: 900,
        margin: '10px 0',
        color: `${blue[900]} !important`,
    },
    productInfoValue: {
        flexBasis: '35%',
        marginRight: '20px',
        textAlign: 'end',
        color: '#555',
    },
    componentContainer: {
        margin: '10px 0 50px',
    },
    fieldsContainer: {
        margin: '15px auto 30px',
        borderRadius: '5px',
        border: '2px solid rgba(0,0,0,0.2)',
        boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.2)',
    },
    productFeature: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        margin: '10px 0',
        '&:last-child': {
            marginBottom: '20px',
        },
    },
    feature: {
        marginLeft: 10,
    },
    iconFeature: {
        marginTop: 5,
        color: green[800],
    },
    sectionHeading: {
        position: 'relative',
        margin: '10px 0',
        '&::before': {
            position: 'absolute',
            content: '""',
            bottom: '-5px',
            left: 2,
            height: '5px',
            width: '50%',
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '5px 0px 5px 0px',
        },
    },
    cardInput: {
        '&::before': {
            backgroundColor: blue[500],
        },
    },
    addressInput: {
        '&::before': {
            backgroundColor: yellow[500],
        },
    },
    disabled: {
        position: 'relative',
        pointerEvents: 'none',
        '&::before': {
            position: 'absolute',
            content: '""',
            top: '5px',
            left: '2%',
            height: '82%',
            width: '96%',
            backgroundColor: 'rgb(232, 240, 254)',
        },
    },
    dateCenter: {
        display: 'grid',
        placeItems: 'center',
    },
    editBtn: {
        backgroundColor: grey[600],
        '&:hover': {
            backgroundColor: grey[900],
        },
    },
    saveBtn: {
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[800],
        },
    },
    // NO PRODUCT FOUND
    noProductContainer: {
        display: 'flex',
        flexFlow: 'column',
        height: '50vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    // BILLING SECTION
    paymentBtn: {
        margin: '20px 0 10px',
    },
    // LOADING
    loading: {
        height: '75vh',
        display: 'grid',
        placeItems: 'center',
    },
    // SUCCESS MSG
    successMsg: {
        width: 300,
    },
    iconConainer: {
        height: 100,
        background:
            'linear-gradient(190deg, rgba(20,164,25,1) 0%, rgba(86,236,35,1) 100%)',
    },
    icon: {
        fontSize: '4em',
        color: grey[200],
    },
    content: {
        padding: '30px 0',
    },
    successBtn: {
        backgroundColor: blue[500],
        color: '#eaeaea',
        '&:hover': {
            backgroundColor: blue[700],
        },
    },

    // NEW BILLING PAGE
    // Some styles are messed up here will change later
    checkoutContainer: {
        minHeight: '90vh',
    },
    checkoutHeading: {
        textAlign: 'center',
        height: '50px',
    },
    sideImage: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '74vh',
        width: '100%',
        left: '0',
        position: 'absolute',
        zIndex: '-200',
        opacity: '0.7',
    },
    cancelImage: {
        backgroundImage: `url(${cancelImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '74vh',
        [theme.breakpoints.down('md')]: {
            height: '250px',
        },
    },
    successImage: {
        position: 'relative',
        height: '74vh',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundImage: `url(${successImage})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '120%',
            height: '100%',
        },
    },
    productDetails: {
        padding: '10px 0',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            backgroundColor: 'rgba(255,255,255,0.4)',
            backdropFilter: 'blur(30px)',
            width: 'min(600px, 80vw)',
            margin: '0 auto',
        },
    },
    successBackground: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            right: '-13vw',
            top: 0,
            height: '100vh',
            width: '100vw',
            backgroundImage: `url(${successBg})`,
            backgroundSize: 'cover',
            zIndex: '-200',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        },
        [theme.breakpoints.only('xs')]: {
            '&:before': {
                right: '0',
            },
        },
    },
    centerTitle: {
        textAlign: 'center',
        marginBottom: 10,
    },
    // BILLING PLAN
    billingPlanContainer: {
        padding: '5px 10px',
        borderRadius: '5px',
        color: '#444',
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(255,255,255,0.4)',
        border: '1px solid rgba(255,255,255,0.9)',
        height: '100%',
        // paddingLeft: 70,
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column',
        // [theme.breakpoints.only('xs')]: {
        //     padding: 0,
        // },
    },
    annualBilling: {
        overflow: 'hidden',
        position: 'relative',
        '&:before': {
            position: 'absolute',
            right: '-35px',
            top: 30,
            padding: '5px 50px',
            transform: 'rotate(36deg)',
            backgroundColor: green[400],
            color: '#fff',
            content: '"Save About 40%"',
        },
    },
    billingSectionContainer: {
        border: '2px solid rgba(0,0,0,0.2)',
        padding: '10px 15px 10px 30px',
        borderRadius: '10px',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            transform: 'scale(1.02)',
            boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
        },
    },
    billingActive: {
        borderColor: green[500],
        transform: 'scale(1.02)',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
    },
    // QUICK PLAN CHANGE CONTAINER
    planChangeContainer: {
        padding: '10px 0',
        width: '100%',
        // paddingLeft: 70,
        // marginTop: '100px',
        justifyContent: 'space-around',
        // [theme.breakpoints.only('xs')]: {
        //     marginTop: '120px',
        //     flexFlow: 'column',
        //     padding: 0,
        // },
    },
    planBox: {
        borderRadius: '2px',
        transition: 'all .2s ease-out',
        // border: '2px solid transparent',
        padding: '10px 20px',
        cursor: 'pointer',
        backgroundColor: 'rgba(255,255,255,0.4)',
        border: '1px solid rgba(0,0,0,0.2)',
        backdropFilter: 'blur(20px)',
        [theme.breakpoints.only('xs')]: {
            margin: '10px auto',
            width: '90%',
        },
    },
    planActive: {
        borderColor: theme.palette.secondary.main,
    },
    planHeading: {
        margin: '5px 10px 0 0',
        fontSize: 'min(2em,7vw)',
    },
    planFeature: {
        margin: '5px 10px 0 0',
    },
    successFeature: {
        textAlign: 'left',
        position: 'relative',
        paddingLeft: '35px',
        '&:before': {
            position: 'absolute',
            content: '""',
            top: '2px',
            left: '5px',
            height: '20px',
            width: '20px',
            backgroundImage: `url(${check})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        },
    },
    // Credit plan box
    creditPriceContainer: {
        // border: '2px solid rgba(0,0,0,0.4)',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
        '& > p': {
            wordSpacing: '5px',
            transition: 'all .2s ease-out',
            width: '300px',
            maxHeight: 46,
            padding: '10px 20px',
            paddingRight: '20px',
            borderRadius: '5px 0px 0px 5px',
            height: '46px',
            border: `1px solid ${theme.palette.secondary.main}`,
        },
        '& > button': {
            padding: '10px',
            borderRadius: '0px 5px 5px 0px',
            borderLeft: 'none',
        },
    },
    row: {
        display: 'flex',
        flexFlow: 'row',
    },
    creditPriceActive: {
        '& > p': {
            border: `1px solid ${theme.palette.secondary.main}`,
            borderColor: green[700],
            backgroundColor: green[500],
            color: '#fafafa',
        },
    },
    borderInput: {
        '& $.MuiOutlinedInput-root': {
            borderRadius: '5px 0px 0px 5px',
        },
    },
    customCredit: {
        height: '40px',
        width: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '4px',
        borderRadius: '0px 5px 5px 0px',
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    activeCredit: {
        borderColor: green[700],
        backgroundColor: green[500],
        color: '#fafafa',
    },
}));
