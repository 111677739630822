import { Box, Typography } from '../ui/index';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

export function CompanyContainer() {
    const { classes } = useStyles();
    return (
        <Box className={classes.root}>
            <Typography className={classes.textHead} variant="subtitle1">
                COMPANY
            </Typography>

            <Typography className={classes.textStyle} variant="subtitle1">
                {/* <a
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial/`}
                    href="/free-enterprise-trial"
                    target="_blank"
                    rel="noreferrer"
                >
                    About Us
                </a> */}
                <Link to="/free-enterprise-trial">About Us</Link>
            </Typography>

            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/company/join-our-team/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Jobs & Careers
                </a>
            </Typography> */}

            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/support/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Help & Support
                </a>
            </Typography> */}
            {/* <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/company/newsroom-press-and-media/`}
                    target="_blank"
                    rel="noreferrer"
                >
                    News, Press & Media
                </a>
            </Typography> */}
            <Typography className={classes.textStyle} variant="subtitle1">
                <a
                    href="https://support.starweaver.com/portal/en/home"
                    target="_blank"
                    rel="noreferrer"
                >
                    Support and <br /> Knowledge Base
                </a>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                {/* <a
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/policies/`}
                    href="/policies"
                    target="_blank"
                    rel="noreferrer"
                >
                    Policies & Terms
                </a> */}
                <Link to="/policies">Policies & Terms</Link>
            </Typography>
            <Typography className={classes.textStyle} variant="subtitle1">
                {/* <a
                    // href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/support/`}
                    href="/support"
                    target="_blank"
                    rel="noreferrer"
                >
                    Contact
                </a> */}
                <Link to="/support">Contact</Link>
            </Typography>
        </Box>
    );
}
