import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Box } from '..';

const useStyles = makeStyles()((theme) => {
    return {
        loading: {
            textDecoration: 'underline',
            fontWeight: 'bold',
            color: '#777777',
            '& > p': {
                color: '#777777',
            },
        },
        link: {
            fontWeight: 'bold',
            color: theme.palette.common.lightBlue,

            '&:hover': {
                textDecoration: 'underline',
            },
        },
    };
});

// export const Anchor = ({
//     to,
//     isExternal,
//     isNewTab,
//     children,
//     className,
//     loading,
//     isComplete,
// }) =>
//     to ? (
//         isExternal ? (
//             <a
//                 href={to}
//                 target={isNewTab ? '_blank' : '_self'}
//                 rel="noreferrer"
//                 className={className}
//             >
//                 {children}
//             </a>
//         ) : (
//             <Link to={to} className={className}>
//                 {children}
//             </Link>
//         )
//     ) : (
//         <Box className={className}>{children}</Box>
//     );

export const Anchor = (props) => {
    const {
        to,
        isExternal,
        isNewTab,
        children,
        className,
        loading,
        style,
    } = props;
    const { classes } = useStyles();

    return (
        <>
            {to ? (
                isExternal ? (
                    <>
                        {loading ? (
                            <div className={classes.loading}>{children}</div>
                        ) : (
                            <a
                                href={to}
                                target={isNewTab ? '_blank' : '_self'}
                                rel="noreferrer"
                                className={className || classes.link}
                                style={style}
                            >
                                {children}
                            </a>
                        )}
                    </>
                ) : (
                    <>
                        {loading ? (
                            <div className={classes.loading}>{children}</div>
                        ) : (
                            <Link to={to} className={className}>
                                {children}
                            </Link>
                        )}
                    </>
                )
            ) : (
                <Box className={className}>{children}</Box>
            )}
        </>
    );
};
