import { Box, Typography, Grid } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {},
    programManagerContainer: {
        marginLeft: 30,
        marginBottom: 30,
    },
    heading: {
        //marginBottom: 10,
    },
    subHeading: {
        marginBottom: 10,
    },
    info: {
        marginTop: 10,
    },
}));

export const ManagersCard = ({
    name = '',
    imgUrl = '',
    designation = '',
    number = '',
    linkedIn = '',
    email = '',
    workHours = '',
}) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.programManagerContainer}>
            <Grid container spacing={4} className={classes.fieldsContainer}>
                <Grid item>
                    <a
                        href="https://wa.me/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ display: 'block', height: '100%' }}
                    >
                        <img
                            width="200px"
                            height={'205px'}
                            src={imgUrl}
                            alt={designation}
                            style={{
                                height: '100%',
                                objectFit: 'cover',
                                mixBlendMode: 'multiply',
                            }}
                        />
                    </a>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="h5"
                        className={classes.heading}
                    >
                        {name}
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h6"
                        className={classes.subHeading}
                    >
                        {designation}
                    </Typography>

                    <Typography className={classes.field}>
                        <strong>WhatsApp:</strong> {number}
                    </Typography>
                    <Typography className={classes.field}>
                        <strong>Linkedin:</strong>{' '}
                        <a
                            href={linkedIn}
                            target="_blank"
                            style={{ color: 'blue' }}
                            rel="noreferrer"
                        >
                            {linkedIn}
                        </a>
                    </Typography>
                    <Typography className={classes.field}>
                        <strong>Email:</strong> {email}
                    </Typography>

                    <Typography className={classes.field}>
                        <strong>Working Hours:</strong> {workHours}
                    </Typography>

                    <Typography className={classes.info}>
                        To message me, click on my photo.​
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
