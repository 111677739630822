import {
    Dialog,
    DialogContent,
    DialogTitle,
    ImageList,
    ImageListItem,
} from '..';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    // root: { marginTop: 40, marginBottom: 40 },
    imageList: {
        width: 500,
        height: 450,
    },
    imageListItem: {
        cursor: 'pointer',
    },
}));

export const GallerySelection = ({
    gallery,
    onClose,
    open,
    onSelection,
    leftshift,
}) => {
    const { classes } = useStyles();
    // const [check, setCheck] = useState(false);

    return (
        <Dialog /* disableScrollLock */ open={open || false} onClose={onClose}>
            <DialogTitle>{'Select image'}</DialogTitle>
            <DialogContent>
                <ImageList
                    rowHeight={160}
                    className={classes.imageList}
                    cols={3}
                >
                    {gallery.map((item) => (
                        <ImageListItem
                            key={item.src}
                            cols={/* item.cols || */ 1}
                            className={classes.imageListItem}
                            onClick={() => {
                                onSelection(item.src, item);
                            }}
                        >
                            <img
                                src={item.src}
                                alt={item.title}
                                style={{
                                    marginLeft: leftshift ? -70 : 0,
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </DialogContent>
        </Dialog>
    );
};
