import { useState, useEffect, useCallback } from 'react';
import { notificationState } from './recoil';
import axios from 'axios';
import { useRecoilState } from 'recoil';

export const useUserNotifications = (
    userId,
    isAdminRoute,
    isInstructorRoute,
    socket
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useRecoilState(notificationState);
    const [starweaverNotification, setStarweaverNotification] = useState(null);

    const fetchNotifications = useCallback(async () => {
        try {
            setIsLoading(true);
            console.log('Fetching Notifications...');
            const notificationsType = isAdminRoute
                ? 'admin'
                : isInstructorRoute
                ? 'instructor'
                : 'user';
            const { data } = await axios.get(
                `/api/notifications/user/${userId}?view=${notificationsType}`
            );
            setNotifications(data.notifications);
            setStarweaverNotification(data.starweaverNotifications);
            console.log('Fetched Notifications...');
        } catch (error) {
            console.log(error.message);
        }
        setIsLoading(false);
    }, [userId, setNotifications, isAdminRoute, isInstructorRoute]);

    const markAllRead = useCallback(async () => {
        if (userId) {
            await axios.post(`/api/notifications/read/all/${userId}/`);
            await fetchNotifications();
        }
    }, [userId, fetchNotifications]);

    const markAllUnread = useCallback(async () => {
        if (userId) {
            await axios.post(`/api/notifications/unread/all/${userId}/`);
            await fetchNotifications();
        }
    }, [userId, fetchNotifications]);

    const markSelectedRead = useCallback(
        async (ids) => {
            try {
                const baseURL = '/api/notifications/read/selected';
                const params = new URLSearchParams();
                params.append('notificationIds', ids.join('|'));
                const url = `${baseURL}?${params.toString()}`;
                await axios.get(url);
                await fetchNotifications();
            } catch (e) {
                throw new Error(e);
            }
        },
        [fetchNotifications]
    );

    const markSelectedUnread = useCallback(
        async (ids) => {
            try {
                const baseURL = '/api/notifications/unread/selected';
                const params = new URLSearchParams();
                params.append('notificationIds', ids.join('|'));
                const url = `${baseURL}?${params.toString()}`;
                await axios.get(url);
                await fetchNotifications();
            } catch (e) {
                throw new Error(e);
            }
        },
        [fetchNotifications]
    );

    const handleRead = useCallback(
        async (notificationId) => {
            if (notificationId) {
                try {
                    const {
                        data: { success, readStatus },
                    } = await axios.put(
                        `/api/notifications/read/${notificationId}/`
                    );
                    console.log('read ' + success);
                    setNotifications((prevNotifications) =>
                        prevNotifications.map((notification) => {
                            if (notification._id === notificationId) {
                                return {
                                    ...notification,
                                    read: readStatus,
                                };
                            } else {
                                return notification;
                            }
                        })
                    );
                } catch (error) {
                    console.log(
                        'cannot-mark-read',
                        error.response?.status || 'Unknown status',
                        error.response?.data || 'No additional data'
                    );
                }
            }
        },
        [setNotifications]
    );

    const handleUnRead = useCallback(
        async (notificationId) => {
            if (notificationId) {
                try {
                    const {
                        data: { readStatus },
                    } = await axios.put(
                        `/api/notifications/unread/${notificationId}/`
                    );
                    setNotifications((prevNotifications) =>
                        prevNotifications.map((notification) => {
                            if (notification._id === notificationId) {
                                return {
                                    ...notification,
                                    read: readStatus,
                                };
                            } else {
                                return notification;
                            }
                        })
                    );
                } catch (error) {
                    console.log(
                        'cannot-mark-unread',
                        error.response?.status || 'Unknown status',
                        error.response?.data || 'No additional data'
                    );
                }
            }
        },
        [setNotifications]
    );

    const deleteNotification = useCallback(
        async (notificationId) => {
            if (notificationId) {
                try {
                    const {
                        data: { success },
                    } = await axios.delete(
                        `/api/notifications/remove/${notificationId}/`
                    );
                    await fetchNotifications();
                    console.log('delete ' + success);
                } catch (error) {
                    console.log('cannot-mark-remove');
                }
            }
        },
        [fetchNotifications]
    );

    const deleteAllNotifications = useCallback(
        async (ids) => {
            try {
                const baseURL = '/api/notifications/delete-all';
                const params = new URLSearchParams();
                params.append('notificationIds', ids.join('|'));
                const url = `${baseURL}?${params.toString()}`;
                await axios.delete(url);
                await fetchNotifications();
            } catch (e) {
                throw new Error(e);
            }
        },
        [fetchNotifications]
    );

    useEffect(() => {
        if (userId) {
            fetchNotifications();
        }
    }, [userId, fetchNotifications]);

    useEffect(() => {
        if (socket) {
            const handleNotification = (notification) => {
                setNotifications((prevNotifications) => [
                    notification,
                    ...prevNotifications,
                ]);
            };

            socket.on('platform:notification', handleNotification);

            return () => {
                socket.off('platform:notification', handleNotification);
            };
        }
    }, [socket, setNotifications]);

    return {
        notifications,
        starweaverNotification,
        isLoading,
        markAllRead,
        markAllUnread,
        markSelectedRead,
        markSelectedUnread,
        handleRead,
        handleUnRead,
        deleteNotification,
        deleteAllNotifications,
    };
};
