// import * as React from 'react';
// import { useState, useEffect } from 'react';
// import { Reducer } from 'redux';
import { f } from '../index';
import { createSlice } from '@reduxjs/toolkit';

const couponSlice = createSlice({
    name: 'coupons',
    initialState: {
        Ratings: ['All Ratings'],
        Discount: [],
        Categories: [],
        'Sub Categories': [],
        // | 'Topics'
        Level: ['All Levels'],
        Language: ['English'],
        Duration: [],
        apiString: '',
        cards: [],
        refetch: false,
    },
    reducers: {
        // this func is called when user clicks a button on the left panel of coupons section
        rootBtn(state, { payload }) {
            let newState = { ...state };
            const arr = f.toggleArray(payload, newState);
            newState[payload.btn] = arr;
            newState.apiString = '';
            newState.cards = [];
            console.log('Turning back refetchE', true);
            newState.refetch = true;
            // if (actioned === 'deleted') {
            //     console.log('DELETED');
            // } else {
            //     console.log('ADDED');
            // }
            return newState;
        },
        // this func is called when the app detects a change in buttons, and wants to refetch data based on new api string just created
        refetchCoupons(state, { payload }) {
            let newState = { ...state };
            newState.apiString = payload;
            return newState;
        },
        clearApiString(state) {
            let newState = { ...state };
            newState.apiString = '';
            newState.cards = [];
            return newState;
        },
        setCards: (state, action) => {
            let newState = { ...state };
            newState.cards = action.payload;
            return newState;
        },
        toggleRefetch: (state, { payload }) => {
            console.log('toggling into', payload);
            let newState = { ...state };
            newState.refetch = payload;
            return newState;
        },
    },
});

export const {
    rootBtn,
    refetchCoupons,
    clearApiString,
    setCards,
    toggleRefetch,
} = couponSlice.actions;

export default couponSlice;
