import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/material';

function AvatarBadge({ src, alt, avatarTxt = '', isOnline = false, ...props }) {
    const theme = useTheme();
    return (
        <Box position="relative">
            <Avatar alt={alt} src={src} {...props}>
                {avatarTxt}
            </Avatar>

            <span
                style={{
                    backgroundColor: isOnline ? '#44b700' : 'grey',
                    // color: '#44b700',
                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    borderRadius: '50%',
                    width: 8,
                    height: 8,
                    display: 'block',
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                }}
            ></span>
        </Box>
    );
}

export { AvatarBadge };
