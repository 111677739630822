import { createSlice } from '@reduxjs/toolkit';

export const templateSlice = createSlice({
    initialState: {
        choosenTemplate: {},
    },
    name: 'templates',
    reducers: {
        storeChoosenTemplate: (state, action) => {
            return { ...state, choosenTemplate: action.payload };
        },
    },
});

export const { storeChoosenTemplate } = templateSlice.actions;
export default templateSlice.reducer;
