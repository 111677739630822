import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Divider,
    Grid,
    Icon,
    Link as MuiLink,
    TextField,
    Typography,
} from '../ui';
import { loadStripe } from '@stripe/stripe-js';
import { voyager_credit_id } from '../plans/PlanDetails';
import { useGetAllCreditsPlan } from '../billing/useGetAllCreditsPlan';
import { useStyles } from './Styles';
import {
    CheckCircleTwoToneIcon,
    AddIcon,
    RemoveIcon,
    LocalActivityIcon,
} from '../icons';
import { EnterPromoCode } from '../billing/EnterPromoCode';
import { ShowDiscount } from '../billing/ShowDiscount';

const STRIPE_PUBLIC_KEY =
    process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_SW_STRIPE_PUBLISHABLE_PROD_KEY
        : process.env.REACT_APP_SW_STRIPE_PUBLISHABLE_TEST_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export const VoyagerPlusEnrollDialog = ({
    user,
    exactCreditNeeded,
    purchaseCreditsId = voyager_credit_id,
}) => {
    const { classes } = useStyles();
    const [customCredit, setCustomCredit] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [promoCode, setPromoCode] = useState({});
    const [resetCode, setResetCode] = useState(false);
    const [creditsQuantity, setCreditsQuantity] = useState(1);
    const [processing, setProcessing] = useState(true);
    const { selectedPlan: plans, isLoading } = useGetAllCreditsPlan(
        purchaseCreditsId
    );

    const memoizedOrignalPrice = useMemo(() => {
        return (
            (selectedPlan.is_one
                ? selectedPlan.amount
                : selectedPlan.amount * creditsQuantity) / 100
        );
    }, [selectedPlan, creditsQuantity]);

    // check promo code for the credit
    const checkPromoCode = async ({ customerId, stripePriceId, promoCode }) => {
        // here we use the amount Id of that amount in stripe
        const {
            data: { response },
        } = await axios.post(
            `/api/billing/${user.user_email}/check-promo-code`,
            { code: promoCode, priceId: stripePriceId }
        );
        return response;
    };

    //plans helper functions
    //plan select function
    const planSelect = (plan) => {
        const minPurchase = plan.min_purchase || 1;
        setCreditsQuantity(plan.credits_to_add * minPurchase);
        setSelectedPlan(plan);
        setCustomCredit(0);
    };
    const enterCustomCredit = (value) => {
        const credits = isNaN(value) ? 0 : value;
        setCustomCredit(credits);
    };
    const checkPlan = useCallback(
        (value) => {
            let planToSelect = {};
            if (value) {
                planToSelect = plans[0];
                for (let i = plans.length - 1; i >= 0; i--) {
                    const plan = plans[i];
                    if (plan?.min_purchase <= value) {
                        planToSelect = plan;
                        break;
                    }
                }
            }
            setSelectedPlan(planToSelect || {});
            setCreditsQuantity(parseInt(value || 1));
        },
        [plans]
    );

    useEffect(() => {
        const timeoutValue = setTimeout(() => {
            if (customCredit !== 0) {
                checkPlan(customCredit);
            }
        }, 500);
        return () => {
            clearTimeout(timeoutValue);
        };
    }, [customCredit, checkPlan]);

    //plan select function

    const removeQuantity = () => {
        if (creditsQuantity > 1) {
            setCreditsQuantity((prevState) => prevState - 1);
        }
    };

    const addQuantity = () => {
        setCreditsQuantity((prevState) => prevState + 1);
    };

    const buyCredits = async () => {
        setProcessing(true);
        const creditsCheckoutObject = {
            email: user.user_email,
            customerId: user.customer_id,
            priceId: selectedPlan._id,
            quantity: creditsQuantity,
            code: promoCode ? promoCode.id : '',
            dest: window.location.pathname,
        };
        const stripe = await stripePromise;
        try {
            const result = await axios.post(
                '/api/billing/create-checkout-session-credits',
                creditsCheckoutObject
            );
            const session_id = result.data.sessionId;
            await stripe.redirectToCheckout({
                sessionId: session_id,
            });
        } catch (error) {
            console.log(error.message);
        }
        setProcessing(false);
    };

    useEffect(() => {
        if (user) {
            setProcessing(false);
        }
    }, [user]);

    useEffect(() => {
        if (plans) {
            if (exactCreditNeeded) {
                const credits = parseInt(exactCreditNeeded);
                if (credits) {
                    setCustomCredit(credits);
                }
            } else {
                setSelectedPlan({});
            }
        }
    }, [plans, exactCreditNeeded]);

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6">Add credits</Typography>
                <Typography variant="subtitle2">
                    Try a subscription&#40;5-day free trial&#41;&nbsp;
                    <MuiLink color="secondary" href="/pricing" target="_blank">
                        Compare?
                    </MuiLink>
                </Typography>
            </Box>
            <Divider />
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
            >
                {isLoading ? (
                    <Grid item xs={12}>
                        <CircularProgress color="secondary" />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} sm={6}>
                            {plans.slice(1).map((plan, index) => (
                                <Box
                                    my={3}
                                    id={plan.id}
                                    className={
                                        selectedPlan._id === plan._id &&
                                        customCredit === 0
                                            ? `${classes.creditPriceContainer}  ${classes.creditPriceActive}`
                                            : `${classes.creditPriceContainer} `
                                    }
                                    onClick={() => planSelect(plan)}
                                >
                                    <Box className={classes.credits}>
                                        <Icon
                                            style={{
                                                marginRight: '15px',
                                            }}
                                        >
                                            <LocalActivityIcon />
                                        </Icon>
                                        <strong>
                                            {plan.credits_to_add *
                                            plan.min_purchase
                                                ? plan.min_purchase
                                                : 1}{' '}
                                            Credits&nbsp;
                                        </strong>
                                        {plan.discountOnPrice !== 0 ? (
                                            <Typography
                                                component="span"
                                                variant="subtitle1"
                                            >
                                                ({plan.discountOnPrice}% saving)
                                            </Typography>
                                        ) : (
                                            ''
                                        )}
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        {' '}
                                        $
                                        {(
                                            (plan.amount *
                                                (plan.min_purchase || 1)) /
                                            100
                                        ).toFixed(2)}{' '}
                                    </Button>
                                </Box>
                            ))}
                            {/* custom credit  quantity */}
                            <Box
                                my={3}
                                className={classes.creditPriceContainer}
                                style={{
                                    cursor: 'default',
                                }}
                            >
                                <Box display={'flex'} width={'300px'}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        value={customCredit}
                                        onChange={(e) =>
                                            enterCustomCredit(e.target.value)
                                        }
                                        color="secondary"
                                        type="number"
                                        variant="outlined"
                                        label={'# credits'}
                                    />
                                </Box>
                                <Box
                                    className={`${classes.customCredit} ${
                                        customCredit > 0
                                            ? classes.activeCredit
                                            : ''
                                    }`}
                                >
                                    <LocalActivityIcon fontSize="default" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.productSummary}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.sectionBillingHeading}
                                >
                                    Product Summary
                                </Typography>
                                <Divider />
                                {selectedPlan.credits_to_add ? (
                                    <>
                                        <Box className={classes.productFeature}>
                                            <CheckCircleTwoToneIcon
                                                className={classes.iconFeature}
                                            />
                                            <Typography
                                                className={classes.feature}
                                                variant="subtitle2"
                                            >
                                                {selectedPlan.credits_to_add ? (
                                                    <>
                                                        Get{' '}
                                                        {selectedPlan.is_one
                                                            ? selectedPlan.credits_to_add
                                                            : selectedPlan.credits_to_add *
                                                              creditsQuantity}{' '}
                                                        Credits
                                                    </>
                                                ) : (
                                                    'Please select a plan'
                                                )}
                                            </Typography>
                                        </Box>
                                        {/* if the crdit is of $1 */}
                                        {customCredit > 0 && (
                                            <Box
                                                className={
                                                    classes.productFeature
                                                }
                                            >
                                                <Typography variant="body2">
                                                    Select the number of
                                                    credits&nbsp;&nbsp;
                                                </Typography>
                                                <ButtonGroup
                                                    color="secondary"
                                                    variant="outlined"
                                                    size="small"
                                                >
                                                    <Button
                                                        size="small"
                                                        onClick={removeQuantity}
                                                    >
                                                        <RemoveIcon />
                                                    </Button>
                                                    <Button
                                                        onClick={addQuantity}
                                                        size="small"
                                                    >
                                                        <AddIcon />
                                                    </Button>
                                                </ButtonGroup>
                                            </Box>
                                        )}
                                        <Box>
                                            <EnterPromoCode
                                                setPromoCodeResponse={
                                                    setPromoCode
                                                }
                                                // pass it or not it will be same i think
                                                customerId={user?.customer_id}
                                                priceId={
                                                    selectedPlan.stripe_price_id
                                                }
                                                // pass it or not it will be same i think
                                                checkCode={checkPromoCode}
                                                reset={resetCode}
                                                setReset={setResetCode}
                                            />
                                        </Box>
                                        <Box
                                            className={
                                                classes.productInfoContainer
                                            }
                                        >
                                            <Typography
                                                className={`${classes.productInfoLabel} ${classes.final}`}
                                            >
                                                Total
                                            </Typography>
                                            <Typography
                                                className={`${
                                                    classes.productInfoValue
                                                } ${classes.final} ${
                                                    promoCode &&
                                                    promoCode.applicable
                                                        ? classes.strike
                                                        : ''
                                                }`}
                                            >
                                                {selectedPlan.amount ? (
                                                    <>
                                                        $
                                                        {memoizedOrignalPrice.toFixed(
                                                            2
                                                        )}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </Typography>
                                        </Box>
                                        {Object.keys(promoCode).length > 0 && (
                                            <>
                                                {promoCode && (
                                                    <ShowDiscount
                                                        heading="Discount"
                                                        amountOff={
                                                            promoCode.amountOff
                                                        }
                                                        percentOff={
                                                            promoCode.percentOff
                                                        }
                                                    />
                                                )}
                                                <Box
                                                    className={
                                                        classes.productInfoContainer
                                                    }
                                                >
                                                    <Typography
                                                        className={`${classes.productInfoLabel} ${classes.final}`}
                                                    >
                                                        Final
                                                    </Typography>
                                                    <Typography
                                                        className={`${classes.productInfoValue} ${classes.final}`}
                                                    >
                                                        {promoCode.amountOff ? (
                                                            <>
                                                                $
                                                                {(
                                                                    memoizedOrignalPrice -
                                                                    promoCode.amountOff /
                                                                        100
                                                                ).toFixed(2)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                $
                                                                {(
                                                                    ((100 -
                                                                        promoCode.percentOff) *
                                                                        memoizedOrignalPrice) /
                                                                    100
                                                                ).toFixed(2)}
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                        {customCredit > 0 &&
                                            selectedPlan.discountOnPrice !==
                                                0 && (
                                                <Box
                                                    className={
                                                        classes.productInfoContainer
                                                    }
                                                >
                                                    <Typography
                                                        className={`${classes.productInfoLabel}`}
                                                    />
                                                    <Box
                                                        className={`${classes.productInfoValue} `}
                                                        style={{
                                                            fontSize: '0.8em',
                                                        }}
                                                    >
                                                        <strong>
                                                            (
                                                            {
                                                                selectedPlan.discountOnPrice
                                                            }
                                                            % savings)
                                                        </strong>
                                                    </Box>
                                                </Box>
                                            )}
                                    </>
                                ) : (
                                    <Box my={1}>
                                        <Typography variant="subtitle1">
                                            <strong>
                                                Please select a plan first
                                            </strong>
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Box my={2}>
                        <Button
                            fullWidth
                            disabled={
                                processing || !selectedPlan.credits_to_add
                            }
                            onClick={buyCredits}
                            variant="contained"
                            color="secondary"
                        >
                            {processing ? (
                                <CircularProgress
                                    color="secondary"
                                    size="2em"
                                />
                            ) : (
                                'Buy Credits'
                            )}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
