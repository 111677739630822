import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useCurrentUser } from '../../my-account/useCurrentUser';
import { Loading } from '../../ui';

const PART_NUM = 10;
const STYLE_COLOR = 'COLOR';
const STYLE_RADIUS = 'RADIUS';

export const useStardustChartUser = ({ data = [], isAdmin = false }) => {
    const chartRef = useRef(null);
    const { user } = useCurrentUser();
    const [clickedUsers, setClickedUsers] = useState([]);
    const [dataset, setDataset] = useState(false);

    // turn true when creating data set
    const getDataAtEvent = useCallback(
        (value) => {
            const selectedBar = value[0]?.index;
            if (!isNaN(selectedBar)) {
                const users = dataset[selectedBar];
                setClickedUsers(users);
            } else {
                setClickedUsers([]);
            }
        },
        [dataset]
    );

    const userIndex = useMemo(() => {
        if (dataset?.length && user?._id) {
            const userId = user._id;
            // find rank no need to do if rank is present
            let datasetUser = null;
            dataset.forEach((users, index) => {
                if (users.some((data) => data._id === userId)) {
                    datasetUser = index;
                }
            });
            return datasetUser;
        } else {
            return null;
        }
    }, [dataset, user]);

    const getPointStyle = useCallback(
        ({ ctx, styleType = STYLE_COLOR }) => {
            const contextIndex = ctx.dataIndex;
            switch (styleType) {
                case STYLE_COLOR: {
                    if (userIndex !== null && userIndex === contextIndex) {
                        return 'rgba(54, 120, 247, 0.8)';
                    }
                    return 'rgba(75, 192, 192, 1)';
                }
                case STYLE_RADIUS: {
                    if (userIndex !== null && userIndex === contextIndex) {
                        return 12;
                    }
                    return 5;
                }
                default:
                    return 's';
            }
        },
        [userIndex]
    );

    const getTooltip = useCallback(
        (ctx) => {
            const contextIndex = ctx.dataIndex;
            const usersLength = ctx.dataset?.data[contextIndex] || 0;
            if (userIndex !== null && userIndex === contextIndex) {
                return 'Your division';
            } else {
                return isAdmin ? `Users ${usersLength}` : '';
            }
        },
        [userIndex, isAdmin]
    );

    useEffect(() => {
        if (data.length) {
            const lastRank = data[data.length - 1].rank;
            let usersClubbed = new Array(PART_NUM).fill(null).map((val) => []);
            data.forEach((user) => {
                // need to calculate normal between 0-100
                const normal = (-100 / lastRank - 1) * (user.rank - lastRank);
                if (normal <= 100 / PART_NUM - 1) {
                    return;
                }
                const currentIndex = Math.trunc(normal / (100 / PART_NUM));
                const rankIndex =
                    currentIndex < 10 ? currentIndex : PART_NUM - 1;

                if (!usersClubbed[rankIndex]) {
                    usersClubbed[rankIndex] = [];
                }
                usersClubbed[rankIndex].push(user);
            });
            setDataset(usersClubbed);
        } else {
        }
    }, [data]);

    // create a chart and return it
    const chart = useMemo(() => {
        // have to change this to some hook that return a chart
        if (dataset.length) {
            const courseDataset = {
                ...courseDataObject,
                data: dataset.map(
                    (users) =>
                        // users.length > 100 ? 16 :
                        users.length
                ),
            };
            // here we have all the labels sorted from hight to low
            const chartData = {
                labels: new Array(PART_NUM).fill(0).map((val, index) => {
                    const decile = PART_NUM - index;
                    return `${decile}${
                        decile === 1 ? 'st' : decile === 2 ? 'nd' : 'th'
                    } Decile`;
                }),
                datasets: [courseDataset],
            };
            const chartOptions = {
                ...options,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => getTooltip(context),
                        },
                    },
                },
                elements: {
                    point: {
                        backgroundColor: (ctx) =>
                            getPointStyle({ ctx, styleType: STYLE_COLOR }),

                        radius: (ctx) =>
                            getPointStyle({ ctx, styleType: STYLE_RADIUS }),
                        hoverRadius: 15,
                    },
                },
            };
            return (
                <Line
                    // getDatasetAtEvent={getDataAtEvent}
                    getElementAtEvent={getDataAtEvent}
                    // getElementsAtEvent={clickedThree}
                    ref={chartRef}
                    data={chartData}
                    options={chartOptions}
                />
            );
        } else {
            return <Loading height="70vh" />;
            // eslint-disable-next-line
        }
    }, [dataset, getDataAtEvent, getTooltip, getPointStyle]);

    return {
        chart,
        chartRef,
        clickedUsers,
    };
};

const courseDataObject = {
    type: 'line',
    label: 'Users',
    fill: false,
    borderColor: 'rgb(75, 192, 192)',
    tension: 0.2,
};

const options = {
    scales: {
        x: {
            title: {
                display: true,
                text: 'Deciles',
            },
        },
        y: {
            grid: {
                display: false,
            },
            title: {
                display: true,
                text: 'No of users',
            },
            ticks: {
                stepSize: 1,
                display: false,
                beginAtZero: true,
            },
        },
    },
};
