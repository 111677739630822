import React from 'react';

import { InputBase, Paper, IconButton } from '..';
import { makeStyles } from 'tss-react/mui';
import { SearchIcon, CloseIcon } from '../../icons';

const useStyles = makeStyles()((theme) => ({
    searchContainer: {
        display: 'flex',
        //width: 300,
        //height: 48,
        // marginLeft: 50,
        // [theme.breakpoints.down('sm')]: {
        //     margin: 0,
        // },
    },
    search: {
        width: '75%',

        height: '100%',
        padding: 10,
        marginRight: 'auto',
    },

    searchBtn: {
        paddingRight: 12,
        paddingLeft: 5,
    },
    searchClrBtn: {
        padding: 0,
    },
}));

export const SearchBox = ({
    searchQuery,
    search,
    handleSearchQueryChange,
    keyPressHandler,
    clearSearch,
    placeHolder,
}) => {
    const { classes } = useStyles();

    return (
        <Paper variant="outlined" className={classes.searchContainer}>
            <InputBase
                fullWidth
                onChange={handleSearchQueryChange}
                onKeyDown={keyPressHandler}
                value={searchQuery}
                placeholder={placeHolder || 'Search'}
                className={classes.search}
            />

            {searchQuery !== '' && (
                <IconButton
                    className={classes.searchClrBtn}
                    onClick={() => {
                        clearSearch();
                    }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            )}

            <IconButton
                className={classes.searchBtn}
                onClick={() => {
                    search();
                }}
                size="large"
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};
