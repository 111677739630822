import { Box, Typography } from '../ui/index';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from './assets/youtube_social_icon_red.png';
import { useStyles } from './styles';

export function IconContainer() {
    const { classes } = useStyles();
    return (
        <Box className={classes.root}>
            <Typography className={classes.textHead} variant="h5">
                FOLLOW US
            </Typography>
            <a
                href="https://www.facebook.com/starweavergroup/"
                target="_blank"
                rel="noreferrer"
            >
                <FacebookIcon
                    style={{
                        opacity: '0.9',
                        color: '#1877f2',
                        cursor: 'pointer',
                    }}
                />
            </a>
            <a
                style={{ paddingLeft: '10px' }}
                href="https://twitter.com/starweavergroup"
                target="_blank"
                rel="noreferrer"
            >
                <TwitterIcon
                    style={{
                        opacity: '0.9',
                        color: '#00acee',
                        cursor: 'pointer',
                    }}
                />
            </a>
            <a
                style={{ paddingLeft: '10px' }}
                href="https://www.linkedin.com/company/starweaver"
                target="_blank"
                rel="noreferrer"
            >
                <LinkedInIcon
                    style={{
                        opacity: '0.9',
                        color: '#0e76a8 ',
                        cursor: 'pointer',
                    }}
                />
            </a>
            <a
                style={{ paddingLeft: '10px' }}
                href="https://www.pinterest.ca/starweavergroup/"
                target="_blank"
                rel="noreferrer"
            >
                <PinterestIcon
                    style={{
                        opacity: '0.9',
                        color: '#c8232c ',
                        cursor: 'pointer',
                    }}
                />
            </a>
            <a
                style={{ paddingLeft: '10px' }}
                href="https://www.instagram.com/starweavergroup/"
                target="_blank"
                rel="noreferrer"
            >
                <InstagramIcon
                    style={{
                        opacity: '0.9',
                        color: '#8a3ab9',
                        cursor: 'pointer',
                    }}
                />
            </a>
            <a
                style={{ paddingLeft: '10px' }}
                href="https://www.youtube.com/c/starweavergroup"
                target="_blank"
                rel="noreferrer"
            >
                <img src={YouTubeIcon} alt="Youtube" height={21} />
                {/* <YouTubeIcon
                    style={{
                        color: '#FF0000',
                        opacity: '0.9',
                        cursor: 'pointer',
                    }}
                /> */}
            </a>
        </Box>
    );
}
