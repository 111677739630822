import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormGroup,
    FormControl,
    FormControlLabel,
    Switch,
} from '@mui/material';

// title: Title of the Accordion
// handleChange: function to handle the change in the button
// filterValue: Values for the Filter
// value for the current filter
export const FilterAccordionSwitches = ({
    title,
    handleChange,
    filterValue,
    filterLabels = [],
    value = [],
}) => {
    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl component="fieldset">
                    <FormGroup>
                        {filterValue.map((filter, index) => (
                            <>
                                <FormControlLabel
                                    label={filterLabels[index]}
                                    value={filter}
                                    control={
                                        <Switch
                                            checked={value.includes(
                                                `${filter}`
                                            )}
                                            onChange={handleChange}
                                        />
                                    }
                                />
                            </>
                        ))}
                    </FormGroup>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
};
