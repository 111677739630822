import { Box, Grid, IconButton, Typography, Button } from '../ui';
import Fade from '@mui/material/Fade';
import { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './styles';
import { useNavigate, Link } from 'react-router-dom';
import { ChevronRightIcon, ChevronLeftIcon } from '../icons';

const defaultHeading = (
    <>
        {' '}
        You don't want to miss these <strong>LIVE</strong> courses
    </>
);

export const OnAirCarousel = ({
    data,
    heading = defaultHeading,
    showViewAll = true,
    linkPath = '/on-air',
    handleOnClick = (item) => {
        console.log('No On Save Function');
        console.log(item);
    },
    showActions = false,
}) => {
    const { classes } = useStyles();
    const currentIndex = useRef(0);
    const [displayCourse, setDisplayCourse] = useState(null);
    const [size, setSize] = useState(3);
    const [loaded, setLoaded] = useState(false);
    let navigate = useNavigate();
    const isLarge = useMediaQuery('(min-width:1200px)');
    const isMedium = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        if (isLarge) {
            setSize(4);
        } else if (isMedium) {
            setSize(2);
        } else {
            setSize(1);
        }
    }, [isLarge, isMedium]);

    const setNext = () => {
        if (data) {
            currentIndex.current += size;
            if (currentIndex.current > data.length - 1) {
                currentIndex.current = 0;
            }
            const newAr = data.slice(
                currentIndex.current,
                currentIndex.current + size
            );
            setDisplayCourse(newAr);
            setLoaded(true);
        }
    };

    const setPrev = () => {
        if (data) {
            currentIndex.current -= size;
            if (currentIndex.current < 0) {
                currentIndex.current = data.length - size;
            }
            const newAr = data.slice(
                currentIndex.current,
                currentIndex.current + size
            );
            setDisplayCourse(newAr);
            setLoaded(true);
        }
    };

    useEffect(() => {
        if (size && data) {
            currentIndex.current = -size;
            setNext();
        }
    }, [size, data]);

    const handleClick = (course) => {
        navigate(`on-air/${course.name}`);
    };

    return (
        <Box my={3}>
            <Typography
                className={`${classes.sectionHeading} ${classes.changeside}`}
            >
                {heading}
                &nbsp;
            </Typography>
            {showViewAll && (
                <Link to={linkPath} className={classes.link}>
                    view all&nbsp;
                    <ChevronRightIcon size="small" />{' '}
                </Link>
            )}

            <Grid
                container
                justifyContent="center"
                className={classes.cardsConatiner}
            >
                {displayCourse ? (
                    <>
                        {displayCourse.map((item, index) => {
                            let delay = 0;
                            if (index > 0) {
                                delay = Math.trunc((500 / size) * index);
                            }
                            return (
                                <Fade
                                    in={loaded}
                                    style={{
                                        transitionDelay: loaded
                                            ? `${delay}ms`
                                            : '0ms',
                                    }}
                                >
                                    <Grid key={index} item xs={12 / size}>
                                        <Box
                                            className={classes.mainBoxWrap}
                                            onClick={() => handleClick(item)}
                                        >
                                            <Box className={classes.mainBox}>
                                                <Box
                                                    className={classes.cardImg}
                                                >
                                                    <img
                                                        src={item.sliderImage}
                                                        width="100%"
                                                        alt="on air"
                                                    ></img>
                                                </Box>
                                                <Box
                                                    component="div"
                                                    className={
                                                        classes.contentBox
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.cardHeading
                                                        }
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                    <Box
                                                        className={
                                                            classes.contentBox
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.time
                                                            }
                                                        >
                                                            {item.instructor} |{' '}
                                                            {item.domain} |{' '}
                                                            {item.time} |{' '}
                                                            {item.timeZone}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.infoContainer
                                                        }
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{
                                                                paddingLeft: 10,
                                                                fontSize: 14,
                                                            }}
                                                            className={
                                                                classes.date
                                                            }
                                                        >
                                                            {item.date}
                                                        </Typography>
                                                    </Box>

                                                    <p className={classes.desc}>
                                                        {item.description.substring(
                                                            0,
                                                            150
                                                        )}
                                                    </p>

                                                    {showActions && (
                                                        <Box
                                                            className={
                                                                classes.cardActions
                                                            }
                                                        >
                                                            <Button
                                                                className={
                                                                    classes.cardBtn
                                                                }
                                                                color="secondary"
                                                                variant="contained"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleOnClick(
                                                                        item
                                                                    );
                                                                }}
                                                            >
                                                                You are in
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Fade>
                            );
                        })}
                    </>
                ) : (
                    ''
                )}
                {displayCourse && displayCourse.length > 3 && (
                    <>
                        <IconButton
                            className={`${classes.controlBtn} ${classes.left}`}
                            onClick={() => {
                                if (size >= data.length) {
                                    return;
                                }
                                setLoaded(false);
                                setTimeout(() => setPrev(), 500);
                            }}
                            size="large"
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        <IconButton
                            className={`${classes.controlBtn} ${classes.right}`}
                            onClick={() => {
                                if (size >= data.length) {
                                    return;
                                }
                                setLoaded(false);
                                setTimeout(() => setNext(), 500);
                            }}
                            size="large"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </>
                )}
            </Grid>
        </Box>
    );
};
