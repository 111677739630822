import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

// title: Title of the Accordian
// handleChange: function to handle the change in the button
// filterValue: Values for the Filter
// value for the current filter
export const FilterAccordianCheckbox = ({
    title,
    handleChange,
    filterValue,
    filterLabels = [],
    value = [],
}) => {
    return (
        <Accordion elevation={3}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl component="fieldset">
                    <FormGroup>
                        {filterValue.map((filter, index) => (
                            <FormControlLabel
                                key={index}
                                value={filter}
                                label={filterLabels[index]}
                                control={
                                    <Checkbox
                                        checked={value.includes(`${filter}`)}
                                        color="secondary"
                                        name={filter}
                                        onChange={handleChange}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
};
