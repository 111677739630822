import { Box, Grid, Divider, Container } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { HeaderContainer } from './HeaderContainer';
import { CompanyContainer } from './CompanyContainer';
import { PartnerContainer } from './PartnerContainer';
import { CommunityContainer } from './CommunityContainer';
import { BusinessContainer } from './BusinessContainer';
import { IconContainer } from './IconContainer';
import { ContentContainer } from './ContentContainer';

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: '#131313',
        // paddingLeft: '10em',
        // paddingTop: '4em',
        // paddingBottom: '5em',
    },
    gridItem: {
        minWidth: '250px',
        maxWidth: '350px',
    },
}));

export function FooterMain() {
    const { classes } = useStyles();

    return (
        <Box className={classes.root} /* mt={6} */>
            <Container maxWidth="xl">
                <HeaderContainer />
                <Grid container item>
                    {/* <Grid item xs={12}>
                        <HeaderContainer />
                    </Grid> */}
                    <Grid item className={classes.gridItem}>
                        <CompanyContainer />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <CommunityContainer />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <BusinessContainer />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <PartnerContainer />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <IconContainer />
                    </Grid>
                    <Grid xs={12} item>
                        <ContentContainer />
                    </Grid>

                    <Divider style={{ backgroundColor: 'green' }} />

                    <Grid item xs={12}>
                        <Box
                            style={{
                                color: '#999',
                                paddingTop: '25px',
                                borderTop: '1px solid #999',
                                marginTop: '25px',
                            }}
                        >
                            © Starweaver Group, Inc. All Rights Reserved.
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default FooterMain;
