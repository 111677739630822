import axios from 'axios';

export const unsubscribeUser = async (userId) => {
    try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();

        if (subscription) {
            await subscription.unsubscribe();
            console.log('User unsubscribed from push notifications');

            // Optionally, remove the subscription from the server
            await axios.post('/api/users/unsubscribe', {
                endpoint: subscription.endpoint,
                userId,
            });
        }
    } catch (error) {
        console.error('Failed to unsubscribe user: ', error);
    }
};
