import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import star from './star.svg';
import moment from 'moment';

const LastRow = ({ card }) => {
    return (
        <Grid container>
            {/* ROW 1 */}
            <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                // style={{ backgroundColor: 'grey' }}
            >
                <Grid
                    xs={7}
                    style={{
                        // border: '2px solid red',
                        height: '3.2rem',
                        overflow: 'hidden',
                        zIndex: 10,
                        // backgroundColor: 'pink',
                    }}
                    // onMouseEnter={(e) => {
                    //     e.currentTarget.style.overflow = 'visible';
                    // }}
                    // onMouseLeave={(e) => {
                    //     e.currentTarget.style.overflow = 'hidden';
                    // }}
                >
                    <Box>
                        <Typography
                            style={{
                                // backgroundColor: 'white',
                                fontWeight: 'bold',
                                textOverflow: 'ellipsis',
                                display: 'block',
                                display: '-webkit-box',
                                '-webkit-line-clamp': '2',
                                '-webkit-box-orient': 'vertical',
                            }}
                            // onMouseEnter={(e) => {
                            //     e.currentTarget.style.backgroundColor =
                            //         '#dbf3ff';
                            //     e.currentTarget.style.padding = '0.3rem';
                            // }}
                            // onMouseLeave={(e) => {
                            //     e.currentTarget.style.backgroundColor = '';
                            //     e.currentTarget.style.padding = '0';
                            // }}
                        >
                            {card.Title}
                        </Typography>
                    </Box>
                </Grid>

                <Box display="flex">
                    <p
                        xs={2}
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'end',
                            margin: '5px',
                        }}
                    >
                        {card.Rating}
                    </p>
                    <img
                        style={{ width: '1.1rem', height: '1.8rem' }}
                        src={star}
                        alt="rating icon"
                    />
                </Box>
            </Grid>
            {/* ROW 2 */}
            <Grid item xs={12} style={{ zIndex: 0 }}>
                {card.Subcategory}
            </Grid>
            {card.Author != '' && card.Author != null ? (
                <Box
                    display="flex"
                    style={{ textAlign: 'start', width: '100%' }}
                >
                    <label style={{ fontWeight: 'bold' }}>
                        Instructor:&nbsp;
                    </label>
                    <label>{card.Author}</label>
                </Box>
            ) : (
                <label style={{ width: '2rem', height: '1rem' }}></label>
            )}
            {/* SPACING */}
            <Grid item style={{ height: '3rem' }} />
            {/* ROW 3 */}
            <Grid
                item
                xs={12}
                style={{
                    textDecoration: 'line-through',
                }}
            >
                ${card.PriceOld}
            </Grid>
            {/* ROW 4 */}
            <Grid xs={12}>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                    {card.DiscountP === 100
                        ? 'Free'
                        : `$${card.PriceDiscounted}`}
                </Typography>
            </Grid>
            {/* SPACING */}
            <Grid item style={{ height: '3rem' }} />

            {/* ------------------------------------------------ROW 5 - start  */}
            <Grid item container direction="row" justifyContent="space-between">
                {/* watch icon */}
                <Grid
                    item
                    container
                    direction="row"
                    xs={7}
                    style={{ opacity: '0.7' }}
                >
                    <Grid xs={2}>
                        <ScheduleIcon
                            style={{
                                height: '1.3rem',
                                width: '1.3rem',
                            }}
                        />
                    </Grid>
                    <Grid xs={8}>
                        {moment.utc(card.CreatedD).from(moment())}
                    </Grid>
                </Grid>
                <Box display="flex" xs={4} style={{ opacity: '0.7' }}>
                    <ChatBubbleOutlineIcon
                        style={{
                            height: '1.3rem',
                            width: '2.3rem',
                        }}
                    />
                    <label xs={6}>{card.Reviews}</label>
                </Box>
            </Grid>
            {/* ------------------------------------------------ROW 5 - end  */}
        </Grid>
    );
};

export default LastRow;
