import {
    red,
    blue,
    pink,
    yellow,
    green,
    lime,
    lightBlue,
    purple,
    orange,
    deepOrange,
} from '@mui/material/colors';
export const defaultColorArray = [
    red[500],
    blue[500],
    pink[500],
    green[500],
    yellow[800],
    lime[600],
    lightBlue[500],
    purple[500],
    orange[500],
    deepOrange[500],
];
