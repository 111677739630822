import React from 'react';
import { Box, Typography } from '..';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    thumbnailInput: {
        opacity: 0,
        position: 'absolute',
        zIndex: -1,
    },

    thumbnailInputLabel: {
        cursor: 'pointer',
    },
    filePicker: {
        width: 400,
        height: 200,
        border: `4px dashed ${theme.palette.grey[700]}`,
        backgroundColor: theme.palette.grey[300],
        borderRadius: 20,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const ImagePicker = ({ selectedImage, setSelectedImage }) => {
    const { classes } = useStyles();

    return (
        <>
            {selectedImage ? (
                <div>
                    <img
                        alt="thumnail"
                        width={'400px'}
                        src={
                            typeof selectedImage === 'string'
                                ? selectedImage
                                : URL.createObjectURL(selectedImage)
                        }
                    />
                    <br />
                    <button onClick={() => setSelectedImage(null)}>
                        Edit thumbnail
                    </button>
                </div>
            ) : (
                <>
                    <label
                        for="upload-photo"
                        className={classes.thumbnailInputLabel}
                    >
                        <Box className={classes.filePicker}>
                            <Typography variant="h6">
                                Upload Thumbnail
                            </Typography>
                        </Box>
                    </label>
                    <input
                        id="upload-photo"
                        className={classes.thumbnailInput}
                        type="file"
                        name="myImage"
                        onChange={(event) => {
                            setSelectedImage(event.target.files[0]);
                        }}
                    />
                </>
            )}
        </>
    );
};
