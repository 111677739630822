import React from 'react';
import { Box, Container, Typography } from '../ui';
import { Navigate } from 'react-router-dom';
import { SignUpContent } from '../auth/SignUpContent';
import { useStyles } from './styles';
import { useUser } from '../auth';
import { features, Features } from '../forOrganisation';
import { TopCompany } from './TopCompany';
import { landingPageRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';
import { NewBanner } from './NewBanner';
import { FeaturedCourses } from './FeaturedCourses';
import { useAllFeaturedCourses } from './useAllFeaturedCourses';
import { InstructorContent } from './InstructorContent';
import { Testimonies } from './Testimonies';
import { JourneySection } from './JourneySection';
import { useMediaQuery } from '@mui/material';
import { sentenceCase } from '../util';
import { ArrowIcon } from '../icons/ArrowIcon';
// import JourneyBuilder from '../constellations/JourneyBuilder';
// import { useCallback, useEffect, useState } from 'react';

export const LandingPage = () => {
    const { classes } = useStyles();
    const { isLoading, tabsState } = useAllFeaturedCourses();
    const { isLoading: isUserLoading, user } = useUser();
    const isSmall = useMediaQuery('(max-width:450px)');

    // const [inBetween, setInBetween] = useState(false);
    // const [bounceStart, setBounceStart] = useState(false);

    // const workBounce = useCallback(() => {
    //     if(!bounceStart){
    //         setBounceStart(true);
    //     }else{
    //     }
    // },[bounceStart, inBetween])

    // React.useEffect(() => {
    //     // let startId;
    //     // startId = setTimeout(() => {
    //     // });
    //     // return () => clearTimeout(startId);

    // }, []);

    // if (
    //     window.location.hostname ===
    //         process.env.REACT_APP_JOURNEYBUILDER_HOSTNAME
    //     // window.location.port == 3005
    // )
    //     return <JourneyBuilder />;

    return (
        <Box mb={6}>
            {!isUserLoading ? (
                <>
                    {user ? (
                        <Navigate to="/on-air" replace />
                    ) : (
                        <>
                            <HelmetWrapper route={route} />
                            <NewBanner isSmall={isSmall} />
                            {/* <Banner /> */}

                            {isLoading && (
                                <div
                                    style={{
                                        minHeight: '500px',
                                        minWidth: '200px',
                                        background: 'rgba(255,0,0,0)',
                                    }}
                                >
                                    Courses Loading...
                                </div>
                            )}

                            <FeaturedCourses
                                isLoading={isLoading}
                                tabs={tabsState}
                                maxWidth="xl"
                            />
                            <Box
                                mt={15}
                                className={classes.sectionBackground}
                                mb={20}
                            >
                                <Container maxWidth="xl">
                                    <Box className={classes.backgroundImage} />
                                    <Box>
                                        <Typography
                                            align="center"
                                            className={`${classes.sectionHeading} ${classes.colorBlue}`}
                                            variant="h3"
                                        >
                                            {sentenceCase(
                                                'DELIVERING TOP QUALITY COMPETENCY RESULTS FOR 100S OF GLOBAL COMPANIES'
                                            )}
                                        </Typography>
                                    </Box>

                                    <TopCompany showHeading={false} />
                                </Container>
                            </Box>

                            <Container maxWidth="xl">
                                {/* <Categories /> */}
                                <Box mt={8} />
                                <Box>
                                    <Typography
                                        align="center"
                                        className={`${classes.sectionHeading} ${classes.colorBlue}`}
                                        variant="h3"
                                    >
                                        <p
                                            style={{
                                                margin: '0 0 10px',
                                            }}
                                        >
                                            Focused & specialized content in
                                        </p>
                                        key subject domains, certifications and
                                        journeys
                                        {/* career  */}
                                        <Box className={classes.followIcon}>
                                            <ArrowIcon
                                                width={'65px'}
                                                height={'65px'}
                                            />
                                        </Box>
                                    </Typography>
                                    <Features cards={features} />
                                </Box>
                                <Box>
                                    <InstructorContent />
                                </Box>
                                <Box mt={15}>
                                    <Box my={5}>
                                        <JourneySection />
                                    </Box>
                                    <Typography
                                        align="center"
                                        className={`${classes.hideLines} ${classes.colorBlue} ${classes.sectionHeading}`}
                                        variant="h3"
                                    >
                                        {sentenceCase('WHAT PEOPLE ARE SAYING')}
                                    </Typography>
                                    <Box mt={2}>
                                        <Testimonies />
                                    </Box>
                                </Box>
                            </Container>

                            <SignUpContent
                                showFeatures={false}
                                showLearning={false}
                                showTestimony={false}
                                showDomainFeatures={false}
                            />
                        </>
                    )}
                </>
            ) : (
                <Box className={classes.pushFooterDown} />
            )}
        </Box>
    );
};
