import { createSlice } from '@reduxjs/toolkit';

export const COMMENT = 'comment';
export const REPLY = 'reply';

const initialState = {
    isFile: false,
    isLoading: false,
    isError: false,
    commentId: '',
    closeEditor: false,
    type: COMMENT,
    replyId: '',
    loadAttachments: {
        commentId: '',
        loading: false,
    },
};

export const commentAttachmentSlice = createSlice({
    name: 'commentAttachments',
    initialState,
    reducers: {
        setCommentId: (state, action) => {
            state.commentId = action.payload;
        },
        setReplyId: (state, action) => {
            state.replyId = action.payload;
        },
        setIsFile: (state, action) => {
            state.isFile = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setCloseEditor: (state, action) => {
            state.closeEditor = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setLoadAttachments: (state, action) => {
            state.loadAttachments = action.payload;
        },
        reset: (state) => {
            state.isFile = false;
            state.isLoading = false;
            state.isError = false;
            state.commentId = '';
            state.replyId = '';
            state.closeEditor = false;
            state.type = COMMENT;
            state.loadAttachments.commentId = '';
            state.loadAttachments.loading = false;
        },
    },
});

export const {
    setCommentId,
    setIsFile,
    setIsLoading,
    reset,
    setCloseEditor,
    setType,
    setReplyId,
    setLoadAttachments,
} = commentAttachmentSlice.actions;

export default commentAttachmentSlice.reducer;
