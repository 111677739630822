import { useState } from 'react';
import {
    Dialog,
    Box,
    Typography,
    IconButton,
    Button,
    TextField,
    Alert,
} from '../ui';
import axios from 'axios';
import { CloseIcon } from '../icons';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    dialogContainer: {
        padding: '0px 15px 20px',
        width: 'min(80vw, 600px)',
        position: 'relative',
    },
    actions: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-end',
    },
    btn: {
        marginRight: '10px',
        textTransform: 'capitalize',
    },
    closeBtn: {
        position: 'absolute',
        top: '10px',
        right: '20px',
    },
}));

export const ForgotPasswordDialog = ({ open, setOpen }) => {
    const [email, setEmail] = useState('');
    const [processing, setProcessing] = useState(false);
    const [linkSent, setLinkSent] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { classes } = useStyles();

    function handleClose() {
        setOpen(false);
    }

    const sendPasswordResetLink = async () => {
        if (email === '') {
            setError('Please Enter The Email');
            return;
        }
        setProcessing(true);
        try {
            await axios.get(`/api/users/${email}/reset-password`);
            setError('');
            setLinkSent(true);
        } catch (error) {
            console.log(error);
            setError('Enter your registered email');
        }
        setProcessing(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            {!linkSent ? (
                <Box className={classes.dialogContainer}>
                    <IconButton
                        className={classes.closeBtn}
                        aria-label="close"
                        onClick={handleClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box my={3}>
                        <Typography
                            variant="h5"
                            color="secondary"
                            gutterBottom
                            align="center"
                        >
                            Reset Password
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400 }}
                            gutterBottom
                            align="center"
                        >
                            Fill in an email account registered with us to
                            receive a password reset link.
                        </Typography>
                    </Box>
                    <Box component="div" my={3}>
                        <TextField
                            fullWidth
                            color="secondary"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            required
                        />
                    </Box>
                    <Box className={classes.actions}>
                        <Button
                            fullWidth
                            disabled={linkSent || processing}
                            color="secondary"
                            variant="contained"
                            className={classes.btn}
                            onClick={sendPasswordResetLink}
                        >
                            {processing ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                'Send Reset Link'
                            )}
                        </Button>
                    </Box>
                    <Box my={2}>
                        {error && (
                            <Alert
                                variant="filled"
                                severity="error"
                                onClose={() => setError('')}
                            >
                                {error}
                            </Alert>
                        )}
                        {success && (
                            <Alert
                                variant="filled"
                                severity="info"
                                onClose={() => setSuccess('')}
                            >
                                {success}
                            </Alert>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box className={classes.dialogContainer}>
                    <IconButton
                        className={classes.closeBtn}
                        aria-label="close"
                        onClick={handleClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box my={3}>
                        <Typography
                            variant="h5"
                            color="secondary"
                            gutterBottom
                            align="center"
                        >
                            Password Reset Sent
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 100 }}
                            gutterBottom
                            align="center"
                        >
                            Thank you. A reset email has been sent. If not
                            received, contact us at
                            <b> security@starweaver.com </b>
                        </Typography>
                    </Box>

                    <Box className={classes.actions}>
                        {
                            <Button
                                fullWidth
                                disabled={!linkSent}
                                color="secondary"
                                variant="contained"
                                className={classes.btn}
                                onClick={handleClose}
                            >
                                Got it
                            </Button>
                        }
                    </Box>
                    <Box my={2}>
                        {error && (
                            <Alert
                                variant="filled"
                                severity="error"
                                onClose={() => setError('')}
                            >
                                {error}
                            </Alert>
                        )}
                        {success && (
                            <Alert
                                variant="filled"
                                severity="info"
                                onClose={() => setSuccess('')}
                            >
                                {success}
                            </Alert>
                        )}
                    </Box>
                </Box>
            )}
        </Dialog>
    );
};
