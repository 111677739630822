import { useEffect, useState } from 'react';
import axios from 'axios';

export const useUsersSearch = (
    query,
    pageNumber,
    resultsPerPage,
    admin,
    instructor,
    clientId
) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        setResults([]);
    }, [query]);

    useEffect(() => {
        let cancel = () => {};

        setLoading(true);
        setError(false);

        axios({
            method: 'GET',
            url: `/api/admin/users/search`,
            params: {
                q: query,
                p: pageNumber,
                n: resultsPerPage,
                a: admin ? 1 : 0,
                i: instructor ? 1 : 0,
                c: clientId,
            },
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
            .then((res) => {
                // console.log(res.data);
                setResults((prevResult) => {
                    return [...prevResult, ...res.data];
                });
                // console.log(res.data.length < resultsPerPage ? false : true);
                setHasMore(res.data.length < resultsPerPage ? false : true);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                if (axios.isCancel(e)) return;
                setError(true);
            });

        return () => cancel();
    }, [query, pageNumber]);

    return { loading, error, results, hasMore };
};
