import { useState, useEffect } from 'react';
import axios from 'axios';
import { Close } from '@mui/icons-material';
import {
    CircularProgress,
    Box,
    Modal,
    Fade,
    Typography,
    IconButton,
    Button,
    useTheme,
    useMediaQuery,
    FormControl,
    Select,
    MenuItem,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { LinearLoader, getBaseURL } from '../../util';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../app/recoil';
import { SWAutoComplete } from './SWAutoComplete';
import SettingsIcon from '@mui/icons-material/Settings';
import SendIcon from '@mui/icons-material/Send';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ADMIN = 'Admin';
const INSTRUCTOR = 'Instructor';

const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '0.25rem',
        maxWidth: 'min(90vw, 34.125rem)',
        width: '100%',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modalTitle: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1.25rem',
        fontWeight: '500',
        lineHeight: 'normal',
        marginRight: 'auto',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
        },
    },
    reqTitle: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1rem',
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
            fontWeight: 500,
            fontSize: '1.25rem',
        },
    },
    menu: {
        alignSelf: 'flex-end',
        position: 'relative',
    },
    menuItems: {
        position: 'absolute',
        right: 0,
        top: '105%',
        fontSize: '16px',
        zIndex: 1,
        '& .menuItem': {
            padding: '3px 0 3px 0',
            '& .item': {
                fontSize: '1rem',
                padding: '5px 16px',
                textAlign: 'center',
                width: '100%',
                '&:hover': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
        '& .menuItem:not(:last-child)': {
            borderBottom: '1px solid #cbcaca',
        },
    },
    copyOptions: {
        marginTop: theme.spacing(2),
    },
    group: {
        display: 'flex',
        alignItems: 'center',
        paddingBlock: theme.spacing(2),
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        '&:first-child': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
    userType: {
        marginRight: 'auto',
    },
    userIconWrapper: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '100vmax',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0.5),
        alignSelf: 'flex-start',
    },
}));

let timeoutId = null;

export const ShareModal = ({ openModal, setOpenModal, title, id, setMatchingInstructorModal = undefined }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const [loading, setLoading] = useState(false);
    const setAlert = useSetRecoilState(alertState);
    const [userType, setUserType] = useState(ADMIN);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [query, setQuery] = useState('');
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [page, setPage] = useState(0);
    const [showCopyOpt, setShowCopyOpt] = useState(false);
    const [selectedUserType, setSelectedUserType] = useState('admin');

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            (async function () {
                try {
                    setLoadingUsers(true);
                    const baseURL = '/api/client-requirements/search-users';
                    const params = new URLSearchParams();
                    if (query.length > 0) {
                        params.append('query', query);
                    }
                    params.append('isAdmin', userType === ADMIN ? 1 : 0);
                    params.append(
                        'isInstructor',
                        userType === INSTRUCTOR ? 1 : 0
                    );
                    params.append('page', page);
                    params.append('rows', 10);
                    const url = `${baseURL}?${params.toString()}`;
                    const userRes = await axios.get(url);
                    if (userRes.data.users?.length > 0) {
                        setUsers((prev) => {
                            const allUsers = [...prev, ...userRes.data.users];
                            const uniqueUsersMap = new Map();
                            allUsers.forEach((user) => {
                                if (!uniqueUsersMap.has(user._id)) {
                                    uniqueUsersMap.set(user._id, user);
                                }
                            });
                            return Array.from(uniqueUsersMap.values());
                        });
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingUsers(false);
                }
            })();
        }, 300);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [page, query, userType]);

    const handleCloseModal = () => {
        setOpenModal(false);
        if (setMatchingInstructorModal){
            setMatchingInstructorModal((prev) => ({...prev, isOpen: true}))
        }
    };

    const reset = () => {
        setPage(0);
        setUsers([]);
    };

    const handleChangeUserType = (e) => {
        reset();
        setUserType(e.target.value);
    };

    const handleClear = () => {
        reset();
        setQuery('');
        setSelectedUsers([]);
    };

    const onSearch = (e) => {
        reset();
        setQuery(e.target.value);
    };

    const removeSelectedUser = (userId) => {
        setSelectedUsers((users) => {
            return users.filter((user) => user._id !== userId);
        });
    };

    const handleSend = async () => {
        try {
            if (selectedUsers.length === 0) return;
            setLoading(true);
            const payload = {
                users: selectedUsers.map((user) => ({
                    _id: user._id,
                    fullName: user.title,
                    email: user.subTitle,
                    isInstructor: user.isInstructor,
                    isAdmin: user.isAdmin,
                    requirementTitle: title,
                    requirementURL: user.isInstructor
                        ? getBaseURL(
                              `/profile/instructor-dashboard/opportunity-management/details/${id}`
                          )
                        : getBaseURL(
                              `/admin/opportunity-management/details/${id}`
                          ),
                })),
            };
            const res = await axios.post(
                '/api/client-requirements/send-requirement-info',
                payload
            );
            if (res.status === 200) {
                setAlert({
                    show: true,
                    severity: severity.SUCCESS,
                    message: res.data.message,
                });
            }
        } catch (e) {
            console.log(e);
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: "Something wen't wrong",
            });
        } finally {
            setLoading(false);
            handleCloseModal();
        }
    };

    const handleCopyClick = () => {
        setShowCopyOpt(true);
    };

    const handleBack = () => {
        setShowCopyOpt(false);
    };

    const handleSelectOpt = (e) => {
        setSelectedUserType(e.target.value);
    };

    const handleCopyLink = async () => {
        const url =
            selectedUserType === 'admin'
                ? getBaseURL(`/admin/opportunity-management/details/${id}`)
                : getBaseURL(
                      `/profile/instructor-dashboard/opportunity-management/details/${id}`
                  );

        try {
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(url);
            } else {
                document.execCommand('copy', true, url);
            }
            setAlert({
                show: true,
                message: 'URL Copied successfully',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Box className="container">
            <Modal
                aria-labelledby="share modal"
                aria-describedby="share modal"
                className={classes.modal}
                open={openModal}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //     timeout: 300,
                // }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <Box className={classes.modalContainer}>
                            {/* header */}
                            <Box className={classes.modalHeader}>
                                {showCopyOpt && (
                                    <IconButton
                                        size="small"
                                        onClick={handleBack}
                                        sx={{
                                            marginLeft: '-5px',
                                            marginRight: '5px',
                                        }}
                                    >
                                        <ChevronLeftRoundedIcon fontSize="small" />
                                    </IconButton>
                                )}

                                {/* modal title */}
                                <Typography
                                    variant="h5"
                                    className={classes.modalTitle}
                                >
                                    Share
                                </Typography>

                                {/* close btn */}
                                <IconButton
                                    onClick={handleCloseModal}
                                    size="small"
                                >
                                    <Close />
                                </IconButton>
                            </Box>

                            <Divider />

                            {/* body */}
                            <Box className={classes.modalBody} mt={1}>
                                <Typography
                                    variant="h6"
                                    className={classes.reqTitle}
                                >
                                    {title}
                                </Typography>
                            </Box>

                            {!showCopyOpt && (
                                <Box my={2}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        color="secondary"
                                        size={isMedium ? 'medium' : 'small'}
                                    >
                                        <Select
                                            labelId="user-type"
                                            id="userType"
                                            value={userType}
                                            onChange={handleChangeUserType}
                                        >
                                            <MenuItem value={ADMIN}>
                                                {ADMIN}
                                            </MenuItem>
                                            <MenuItem value={INSTRUCTOR}>
                                                {INSTRUCTOR}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}

                            {!showCopyOpt && (
                                <Box>
                                    {loadingUsers && <LinearLoader />}
                                    <SWAutoComplete
                                        query={query}
                                        handleChangeQuery={onSearch}
                                        handleRemoveSelectedUser={
                                            removeSelectedUser
                                        }
                                        handleClear={handleClear}
                                        rows={users.map((user) => ({
                                            _id: user._id,
                                            title: user.fullName,
                                            subTitle: user.email,
                                            imgURL: user.profilePicUrl,
                                            isInstructor: user.isInstructor,
                                            isAdmin: user.isAdmin,
                                        }))}
                                        selectedRows={selectedUsers}
                                        setSelectedRows={setSelectedUsers}
                                        setPage={setPage}
                                        showClearBtn={selectedUsers.length > 0}
                                    />
                                </Box>
                            )}

                            {showCopyOpt && (
                                <FormControl
                                    fullWidth
                                    className={classes.copyOptions}
                                >
                                    <RadioGroup
                                        onChange={handleSelectOpt}
                                        value={selectedUserType}
                                    >
                                        <Box className={classes.group}>
                                            <Box
                                                className={
                                                    classes.userIconWrapper
                                                }
                                            >
                                                <AdminPanelSettingsIcon
                                                    color="primary"
                                                    fontSize="medium"
                                                />
                                            </Box>
                                            <Box className={classes.userType}>
                                                <Typography
                                                    fontWeight={'500'}
                                                    variant="body1"
                                                >
                                                    Admin
                                                </Typography>
                                                <Typography variant="caption">
                                                    Share with TAD & CAD.
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    value="admin"
                                                    control={
                                                        <Radio color="secondary" />
                                                    }
                                                    color="secondary"
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={classes.group}>
                                            <Box
                                                className={
                                                    classes.userIconWrapper
                                                }
                                            >
                                                <GroupIcon
                                                    color="primary"
                                                    fontSize="medium"
                                                />
                                            </Box>
                                            <Box className={classes.userType}>
                                                <Typography
                                                    fontWeight={'500'}
                                                    variant="body1"
                                                >
                                                    Instructor
                                                </Typography>
                                                <Typography variant="caption">
                                                    Share With SME
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    value="instructor"
                                                    control={
                                                        <Radio color="secondary" />
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </RadioGroup>
                                </FormControl>
                            )}

                            {/* footer */}
                            <Box
                                className={classes.btnGroup}
                                mt={4}
                                display={'flex'}
                                justifyContent={'flex-end'}
                                sx={{ gap: (theme) => theme.spacing(1) }}
                            >
                                {!showCopyOpt ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        endIcon={
                                            <SettingsIcon fontSize="small" />
                                        }
                                        onClick={handleCopyClick}
                                    >
                                        Copy Link
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        endIcon={
                                            <ContentCopyIcon fontSize="small" />
                                        }
                                        onClick={handleCopyLink}
                                    >
                                        Copy
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    // onClick={onSend}
                                    onClick={handleSend}
                                    disabled={
                                        loading ||
                                        loadingUsers ||
                                        selectedUsers.length === 0
                                    }
                                    size={isMedium ? 'medium' : 'small'}
                                    endIcon={<SendIcon fontSize="small" />}
                                >
                                    {loading ? (
                                        <CircularProgress size="1.9em" />
                                    ) : (
                                        <>Send</>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </Fade>
            </Modal>
        </Box>
    );
};
