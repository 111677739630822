import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { AppBar, Tab, Tabs } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    appbar: {
        boxShadow: 'none',
        backgroundColor: '#fafafa',
    },
    tabLabel: {
        fontWeight: 600,
        fontSize: '1.1em',
    },
}));
export const DomainsTabs = ({ collections, value, setValue }) => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    // STATIC DATA

    const handleChange = (event, newValue) => {
        //console.log(newValue);
        //setValue(newValue);
        const domain = collections[newValue].value;

        navigate(`/journeys/${domain}`);
    };

    return (
        <>
            <AppBar position="static" className={classes.appbar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // WE CAN USE THIS IN COLOR IN THE COURSES PAGE TOO
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {collections.map((collection, index) => {
                        return (
                            <Tab
                                className={classes.tabLabel}
                                key={index}
                                /* value={index} */
                                label={collection.name}
                                swid={'course-tabs-' + collection.name}
                            />
                        );
                    })}
                </Tabs>
            </AppBar>
        </>
    );
};
