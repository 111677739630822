import { Box, Button, Typography, Icon } from '../';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

export const CustomDashboardCard = ({
    CardIcon,
    title,
    description,
    buttonLink,
    isDisabled,
    disableText = '',
}) => {
    const { classes } = useStyles();
    return (
        <Box className={classes.box}>
            <Box className={`${classes.icon} ${classes.item}`}>
                <Icon className={classes.color}>{<CardIcon />}</Icon>
            </Box>
            <Typography className={`${classes.heading} ${classes.item}`}>
                {title}
            </Typography>
            <Typography className={`${classes.subHeading} ${classes.item}`}>
                {description}
            </Typography>
            {/* {buttonCard} */}
            {buttonLink && (
                <Link to={isDisabled ? '#' : buttonLink}>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={isDisabled}
                    >
                        {disableText ? disableText : 'access'}
                    </Button>
                </Link>
            )}
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    box: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        padding: '10px',
        minHeight: '250px',
        boxShadow: '0px 5px 10px 3px rgba(0,0,0,0.2)',
        transition: '0.2s all ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)',
            boxShadow: '0px 5px 10px 4px rgba(0,0,0,0.2)',
        },
    },
    item: {
        margin: '5px 0',
    },
    color: {
        color: theme.palette.common.blue,
    },
    icon: {
        width: '50px',
        height: '50px',
        display: 'grid',
        placeItems: 'center',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
        borderRadius: '50%',
    },
    heading: {
        fontSize: 'min(1.3em, 6vw)',
        fontWeight: '600',
    },
    subHeading: {
        fontSize: 'min(1em, 5vw)',
        textAlign: 'center',
        marginBottom: '25px',
    },
}));
