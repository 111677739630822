import {
    Box,
    Button,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { CloseIcon } from '../../icons';
import { makeStyles } from 'tss-react/mui';
import { useEffect, useState, useCallback } from 'react';
import { yellow } from '@mui/material/colors';
import small from '../../img/small.svg';
import { useCurrentUser } from '../../my-account/useCurrentUser';
import { offers } from '../../util/offerDetails';
import { useRecoilState } from 'recoil';
import { offerPopUpPermanentClosedState } from '../../billing/recoil';

const offersUrl = offers.map((offer) => `/sale/${offer.url}`);
const offersUrlWithSlash = offers.map((offer) => `/sale/${offer.url}/`);

const excludeFromPages = [
    '/signup',
    '/checkout',
    '/credits/checkout',
    '/checkout/success',
    '/checkout/success/',
    '/checkout/canceled',
    ...offersUrl,
    ...offersUrlWithSlash,
];
const offerPageUrl = '/sale/welcome-2022/';

export const OfferPopup = ({ hasVisitedFirstTime = false }) => {
    const { classes } = useStyles();
    const showSmallSize = useMediaQuery('(max-width:900px)');
    const [show, setShow] = useState(false);
    const { user } = useCurrentUser();

    const [isPermanentlyClosed, setIsPermanentlyClosed] = useRecoilState(
        offerPopUpPermanentClosedState
    );

    const openPopUp = useCallback(() => {
        if (!user || user?.user_subscription_id === '') {
            const doNotOpen = excludeFromPages.includes(
                window.location.pathname
            );
            if (
                !show &&
                !hasVisitedFirstTime &&
                !doNotOpen &&
                !isPermanentlyClosed
            ) {
                setShow(true);
            }
        } else {
            console.log('sub');
        }
    }, [user, hasVisitedFirstTime, show, isPermanentlyClosed]);

    useEffect(() => {
        const openTimeout = setTimeout(() => openPopUp(), 4000);
        return () => {
            clearTimeout(openTimeout);
        };
    }, [openPopUp]);

    const onClose = () => {
        setShow(false);
        setIsPermanentlyClosed(true);
    };

    return (
        <Slide in={show} direction="up">
            <Box className={classes.popupContainer}>
                <Toolbar variant="dense" disableGutters>
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6"></Typography>
                        <IconButton
                            size={showSmallSize ? 'small' : 'medium'}
                            color="primary"
                            onClick={onClose}
                        >
                            <CloseIcon
                                fontSize={showSmallSize ? 'small' : 'default'}
                                color="primary"
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
                <Box
                    style={{
                        position: 'relative',
                    }}
                    display="flex"
                >
                    <Box width={imageWidth}>
                        <img
                            src={imageUrl}
                            alt="popup"
                            className={classes.image}
                        />
                        {/* <Box className={classes.image} /> */}
                    </Box>
                    <Box
                        pl={5}
                        width="80%"
                        display="flex"
                        flexDirection="column"
                    >
                        <Box>
                            <Typography
                                className={classes.heading}
                                variant="h5"
                                align="center"
                            >
                                BECOME AN ASTRONAUT
                            </Typography>
                            <Typography
                                className={classes.secondHeading}
                                variant="h5"
                                align="center"
                                gutterBottom
                            >
                                TRY 1 MONTH FOR $1
                            </Typography>
                            <Typography
                                style={{
                                    width: '95%',
                                    margin: '0 auto',
                                }}
                                variant="subtitle2"
                                align="center"
                            >
                                Unwrap 1000s of hours of education (Saving
                                $140/year)
                            </Typography>
                        </Box>
                        {/* coupon box */}
                        <Box
                            display="flex"
                            alignItems="center"
                            mt={showSmallSize ? 1 : 3}
                        >
                            <Box
                                style={{
                                    backgroundImage: `url(${small})`,
                                    backgroundSize: 'cover',
                                    width: showSmallSize ? '25px' : '50px',
                                    height: showSmallSize ? '25px' : '50px',
                                }}
                            />
                            <Box className={classes.couponCode}>
                                <Typography
                                    variant="h6"
                                    className={classes.code}
                                >
                                    Coupon Code: <strong>"CYBERMONDAY"</strong>
                                </Typography>
                            </Box>
                        </Box>
                        {/* coupon box */}
                        <Box>
                            <Typography
                                variant={
                                    showSmallSize ? 'caption' : 'subtitle2'
                                }
                                component="div"
                                align="center"
                            >
                                (Offer ends on Dec 10, 2021)
                            </Typography>
                        </Box>
                        <Box mt={showSmallSize ? 1 : 3} mx="auto">
                            <Button
                                size={showSmallSize ? 'small' : 'medium'}
                                variant="contained"
                                href={offerPageUrl}
                                color="secondary"
                            >
                                Try now
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Slide>
    );
};
const imageSmallWidth = 120;
const imageWidth = 170;
const imageUrl =
    'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/popup-image%2Fpaul%20siegel%20(10).png?alt=media&token=a3dea791-d39d-4bd2-8ac6-aa62fd84f498';

const useStyles = makeStyles()((theme) => ({
    popupContainer: {
        position: 'fixed',
        bottom: 0,
        zIndex: theme.zIndex.drawer + 100,
        width: '590px',
        height: '330px',
        color: '#fafafa',
        background: '#333',
        paddingBottom: 30,
        backgroundImage:
            'radial-gradient(circle, rgba(48,48,48,1) 0%, rgba(0,0,0,1) 100%)',
        right: 0,
        [theme.breakpoints.down('md')]: {
            width: '400px',
            height: '250px',
        },
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 30,
        width: imageWidth,
        margin: '0 auto',
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            width: imageSmallWidth,
        },
        // backgroundImage: `url(${imageUrl})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
    },
    heading: {
        fontWeight: 700,
        fontSize: '2em',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.3em',
        },
    },
    secondHeading: {
        fontWeight: 500,
        fontSize: '1.6em',
        color: yellow[700],
        [theme.breakpoints.down('md')]: {
            fontSize: '1em',
        },
    },
    couponCode: {
        padding: '3px 5px',
        backgroundColor: '#051E0E',
        position: 'relative',
        color: yellow[700],
        '&:before': {
            content: '""',
            zIndex: '-1',
            borderRadius: '50% 0 0 50%',
            position: 'absolute',
            top: 0,
            left: '-30px',
            width: '30px',
            height: '100%',
            backgroundColor: '#051E0E',
        },
    },
    code: {
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8em',
        },
    },
}));
