import * as XLSX from 'xlsx';

/**
 *
 * @param {Blob} csv
 * @returns {Promise<any>}
 */
function convertCSVToJSON(csv) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                resolve(json);
            };

            reader.onerror = (e) => {
                reject(e);
            };

            reader.readAsArrayBuffer(csv);
        } catch (e) {
            reject(e);
        }
    });
}

export { convertCSVToJSON };
