import React from 'react';
import { Box, Typography } from '..';
import { makeStyles } from 'tss-react/mui';
// import { useTimer } from 'react-timer-hook';

const useStyles = makeStyles()((theme) => ({
    timer: {
        display: 'flex',
        flexWrap: 'nowrap',
        //marginBottom: '20px',
    },

    timeContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        backgroundColor: 'White',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '5px',
        padding: '5px',
        margin: '2px',
    },

    time: {
        fontSize: '1.2rem',
        // fontWeight: 'bold',
    },

    timeLabel: {
        minWidth: 61,
        textAlign: 'center',
    },
}));

export const TimerUI2 = ({
    /* expiryTimestamp,
    onExpire */
    seconds,
    minutes,
    hours,
    days,
}) => {
    const { classes } = useStyles();

    // const {
    //     seconds,
    //     minutes,
    //     hours,
    //     days,
    //     // isRunning,
    //     // start,
    //     // pause,
    //     // resume,
    //     // restart,
    // } = useTimer({
    //     expiryTimestamp,
    //     onExpire: () => {
    //         if (onExpire) onExpire();
    //     },
    // });

    return (
        <Box className={classes.timer}>
            {days > 0 && (
                <Box className={classes.timeContainer}>
                    <Typography className={classes.time}>{days}d</Typography>
                    {/* <Typography
                        variant="overline"
                        className={classes.timeLabel}
                    >
                        days
                    </Typography> */}
                </Box>
            )}

            {(hours > 0 || days > 0) > 0 && (
                <Box className={classes.timeContainer}>
                    <Typography className={classes.time}>{hours}h</Typography>
                    {/* <Typography
                        variant="overline"
                        className={classes.timeLabel}
                    >
                        hours
                    </Typography> */}
                </Box>
            )}

            {(minutes > 0 || hours > 0 || days > 0) > 0 && (
                <Box className={classes.timeContainer}>
                    <Typography className={classes.time}>{minutes}m</Typography>
                    {/* <Typography
                        variant="overline"
                        className={classes.timeLabel}
                    >
                        minutes
                    </Typography> */}
                </Box>
            )}

            {(seconds > 0 || minutes > 0 || hours > 0 || days > 0) && (
                <Box className={classes.timeContainer}>
                    <Typography className={classes.time}>{seconds}s</Typography>{' '}
                    {/* <Typography
                        variant="overline"
                        className={classes.timeLabel}
                    >
                        seconds
                    </Typography> */}
                </Box>
            )}
        </Box>
    );
};
