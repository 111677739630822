import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from '../ui';

import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { astronaut_id } from '../plans/PlanDetails';

import { trialEndPopUpOpenState } from './recoil';

import logo from '../starweaver-logo-v2.png';
import astro from '../astro.jpg';

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: 30,
    },
    heading: {
        textAlign: 'center',
    },
    info: {
        textAlign: 'center',
    },
}));

export const TrialEndDialog = () => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useRecoilState(trialEndPopUpOpenState);

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                //disableBackdropClick={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                >
                    <Grid item>
                        <img src={logo} alt="Starweaver logo" width={200} />
                    </Grid>
                    <Grid item>
                        <DialogTitle
                            id="alert-dialog-title"
                            className={classes.heading}
                        >
                            Your free trial has just ended :-(
                        </DialogTitle>
                    </Grid>
                    <Grid item>
                        <img src={astro} alt="Starweaver logo" width={300} />
                    </Grid>
                    <Grid item>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                            >
                                We know everyone gets busy, so if you've just
                                forgotten to enter your billing information then
                                you can still do it here
                            </DialogContentText>
                        </DialogContent>
                    </Grid>
                    <Grid item>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    navigate(
                                        `/checkout?p_id=${astronaut_id}&t=0`
                                    );
                                    handleClose();
                                }}
                                variant="contained"
                                color="primary"
                            >
                                UPGRADE MY PLAN
                            </Button>
                        </DialogActions>
                    </Grid>

                    <Grid item>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                            >
                                Questions? Mail us at helpdesk@starweaver.com
                            </DialogContentText>
                        </DialogContent>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
};
