import { selector } from 'recoil';
import {
    usersState,
    userAllTeamsState,
    editTeamState,
    disabledUsersState,
    mostEnrolledCourseStateState,
    selectedUsersForReportState,
    userPlanState,
    userCreditsInfoState,
    userTeamState,
    allUserObjectState,
} from './state';

export const addUserToAllUserObject = selector({
    key: 'addUserToAllUserObject',
    set: ({ set, get }, newUser) => {
        let newState = { ...get(allUserObjectState) };
        if (!newState[`${newUser._id}`]) {
            newState[`${newUser._id}`] = newUser;
            set(allUserObjectState, newState);
        }
    },
});

// for ech new editable info add a selector to handle change
// can add this selector in a hook and then perform the changes
// edit user info selectors
export const chnageUserPlanState = selector({
    key: 'chnageUserPlanState',
    set: ({ set, get }, { name, value }) => {
        let newValue = { ...get(userPlanState) };
        newValue[`${name}`] = value;
        set(userPlanState, newValue);
    },
});

export const chnageUserCreditsState = selector({
    key: 'chnageUserCreditsState',
    set: ({ set, get }, { name, value }) => {
        let newValue = { ...get(userCreditsInfoState) };
        newValue[`${name}`] = value;
        set(userCreditsInfoState, newValue);
    },
});

export const changeUserTeamState = selector({
    key: 'changeUserTeamState',
    set: ({ set, get }, { name, value }) => {
        let newValue = { ...get(userTeamState) };
        newValue[`${name}`] = value;
        set(userTeamState, newValue);
    },
});
// delete team
export const deleteTeamSelector = selector({
    key: 'deleteTeamSelector',
    set: ({ set, get }, teamId) => {
        const allTeams = get(userAllTeamsState);
        const newTeams = allTeams.filter((team) => team._id !== teamId);
        set(userAllTeamsState, newTeams);
    },
});
// make a team editable
export const setTeamToEdit = selector({
    key: 'setTeamToEdit',
    set: ({ set, get }, values) => {
        const teamId = values?.teamId;
        const optionType = values?.optionType;
        let currentEditState = { ...get(editTeamState) };
        if (teamId) {
            currentEditState.editTeam = true;
            currentEditState.optionType = optionType;
            currentEditState.id = teamId;
            set(editTeamState, currentEditState);
        } else {
            currentEditState.editTeam = false;
            currentEditState.optionType = '';
            currentEditState.id = null;
            set(editTeamState, currentEditState);
        }
    },
});
// this selector return the required data
export const getAllUserEditInfoValues = selector({
    key: 'getAllUserEditInfoValues',
    get: ({ get }) => {
        const planState = get(userPlanState);
        const creditState = get(userCreditsInfoState);
        const teamState = get(userTeamState);
        let editAbleInfoObject = {};
        if (planState.show) {
            const hasExpiry =
                planState.accountType === 'VOYAGER' ||
                planState.accountType === 'VOYAGER_PLUS'
                    ? false
                    : true;
            editAbleInfoObject.account = planState.accountType;
            editAbleInfoObject.expiry = hasExpiry
                ? planState.expirationDate
                : null;
        }
        if (creditState.show) {
            editAbleInfoObject.creditTo = creditState.creditsChangeType;
            editAbleInfoObject.creditAmount = creditState.amount;
        }
        if (teamState.show) {
            editAbleInfoObject.addToTeam = teamState.teamId;
        }
        return editAbleInfoObject;
    },
});
// edit user info selectors

export const setUsersForReportSelector = selector({
    key: 'setUsersForReportSelector',
    set: ({ set }, users = []) => {
        set(selectedUsersForReportState, users);
    },
});

export const mostEnrolledCourseSelector = selector({
    key: 'mostEnrolledCourseSelector',
    set: ({ set }, name = '') => {
        set(mostEnrolledCourseStateState, name);
    },
});

export const enableUsersInState = selector({
    key: 'enableUsersInState',
    set: ({ set, get }, userIds = []) => {
        const disabledUsers = get(disabledUsersState);
        const activeUsers = get(usersState);
        const newDisabledUsers = disabledUsers.filter(
            ({ _id }) => !userIds.includes(_id)
        );
        const userToActive = disabledUsers.filter(({ _id }) =>
            userIds.includes(_id)
        );
        set(disabledUsersState, newDisabledUsers);
        const newActiveUsers = [...activeUsers, ...userToActive];
        set(usersState, newActiveUsers);
    },
});

export const disableUsersInState = selector({
    key: 'disableUsersInState',
    set: ({ set, get }, userIds = []) => {
        const disabledUsers = get(disabledUsersState);
        const activeUsers = get(usersState);
        const newActiveUsers = activeUsers.filter(
            ({ _id }) => !userIds.includes(_id)
        );
        const userToDisabled = activeUsers.filter(({ _id }) =>
            userIds.includes(_id)
        );
        const newDisabledUsers = [...disabledUsers, ...userToDisabled];
        set(disabledUsersState, newDisabledUsers);
        set(usersState, newActiveUsers);
    },
});

// wasn't able to use selector within selector will find later
// export const resetAllAdminStates = selector({
//     key: 'resetAllAdminStates',
//     set: ({ set }) => {
//         set(usersState, {});
//         set(clientDetailsState, {});
//         set(disabledUsersState, []);
//     },
// });
