import React, { useCallback, useEffect, useState, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Chip } from '@mui/material';
import Fuse from 'fuse.js';
import {
    Box,
    Button,
    Card,
    // Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    // Paper,
    // Radio,
    TextField,
    Typography,
    Tooltip,
    ListItemSecondaryAction,
    Checkbox,
    IconButton,
    CircularProgress,
} from '..';

import { CloseIcon } from '../../icons';

const useStyles = makeStyles()((theme) => ({
    root: { padding: 16, marginBottom: 20 /* marginTop: 20 */ },
    heading: {},
    subHeading: { marginBottom: 20 },
    searchContainer: {
        position: 'relative',
    },
    search: {
        marginBottom: 20,
    },
    addSearchTermBtn: {
        position: 'absolute',
        right: 0,
        top: 10,
    },
    list: {
        height: 350,
        overflow: 'auto',
    },
    selectedListContainerContainer: { maxHeight: 482, overflow: 'auto' },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 10,
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    headingWrappwer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const SERVER = 'server';

export const ListSearchAndSelectSingle = ({
    list,
    heading,
    subHeading,

    handleItemSelect,
    skillErr,
    idField,
    textField,
    paginationMode,
    search,
    setSearch,
    searchKeys,
    setPageNumber,
    loading,
    hasMore,
    setSkillErr,
    canAddSearchTerm,
    addedSearchTerms,
    setAddedSearchTerms,
    onAddSearchTerm,
    resetError = undefined,
    getDisplayText,
    subTextField,
    onClickSelected,
    selectedListView, //"chips", "list"
    selectedWithinSelected,
    showSelectionWithinSelection,
    handleSelectWithinSelect,
    selectedWithinSelectedTooltip,
    showSelectionActionBtn,
    onClickSelectionActionBtn,
    selectionActionBtnTooltip,
    selectionActionBtnIcon,
    selectionActionBtnLoadingIds,
    readOnly = false,
    draggable,
    disabled,
    searchInputSize,
    CustomComponent = null,
}) => {
    const SelectionActionBtnIcon = selectionActionBtnIcon;
    const { classes } = useStyles();

    const leftSideRef = useRef();

    const [searchQuery, setSearchQuery] = useState('');

    const [searchFunc, setSearchFunc] = useState(() => () => []);

    const selectionList =
        paginationMode === SERVER ? list : searchFunc(searchQuery);

    useEffect(() => {
        if (paginationMode !== SERVER) {
            console.log('Setting up fuse.');

            const fuse = new Fuse(list, {
                keys: searchKeys
                    ? searchKeys
                    : textField
                    ? [textField]
                    : ['name', 'title'],
                includeScore: true,
            });
            setSearchFunc(() => {
                return (query) => {
                    return query !== ''
                        ? fuse
                              .search(query)
                              .filter(({ score }) => score < 0.5)
                              .map(({ item }) => item)
                        : list;
                };
            });
        }
    }, [list, paginationMode, textField, searchKeys]);

    const handleSearchQueryChange = (e) => {
        if (skillErr?.error || skillErr?.message > 0) {
            if (setSkillErr) setSkillErr({ error: false, message: '' });
        }
        if (setSearch) {
            setPageNumber(1);
            setSearch(e.target.value);
        } else setSearchQuery(e.target.value);
        if (resetError) {
            resetError.handler(resetError.key);
        }
    };

    const _handleItemSelect = (item) => {
        if (disabled) return;
        if (handleItemSelect) handleItemSelect(item);
        else {
            console.log(item);
        }
    };

    const observer = useRef();
    const lastSearchItemRef = useCallback(
        (node) => {
            if (paginationMode === SERVER) {
                if (loading) return;
                if (observer.current) observer.current.disconnect();
                observer.current = new IntersectionObserver((entries) => {
                    // console.log(entries[0].isIntersecting);
                    // console.log(hasMore);
                    if (entries[0].isIntersecting && hasMore) {
                        setPageNumber((prevPageNumber) => prevPageNumber + 1);
                    }
                });
                if (node) observer.current.observe(node);
            }
        },
        [paginationMode, loading, hasMore, setPageNumber]
    );

    const scrollToBottom = () => {
        setTimeout(() => {
            if (leftSideRef.current) {
                leftSideRef.current.scrollTop =
                    leftSideRef?.current?.scrollHeight;
            }
        }, 0);
    };

    // console.log(selectionList);

    return (
        <Card className={classes.root}>
            <Grid container spacing={3}>
                {readOnly === false && (
                    <Grid item xs={12}>
                        <Box className={classes.headingWrappwer}>
                            <Typography
                                variant="h6"
                                className={classes.heading}
                            >
                                {heading}
                            </Typography>

                            {/* Custom component */}
                            {CustomComponent !== null && (
                                <CustomComponentContainer>
                                    {CustomComponent}
                                </CustomComponentContainer>
                            )}
                        </Box>
                        {subHeading && (
                            <Typography
                                component="p"
                                variant="small"
                                className={classes.subHeading}
                            >
                                {subHeading}
                            </Typography>
                        )}

                        <Box className={classes.searchContainer}>
                            <TextField
                                label="Search"
                                variant="outlined"
                                color="secondary"
                                value={search || searchQuery}
                                onChange={handleSearchQueryChange}
                                fullWidth
                                className={classes.search}
                                error={skillErr?.error}
                                helperText={
                                    skillErr?.error ? skillErr.message : ''
                                }
                                disabled={disabled}
                                size={searchInputSize || 'medium'}
                            />
                        </Box>
                        <Card component="div" className={classes.list}>
                            <List>
                                {selectionList.map((item, index) => {
                                    const label =
                                        item[textField] ||
                                        item.name ||
                                        item.title ||
                                        (getDisplayText &&
                                            getDisplayText(item));
                                    return (
                                        <ListItem
                                            ref={
                                                paginationMode === SERVER &&
                                                selectionList.length ===
                                                    index + 1
                                                    ? lastSearchItemRef
                                                    : null
                                            }
                                            // style={{
                                            //     border:
                                            //         paginationMode === SERVER &&
                                            //         list.length === index + 1
                                            //             ? '1px solid #c5c5c5'
                                            //             : null,
                                            // }}
                                            key={index}
                                            button
                                            onClick={() => {
                                                _handleItemSelect(item);
                                            }}
                                        >
                                            <ListItemText
                                                /* style={{
                                            textTransform: 'capitalize',
                                        }} */
                                                primary={label}
                                                secondary={
                                                    subTextField
                                                        ? item[subTextField]
                                                        : null
                                                }
                                            />
                                        </ListItem>
                                    );
                                })}

                                {loading && (
                                    <ListItem>
                                        <ListItemText primary={'Loading...'} />
                                    </ListItem>
                                )}
                            </List>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

function CustomComponentContainer({ children }) {
    return <div>{children}</div>;
}
