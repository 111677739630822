import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from '../ui';

import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { astronaut_id } from '../plans/PlanDetails';

import { freeCreditsPopUpOpenState } from './recoil';

import logo from '../starweaver-logo-v2.png';
import dollarsImg from './assets/oneSixtyDollar.png';
import badgeImg from './assets/emplyBadge.png';
import astro from '../astro.jpg';

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: 30,
    },
    heading: {
        textAlign: 'center',
        color: theme.palette.secondary.main,

        //'&:first-child': { fontSize: '5px !important' },
    },
    info: {
        textAlign: 'center',
        color: theme.palette.common.blue,
    },
    graphics: { position: 'relative', width: 300 },
    dollarsImgContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-59px 0 0 -100px',
    },
}));

export const FreeCreditsDialog = () => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useRecoilState(freeCreditsPopUpOpenState);

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                //disableBackdropClick={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                >
                    <Grid item>
                        <Box className={classes.graphics}>
                            <img
                                src={badgeImg}
                                alt="Starweaver logo"
                                width="100%"
                            />
                            <Box className={classes.dollarsImgContainer}>
                                <img
                                    src={dollarsImg}
                                    alt="Starweaver logo"
                                    width={200}
                                    className={classes.dollarsImg}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <DialogTitle
                            id="alert-dialog-title"
                            className={classes.heading}
                        >
                            You've got Free Credits
                        </DialogTitle>
                    </Grid>

                    <Grid item>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                                component="h1"
                            >
                                We've added free credits
                            </DialogContentText>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                            >
                                worth $160 to your account
                            </DialogContentText>
                        </DialogContent>
                    </Grid>
                    <Grid item>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    //navigate((`/category/all/courses`));
                                    handleClose();
                                }}
                                variant="contained"
                                color="primary"
                            >
                                OK!
                            </Button>
                        </DialogActions>
                    </Grid>

                    {/* <Grid item>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                            >
                                Questions? Mail us at helpdesk@starweaver.com
                            </DialogContentText>
                        </DialogContent>
                    </Grid> */}
                </Grid>
            </Dialog>
        </div>
    );
};
