import { useState, useRef, useCallback } from 'react';

import { Box, Button, Grid, Hidden, Typography } from '../ui';
import { useStyles } from './styles';
import { pros, Features } from '../forOrganisation';
import { useMediaQuery } from '@mui/material';

import { LazyLoadComponent } from 'react-lazy-load-image-component';

export const VideoContainer = () => {
    const { classes } = useStyles();

    return (
        <Box width="auto" height="100%" className={classes.videoContainer}>
            <LazyLoadComponent threshold={500}>
                <img
                    width={'100%'}
                    src="https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Landing%20Image%2FlandingPageJourney.png?alt=media&token=d16cda20-c400-4194-a5c2-42786c534f2d"
                    alt="journeyImage"
                />
                {/* <video
                    // src="https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/gifs%20landing%20page%2Fjourney%20gif.mp4?alt=media&token=ff2c9cb7-1927-41a8-8dd3-288a08a1e05f"
                    src="https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/gifs%20landing%20page%2Fjourneys.final.mp4?alt=media&token=11bb34f5-573d-4b09-873f-4658a9140293"
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    type="video/mp4"
                    width={'100%'}
                    height={'100%'}
                /> */}
            </LazyLoadComponent>

            {/* <iframe
                src="https://player.vimeo.com/video/657634195?h=afee7ee6ae&title=0&byline=0&portrait=0&autoplay=1&loop=1"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                width="100%"
                height="100%"
                title="Learning by connecting"
            ></iframe> */}
        </Box>
    );
};

export const JourneySection = () => {
    const { classes } = useStyles();
    const showSmallSize = useMediaQuery('(max-width:960px)');

    return (
        <Box mt={5}>
            <Grid container>
                <Grid
                    container
                    direction={showSmallSize ? 'row-reverse' : 'row'}
                    item
                    xs={12}
                    spacing={5}
                >
                    <Grid item xs={12} sm={5} md={6}>
                        <Hidden mdDown>
                            <VideoContainer />
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={7} md={6}>
                        <Typography
                            className={`${classes.sectionHeading} ${classes.colorBlue}`}
                            style={{
                                fontWeight: 500,
                            }}
                            gutterBottom
                        >
                            Deep and rich content built around your job and
                            career objectives.
                        </Typography>
                        <Box mt={showSmallSize ? 4 : 8}>
                            <Button
                                className={
                                    showSmallSize
                                        ? ''
                                        : ` ${classes.actionButton}`
                                }
                                size={showSmallSize ? 'medium' : 'large'}
                                color="secondary"
                                variant="contained"
                                href="/student-onboarding"
                            >
                                CREATE YOUR JOURNEY ABSOLUTELY FREE​
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Hidden mdUp>
                            <VideoContainer />
                        </Hidden>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={2}>
                        <Features cards={pros} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
