import React, { useCallback, useEffect, useState, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Chip } from '@mui/material';
import Fuse from 'fuse.js';
import {
    Box,
    Button,
    Card,
    // Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    // Paper,
    // Radio,
    TextField,
    Typography,
    Tooltip,
    ListItemSecondaryAction,
    Checkbox,
    IconButton,
    CircularProgress,
} from '..';

import { CloseIcon } from '../../icons';

const useStyles = makeStyles()((theme) => ({
    root: { padding: 16, marginBottom: 20 /* marginTop: 20 */ },
    heading: {},
    subHeading: { marginBottom: 20 },
    searchContainer: {
        position: 'relative',
    },
    search: {
        marginBottom: 20,
    },
    addSearchTermBtn: {
        position: 'absolute',
        right: 0,
        top: 10,
    },
    list: {
        height: 350,
        overflow: 'auto',
    },
    selectedListContainerContainer: { maxHeight: 482, overflow: 'auto' },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 10,
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    headingWrappwer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const SERVER = 'server';

export const ListSearchAndSelect = ({
    list,
    heading,
    subHeading,
    selectedList,
    setSelectedList,
    handleItemSelect,
    handleItemDelete,
    skillErr,
    idField,
    textField,
    paginationMode,
    search,
    setSearch,
    searchKeys,
    setPageNumber,
    loading,
    hasMore,
    setSkillErr,
    canAddSearchTerm,
    addedSearchTerms,
    setAddedSearchTerms,
    onAddSearchTerm,
    resetError = undefined,
    getDisplayText,
    subTextField,
    onClickSelected,
    selectedListView, //"chips", "list"
    selectedWithinSelected,
    showSelectionWithinSelection,
    handleSelectWithinSelect,
    selectedWithinSelectedTooltip,
    showSelectionActionBtn,
    onClickSelectionActionBtn,
    selectionActionBtnTooltip,
    selectionActionBtnIcon,
    selectionActionBtnLoadingIds,
    readOnly = false,
    draggable,
    disabled,
    searchInputSize,
    CustomComponent = null,
}) => {
    const SelectionActionBtnIcon = selectionActionBtnIcon;
    const { classes } = useStyles();

    const leftSideRef = useRef();
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [dragOverItemIndex, setDragOverItemIndex] = useState(null);

    const [searchQuery, setSearchQuery] = useState('');

    const [searchFunc, setSearchFunc] = useState(() => () => []);

    const selectionList =
        paginationMode === SERVER ? list : searchFunc(searchQuery);

    useEffect(() => {
        if (paginationMode !== SERVER) {
            console.log('Setting up fuse.');

            const fuse = new Fuse(list, {
                keys: searchKeys
                    ? searchKeys
                    : textField
                    ? [textField]
                    : ['name', 'title'],
                includeScore: true,
            });
            setSearchFunc(() => {
                return (query) => {
                    return query !== ''
                        ? fuse
                              .search(query)
                              .filter(({ score }) => score < 0.5)
                              .map(({ item }) => item)
                        : list;
                };
            });
        }
    }, [list, paginationMode, textField, searchKeys]);

    const handleSearchQueryChange = (e) => {
        if (skillErr?.error || skillErr?.message > 0) {
            if (setSkillErr) setSkillErr({ error: false, message: '' });
        }
        if (setSearch) {
            setPageNumber(1);
            setSearch(e.target.value);
        } else setSearchQuery(e.target.value);
        if (resetError) {
            resetError.handler(resetError.key);
        }
    };

    const _handleItemSelect = (item) => {
        if (disabled) return;
        if (handleItemSelect) handleItemSelect(item);
        else {
            setSelectedList((prevList) => {
                if (
                    prevList.findIndex((elem) =>
                        idField
                            ? elem[idField] === item[idField]
                            : elem.id === item.id
                    ) === -1
                ) {
                    return [...prevList, item];
                } else {
                    return prevList;
                }
            });
            scrollToBottom();
        }
    };

    const _handleItemDelete = (itemToDelete) => {
        if (disabled) return;
        if (handleItemDelete) {
            handleItemDelete(itemToDelete);
        } else
            setSelectedList((items) =>
                items.filter((item) =>
                    idField
                        ? item[idField] !== itemToDelete[idField]
                        : item.id !== itemToDelete.id
                )
            );
    };

    const dragStart = (e, position, side) => {
        if (disabled) return;
        dragItem.current = { position, side };
    };

    const dragEnter = (e, position, side) => {
        if (disabled) return;
        dragOverItem.current = { position, side };

        if (side === 'right') setDragOverItemIndex(position);
        else setDragOverItemIndex(null);
    };

    const drop = async (e) => {
        if (disabled) return;
        if (
            dragItem.current?.side === 'right' &&
            dragOverItem.current?.side === 'right'
        ) {
            const copyListItems = [...selectedList];
            const dragItemContent = copyListItems[dragItem.current.position];
            copyListItems.splice(dragItem.current.position, 1);
            copyListItems.splice(
                dragOverItem.current.position,
                0,
                dragItemContent
            );
            setSelectedList(copyListItems);
        }
        if (
            dragItem.current?.side === 'left' &&
            dragOverItem.current?.side === 'right'
        ) {
            const copyListItems = [...selectedList];
            const dragItemContent = selectionList[dragItem.current.position];
            copyListItems.splice(
                dragOverItem.current.position,
                0,
                dragItemContent
            );
            setSelectedList(copyListItems);
        }

        if (
            dragItem.current?.side === 'right' &&
            dragOverItem.current?.side === 'left'
        ) {
            const dragItemContent = selectedList[dragItem.current.position];
            if (handleItemDelete) handleItemDelete(dragItemContent);
            else _handleItemDelete(dragItemContent);
        }

        dragItem.current = null;
        dragOverItem.current = null;
        setDragOverItemIndex(null);
    };

    const addSearchTerm = (term) => {
        if (disabled) return;
        setAddedSearchTerms((prev) => [...prev, term]);
        setSearchQuery('');
        if (setSearch) {
            setPageNumber(1);
            setSearch('');
        }
    };

    const removeSearchTerm = (term) => {
        if (disabled) return;
        setAddedSearchTerms((prev) => prev.filter((elem) => elem !== term));
    };

    const observer = useRef();
    const lastSearchItemRef = useCallback(
        (node) => {
            if (paginationMode === SERVER) {
                if (loading) return;
                if (observer.current) observer.current.disconnect();
                observer.current = new IntersectionObserver((entries) => {
                    // console.log(entries[0].isIntersecting);
                    // console.log(hasMore);
                    if (entries[0].isIntersecting && hasMore) {
                        setPageNumber((prevPageNumber) => prevPageNumber + 1);
                    }
                });
                if (node) observer.current.observe(node);
            }
        },
        [paginationMode, loading, hasMore, setPageNumber]
    );

    const scrollToBottom = () => {
        setTimeout(() => {
            if (leftSideRef.current) {
                leftSideRef.current.scrollTop =
                    leftSideRef?.current?.scrollHeight;
            }
        }, 0);
    };

    // console.log(selectionList);

    return (
        <Card className={classes.root}>
            <Grid container spacing={3}>
                {readOnly === false && (
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.headingWrappwer}>
                            <Typography
                                variant="h6"
                                className={classes.heading}
                            >
                                {heading}
                            </Typography>

                            {/* Custom component */}
                            {CustomComponent !== null && (
                                <CustomComponentContainer>
                                    {CustomComponent}
                                </CustomComponentContainer>
                            )}
                        </Box>
                        {subHeading && (
                            <Typography
                                component="p"
                                variant="small"
                                className={classes.subHeading}
                            >
                                {subHeading}
                            </Typography>
                        )}

                        <Box className={classes.searchContainer}>
                            <TextField
                                label="Search"
                                variant="outlined"
                                color="secondary"
                                value={search || searchQuery}
                                onChange={handleSearchQueryChange}
                                fullWidth
                                className={classes.search}
                                error={skillErr?.error}
                                helperText={
                                    skillErr?.error ? skillErr.message : ''
                                }
                                disabled={disabled}
                                size={searchInputSize || 'medium'}
                            />
                            {canAddSearchTerm
                                ? (paginationMode === SERVER
                                      ? search !== '' &&
                                        search?.length > 2 &&
                                        !loading
                                      : //   &&
                                        //   list.length === 0
                                        searchQuery !== '' &&
                                        searchFunc(searchQuery).length ===
                                            0) && (
                                      <Button
                                          className={classes.addSearchTermBtn}
                                          onClick={() => {
                                              if (setAddedSearchTerms)
                                                  addSearchTerm(
                                                      paginationMode === SERVER
                                                          ? search
                                                          : searchQuery
                                                  );

                                              if (onAddSearchTerm)
                                                  onAddSearchTerm(
                                                      paginationMode === SERVER
                                                          ? search
                                                          : searchQuery
                                                  );
                                          }}
                                      >
                                          Add New
                                      </Button>
                                  )
                                : null}
                        </Box>
                        <Card
                            component="div"
                            className={classes.list}
                            onDragEnter={(e) => {
                                e.preventDefault();
                                dragEnter(e, selectionList.length, 'left');
                            }}
                            onDragEnd={drop}
                            onDragOver={(e) => {
                                e.dataTransfer.dropEffect = 'move';
                                e.preventDefault();
                            }}
                        >
                            <List>
                                {selectionList.map((item, index) => {
                                    const label =
                                        item[textField] ||
                                        item.name ||
                                        item.title ||
                                        (getDisplayText &&
                                            getDisplayText(item));
                                    return (
                                        <ListItem
                                            ref={
                                                paginationMode === SERVER &&
                                                selectionList.length ===
                                                    index + 1
                                                    ? lastSearchItemRef
                                                    : null
                                            }
                                            // style={{
                                            //     border:
                                            //         paginationMode === SERVER &&
                                            //         list.length === index + 1
                                            //             ? '1px solid #c5c5c5'
                                            //             : null,
                                            // }}
                                            key={index}
                                            button={!draggable}
                                            onClick={() => {
                                                _handleItemSelect(item);
                                            }}
                                            onDragStart={(e) => {
                                                dragStart(e, index, 'left');
                                            }}
                                            onDragEnter={(e) => {
                                                e.preventDefault();
                                                dragEnter(e, index, 'left');
                                            }}
                                            onDragEnd={drop}
                                            onDragOver={(e) => {
                                                e.dataTransfer.dropEffect =
                                                    'move';
                                                e.preventDefault();
                                            }}
                                            draggable={
                                                draggable &&
                                                selectedList?.findIndex(
                                                    (elem) =>
                                                        idField
                                                            ? elem[idField] ===
                                                              item[idField]
                                                            : elem.id ===
                                                              item.id
                                                ) === -1
                                            }
                                            style={{
                                                cursor:
                                                    selectedList?.findIndex(
                                                        (elem) =>
                                                            idField
                                                                ? elem[
                                                                      idField
                                                                  ] ===
                                                                  item[idField]
                                                                : elem.id ===
                                                                  item.id
                                                    ) !== -1
                                                        ? 'default'
                                                        : draggable
                                                        ? 'move'
                                                        : 'pointer',
                                            }}
                                        >
                                            <ListItemText
                                                /* style={{
                                            textTransform: 'capitalize',
                                        }} */
                                                primary={label}
                                                secondary={
                                                    subTextField
                                                        ? item[subTextField]
                                                        : null
                                                }
                                                primaryTypographyProps={{
                                                    style: {
                                                        color:
                                                            selectedList.findIndex(
                                                                (elem) =>
                                                                    idField
                                                                        ? elem[
                                                                              idField
                                                                          ] ===
                                                                          item[
                                                                              idField
                                                                          ]
                                                                        : elem.id ===
                                                                          item.id
                                                            ) !== -1
                                                                ? 'LightGray'
                                                                : 'Black',
                                                    },
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}

                                {loading && (
                                    <ListItem button={!draggable}>
                                        <ListItemText primary={'Loading...'} />
                                    </ListItem>
                                )}
                            </List>
                        </Card>
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    sm={readOnly === false ? 6 : 12}
                    ref={leftSideRef}
                    className={classes.selectedListContainerContainer}
                    onDragEnter={(e) => {
                        e.preventDefault();
                        dragEnter(e, selectedList.length, 'right');
                    }}
                    onDragEnd={drop}
                    onDragOver={(e) => {
                        e.dataTransfer.dropEffect = 'move';
                        e.preventDefault();
                    }}
                >
                    {(selectedList?.length !== 0 ||
                        addedSearchTerms?.length > 0) &&
                        (selectedListView === 'list' ? (
                            <Card
                                component="div" /* className={classes.list} */
                            >
                                <List>
                                    {selectedList.map((data, index) => {
                                        const label =
                                            data[textField] ||
                                            data.name ||
                                            data.title ||
                                            (getDisplayText &&
                                                getDisplayText(data));
                                        return (
                                            <ListItem
                                                key={index}
                                                button={!draggable}
                                                onClick={
                                                    onClickSelected
                                                        ? () => {
                                                              onClickSelected(
                                                                  data
                                                              );
                                                          }
                                                        : null
                                                }
                                                onDragStart={(e) => {
                                                    dragStart(
                                                        e,
                                                        index,
                                                        'right'
                                                    );
                                                }}
                                                onDragEnter={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    dragEnter(
                                                        e,
                                                        index,
                                                        'right'
                                                    );
                                                }}
                                                onDragEnd={drop}
                                                onDragOver={(e) => {
                                                    e.dataTransfer.dropEffect =
                                                        'move';
                                                    e.preventDefault();
                                                }}
                                                draggable={draggable}
                                                style={{
                                                    borderTop:
                                                        '2px solid white',
                                                    ...(draggable && {
                                                        cursor: 'move',
                                                    }),
                                                    ...(dragOverItemIndex ===
                                                        index && {
                                                        // backgroundColor: 'pink',
                                                        borderTop:
                                                            '2px solid lightGrey',
                                                    }),
                                                }}
                                            >
                                                <ListItemText
                                                    primary={label}
                                                    secondary={
                                                        subTextField
                                                            ? data[subTextField]
                                                            : null
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    {showSelectionWithinSelection && (
                                                        <Tooltip
                                                            title={
                                                                selectedWithinSelectedTooltip
                                                            }
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    selectedWithinSelected?.findIndex(
                                                                        (
                                                                            elem
                                                                        ) =>
                                                                            idField
                                                                                ? elem[
                                                                                      idField
                                                                                  ] ===
                                                                                  data[
                                                                                      idField
                                                                                  ]
                                                                                : elem.id ===
                                                                                  data.id
                                                                    ) !== -1
                                                                }
                                                                onChange={() => {
                                                                    handleSelectWithinSelect(
                                                                        data
                                                                    );
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {showSelectionActionBtn &&
                                                        showSelectionActionBtn(
                                                            data
                                                        ) && (
                                                            <IconButton size="large">
                                                                {selectionActionBtnLoadingIds.includes(
                                                                    data[
                                                                        idField
                                                                    ] || data.id
                                                                ) ? (
                                                                    <CircularProgress
                                                                        color="secondary"
                                                                        size="1em"
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <Tooltip
                                                                            title={
                                                                                selectionActionBtnTooltip
                                                                            }
                                                                        >
                                                                            <SelectionActionBtnIcon
                                                                                onClick={() => {
                                                                                    onClickSelectionActionBtn(
                                                                                        data
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                            </IconButton>
                                                        )}

                                                    {!disabled && (
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={() => {
                                                                _handleItemDelete(
                                                                    data
                                                                );
                                                            }}
                                                            size="large"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    )}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Card>
                        ) : (
                            <Card
                                component="ul"
                                className={classes.chipsContainer}
                                /* style={{
                        padding: selectedList.length === 0 ? 0 : 10,
                        }} */
                            >
                                {selectedList.map((data, index) => {
                                    return (
                                        <li
                                            key={index}
                                            /* style={{ textTransform: 'capitalize' }} */
                                        >
                                            {readOnly ? (
                                                <Chip
                                                    label={
                                                        (data[textField] ||
                                                            data.name ||
                                                            data.title ||
                                                            (getDisplayText &&
                                                                getDisplayText(
                                                                    data
                                                                ))) +
                                                        (subTextField
                                                            ? ` (${data[subTextField]})`
                                                            : '')
                                                    }
                                                    onClick={
                                                        onClickSelected
                                                            ? () => {
                                                                  onClickSelected(
                                                                      data
                                                                  );
                                                              }
                                                            : null
                                                    }
                                                    className={classes.chip}
                                                    color="secondary"
                                                    style={{
                                                        pointerEvents: 'auto',
                                                    }}
                                                />
                                            ) : (
                                                <Chip
                                                    label={
                                                        (data[textField] ||
                                                            data.name ||
                                                            data.title ||
                                                            (getDisplayText &&
                                                                getDisplayText(
                                                                    data
                                                                ))) +
                                                        (subTextField
                                                            ? ` (${data[subTextField]})`
                                                            : '')
                                                    }
                                                    onDelete={() => {
                                                        _handleItemDelete(data);
                                                    }}
                                                    onClick={
                                                        onClickSelected
                                                            ? () => {
                                                                  onClickSelected(
                                                                      data
                                                                  );
                                                              }
                                                            : null
                                                    }
                                                    className={classes.chip}
                                                    color="secondary"
                                                    style={{
                                                        pointerEvents: 'auto',
                                                    }}
                                                />
                                            )}
                                        </li>
                                    );
                                })}
                                {addedSearchTerms?.map((data, index) => {
                                    return (
                                        <li key={index}>
                                            {readOnly ? (
                                                <Chip
                                                    label={data}
                                                    className={classes.chip}
                                                    color="primary"
                                                    style={{
                                                        pointerEvents: 'none',
                                                    }}
                                                />
                                            ) : (
                                                <Chip
                                                    label={data}
                                                    onDelete={() => {
                                                        removeSearchTerm(data);
                                                    }}
                                                    className={classes.chip}
                                                    color="secondary"
                                                    style={{
                                                        pointerEvents: 'auto',
                                                    }}
                                                />
                                            )}
                                        </li>
                                    );
                                })}
                            </Card>
                        ))}
                </Grid>
            </Grid>
        </Card>
    );
};

function CustomComponentContainer({ children }) {
    return <div>{children}</div>;
}
