import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';

const useFileValidation = () => {
    const setAlertState = useSetRecoilState(alertState);

    const validFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'text/plain',
        'image/jpeg',
        'image/webp',
        'image/png',
        'video/mp4',
        'video/mpeg',
        'application/vnd.rar',
        'application/zip',
        'application/x-zip-compressed',
    ];

    const isInvalidMimeType = (files) => {
        return Array.from(files).some(
            (file) => !validFileTypes.includes(file.type)
        );
    };

    const isInvalidFileLength = (files) => {
        return Array.from(files).length > 5;
    };

    const isInvalidFileSize = (files) => {
        const validFileSize = 30 * 1000 * 1000;
        const totalFileSize = Array.from(files).reduce(
            (prevState, currValue) => {
                return prevState + currValue.size;
            },
            0
        );
        return totalFileSize > validFileSize;
    };

    const showInvalidFileAlert = (message) => {
        setAlertState({
            show: true,
            message,
            severity: severity.WARNING,
        });
    };

    const validateFiles = (files) => {
        if (isInvalidFileLength(files)) {
            showInvalidFileAlert('Can add up to 5 files');
            return false;
        }

        if (isInvalidFileSize(files)) {
            showInvalidFileAlert('Maximum 30MB is allowed');
            return false;
        }

        if (isInvalidMimeType(files)) {
            showInvalidFileAlert('Invalid file type');
            return false;
        }

        return true;
    };

    return { alertState, validateFiles };
};

export default useFileValidation;
