import { Modal, Fade, Backdrop } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { VideoPlayer } from '../../admin/VideoPlayer';

const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        boxShadow: theme.shadows[5],
        backgroundColor: 'black',
        height: '70vh',
    },
}));

export const VideoPlayerModal = ({
    open,
    setOpen,
    blobURL,
    title = 'video',
    type,
}) => {
    const { classes } = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Modal
                aria-labelledby="video player modal"
                aria-describedby="video player modal"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //     timeout: 500,
                // }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <VideoPlayer
                            blobURL={blobURL}
                            title={title}
                            type={type}
                        />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};
