import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    CircularProgress,
} from '..';
import { useState } from 'react';

export const ConfirmDialog = ({
    onClose,
    open,
    onConfirm,
    title = 'Are you sure?',
    subtitle = '',
    checkbox = false,
    isLoading = false,
    onCancel,
    confirmLabel,
    cancelLabel,
    addGap = true,
}) => {
    const [check, setCheck] = useState(false);

    return (
        <Dialog disableScrollLock open={open} onClose={onClose}>
            <DialogTitle
                style={{ ...(addGap === false ? { paddingBottom: 0 } : {}) }}
            >
                {title}
            </DialogTitle>
            {subtitle && (
                <DialogContent>
                    <DialogContentText>{subtitle}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                {checkbox && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={check}
                                onChange={() => setCheck(!check)}
                            />
                        }
                        label="Check to continue"
                    />
                )}
                <Button
                    onClick={() => {
                        if (onCancel) onCancel();
                        else onClose();
                    }}
                    color="secondary"
                    variant="outlined"
                    disabled={isLoading}
                >
                    {cancelLabel ? cancelLabel : 'Cancel'}
                </Button>
                <Button
                    onClick={onConfirm}
                    color="secondary"
                    variant="contained"
                    disabled={isLoading ? true : checkbox ? !check : false}
                    autoFocus
                >
                    {isLoading ? (
                        <CircularProgress size="1.9em" />
                    ) : (
                        <>{confirmLabel ? confirmLabel : 'Confirm'}</>
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
