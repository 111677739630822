import React from 'react';
import { Grid, Box, Typography } from '../ui';

import { LazyTopFiveLogoArray } from './LogoArray';
// import { LazyKnownArray } from './KnownLogoArray';

export const TopCompany = ({ showHeading = true }) => {
    return (
        <Box>
            {showHeading && (
                <Typography
                    variant="h6"
                    color="secondary"
                    align="center"
                    gutterBottom
                >
                    Boosting careers for 100,000s at leading companies ...
                </Typography>
            )}
            <Box my={2}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <LazyTopFiveLogoArray />
                </Grid>
            </Box>
            {/* <Box my={2}>
                <Grid container justify="center" alignItems="center">
                    <LazyKnownArray />
                </Grid>
            </Box> */}
        </Box>
    );
};

export default TopCompany;
