import americanExpress from './assets/logo-0.png';
// logo-1 can be removed
import bankOfAmericaLogo from './assets/boa-logo.png';
import barcleys from './assets/logo-2.png';
// logo-3 can be removed
import boiengLogo from './assets/boieng.png';
import hsbcLogo from './assets/hsbc-logo.png';
import commerzBankLogo from './assets/logo-5.png';
import genpactLogo from './assets/genpact-logo.png';
import haclLogo from './assets/logo-7.png';
import cignaLogo from './assets/logo-8.png';
import jpMorganLogo from './assets/logo-9.png';
import cognizantLogo from './assets/cognizant-logo.png';
import nabLogo from './assets/logo-11.png';
import tataLogo from './assets/tcs-logo.png';
import wellsFargoLogo from './assets/well-fargo-logo.png';
import auBankLogo from './assets/logo-14.png';
import dbsBankLogo from './assets/logo-15.png';
import ftBankLogo from './assets/logo-16.png';
import keyBankLogo from './assets/logo-17.png';
import pncLogo from './assets/logo-18.png';
import regionsLogo from './assets/logo-19.png';
import hartfordLogo from './assets/logo-20.png';
import microsoftLogo from './assets/microsoft-logo.png';
import attLogo from './assets/att-logo.png';
import amazonLogo from './assets/amazon-logo.png';
import legalAndGenralLogo from './assets/legal-and-genral-logo.png';

// signupPageLogos
export const signupPageLogos = [
    tataLogo,
    cognizantLogo,
    attLogo,
    genpactLogo,
    bankOfAmericaLogo,
    boiengLogo,
    wellsFargoLogo,
    hsbcLogo,
];

export const logoArray = [
    americanExpress,
    bankOfAmericaLogo,
    barcleys,
    boiengLogo,
    hsbcLogo,
    commerzBankLogo,
    genpactLogo,
    haclLogo,
    cignaLogo,
    jpMorganLogo,
    cognizantLogo,
    nabLogo,
    tataLogo,
    wellsFargoLogo,
    auBankLogo,
    dbsBankLogo,
    ftBankLogo,
    keyBankLogo,
    pncLogo,
    regionsLogo,
    hartfordLogo,
];

export const topFiveLogos = [
    tataLogo,
    americanExpress,
    hartfordLogo,
    boiengLogo,
    barcleys,
];

export const otherLogos = [
    haclLogo,
    cignaLogo,
    jpMorganLogo,
    cognizantLogo,
    nabLogo,
    wellsFargoLogo,
    auBankLogo,
    dbsBankLogo,
    ftBankLogo,
];

// microsoft att amazon
// export const topKnownLogos = [microsoftLogo, attLogo, amazonLogo];
export const topKnownLogos = [
    cognizantLogo,
    tataLogo,
    genpactLogo,
    hsbcLogo,
    wellsFargoLogo,
];

export const cognizantPageLogos = [
    hsbcLogo,
    bankOfAmericaLogo,
    tataLogo,
    cognizantLogo,
    genpactLogo,
    legalAndGenralLogo,
    hartfordLogo,
    americanExpress,
];
