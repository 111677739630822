export const imageHasTransparency = (src) =>
    new Promise((resolve, reject) => {
        let img = new Image();
        img.src = src;
        img.crossOrigin = 'anonymous';
        img.onload = () => {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0);
            let imgData = context.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
            );
            let data = imgData.data;
            let found = false;
            for (let i = 0; i < data.length; i += 10) {
                if (data[i + 3] === 0) {
                    found = true;
                    break;
                }
            }
            resolve(found);
        };
        img.onerror = reject;
    });
