import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { NavContents } from './NavContents';
import { Box, Drawer, Hidden } from '../ui';
import ProfileDropDownTwo from './ProfileDropDownTwo';
const drawerWidth = 360;

const useStyles = makeStyles()((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerPaperOne: {
        width: '0px',
        overflow: 'hidden',
        transition: '0.5s',
        marginLeft: '-20px',
    },
    drawerPaperOneWithUser: {
        width: '76px',
        overflow: 'hidden',
        transition: '0.5s',
    },
    drawerPaperExpand: {
        width: drawerWidth,
        transition: '0.5s',
        boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    },
    twoWrapperContainer: {
        position: 'relative',
    },
    profileWrapperContainer: {
        position: 'absolute',
        top: '60px',
        right: '-10px',
        zIndex: '10000',
    },
}));

export const SideNav = ({
    showDrawer,
    onToggleDrawer,
    user,
    isInstructorRoute,
    isAdminRoute,
    routeRole,
}) => {
    const container =
        window !== undefined ? () => window.document.body : undefined;

    const { classes } = useStyles();
    const theme = useTheme();
    //const [searchValue, setSearchValue] = useState('');
    const [mouseIsOver, setMouseIsOver] = useState(false);
    const expand = mouseIsOver; /* || searchValue */
    const [dropDownBox, setDropDownBox] = useState(false);

    return (
        <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={showDrawer}
                    onClose={onToggleDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <Box className={classes.twoWrapperContainer}>
                        <Box>
                            <NavContents
                                onClickItem={() => {
                                    setTimeout(onToggleDrawer, 200);
                                }}
                                expand={true}
                                onToggleDrawer={onToggleDrawer}
                                showClose={true}
                                setDropDownBox={setDropDownBox}
                                dropDownBox={dropDownBox}
                                isAdminView={isAdminRoute}
                                isInstructorView={isInstructorRoute}
                                routeRole={routeRole}
                                /* searchBoxValue={searchValue}
                                    onSearchBoxChange={(value) =>
                                        setSearchValue(value)
                                    } */
                            />
                        </Box>
                        <Hidden mdUp>
                            <Box className={classes.profileWrapperContainer}>
                                <ProfileDropDownTwo
                                    onToggleDrawer={onToggleDrawer}
                                    dropDownBox={dropDownBox}
                                    setDropDownBox={setDropDownBox}
                                    isAdminView={isAdminRoute}
                                    isInstructorView={isInstructorRoute}
                                />
                            </Box>
                        </Hidden>
                    </Box>
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer
                    onMouseEnter={() => {
                        setMouseIsOver(true);
                    }}
                    onMouseLeave={() => {
                        setMouseIsOver(false);
                    }}
                    className={classes.drawer}
                    variant="permanent"
                    classes={
                        expand
                            ? {
                                  paper: classes.drawerPaperExpand,
                              }
                            : {
                                  paper: user
                                      ? classes.drawerPaperOneWithUser
                                      : classes.drawerPaperOne,
                              }
                    }
                >
                    <NavContents
                        expand={expand}
                        isAdminView={isAdminRoute}
                        isInstructorView={isInstructorRoute}
                        /* searchBoxValue={searchValue}
                            onSearchBoxChange={(value) => setSearchValue(value)} */
                        onToggleDrawer={() => {}}
                        routeRole={routeRole}
                    />
                </Drawer>
            </Hidden>
        </nav>
    );
};
