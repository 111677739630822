//import { coursesSlice } from '../courses/coursesSlice';
//import { useSelector } from 'react-redux';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
//import { useCurrentUser } from '../my-account/useCurrentUser';
import axios from 'axios';
import { allCourseIdsInUserJourneysFetchedForUserState } from './Recoil/State';

export const useGetAllCourseIdsInUserJourneys = (
    user,
    courseId,
    thinkificId
) => {
    const [isLoading, setIsLoading] = useState(false);
    // const [fetching, setFetching] = useState(false);
    const fetching = useRef(false);
    const [onDemandCourseIds, setOnDemandCourseIds] = useState([]);
    const [onAirThinkificIds, setOnAirthinkificIds] = useState([]);
    const [retroCourseIds, setRetroCourseIds] = useState([]);
    const [
        allCourseIdsInUserJourneysFetchedForUser,
        setAllCourseIdsInUserJourneysFetchedForUser,
    ] = useRecoilState(allCourseIdsInUserJourneysFetchedForUserState);

    // useEffect(() => {
    //     if (
    //         !fetching.current &&
    //         user &&
    //         user.id !== allCourseIdsInUserJourneysFetchedForUser
    //     ) {
    //         (async () => {
    //             try {
    //                 setIsLoading(true);
    //                 // setFetching(true);
    //                 fetching.current = true;
    //                 if (user && user.user_subscription_id !== '') {
    //                     const resJourneysIds = await axios.get(
    //                         `/api/journeys-old/${user._id}`
    //                     );

    //                     const resCourseIds = await axios.get(
    //                         `${
    //                             process.env.REACT_APP_SW_WORDPRESS_DOMAIN
    //                         }/wp-json/headless/journeys/course-ids?id=${resJourneysIds?.data
    //                             ?.map(({ id }) => id)
    //                             ?.join()}`
    //                     );
    //                     setOnDemandCourseIds(
    //                         resCourseIds.data.onDemandCourseIds
    //                     );
    //                     setOnAirthinkificIds(
    //                         resCourseIds.data.onAirThinkificCourseIds
    //                     );
    //                     setRetroCourseIds(resCourseIds.data.retroCourseIds);
    //                     setIsLoading(false);
    //                 } else {
    //                     // const products = [];
    //                     const {
    //                         data: { products },
    //                     } = await axios.get(
    //                         `/api/users/${user._id}/purchased-journeys?testquery=2`
    //                     );

    //                     const resCourseIds = await axios.get(
    //                         `${
    //                             process.env.REACT_APP_SW_WORDPRESS_DOMAIN
    //                         }/wp-json/headless/journeys/course-ids?id=${products
    //                             .map(({ product_id }) => product_id)
    //                             .join()}`
    //                     );
    //                     setOnDemandCourseIds(
    //                         resCourseIds.data.onDemandCourseIds
    //                     );
    //                     setRetroCourseIds(resCourseIds.data.retroCourseIds);
    //                     setIsLoading(false);
    //                     // setFetching(false);
    //                     fetching.current = false;
    //                 }
    //                 setAllCourseIdsInUserJourneysFetchedForUser(user.id);
    //             } catch (err) {
    //                 setIsLoading(false);
    //                 // setFetching(false);
    //                 fetching.current = false;
    //                 console.log(err);
    //             }
    //         })();
    //         // console.log('call');
    //     } else {
    //         if (!user) {
    //             setIsLoading(false);
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [user]);

    const hasCourseInJourney = useMemo(() => {
        if (user && (courseId || thinkificId)) {
            const inOnDemand = onDemandCourseIds.some(
                // eslint-disable-next-line
                (id) => id == courseId || id == thinkificId
            );
            const inOnAir = onAirThinkificIds.some(
                // eslint-disable-next-line
                (id) => id == courseId || id == thinkificId
            );
            return inOnAir || inOnDemand;
        } else {
            return false;
        }
    }, [user, onDemandCourseIds, courseId, onAirThinkificIds, thinkificId]);

    return { onDemandCourseIds, retroCourseIds, isLoading, hasCourseInJourney };
};
