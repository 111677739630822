import { useState, useMemo } from 'react';
import {
    Badge,
    Box,
    Button,
    IconButton,
    Loading,
    Paper,
    Popover,
    Typography,
} from '../ui';
import { useUserNotifications } from './useUserNotifications';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { NotificationShortItem } from './NotificationShortItem';
import { ChevronRightIcon } from '../icons';
import NotificationsIcon from '@mui/icons-material/Notifications';

export const NotificationButton = ({
    isAdminRoute,
    isInstructorRoute,
    socket,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, isLoading: loadingUser } = useCurrentUser();
    const {
        notifications,
        isLoading,
        markAllRead,
        handleRead,
        handleUnRead,
        deleteNotification,
    } = useUserNotifications(
        user?._id,
        isAdminRoute,
        isInstructorRoute,
        socket
    );

    const unReadNotifications = useMemo(() => {
        if (notifications.length) {
            const unReadOnes = notifications.filter(
                (notification) => !notification.read
            ).length;
            return unReadOnes;
        } else {
            return 0;
        }
    }, [notifications]);

    // Link for notification page
    const notificationPagePath = useMemo(() => {
        if (isAdminRoute) return '/admin/notifications';
        if (isInstructorRoute)
            return '/profile/instructor-dashboard/notifications';
        return '/profile/dashboard/notifications';
    }, [isAdminRoute, isInstructorRoute]);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box /* style={{ marginLeft: 'auto' }} */>
            <IconButton onClick={openMenu} size="large">
                <Badge color="error" badgeContent={unReadNotifications}>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableScrollLock
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper
                    style={{
                        overflowX: 'hidden',
                        minWidth: '280px',
                        maxWidth: '320px',
                    }}
                >
                    <Box p={2}>
                        <Box
                            mb={1}
                            display={'flex'}
                            justifyContent="space-between"
                        >
                            <Typography variant="h6">Notifications</Typography>
                            <Button size="small" onClick={markAllRead}>
                                mark all read
                            </Button>
                        </Box>

                        {isLoading && loadingUser ? (
                            <Loading height="200px" />
                        ) : notifications.length ? (
                            notifications
                                .slice(0, 4)
                                .map((notification) => (
                                    <NotificationShortItem
                                        key={notification._id}
                                        handleRead={handleRead}
                                        handleUnread={handleUnRead}
                                        deleteNotification={deleteNotification}
                                        notification={notification}
                                    />
                                ))
                        ) : (
                            <Typography>There are no notifications</Typography>
                        )}

                        <Box mt={1}>
                            <Button
                                href={notificationPagePath}
                                color="secondary"
                                size="small"
                                endIcon={<ChevronRightIcon />}
                            >
                                See all
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Popover>
        </Box>
    );
};
