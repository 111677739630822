import React, { useState } from 'react';
import { Box, Button, Typography, Menu, MenuItem } from '..';
import { AppsIcon, FormatListBulletedIcon } from '../../icons';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: { display: 'inline', display: 'flex' },
}));

export const ListGridChooseButtons = ({ istListView, setIsListView }) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.root}>
            <Button
                disableElevation
                color="primary"
                variant={istListView ? null : 'contained'}
                onClick={(e) => {
                    setIsListView(false);
                }}
                startIcon={<AppsIcon />}
                style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                Grid
            </Button>

            <Button
                disableElevation
                color="primary"
                variant={istListView ? 'contained' : null}
                onClick={(e) => {
                    setIsListView(true);
                }}
                startIcon={<FormatListBulletedIcon />}
                style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                List
            </Button>
        </Box>
    );
};
