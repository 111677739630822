import React from 'react';
import { Box, Typography } from '..';
import { makeStyles } from 'tss-react/mui';
import { useTimer } from 'react-timer-hook';
// import { useTimer } from 'react-timer-hook';

const useStyles = makeStyles()((theme) => ({
    timer: {
        display: 'flex',
        flexWrap: 'nowrap',
        //marginBottom: '20px',
    },

    timeContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        backgroundColor: 'White',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '5px',
        padding: '5px',
        margin: '2px',
    },

    time: {
        fontSize: '1.2rem',
        // fontWeight: 'bold',
    },
}));

export const TimerDays = ({ expiryTimestamp, onExpire }) => {
    const { classes } = useStyles();

    const { days } = useTimer({
        expiryTimestamp,
        onExpire: () => {
            if (onExpire) onExpire();
        },
    });

    return (
        <Box className={classes.timer}>
            {days > 0 && (
                <Box className={classes.timeContainer}>
                    <Typography className={classes.time}>
                        {days} day{days > 1 ? 's' : ''}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
