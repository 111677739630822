import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Link, Typography, Modal } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { useUser } from '../../src/auth/useUser';
import CheckIcon from '@mui/icons-material/Check';
import { blue } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
    modalContent: {
        position: 'absolute',
        width: '50%',
        // backgroundColor: 'rgb(20, 48, 89)',
        backgroundColor: 'white',
        /* border: '2px solid #000',
        boxShadow: theme.shadows[5], */
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        [theme.breakpoints.down('md')]: {
            width: 600,
        },
        [theme.breakpoints.down('sm')]: {
            width: 370,
        },
    },
    link: {
        color: blue[700],
        '&:hover': {
            color: blue[900],
            textDecoration: 'none',
        },
    },
    subHeading: {
        color: '#EEEEEE',
        fontSize: 15,
        padding: '15px 5px ',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    headingText: {
        color: 'Black',
        fontWeight: '800',
        paddingTop: 20,
        paddingBottom: 10,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    clkIcon: {
        fontSize: 74,
        color: 'white',
    },
    paper: {
        /* marginTop: theme.spacing(4), */
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        /*  maxWidth: '600px', */
        margin: 'auto',
    },

    form: {
        width: '95%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },

    discoverBtn: {
        backgroundColor: 'rgb(25, 118, 210)',
        color: 'white',
        height: 'auto',
        marginRight: 25,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(25, 118, 210)',
            textDecoration: 'none',
        },
    },

    NoThanks: {
        backgroundColor: '#ea7112',
        color: 'white',
        height: 'auto',
        marginLeft: 15,
        marginTop: 20,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#ea7112',
        },
    },
    title: {
        fontWeight: '800',
        fontSize: '15px',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        textDecoration: 'underline',
    },
    credits: {
        marginLeft: 15,
        fontSize: 15,
        fontWeight: '600',
        textAlign: 'center',
    },
    desc: {
        fontWeight: '600',
        fontSize: '13px',
        paddingLeft: 30,
        paddingBottom: 10,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    tick: {
        marginRight: 8,
    },
    divView: {
        display: 'flex',
    },
}));
export const EnrollFreePopupInst = ({
    open,
    onRequestClose = () => {},
    getClass,
    courseCost,
}) => {
    const { classes } = useStyles();
    const { user } = useUser();
    let navigate = useNavigate();
    const redirectPage = (e, link) => {
        e.stopPropagation();
        onRequestClose();
        navigate(link);
    };

    return (
        <>
            <Modal open={open} onClose={onRequestClose}>
                <div className={classes.modalContent}>
                    <Container disableGutters component="main" maxWidth="md">
                        <div className={classes.paper}>
                            <Typography
                                className={classes.headingText}
                                variant="h4"
                            >
                                {' 5-Day Free Trial '}
                            </Typography>
                            <div className={classes.form}>
                                <div>
                                    <div>
                                        <div className={classes.divView}>
                                            <CheckIcon
                                                className={classes.tick}
                                            />
                                            <div className={classes.title}>
                                                100s of hours of new, fresh, and
                                                top courses and Journeys added
                                                weekly
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.desc}>
                                        Learn at your own pace. Top content.
                                        Many hands-on exercises, labs, and
                                        assessments.
                                    </div>

                                    <div className={classes.divView}>
                                        <CheckIcon className={classes.tick} />
                                        <div className={classes.title}>
                                            Connect with live instructors,
                                            teaching assistants and mentors
                                        </div>
                                    </div>
                                    <div className={classes.desc}>
                                        Leading experts globally. Broad and deep
                                        coverage. Real time discussion forums.
                                        Always available just in time to ask
                                        questions, interact and get answers for
                                        you.
                                    </div>

                                    <div className={classes.divView}>
                                        <CheckIcon className={classes.tick} />
                                        <div className={classes.title}>
                                            Cancel whenever you want
                                        </div>
                                    </div>
                                    <div className={classes.desc}>
                                        No long-term obligation. Cancel whenever
                                        you want.
                                    </div>
                                    <div className={classes.divView}>
                                        <CheckIcon className={classes.tick} />
                                        <div className={classes.title}>
                                            Very low cost and flexible
                                            subscription / course purchase
                                            options
                                        </div>
                                    </div>
                                    <div className={classes.desc}>
                                        Four (4) flexible plans to choose from:
                                        Voyager, Voyager+, Astronaut and
                                        Commander. Plans suited to your needs.
                                        And, scholarships are available.
                                    </div>
                                    <div className={classes.divView}>
                                        <CheckIcon className={classes.tick} />
                                        <div className={classes.title}>
                                            Certificates you can share available
                                            upon completion
                                        </div>
                                    </div>
                                    <div className={classes.desc}>
                                        Share your accomplishments on your
                                        LinkedIn profile, social media and
                                        resume/CV.
                                    </div>
                                </div>

                                <Grid
                                    container
                                    item
                                    xs={12}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        paddingBottom: '20px',
                                        gap: '0.9rem',
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        justifyContent="center"
                                        spacing={2}
                                        alignItems="center"
                                        style={{ paddingTop: 25 }}
                                    >
                                        <Grid
                                            item
                                            xs={5}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={(e) =>
                                                        redirectPage(
                                                            e,
                                                            '/pricing'
                                                        )
                                                    }
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    className={
                                                        classes.discoverBtn
                                                    }
                                                >
                                                    Start Free Trial
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                            direction="row"
                                            justifyContent="center"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Button
                                                type="submit"
                                                fullWidth
                                                color="primary"
                                                variant="contained"
                                                className={classes.NoThanks}
                                                onClick={getClass}
                                            >
                                                Get Class
                                            </Button>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.credits}
                                            >
                                                {courseCost} credits
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box mx="auto" width="fit-content">
                                                <Link
                                                    className={classes.link}
                                                    href="/login"
                                                >
                                                    Already have an account?
                                                </Link>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Container>
                </div>
            </Modal>
        </>
    );
};
