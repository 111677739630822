import { useCallback, useState, useEffect } from 'react';
import { AzureBlobStorage } from './BlobStorage';

export const useFetchContainerFiles = (containerName, dirName = undefined) => {
    const [fileData, setFileData] = useState({ size: 0, fileNames: [] });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchFileData = useCallback(async (containerName, dirName) => {
        try {
            setLoading(true);

            const blobStorage = new AzureBlobStorage();

            let containerFiles;

            if (dirName) {
                containerFiles = await blobStorage.listFilesFromFolder(
                    containerName,
                    dirName
                );
            } else {
                containerFiles = await blobStorage.listFiles(containerName);
            }

            setFileData(containerFiles);
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        let willUnmount = false;

        if (willUnmount) return;

        if (!containerName) return;

        fetchFileData(containerName, dirName);

        return () => {
            willUnmount = true;
        };
    }, [containerName, dirName, fetchFileData]);

    return { fileData, loading, error, setFileData, fetchFileData };
};
