import { Box, Divider, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';

export const AdminNotification = ({ notification }) => {
    const { classes } = useStyles();

    const handleClick = (e) => {
        if (notification.button_link) {
            const win = window.open(notification.button_link, '_blank');
            win?.focus();
        }
    };
    return (
        <Box className={`${classes.notification}  `}>
            <Box className={` ${classes.notificationBox}`}>
                <Box
                    onClick={handleClick}
                    flexGrow={1}
                    display={'flex'}
                    flexDirection="column"
                    pl={1}
                >
                    <Typography className={classes.notificationText}>
                        {notification.notification_text}
                    </Typography>
                </Box>

                {notification.notification_image && (
                    <Box onClick={handleClick} ml={2}>
                        <img
                            src={notification.notification_image}
                            width="65px"
                            height="auto"
                            alt="Notification"
                        />
                    </Box>
                )}
            </Box>
            <Divider />
        </Box>
    );
};
const RADIUS = '4px';

const useStyles = makeStyles()((theme) => ({
    notification: {
        cursor: 'pointer',
        transition: '0.1s all ease-in',
        position: 'relative',
        display: 'flex',
        margin: '0 0 25px',
        flexDirection: 'column',
        '&::before': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            borderRadius: RADIUS,
            transform: 'translate(-50%,-50%)',
            width: '105%',
            height: '105%',
            content: '""',
            transition: '0.1s all ease-in',
            backgroundColor: 'rgba(150,150,150,0.4)',
            opacity: 0.5,
        },
        '&:hover': {
            '&::before': {
                backgroundColor: 'rgba(150,150,150,0.6)',
                opacity: 1,
            },
        },
    },
    notificationRead: {
        backgroundColor: '',
        '&::before': {
            position: 'absolute',
            top: '7px',
            width: '7px',
            height: '7px',
            left: '-8px',
            content: '""',
            borderRadius: '50%',
            backgroundColor: theme.palette.common.lightBlue,
        },
    },
    notificationText: {
        fontSize: 'min(1.2em, 3vw)',
        fontWeight: '500',
    },
    notificationBox: {
        margin: '15px 0',
        minWidth: '280px',
        position: 'relative',
        display: 'flex',
        marginLeft: '8px',
        justifyContent: 'space-between',
    },
    menuItem: {
        fontSize: '1em',
        fontWeight: 500,
    },
}));
