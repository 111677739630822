import {
    AccountTree,
    BarChart,
    LaptopWindows,
    NotificationsActiveRounded,
    PersonPinCircle,
    SupervisorAccount,
    ViewStream,
} from '@mui/icons-material';
import { lazy } from 'react';

import { Navigate } from 'react-router-dom';
// import NewcoursesDetailsPage from '../../courses-detail-page/NewCoursesDetailsPage';
// import NewMainSection from '../../courses-detail-page/NewMainSection';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { roleViews } from '../recoil/roleView.js';
import {
    AddBoxIcon,
    AccessibilityNewIcon,
    AccountBoxIcon,
    AccountCircleIcon,
    AssessmentIcon,
    AssignmentIndIcon,
    BuildIcon,
    Brightness4Icon,
    BusinessIcon,
    BlockIcon,
    BrushIcon,
    ChatIcon,
    CodeIcon,
    ContactsIcon,
    DashboardIcon,
    DirectionsWalkIcon,
    EqualizerIcon,
    EventIcon,
    FaceIcon,
    FilterListIcon,
    FlareIcon,
    FlightTakeoffIcon,
    ForumIcon,
    FeedbackIcon,
    GetAppIcon,
    HelpIcon,
    HomeIcon,
    InboxIcon,
    InsertChartIcon,
    LaptopChromebookIcon,
    LibraryAddIcon,
    LinkIcon,
    ListAltIcon,
    LiveTvIcon,
    LocalMallIcon,
    PublicIcon,
    MicIcon,
    NetworkCheckIcon,
    NoteIcon,
    NotListedLocationIcon,
    PaymentIcon,
    PeopleIcon,
    PeopleOutlineIcon,
    QuestionAnswerIcon,
    SchoolIcon,
    SentimentVerySatisfiedIcon,
    SettingsIcon,
    SettingsInputAntennaRoundedIcon,
    ShopTwoIcon,
    StarBorderIcon,
    SubjectIcon,
    SupervisedUserCircleIcon,
    TrendingUpIcon,
    AccountBalanceWalletIcon,
    VolumeUpIcon,
    EmailIcon,
    GroupIcon,
    LocalLibraryIcon,
    PublishIcon,
    LibraryBooksIcon,
    CreateNewFolderIcon,
    PollIcon,
    WhatshotIcon,
    DraftsIcon,
    VideocamIcon,
    FileCopyIcon,
} from '../../icons';
import Invitations from '../../instructor-dashboard/Invitations';
// import NewJourneyDetailsPage from '../../journeys-detail-page/NewJourneyDetailsPage';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import SSOAnalytics from '../../sso/SSOAnalytics';
import SSOPage from '../../sso/SSOPage';

// import JourneysLandingPage from '../../journeys-landing-page/JourneysLandingPage';

import LandingPage from '../../landing-page';

import { COLLABORATOR } from '../../auth/roles';
import { SwitchDomain } from '../SwitchDomain.js';

const lazyRetrySessionKey = (name) => {
    return `retry-${name}-refreshed`;
};

const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(lazyRetrySessionKey(name)) || 'false'
        );

        /* TRY TO IMPORT THE COMPONENT */
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem(
                    lazyRetrySessionKey(name),
                    'false'
                ); // success so reset the refresh
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    window.sessionStorage.setItem(
                        lazyRetrySessionKey(name),
                        'true'
                    ); // we are now going to refresh
                    return window.location.reload(); // refresh the page
                }
                reject(error);
            });
    });
};

const ProductCheckout = lazy(() =>
    lazyRetry(() => import('../../billing/ProductCheckout'), 'ProductCheckout')
);

const ProductCheckoutCredits = lazy(() =>
    lazyRetry(
        () => import('../../billing/ProductCheckoutCredits'),
        'ProductCheckoutCredits'
    )
);

const AdminsManagement = lazy(() =>
    lazyRetry(() => import('../../admin/AdminsManagement'), 'AdminsManagement')
);

const AccessRestricted = lazy(() =>
    lazyRetry(
        () => import('../../access-denied/AccessRestricted'),
        'AccessRestricted'
    )
);

const AdminAccessRestricted = lazy(() =>
    lazyRetry(
        () => import('../../access-denied/AdminAccessRestricted'),
        'AdminAccessRestricted'
    )
);

const Chat = lazy(() => lazyRetry(() => import('../../chat'), 'Chat'));

const CheckoutSuccess = lazy(() =>
    lazyRetry(() => import('../../billing/CheckoutSuccess'), 'CheckoutSuccess')
);

const CheckoutCancled = lazy(() =>
    lazyRetry(() => import('../../billing/CheckoutCancled'), 'CheckoutCancled')
);

// const CategoryPage = lazy(() =>
//     lazyRetry(() => import('../../category-landing-page'), 'CategoryPage')
// );

const HowItWorks = lazy(() =>
    lazyRetry(() => import('../../coming-soon'), 'HowItWorks')
);

const DashboardPage = lazy(() =>
    lazyRetry(() => import('../../dashboard'), 'DashboardPage')
);

// const CoursesDetailPage = lazy(() => import('../../courses-detail-page'));//Not in use

const ClientRequirementsCreateForm = lazy(() =>
    lazyRetry(
        () => import('../../admin/ClientRequirementsCreateForm'),
        'ClientRequirementsCreateForm'
    )
);

const ClientRequirementsCreateFormLongForm = lazy(() =>
    lazyRetry(
        () => import('../../admin/ClientRequirementsCreateFormLongForm'),
        'ClientRequirementsCreateFormLongForm'
    )
);

const ClientRequirementsCreateFormOnDemand = lazy(() =>
    lazyRetry(
        () => import('../../admin/ClientRequirementsCreateFormOnDemand'),
        'ClientRequirementsCreateFormOnDemand'
    )
);

const ClientRequirementsCreateFormGuidedProjects = lazy(() =>
    lazyRetry(
        () => import('../../admin/ClientRequirementsCreateFormGuidedProjects'),
        'ClientRequirementsCreateFormGuidedProjects'
    )
);

const ClientRequirementCustomReportForm = lazy(() =>
    lazyRetry(
        () => import('../../admin/ClientRequirementCustomReportForm'),
        'ClientRequirementCustomReportForm'
    )
);

const CollaboratorHome = lazy(() =>
    lazyRetry(
        () => import('../../collaborator/CollaboratorHome'),
        'CollaboratorHome'
    )
);

const CoursesManage = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/CoursesManage'),
        'CoursesManage'
    )
);

const CourseForm = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/CourseForm'),
        'CourseForm'
    )
);

// const OnAirPage = lazy(() =>
//     lazyRetry(() => import('../../on-air'), 'OnAirPage')
// );

// const OnAirDetailPage = lazy(() =>
//     lazyRetry(() => import('../../on-air-detail-page'), 'OnAirDetailPage')
// );
// const MainSectionTest = lazy(() =>
//     import('../../on-air-detail-page/MainSectionTest')
// );
const MtgDetailPage = lazy(() =>
    lazyRetry(() => import('../../on-air-mtg-detail-page'), 'MtgDetailPage')
);
const FirstTimeUserPage = lazy(() =>
    lazyRetry(() => import('../../onboarding'), 'FirstTimeUserPage')
);
const HowItWorksPage = lazy(() =>
    lazyRetry(() => import('../../how-it-works'), 'HowItWorksPage')
);
// we should remove components from this page it won't be used in future
// const JourneysPage = lazy(() =>
//     lazyRetry(() => import('../../journeys'), 'JourneysPage')
// );

const JourneysManage = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/JourneysManage'),
        'JourneysManage'
    )
);

const JobRolesManage = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/JobRolesManage'),
        'JobRolesManage'
    )
);

// const CustomJourneyPage = lazy(() =>
//     lazyRetry(
//         () => import('../../custom-journey-details-page/CustomJourneyPage'),
//         'CustomJourneyPage'
//     )
// );
const BuildCustomJourneyPage = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/BuildJourneyPage'),
        'BuildCustomJourneyPage'
    )
);

const CurationHomePage = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/CurationHomePage'),
        'CurationHomePage'
    )
);

const JourneyForm = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/JourneyForm'),
        'JourneyForm'
    )
);

// const JourneysLandingPage = lazy(() =>
//     lazyRetry(
//         () => import('../../journeys-landing-page/JourneysLandingPage'),
//         'JourneysLandingPage'
//     )
// );

// const NewJourneyDetailsPage = lazy(() =>
//     lazyRetry(
//         () => import('../../journeys-detail-page/NewJourneyDetailsPage'),
//         'NewJourneyDetailsPage'
//     )
// );

const JourneyConstallationPreviewPage = lazy(() =>
    lazyRetry(
        () =>
            import(
                '../../journeys-landing-page/JourneyConstallationPreview.js'
            ),
        'JourneyConstallationPreviewPage'
    )
);
// const JourneysDetailPage = lazy(() => import('../../journeys-detail-page'));//not in use
const LearnPress = lazy(() =>
    lazyRetry(() => import('../../learn-press'), 'LearnPress')
);
const RetroCoursePage = lazy(() =>
    lazyRetry(() => import('../../retro-course-detail-page'), 'RetroCoursePage')
);

const MainApp = lazy(() =>
    lazyRetry(() => import('../../my-account/MainApp'), 'MainApp')
);
const PersonalInfo = lazy(() =>
    lazyRetry(() => import('../../my-account/PersonalInfo'), 'PersonalInfo')
);
const SignInInfo = lazy(() =>
    lazyRetry(() => import('../../my-account/SignInInfo'), 'SignInInfo')
);
const Notifications = lazy(() =>
    lazyRetry(() => import('../../my-account/Notifications'), 'Notifications')
);
const Billing = lazy(() =>
    lazyRetry(() => import('../../my-account/Billing'), 'Billing')
);
const MyTraining = lazy(() =>
    lazyRetry(() => import('../../my-account/MyTraining'), 'MyTraining')
);
const DataPrivacy = lazy(() =>
    lazyRetry(() => import('../../my-account/DataPrivacy'), 'DataPrivacy')
);
const Leaderboard = lazy(() =>
    lazyRetry(() => import('../../my-account/Leaderboard'), 'Leaderboard')
);

// const CoursesPage = lazy(() =>
//     lazyRetry(() => import('../../courses/CoursesPage'), 'CoursesPage')
// );
// const CertificationsPage = lazy(() =>
//     lazyRetry(
//         () => import('../../courses/CertificationsPage'),
//         'CertificationsPage'
//     )
// );
// const CertificationPage = lazy(() =>
//     lazyRetry(
//         () => import('../../certifications-landing-page/CertificationPage'),
//         'CertificationPage'
//     )
// );

const CardCreationPage = lazy(() =>
    lazyRetry(
        () => import('../../card-creation-component/CardCreationPage'),
        'CardCreationPage'
    )
);

const AdminNotificationPage = lazy(() =>
    lazyRetry(
        () => import('../../admin/Notifications'),
        'AdminNotificationPage'
    )
);

const ChannelConfiguration = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/ChannelConfiguration'),
        'ChannelConfiguration'
    )
);

// const TestPrepsPage = lazy(() =>
//     lazyRetry(() => import('../../test-prep'), 'TestPrepsPage')
// );
// const TestPrepCategoryPage = lazy(() =>
//     lazyRetry(
//         () => import('../../test-prep-landing-page'),
//         'TestPrepCategoryPage'
//     )
// );
const InstructorDashboard = lazy(() =>
    lazyRetry(() => import('../../instructor-dashboard'), 'InstructorDashboard')
);

const InstructorProgramManagement = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/ProgramManager.js'),
        'InstructorProgramManagement'
    )
);

const InstructorLearnHow = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/learn-how'),
        'LearnHowPage'
    )
);

const InstructorHome = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/InstructorHome'),
        'InstructorHome'
    )
);

const AdminAndFinance = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/AdminAndFinance'),
        'AdminAndFinance'
    )
);

const InstructorInformational = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/InstructorInformational'),
        'InstructorInformational'
    )
);
const InstructorOpportunityManagement = lazy(() =>
    lazyRetry(
        () =>
            import(
                '../../instructor-dashboard/InstructorOpportunityManagement'
            ),
        'InstructorOpportunityManagement'
    )
);

// const InstructorContentProducer = lazy(() =>
//     lazyRetry(
//         () => import('../../instructor-dashboard/InstructorContentProducer'),
//         'InstructorContentProducer'
//     )
// );

const InstructorContentCreateSelectType = lazy(() =>
    lazyRetry(
        () =>
            import(
                '../../instructor-dashboard/InstructorContentCreateSelectType'
            ),
        'InstructorContentCreateSelectType'
    )
);

const InstructorStreamNow = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/InstructorStreamNow'),
        'InstructorStreamNow'
    )
);

const InstructorContentProducerForm = lazy(() =>
    lazyRetry(
        () =>
            import('../../instructor-dashboard/InstructorContentProducerForm'),
        'InstructorContentProducerForm'
    )
);

const LMSManageCourses = lazy(() =>
    lazyRetry(
        () =>
            import(
                '../../instructor-dashboard/NewInstructorContentProducerForm'
            ),
        'InstructorContentProducerForm'
    )
);

const UdemyUserCourses = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/UdemyUserCourses'),
        'UdemyUserCourses'
    )
);

// const ComingSoon = lazy(() => import('../../instructor-dashboard/ComingSoon'));

const InstructorReports = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/InstructorReports'),
        'InstructorReports'
    )
);
const Reports = lazy(() =>
    lazyRetry(() => import('../../instructor-dashboard/Reports'), 'Reports')
);
const ReportsEngagement = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/ReportsEngagement'),
        'ReportsEngagement'
    )
);

// const InstructorIntegrations = lazy(() =>
//     lazyRetry(
//         () => import('../../instructor-dashboard/InstructorIntegrations'),
//         'InstructorIntegrations'
//     )
// );

// const InstructorSupportedPersonas = lazy(() =>
//     lazyRetry(
//         () =>
//             import(
//                 '../../instructor-dashboard/content-personas/InstructorSupportedPersonas'
//             ),
//         'InstructorSupportedPersonas'
//     )
// );

const InstructorSupportedBlogs = lazy(() =>
    lazyRetry(
        () =>
            import(
                '../../instructor-dashboard/content-techbytes/InstructorSupportedTechbytes'
            ),
        'InstructorSupportedBlogs'
    )
);

const InstructorCourseOutlines = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/InstructorCourseOutline'),
        'InstructorCourseOutlines'
    )
);

const InstructorPage = lazy(() =>
    lazyRetry(() => import('../../instructor'), 'InstructorPage')
);

const AdminInstructorPage = lazy(() =>
    lazyRetry(
        () => import('../../admin-side-instructor'),
        'AdminInstructorPage'
    )
);

const UserProfiles = lazy(() =>
    lazyRetry(() => import('../../admin/UserProfiles'), 'UserProfiles')
);
// const NewUserProfile = lazy(() =>
//     lazyRetry(() => import('../../admin/NewUsersProfile'), 'NewUserProfile')
// );
const NewUserProfileActive = lazy(() =>
    lazyRetry(
        () => import('../../admin/NewUsersProfileActive'),
        'NewUserProfileActive'
    )
);

const UserDetailsPage = lazy(() =>
    lazyRetry(() => import('../../admin/UserDetailsPage'), 'UserDetailsPage')
);

const ClientDetailsPage = lazy(() =>
    lazyRetry(
        () => import('../../client-management/ClientDetailsPage'),
        'ClientDetailsPage'
    )
);

const RoadmapEditor = lazy(() =>
    lazyRetry(
        () => import('../../constellations/ConstellationEditor.js'),
        'RoadmapEditor'
    )
);

const RoadmapPreview = lazy(() =>
    lazyRetry(
        () => import('../../constellations/PreviewPage.js'),
        'RoadmapPreview'
    )
);

const GuidancePreviewPage = lazy(() =>
    lazyRetry(
        () => import('../../admin/components/OMDGuidance/GuidancePreview.js'),
        'GuidancePreviewPage'
    )
);

const ConstellationsPage = lazy(() =>
    lazyRetry(
        () => import('../../constellations/index.js'),
        'ConstellationsPage'
    )
);

const JourneyBuilderPage = lazy(() =>
    lazyRetry(
        () => import('../../constellations/JourneyBuilder.js'),
        'JourneyBuilderPage'
    )
);

// const NewInstructorsProfileActive = lazy(() =>
//     lazyRetry(
//         () => import('../../admin/NewInstructorsProfileActive'),
//         'NewInstructorsProfileActive'
//     )
// );

const NewUserProfileSuspended = lazy(() =>
    lazyRetry(
        () => import('../../admin/NewUsersProfileSuspended'),
        'NewUserProfileSuspended'
    )
);
const AdminHome = lazy(() =>
    lazyRetry(() => import('../../admin/AdminHome'), 'AdminHome')
);

const MySitePage = lazy(
    () => lazyRetry(() => import('../../my-site')),
    'MySitePage'
);

const ReportingCompare = lazy(() =>
    lazyRetry(() => import('../../admin/ReportingCompare'), 'ReportingCompare')
);
const ClientReports = lazy(() =>
    lazyRetry(() => import('../../admin/ClientReports'), 'ClientReports')
);
const StardustReports = lazy(() =>
    lazyRetry(
        () => import('../../admin/reports/StardustReports'),
        'StardustReports'
    )
);
const AdminNotification = lazy(() =>
    lazyRetry(
        () => import('../../admin/create-message/MessagingLibrary'),
        'AdminNotification'
    )
);
const AdminDashboard = lazy(() =>
    lazyRetry(() => import('../../admin/AdminDashboard'), 'AdminDashboard')
);
const AddUserPage = lazy(() =>
    lazyRetry(() => import('../../admin/AddUserPage'), 'AddUserPage')
);
const InstructorsManagement = lazy(() =>
    lazyRetry(
        () => import('../../admin/InstructorsManagement'),
        'InstructorsManagement'
    )
);
const InstructorDetailsView = lazy(() =>
    lazyRetry(
        () => import('../../admin/InstructorDetailsView'),
        'InstructorDetailsView'
    )
);

const OpportunityManagement = lazy(() =>
    lazyRetry(
        () => import('../../admin/OpportunityManagement'),
        'OpportunityManagement'
    )
);

const OverallAnalytics = lazy(() =>
    lazyRetry(() => import('../../admin/OverallAnalytics'), 'OverallAnalytics')
);

const FeedsAndActivity = lazy(() =>
    lazyRetry(() => import('../../admin/FeedsAndActivity'), 'FeedsAndActivity')
);

const RequirementDetails = lazy(() =>
    lazyRetry(
        () => import('../../admin/RequirementDetails'),
        'RequirementDetails'
    )
);
// const CallWithChatExperience = lazy(() =>
//     import('../../teams/CallWithChatExperience')
// );

// const ContentCuration = lazy(() =>
//     import('../../admin/content-curation/ContentCurationPage')
// );

const AdminPersonas = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/AdminPersonas'),
        'AdminPersonas'
    )
);

const AdminTechbytes = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/AdminTechbytes'),
        'AdminTechbytes'
    )
);

const Guidance = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/Guidance.js'),
        'Guidance'
    )
);

const LogisticsTemplate = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/LogisticsTemplate.js'),
        'LogisticsTemplate'
    )
);

const LogisticsTemplateDetailsPage = lazy(() =>
    lazyRetry(
        () =>
            import(
                '../../admin/content-curation/LogisticsTemplateDetailsPage.js'
            ),
        'LogisticsTemplateDetailsPage'
    )
);

const AdminAllContent = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/AdminAllContent'),
        'AdminAllContent'
    )
);

const PlatformContentAssign = lazy(() =>
    lazyRetry(
        () => import('../../admin/content-curation/PlatformContentAssign'),
        'PlatformContentAssign'
    )
);

const MessageCreationPage = lazy(() =>
    lazyRetry(
        () => import('../../admin/create-message/MessageCreationPage'),
        'MessageCreationPage'
    )
);

const CreateNotification = lazy(() =>
    lazyRetry(
        () => import('../../admin/create-message/CreateStarweaverNotification'),
        'CreateNotification'
    )
);

// const JobRoleInfoPage = lazy(() =>
//     lazyRetry(() => import('../../job-role/JobRoleInfoPage'), 'JobRoleInfoPage')
// );

const JobRoleEditPage = lazy(() =>
    lazyRetry(() => import('../../job-role/JobRoleEditPage'), 'JobRoleEditPage')
);

const JobRoleForm = lazy(() =>
    lazyRetry(() => import('../../job-role/JobRoleForm'), 'JobRoleForm')
);

// const TechbytesEditPage = lazy(() =>
//     lazyRetry(
//         () => import('../../techbytes/TechbytesEditPage'),
//         'TechbytesEditPage'
//     )
// );

// const JobRolesLandingPage = lazy(() =>
//     lazyRetry(
//         () => import('../../job-role/JobRolesLandingPage'),
//         'JobRolesLandingPage'
//     )
// );

// const ChannelLandingPage = lazy(() =>
//     lazyRetry(() => import('../../channels'), 'ChannelLandingPage')
// );

// const AdminChannelLandingPage = lazy(() =>
//     lazyRetry(() => import('../../admin-channels'), 'AdminChannelLandingPage')
// );

const AdminProgramManagement = lazy(() =>
    lazyRetry(
        () => import('../../admin/AdminProgramManagement'),
        'AdminProgramManagement'
    )
);
const TeamManagementPage = lazy(() =>
    lazyRetry(
        () => import('../../admin/TeamManagementPage'),
        'TeamManagementPage'
    )
);
const Lab = lazy(() => lazyRetry(() => import('../../labs/Lab'), 'Lab'));

const AllPlans = lazy(() =>
    lazyRetry(() => import('../../plans/AllPlans'), 'AllPlans')
);
const ComparePlans = lazy(() =>
    lazyRetry(() => import('../../plans/ComparePlans'), 'ComparePlans')
);
const ComparePlansTeam = lazy(() =>
    lazyRetry(() => import('../../plans/ComaprePlansTeams'), 'ComparePlansTeam')
);

const LiveLabsPage = lazy(() =>
    lazyRetry(() => import('../../live-labs'), 'LiveLabsPage')
);
const LiveLabsDetailPage = lazy(() =>
    lazyRetry(
        () => import('../../live-labs-details-page'),
        'LiveLabsDetailPage'
    )
);
const ForOrganisation = lazy(() =>
    lazyRetry(() => import('../../forOrganisation'), 'ForOrganisation')
);
//const LandingPage = lazy(() => import('../../landing-page'));

const LinkedinRedirect = lazy(() =>
    lazyRetry(() => import('../../auth/LinkedInLogin'), 'LinkedinRedirect')
);
const Login = lazy(() => lazyRetry(() => import('../../auth/Login'), 'Login'));
const Logout = lazy(() =>
    lazyRetry(() => import('../../auth/Logout'), 'Logout')
);
const SignUp = lazy(() =>
    lazyRetry(() => import('../../auth/SignUp'), 'SignUp')
);
const MigrationSignUp = lazy(() =>
    lazyRetry(() => import('../../auth/MigrationSignUp'), 'MigrationSignUp')
);
const ResetPassword = lazy(() =>
    lazyRetry(() => import('../../auth/ResetPassword'), 'ResetPassword')
);

const SetPassword = lazy(() =>
    lazyRetry(() => import('../../auth/SetPassword'), 'SetPassword')
);
const CompanySignUpPage = lazy(() =>
    lazyRetry(() => import('../../auth/CompanySignUpPage'), 'CompanySignUpPage')
);
const CouponSignUp = lazy(() =>
    lazyRetry(() => import('../../auth/CouponSignUp'), 'CouponSignUp')
);
const InstructorSignUp = lazy(() =>
    lazyRetry(() => import('../../auth/InstructorSignUp'), 'InstructorSignUp')
);
const InstructorLogin = lazy(() =>
    lazyRetry(() => import('../../auth/InstructorLogin'), 'InstructorLogin')
);

const ServerInfo = lazy(() =>
    lazyRetry(() => import('../../auth/ServerInfo'), 'ServerInfo')
);

const SearchResultPage = lazy(() =>
    lazyRetry(
        () => import('../../search-and-browse/SearchResultPage'),
        'SearchResultPage'
    )
);
const BrowseCategoriesPage = lazy(() =>
    lazyRetry(
        () => import('../../search-and-browse/BrowseCategoriesPage'),
        'BrowseCategoriesPage'
    )
);
const BrowseResultPage = lazy(() =>
    lazyRetry(
        () => import('../../search-and-browse/BrowseResultPage'),
        'BrowseResultPage'
    )
);

// const RoleManager = lazy(() =>
//     import('../../instructor-dashboard/RoleManager')
// );//Not in use

const StudentOnboardingPage = lazy(() =>
    lazyRetry(() => import('../../student-onboarding'), 'StudentOnboardingPage')
);
const GlobalMain = lazy(() =>
    lazyRetry(() => import('../../global-access-page'), 'GlobalMain')
);
// const OnboardingContainer = lazy(() =>
//     lazyRetry(
//         () => import('../../instructor-onboarding'),
//         'OnboardingContainer'
//     )
// );

const OnAirManage = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/OnAirManage'),
        'OnAirManage'
    )
);

const OnAirForm = lazy(() =>
    lazyRetry(() => import('../../instructor-dashboard/OnAirForm'), 'OnAirForm')
);

const OnboardingContainerNew = lazy(() =>
    lazyRetry(
        () => import('../../instructor-onboarding/OnboardingContainerNew'),
        'OnboardingContainerNew'
    )
);

const SubmissionMessage = lazy(() =>
    lazyRetry(
        () => import('../../instructor-onboarding/SubmissionMessage'),
        'SubmissionMessage'
    )
);

const InstructorMSA = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/InstructorMSA'),
        'InstructorMSA'
    )
);

const MyChannel = lazy(() =>
    lazyRetry(() => import('../../instructor/MyChannel'), 'MyChannel')
);

const CouponsPage = lazy(() =>
    lazyRetry(() => import('../../coupons'), 'CouponsPage')
);

const OfferPage = lazy(() =>
    lazyRetry(() => import('../../ui/CustomUI/OfferPage'), 'OfferPage')
);

// const MsgSuiteTempList = lazy(() =>
//     import('../../message-suite/MsgSuiteTempList')
// );

const MobileOtpVerificationPage = lazy(() =>
    lazyRetry(
        () => import('../../verify-mobile-number'),
        'MobileOtpVerificationPage'
    )
);

//const Chat = lazy(() => import('../../chat'));

const CreateNewEmailTempl = lazy(() =>
    lazyRetry(
        () => import('../../message-suite/CreateNewEmailTempl'),
        'CreateNewEmailTempl'
    )
);
// const CertificationsDetailPage = lazy(() =>
//     lazyRetry(
//         () => import('../../courses/CertificationsDetailPage'),
//         'CertificationsDetailPage'
//     )
// );

const UniversityStudentLandingPage = lazy(() =>
    lazyRetry(
        () => import('../../university-student-landing-page'),
        'UniversityStudentLandingPage'
    )
);

// const TechbytesArticlePage = lazy(() =>
//     lazyRetry(
//         () => import('../../techbytes/TechbytesArticlePage'),
//         'TechbytesArticlePage'
//     )
// );

// const TechBytes = lazy(() =>
//     lazyRetry(() => import('../../techbytes/TechBytes'), 'TechBytes')
// );

const TechByteEditPage = lazy(() =>
    lazyRetry(
        () => import('../../techbytes/TechByteEditPage'),
        'TechByteEditPage'
    )
);

const Podcasts = lazy(() =>
    lazyRetry(() => import('../../podcasts'), 'Podcasts')
);

// const VoiceCall = lazy(() =>
//     lazyRetry(() => import('../../teams-meeting'), 'VoiceCall')
// );

const ZoomOauthRedirectPage = lazy(() =>
    lazyRetry(
        () => import('../../zoom/ZoomOauthRedirectPage'),
        'ZoomOauthRedirectPage'
    )
);

const PoliciesPage = lazy(() =>
    lazyRetry(() => import('../../footer/PoliciesPage'), 'PoliciesPage')
);

const SupportPage = lazy(() =>
    lazyRetry(() => import('../../footer/Support'), 'SupportPage')
);

const BooksPage = lazy(() =>
    lazyRetry(() => import('../../footer/Books'), 'BooksPage')
);

const EnterprisePage = lazy(() =>
    lazyRetry(() => import('../../footer/Enterprise'), 'EnterprisePage')
);

const ScreenTestPage = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/ScreenTest'),
        'ScreenTestPage'
    )
);

const IntegrationsPage = lazy(() =>
    lazyRetry(
        () => import('../../instructor-dashboard/Integrations'),
        'IntegrationsPage'
    )
);

const ClientsPage = lazy(() =>
    lazyRetry(() => import('../../client-management/Clients'), 'ClientsPage')
);
//const DashboardPage = lazy(() => import('../../dashboard'));

/* export const authorSignupRoute = {
    name: 'Author Signup',
    icon: FaceIcon,
    path: '/author-signup',
    Component: AuthorSignUpPage,
    isPublic: true,
    hideSideNav: true,
}; */

export const starweaverAdminRoute = {
    name: 'Starweaver Admin',
    icon: FaceIcon,
    path: '/starweaver/admin',
    Component: AdminDashboard,
};

export const courseCardRoute = {
    name: 'CourseCard Admin',
    icon: FaceIcon,
    path: '/admin/card/route/',
    Component: CardCreationPage,
};

export const adminRoute = {
    // name: 'Admin',
    name: 'Home',
    icon: FaceIcon,
    path: '/admin',
    Component: AdminHome,
    admin: true,
};

export const mySiteRoute = {
    name: 'My Site',
    icon: LaptopWindows,
    path: '/my-site',
    Component: MySitePage,
    admin: true,
};

export const AdminNotificationRoute = {
    name: 'Notifications',
    icon: NotificationsActiveRounded,
    path: '/admin/notifications',
    Component: AdminNotificationPage,
    admin: true,
};

// User and team management
export const adminUserProfiles = {
    name: 'Create & Manage',
    icon: AccountCircleIcon,
    path: '/admin/user-profiles',
    // Component: UserProfiles,
    // Component: NewUserProfile,
    Component: NewUserProfileActive,
    admin: true,
};

export const adminUserProfilesRoute = {
    name: 'Create & Manage',
    icon: AccountCircleIcon,
    path: '/admin/user-profiles/active/',
    // Component: UserProfiles,
    // Component: NewUserProfile,
    Component: NewUserProfileActive,
    admin: true,
};

// export const adminUserProfileRoute = {
//     name: 'Create & Manage',
//     icon: AccountCircleIcon,
//     path: '/admin/user-profiles/active/:id',
//     // Component: UserProfiles,
//     // Component: NewUserProfile,
//     Component: UserDetailsPage,
//     admin: true,
// };

export const adminUserProfileRoute = {
    name: 'Create & Manage',
    icon: AccountCircleIcon,
    path: [
        '/admin/user-profiles/active/:id',
        '/admin/user-profiles/active/:id/:section',
        '/admin/user-profiles/active/:id/:section/:subSection',
    ],
    // Component: UserProfiles,
    // Component: NewUserProfile,
    Component: UserDetailsPage,
    admin: true,
};

export const adminUserSuspendedRoute = {
    name: 'Suspended Users',
    icon: BlockIcon,
    path: '/admin/user-profiles/suspended/',
    // Component: UserProfiles,
    // Component: NewUserProfile,
    Component: NewUserProfileSuspended,
    admin: true,
};

export const adminUserTestActProfile = {
    name: 'Create & Manage',
    icon: AccountCircleIcon,
    path: '/admin/user-profiles/test-active/',
    Component: UserProfiles,
    // Component: NewUserProfile,
    admin: true,
};

export const adminUserTestProfile = {
    name: 'Create & Manage',
    icon: AccountCircleIcon,
    path: '/admin/user-profiles/test-suspended/',
    Component: UserProfiles,
    // Component: NewUserProfile,
    admin: true,
};

export const teamManagementRoute = {
    name: 'Team Management',
    icon: GroupIcon,
    path: '/admin/team-management',
    Component: TeamManagementPage,
    admin: true,
};

export const adminOpportunityManagementRoute = {
    name: 'Opportunity Management',
    icon: WhatshotIcon,
    path: '/admin/opportunity-management',
    Component: OpportunityManagement,
    admin: true,
};

export const adminOpportunityManagementAnalyticsRoute = {
    name: 'Insights',
    icon: BarChart,
    path: '/admin/opportunity-management/insights',
    Component: OverallAnalytics,
    admin: true,
    exact: true,
};

export const adminOpportunityManagementFeedRoute = {
    name: 'Streams',
    icon: ViewStream,
    path: '/admin/opportunity-management/streams',
    Component: () => (
        <Navigate to="/admin/opportunity-management/streams/feeds" replace />
    ),
    admin: true,
    exact: true,
};

export const adminOpportunityManagementFeedAndActivityRoute = {
    name: 'Events',
    icon: ViewStream,
    path: '/admin/opportunity-management/streams/:section',
    Component: FeedsAndActivity,
    admin: true,
    exact: true,
};

export const adminOpportunityManagementCustomReportFormRoute = {
    name: 'Customized Report',
    icon: BarChart,
    path: [
        '/admin/opportunity-management/custom-report-form',
        '/admin/opportunity-management/custom-report-form/:id',
    ],
    Component: ClientRequirementCustomReportForm,
    admin: true,
    exact: true,
    // hideNavBar: true,
    hideFooter: true,
};

export const adminOpportunityManagementSectionsRoute = {
    name: 'Opportunity Management',
    icon: WhatshotIcon,
    path: '/admin/opportunity-management/:section',
    Component: OpportunityManagement,
    admin: true,
};

export const adminOpportunityManagementDetailsRoute = {
    name: 'Opportunity  Management',
    icon: WhatshotIcon,
    path: [
        '/admin/opportunity-management/details/:requirementId',
        '/admin/opportunity-management/details/:requirementId/:section',
    ],
    Component: RequirementDetails,
    admin: true,
    exact: true,
};

export const adminOpportunityManagementRequirementsRoute = {
    name: 'Requirements',
    icon: NotListedLocationIcon,
    path: '/admin/opportunity-management/requirements',
    Component: OpportunityManagement,
    admin: true,
};

export const adminOpportunityManagementProjectsRoute = {
    name: 'Projects',
    icon: BrushIcon,
    path: '/admin/opportunity-management/projects',
    Component: OpportunityManagement,
    admin: true,
};

export const adminsPending = {
    name: 'Admins',
    icon: LocalLibraryIcon,
    // path: '/admins/pending',
    path: '/admin/pending',
    Component: AdminsManagement,
    admin: true,
};

// Admin instructor

export const adminInstructorDetails = {
    name: 'Instructor Details',
    icon: LocalLibraryIcon,
    path: '/admin/instructors/details',
    Component: InstructorDetailsView,
    admin: true,
};

export const adminInstructorDetailsSection = {
    name: 'Instructor Details Section',
    icon: LocalLibraryIcon,
    path: '/admin/instructors/details/:section',
    Component: InstructorDetailsView,
    admin: true,
};

export const adminInstructorDetailsSubSection = {
    name: 'Instructor Details Sub-Section',
    icon: LocalLibraryIcon,
    path: '/admin/instructors/details/:section/:subSection',
    Component: InstructorDetailsView,
    admin: true,
};

export const adminInstructors = {
    name: 'Instructors/SMEs',
    icon: LocalLibraryIcon,
    path: '/admin/instructors',
    Component: InstructorsManagement,
    // Component: NewInstructorsProfileActive,
    admin: true,
};

export const adminInstructorsActive = {
    name: 'Instructors/SMEs',
    icon: LocalLibraryIcon,
    path: '/admin/instructors/active',
    // Component: InstructorsManagement,
    // Component: NewInstructorsProfileActive,
    admin: true,
};
export const adminInstructorsRejected = {
    name: 'Rejected Instructors',
    icon: TrendingUpIcon,
    path: '/admin/instructors/rejected',
    // Component: InstructorsManagement,
    admin: true,
};
export const adminInstructorsSubmissions = {
    name: 'Submissions',
    icon: PublishIcon,
    path: '/admin/instructors/submissions',
    // Component: InstructorsManagement,
    admin: true,
};
export const adminInstructorsSuspended = {
    name: 'Suspended',
    icon: PublishIcon,
    path: '/admin/instructors/suspended',
    // Component: InstructorsManagement,
    admin: true,
};

export const adminInstructorsReports = {
    name: 'Reports',
    icon: AssessmentIcon,
    path: '/admin/instructors/reports',
    Component: InstructorsManagement,
    admin: true,
};

export const adminInstructorsRequirements = {
    name: 'Requirements',
    icon: LocalLibraryIcon,
    path: '/admin/instructors/requirements',
    Component: InstructorsManagement,
    admin: true,
};

export const adminInstructorsRoute = {
    name: 'Instructors/SMEs',
    icon: LocalLibraryIcon,
    path: '/admin/instructors/:section',
    Component: InstructorsManagement,
    // Component: NewInstructorsProfileActive,
    admin: true,
};

// Content Management
// export const contentAssignAndManageRoute = {
//     name: 'Content Management',
//     icon: LibraryBooksIcon,
//     path: '/admin/content/assign',
//     Component: ContentCuration,
//     admin: true,
// };

// export const contentAdminRoute = {
//     name: 'Content Management',
//     icon: LibraryBooksIcon,
//     path: '/admin/content/all',
//     Component: AdminAllContent,
//     admin: true,
// };

export const contentAdminAssignRoute = {
    name: 'Content Assign',
    icon: LibraryBooksIcon,
    path: '/admin/content/assign',
    Component: PlatformContentAssign,
    admin: true,
};

export const addCustomJourneyLanding = {
    name: 'Add Custom Journeys',
    icon: CreateNewFolderIcon,
    path: '/admin/content/curate/journey',
    Component: BuildCustomJourneyPage,
    admin: true,
};

export const addCustomJourneyLandingPage = {
    name: 'Add Custom Journeys',
    icon: CreateNewFolderIcon,
    path: '/admin/content/curate/journey/:journeySlug/',
    Component: BuildCustomJourneyPage,
    admin: true,
};

export const contentCurationRoute = {
    name: 'Curate',
    icon: CreateNewFolderIcon,
    path: '/admin/content/curate',
    Component: CurationHomePage,
    admin: true,
};

export const contentCurationAccessDeniedRoute = {
    name: 'Curate',
    icon: CreateNewFolderIcon,
    path: '/admin/content/curate/access-denied',
    Component: AdminAccessRestricted,
    admin: true,
};

export const contentPersonasRoute = {
    name: 'Personas',
    icon: FaceIcon,
    path: '/admin/content/personas',
    Component: () => <Navigate to="/admin/content/personas/publish" replace />,
    // Component: AdminPersonas,
    admin: true,
};

export const contentPersonasEditRoute = {
    name: 'Careers Edit',
    icon: FaceIcon,
    path: '/admin/content/personas/edit',
    Component: JobRoleEditPage,
    admin: true,
};

export const contentPersonasStatusRoute = {
    name: 'Personas',
    icon: FaceIcon,
    path: '/admin/content/personas/:status',
    Component: AdminPersonas,
    admin: true,
};

export const contentBlogsEditRoute = {
    name: 'Blogs Edit',
    icon: CodeIcon,
    path: ['/admin/content/blogs/form', '/admin/content/blogs/form/:id'],
    Component: TechByteEditPage,
    admin: true,
};

export const contentBlogsRoute = {
    name: 'Blogs',
    icon: CodeIcon,
    path: '/admin/content/blogs',
    Component: AdminTechbytes,
    admin: true,
};

export const GuidanceRoute = {
    name: 'Guidance',
    icon: SupportAgentIcon,
    path: '/admin/content/guidance',
    Component: Guidance,
    admin: true,
};

export const LogisticsTemplateRoute = {
    name: 'Logistics Templates',
    icon: TableRowsIcon,
    path: '/admin/content/logistics-templates',
    Component: LogisticsTemplate,
    admin: true,
};

// export const LogisticsTemplateDetailsPageRoute = {
//     name: 'Logistics Templates',
//     icon: TableRowsIcon,
//     path: '/admin/content/logistics-templates/:templateId',
//     Component: LogisticsTemplateDetailsPage,
//     admin: true,
// };

export const LogisticsTemplateDetailsSectionPageRoute = {
    name: 'Logistics Templates',
    icon: TableRowsIcon,
    path: '/admin/content/logistics-templates/:templateId/:section',
    Component: LogisticsTemplateDetailsPage,
    admin: true,
};

export const JourneyBuilderRoute = {
    name: 'Constellations',
    icon: AccountTree,
    path: '/journeybuilder',
    Component: JourneyBuilderPage,
    isPublic: false,
    hideSideNav: true,
    hideNavBar: true,
};

export const ConstellationsRoute = {
    name: 'Constellations',
    icon: AccountTree,
    path: '/content/constellations',
    Component: ConstellationsPage,
    allowedRoleViews: [roleViews.ADMIN, roleViews.INSTRUCTOR],
};

export const ConstellationsEditRoute = {
    name: 'Constellations',
    icon: AccountTree,
    path: '/content/constellations/edit/:roadmapId',
    Component: RoadmapEditor,
    allowedRoleViews: [roleViews.ADMIN, roleViews.INSTRUCTOR],
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const ConstellationsPreviewRoute = {
    name: 'Constellations',
    icon: AccountTree,
    path: '/content/constellations/preview/:roadmapId',
    Component: RoadmapPreview,
    allowedRoleViews: [roleViews.ADMIN, roleViews.INSTRUCTOR],
};

export const ConstellationsEditRedirectRoute = {
    name: 'Constellations',
    icon: AccountTree,
    path: '/content/constellations/edit',
    Component: () => <Navigate to="/content/constellations" replace />,
    allowedRoleViews: [roleViews.ADMIN, roleViews.INSTRUCTOR],
};

export const ConstellationsTabRoute = {
    name: 'Constellations',
    icon: AccountTree,
    path: '/content/constellations/:section',
    Component: ConstellationsPage,
    allowedRoleViews: [roleViews.ADMIN, roleViews.INSTRUCTOR],
};

export const contentAdminRoute = {
    name: 'Content Management',
    icon: LibraryBooksIcon,
    path: '/admin/content/:section',
    defaultPath: '/admin/content/all',
    Component: AdminAllContent,
    admin: true,
};

export const contentAdmin = {
    name: 'Content Management',
    icon: LibraryBooksIcon,
    path: '/admin/content',
    Component: AdminAllContent,
    admin: true,
};

// export const contentAdminRoute = {
//     name: 'Content Management',
//     icon: LibraryBooksIcon,
//     path: '/admin/content/all',
//     Component: AdminAllContent,
//     admin: true,
// };

// Messaging suite

// export const msgSuiteTemplateList = {
//     name: 'Message Library',
//     icon: EmailIcon,
//     path: '/admin/message-suite',
//     Component: MsgSuiteTempList,
//     admin: true,
// };

export const createMessageHomePageRoute = {
    name: 'Create',
    icon: LibraryAddIcon,
    path: '/admin/create/message/',
    Component: MessageCreationPage,
    admin: true,
};

export const createEmailTemplateRoute = {
    name: 'Create Template',
    icon: LibraryAddIcon,
    path: '/admin/create/message/template',
    Component: CreateNewEmailTempl,
    admin: true,
};

export const createNotificationsRoute = {
    name: 'Create Notification',
    icon: LibraryAddIcon,
    path: '/admin/create/message/notification',
    Component: CreateNotification,
    admin: true,
};

export const createMessageAccessDenied = {
    name: 'Access Denied',
    icon: LibraryAddIcon,
    path: '/admin/create/message/access-denied',
    Component: AdminAccessRestricted,
    admin: true,
};

export const adminProgramManagementRoute = {
    name: 'Connect with us',
    icon: HelpIcon,
    path: '/admin/program-management',
    Component: AdminProgramManagement,
    admin: true,
};

export const adminProgramManagementSectionRoute = {
    name: 'Connect with us',
    icon: HelpIcon,
    path: '/admin/program-management/:section',
    Component: AdminProgramManagement,
    admin: true,
};

export const adminAddUserRoute = {
    name: 'Admin',
    icon: FaceIcon,
    path: '/admin/add/user/',
    Component: AddUserPage,
    admin: true,
};

// Admin reports
export const adminComapreUserReportsRoute = {
    name: 'Admin User Reports',
    icon: AccountCircleIcon,
    path: '/admin/reports/compare/',
    Component: ReportingCompare,
    admin: true,
};

export const adminReportingRoute = {
    name: 'Activity',
    icon: TrendingUpIcon,
    path: '/admin/reports/',
    Component: ClientReports,
    admin: true,
};

export const adminReportingSectionRoute = {
    name: 'Reports',
    icon: QuestionAnswerIcon,
    path: '/admin/reports/:section',
    Component: ClientReports,
    admin: true,
};

export const adminMessagingRoute = {
    name: 'Message Library',
    icon: EmailIcon,
    path: '/admin/messaging/',
    Component: AdminNotification,
    admin: true,
};

export const adminMessagingSectionRoute = {
    name: 'Message Library',
    icon: EmailIcon,
    path: '/admin/messaging/:section',
    Component: AdminNotification,
    admin: true,
};

export const adminProfilerReportingRoute = {
    name: 'Profiler',
    icon: QuestionAnswerIcon,
    path: '/admin/reports/profiler',
    Component: ClientReports,
    admin: true,
};

export const adminEngagementReportingRoute = {
    name: 'Engagement',
    icon: EqualizerIcon,
    path: '/admin/reports/engagement',
    Component: ClientReports,
    admin: true,
};

export const adminStardustReportsRoute = {
    name: 'Leaderboards',
    icon: PollIcon,
    path: '/admin/reports/leaderboards',
    Component: StardustReports,
    admin: true,
};

export const browseRoute = {
    name: 'Browse Categories',
    icon: '',
    path: '/browse',
    Component: BrowseCategoriesPage,
    isPublic: true,
};

export const browseSubSubCategoryResultRoute = {
    name: 'Browse Result',
    icon: '',
    path: '/browse/:category/:subCategory/:subSubCategory/*',
    Component: BrowseResultPage,
    isPublic: true,
};

export const browseSubCategoryResultRoute = {
    name: 'Browse Result',
    icon: '',
    path: '/browse/:category/:subCategory/*',
    Component: BrowseResultPage,
    isPublic: true,
};

export const browseCategoroyResultRoute = {
    name: 'Browse Result',
    icon: '',
    path: '/browse/:category/*',
    Component: BrowseResultPage,
    isPublic: true,
};

// -----------------------------------------------

// export const chatRoute = {
//     name: 'Chat',
//     icon: SchoolIcon,
//     path: '/chat',
//     Component: Chat,
//     //isPublic: true,
// };

export const certificationAllRoute = {
    name: 'Certifications',
    icon: SchoolIcon,
    path: '/certifications/:categoryName/:contentTab',
    defaultPath: '/certifications/all/c',
    // Component: CertificationPage,
    Component: () => <Navigate to="/certifications" />,
    isPublic: true,
};

// export const certificationAllRoute = {
//     name: 'Certifications',
//     icon: SchoolIcon,
//     path: '/certifications/all/c',
//     Component: CertificationPage,
//     isPublic: true,
// };

export const oldCertificationsRoute = {
    name: 'Old Certifications',
    icon: SchoolIcon,
    path: '/certifications',
    // Component: CertificationPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const certificationsDomainRoute = {
    name: 'Certifications Domain',
    icon: DirectionsWalkIcon,
    path: [
        '/certifications/data-science',
        '/certifications/cloud',
        '/certifications/full-stack',
        '/certifications/cybersecurity',
        '/certifications/agility',
        '/certifications/devops',
        '/certifications/networking',
        '/certifications/ui-ux',
        '/certifications/finance',
        '/certifications/business',
        '/certifications/coming-soon',
    ],
    // Component: CertificationsPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const certificationsDetailRoute = {
    name: 'Certifications Detail',
    path: '/certifications/:slug',
    // Component: CertificationsDetailPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};
// ----------------------------------------------

export const categoryAllCoursesRoute = {
    name: 'Courses',
    icon: LaptopChromebookIcon,
    path: '/category/:categoryName/:contentTab',
    defaultPath: '/category/all/courses',
    // Component: CategoryPage,
    Component: () => <Navigate to="/courses" replace />,
    isPublic: true,
};

// export const categoryAllCoursesRoute = {
//     name: 'Courses',
//     icon: LaptopChromebookIcon,
//     path: '/category/all/courses',
//     Component: CategoryPage,
//     isPublic: true,
// };

export const challengesRoute = {
    name: 'Challenges',
    icon: ListAltIcon,
    path: 'https://github.com/starweaver-dev/react-code-challenge',
    isExternalLink: true,
    // path: '/challenges',
    // releaseDate: 'Coming Soon',
    // Component: HowItWorks,
};

export const communityRoute = {
    name: 'Community',
    icon: SupervisedUserCircleIcon,
    path: 'https://support.starweaver.com/portal/en/community/recent',
    isExternalLink: true,
    isNewTab: true,
};

export const teachingCamp = {
    // name: 'Teaching Camp',
    name: 'FAQs',
    icon: SupervisedUserCircleIcon,
    path: 'https://support.starweaver.com/portal/en/community/recent',
    isExternalLink: true,
    isNewTab: true,
};

export const constellationsRoute = {
    name: 'Constellations',
    icon: FlareIcon,
    path: 'https://forums.starweaver.com/category/2/constellations',
    releaseDate: 'Coming Soon',
    isExternalLink: true,
};

export const couponRoute = {
    name: 'Coupons',
    icon: FaceIcon,
    path: '/udemy-coupon-codes',
    Component: CouponsPage,
    isPublic: true,
};

/* export const coursePaywallRoute = {
    name: 'Course Paywall',
    path: '/courses/:title',
    Component: CoursePaywallPage,
    isPublic: true,
    hideSideNav: true,
}; */

export const coursesFormEditRoute = {
    name: 'Manage Course',
    icon: LaptopChromebookIcon,
    path: '/manage/courses/form/:id',
    Component: CourseForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const coursesFormRoute = {
    name: 'Manage Course',
    icon: LaptopChromebookIcon,
    path: '/manage/courses/form',
    Component: CourseForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const coursesManageRoute = {
    name: 'Courses',
    icon: LaptopChromebookIcon,
    path: '/manage/courses',
    Component: CoursesManage,
    allowedRoleViews: [roleViews.ADMIN],
};

export const coursesManageSectionRoute = {
    name: 'Manage Course',
    icon: LaptopChromebookIcon,
    path: '/manage/courses/:section',
    Component: CoursesManage,
    allowedRoleViews: [roleViews.ADMIN],
};

export const coursesRoute = {
    name: 'Courses',
    icon: LaptopChromebookIcon,
    path: '/courses',
    // Component: CoursesPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const coursesDomainRoute = {
    name: 'Courses Domain',
    icon: LiveTvIcon,
    path: [
        '/courses/data-science',
        '/courses/cloud',
        '/courses/full-stack',
        '/courses/cybersecurity',
        //'/courses/agility',
        //'/courses/devops',
        '/courses/devops-agility',
        //'/courses/networking',
        //'/courses/ui-ux',
        '/courses/finance',
        '/courses/business',
        '/courses/private-classes',
    ],
    // Component: CoursesPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

/*export const coursesDetailRoute = {
    name: 'Courses Course Detail',
    //path: '/courses/:productId',
    path: '/courses/:courseSlug',
    Component: CoursesDetailPage,
    isPublic: true,
};*/

export const coursesDetailRoute = {
    name: 'Courses Course Detail',
    //path: '/courses/:productId',
    path: '/courses/:courseSlug',
    // Component: NewcoursesDetailsPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const clientRequirementsCreateFromRoute = {
    name: 'Create',
    icon: AccountCircleIcon,
    path: '/admin/client-requirements/create/on-air',
    Component: ClientRequirementsCreateForm,
    admin: true,
};

export const clientRequirementsCreateFromRouteLongForm = {
    name: 'Create',
    icon: AccountCircleIcon,
    path: '/admin/client-requirements/create/long-form',
    Component: ClientRequirementsCreateFormLongForm,
    admin: true,
};

export const clientRequirementsCreateFromRouteOnDemand = {
    name: 'Create',
    icon: AccountCircleIcon,
    path: '/admin/client-requirements/create/on-demand',
    Component: ClientRequirementsCreateFormOnDemand,
    admin: true,
};

export const clientRequirementsCreateFromRouteGuidedProjects = {
    name: 'Create',
    icon: AccountCircleIcon,
    path: '/admin/client-requirements/create/guided-projects',
    Component: ClientRequirementsCreateFormGuidedProjects,
    admin: true,
};

export const dashboardRoute = {
    name: 'Dashboard',
    // icon: DashboardIcon,
    icon: HomeIcon,
    Component: DashboardPage,
    path: '/profile/dashboard',
};

export const dashboardSectionRoute = {
    name: 'Dashboard Section',
    icon: DashboardIcon,
    Component: DashboardPage,
    path: '/profile/dashboard/:section',
};

export const dashboardSubSectionRoute = {
    name: 'Dashboard Sub Section',
    icon: DashboardIcon,
    Component: DashboardPage,
    path: '/profile/dashboard/:section/:subSection',
};

export const datasetsRoute = {
    name: 'Datasets',
    icon: AssessmentIcon,
    Component: HowItWorks,
    releaseDate: 'Coming Soon',
    path: '/datasets',
};

export const eventsRoute = {
    name: 'Events',
    icon: EventIcon,
    path: 'https://events.starweaver.com',
    isExternalLink: true,
    isNewTab: true,
};

export const faqsRoute = {
    name: 'Support & FAQs',
    icon: HelpIcon,
    path: 'https://support.starweaver.com/portal/en/home',
    isExternalLink: true,
    isNewTab: true,
};

export const firstTimeUserRoute = {
    name: 'First Time Users',
    icon: AccessibilityNewIcon,
    path: '/first-time',
    Component: FirstTimeUserPage,
};

// export const forBusiness = {
//     name: 'Starweaver for Business',
//     icon: BusinessIcon,
//     path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial`,
//     isExternalLink: true,
// };

export const forOrganisation = {
    name: 'For Organisation',
    icon: FlightTakeoffIcon,
    path: '/organisation',
    Component: ForOrganisation,
    isPublic: true,
    hideSideNav: true,
};

export const globalAccessPage = {
    name: 'Global Access page',
    icon: null,
    path: '/globalaccesspage',
    Component: GlobalMain,
};

// export const meetTheGurusRoute = {
//     name: 'Meet the Gurus',
//     icon: PeopleIcon,
//     path: '/on-air/meet-the-gurus',
//     Component: OnAirPage,
//     //isExternalLink: true,
//     isPublic: true,
//     //hideSideNav: true,
// };

export const migrationSignUpRoute = {
    name: 'Migration',
    icon: '',
    path: '/migration',
    Component: MigrationSignUp,
    isPublic: true,
    hideSideNav: true,
};

export const howItWorksRoute = {
    name: 'How It Works',
    icon: BuildIcon,
    path: '/how-it-works',
    Component: HowItWorksPage,
    isPublic: true,
};

export const communityTab = {
    name: 'Community',
    icon: ForumIcon,
    path:
        'https://support.starweaver.com/portal/en/community/ask-questions-answer-others-get-support',
    isExternalLink: true,
};

// export const instructorApplicationSubmissionsRoute = {
//     name: 'Instructor Application Submissions',
//     icon: FaceIcon,
//     path: '/admin/instructors/submissions',
//     Component: InstructorSubmissions,
// };

// export const instructorPageRoute = {
//     name: 'Instructor Page',
//     path: '/instructors/:slug',
//     Component: InstructorPage,
//     isPublic: true,
// };

export const chatRoute = {
    name: 'Chart',
    icon: ContactsIcon,
    path: '/messaging',
    Component: Chat,
    exact: true,
    // isPublic: true,
    hideFooter: true,
    // hideSideNav: true,
    allowedRoleViews: [
        roleViews.STUDENT,
        roleViews.ADMIN,
        roleViews.COLLABORATOR,
        roleViews.INSTRUCTOR,
    ],
};

// new channel page
export const channelRoute = {
    name: 'Channels',
    icon: ContactsIcon,
    path: '/channels',
    // Component: ChannelLandingPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    exact: true,
    isPublic: true,
};

// export const adminChannelRoute = {
//     name: 'Channels',
//     icon: ContactsIcon,
//     path: '/admin/channels',
//     Component: AdminChannelLandingPage,
//     exact: true,
//     isPublic: false,
//     admin: true,
// };

export const channelsDomainRouter = {
    name: 'Channels roles Domain',
    icon: FaceIcon,
    path: [
        '/channels/data-science',
        '/channels/cloud',
        '/channels/full-stack',
        '/channels/cybersecurity',
        '/channels/devops-agility',
        '/channels/business',
        '/channels/finance',
    ],
    // Component: ChannelLandingPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    exact: true,
    isPublic: true,
};

export const adminChannelsDomainRouter = {
    name: 'Channels roles Domain',
    icon: FaceIcon,
    path: [
        '/admin/channels/data-science',
        '/admin/channels/cloud',
        '/admin/channels/full-stack',
        '/admin/channels/cybersecurity',
        '/admin/channels/devops-agility',
        '/admin/channels/business',
        '/admin/channels/finance',
    ],
    // Component: AdminChannelLandingPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    exact: true,
    isPublic: false,
    admin: true,
};

export const instructorPageRoute = {
    name: 'Instructor Page',
    path: '/channels/:instructorSlug/:tab/',
    // Component: InstructorPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const adminInstructorPageRoute = {
    name: 'Instructor Page',
    path: '/admin/channels/:instructorName/:tab/',
    // Component: AdminInstructorPage,
    Component: () => <Navigate to="/channels" replace />,
    isPublic: false,
    admin: true,
};

export const instructorRoute = {
    name: 'Instructor Page',
    path: '/channels/:instructorSlug',
    // Component: InstructorPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const adminInstructorRoute = {
    name: 'Instructor Page',
    path: '/admin/channels/:instructorName',
    // Component: AdminInstructorPage,
    Component: () => <Navigate to="/channels" replace />,
    isPublic: false,
    admin: true,
};
//! old channel route commented out. Now /channel will redirect to NewChannelLandingPage
/*export const channgleRedirectRoute = {
    name: 'Channel Redirect',
    path: '/channels',
    Component: () => <Navigate to="/on-air"  replace />,
    isPublic: true,
};*/

export const instructorChannelConfigurationRoute = {
    name: 'Channel Configuration',
    icon: SettingsIcon,
    path: '/profile/instructor-dashboard/channel-configuration',
    Component: ChannelConfiguration,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorChannelConfigurationSectionRoute = {
    name: 'Channel Configuration',
    icon: SettingsIcon,
    path: '/profile/instructor-dashboard/channel-configuration/:section',
    Component: ChannelConfiguration,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorClientRequirementsCreateOnAirFormRoute = {
    name: 'Create',
    icon: AccountCircleIcon,
    path: '/profile/instructor-dashboard/network/clients/on-air',
    Component: () => <ClientRequirementsCreateForm isInstructor />,
    isInstructorRoute: true,
};

export const instructorDashboard = {
    name: 'Home',
    icon: HomeIcon,
    path: '/profile/instructor-dashboard',
    Component: InstructorHome,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardHome = {
    name: 'Home',
    icon: HomeIcon,
    path: '/profile/instructor-dashboard/home',
    Component: InstructorDashboard,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardProfile = {
    name: 'Profile',
    icon: AccountCircleIcon,
    path: '/profile/instructor-dashboard/profile',
    Component: InstructorDashboard,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardProfilePublic = {
    name: 'My Channel',
    icon: PublicIcon,
    path: '/profile/instructor-dashboard/public',
    Component: MyChannel,
    //hideSideNav: true,
    isInstructorRoute: true,
    isNewTab: true,
    isExternalLink: true,
};

export const instructorDashboardIntegrations = {
    name: 'Integrations',
    icon: AddBoxIcon,
    path: '/profile/instructor-dashboard/integrations',
    Component: IntegrationsPage,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardInformational = {
    name: 'Instructor Informational',
    icon: AccountCircleIcon,
    path: '/profile/instructor-dashboard/instructor-informational',
    Component: InstructorInformational,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardAdminAndFinance = {
    name: 'Admin & Finance',
    icon: SupervisorAccount,
    path: '/profile/instructor-dashboard/admin',
    Component: AdminAndFinance,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardAffliateProgram = {
    name: 'Affliate program link',
    icon: LinkIcon,
    path: '/profile/instructor-dashboard/affliate-program',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardMSA = {
    name: 'Master Services Agreement',
    icon: SubjectIcon,
    path: '/profile/instructor-dashboard/msa',
    Component: InstructorMSA,
    //hideSideNav: true,
    isInstructorRoute: true,
};

// export const instructorDashboardContent = {
//     name: 'Content',
//     icon: LocalMallIcon,
//     path: '/profile/instructor-dashboard/content',
//     Component: InstructorDashboard,
//     //hideSideNav: true,
//     isInstructorRoute: true,
// };

export const instructorDashboardStreamNow = {
    name: 'Stream Now',
    icon: SettingsInputAntennaRoundedIcon,
    path: '/profile/instructor-dashboard/stream',
    Component: InstructorStreamNow,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardNetwork = {
    name: 'Network',
    icon: NetworkCheckIcon,
    path: '/profile/instructor-dashboard/network',
    Component: InstructorDashboard,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardNetworkRoleManager = {
    name: 'Role Manager',
    icon: AccountBoxIcon,
    path: '/profile/instructor-dashboard/network/role-manager',
    Component: AccessRestricted,
    // Component: RoleManager,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardNetworkInvitations = {
    name: 'Invitations',
    icon: DraftsIcon,
    path: '/profile/instructor-dashboard/network/invitations',
    Component: Invitations,
    isInstructorRoute: true,
};

export const instructorDashboardNetworkClients = {
    name: 'Clients',
    icon: PeopleIcon,
    path: '/profile/instructor-dashboard/network/clients',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorOpportunityManagement = {
    name: 'Opportunity Management',
    icon: PeopleIcon,
    path: '/profile/instructor-dashboard/opportunity-management',
    Component: InstructorOpportunityManagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorOpportunityManagementFeedRoute = {
    name: 'Streams',
    icon: ViewStream,
    path: '/profile/instructor-dashboard/opportunity-management/streams',
    Component: () => (
        <Navigate
            to="/profile/instructor-dashboard/opportunity-management/streams/feeds"
            replace
        />
    ),
    isInstructorRoute: true,
    exact: true,
};

export const instructorOpportunityManagementFeedAndActivityRoute = {
    name: 'Events',
    icon: ViewStream,
    path:
        '/profile/instructor-dashboard/opportunity-management/streams/:section',
    Component: () => <FeedsAndActivity isInstructorView={true} />,
    isInstructorRoute: true,
    exact: true,
};

export const instructorOpportunityManagementDashboard = {
    name: 'Opportunity Management Dashboard',
    icon: PeopleIcon,
    path: [
        '/profile/instructor-dashboard/opportunity-management/details/:requirementId',
        '/profile/instructor-dashboard/opportunity-management/details/:requirementId/:section',
    ],
    Component: () => <RequirementDetails isInstructor={true} />,
    //hideSideNav: true,
    isInstructorRoute: true,
    exact: true,
};

export const instructorOpportunityManagementSection = {
    name: 'Opportunity Management',
    icon: PeopleIcon,
    path: '/profile/instructor-dashboard/opportunity-management/:section',
    Component: InstructorOpportunityManagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorOpportunityManagementInstructors = {
    name: 'Instructor Network',
    icon: PersonPinCircle,
    path: '/profile/instructor-dashboard/opportunity-management/instructors',
    Component: InstructorOpportunityManagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorOpportunityManagementRequirements = {
    name: 'Requirements',
    icon: NotListedLocationIcon,
    path: '/profile/instructor-dashboard/opportunity-management/requirements',
    Component: InstructorOpportunityManagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};
export const instructorOpportunityManagementProjectRoute = {
    name: 'Projects',
    icon: BrushIcon,
    path: '/profile/instructor-dashboard/opportunity-management/projects',
    Component: InstructorOpportunityManagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};
export const instructorDashboardNetworkFollowers = {
    name: 'Followers',
    icon: PeopleOutlineIcon,
    path: '/profile/instructor-dashboard/network/followers',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardMessaging = {
    name: 'Discussion',
    icon: ChatIcon,
    path: '/profile/instructor-dashboard/messaging',
    Component: InstructorDashboard,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardChatGroups = {
    name: 'Chat groups',
    icon: ChatIcon,
    path: '/profile/instructor-dashboard/chat-groups',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardAnnouncements = {
    name: 'Announcements',
    icon: VolumeUpIcon,
    path: '/profile/instructor-dashboard/announcements',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardReportsFeedback = {
    name: 'Feedback',
    icon: FeedbackIcon,
    path: '/profile/instructor-dashboard/reports/feedback',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardReportsStudents = {
    name: 'Students',
    icon: SentimentVerySatisfiedIcon,
    path: '/profile/instructor-dashboard/reports/students',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardReports = {
    name: 'Reports',
    icon: AssessmentIcon,
    path: '/profile/instructor-dashboard/reports/:section',
    Component: InstructorReports,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardReportsUsage = {
    name: 'Usage',
    icon: AssessmentIcon,
    path: '/profile/instructor-dashboard/reports/usage',
    Component: Reports,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardReportsEngagement = {
    name: 'Engagement',
    icon: EqualizerIcon,
    path: '/profile/instructor-dashboard/reports/engagement',
    Component: ReportsEngagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardPayouts = {
    name: 'Payouts',
    icon: AccountBalanceWalletIcon,
    path: '/profile/instructor-dashboard/channel-configuration/payouts',
    Component: InstructorDashboard,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorScreenTests = {
    name: 'Screen Tests',
    icon: VideocamIcon,
    path: '/profile/instructor-dashboard/screen-test',
    Component: ScreenTestPage,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardConnect = {
    // name: 'Connect with us',
    name: 'Connect',
    icon: HelpIcon,
    path: '/profile/instructor-dashboard/connect',
    Component: InstructorProgramManagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardConnectSection = {
    // name: 'Connect with us',
    name: 'Connect',
    icon: HelpIcon,
    path: '/profile/instructor-dashboard/connect/:section',
    Component: InstructorProgramManagement,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardProgramManager = {
    // name: 'Connect with us',
    name: 'Connect',
    icon: HelpIcon,
    path: '/profile/instructor-dashboard/program-manager',
    Component: InstructorDashboard,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorLearHow = {
    name: 'Learn How',
    icon: EmojiObjectsIcon,
    path: '/profile/instructor-dashboard/learn-how/tutorials',
    Component: InstructorLearnHow,
    isInstructorRoute: true,
};

export const instructorLearHowSection = {
    name: 'Learn How',
    icon: EmojiObjectsIcon,
    path: '/profile/instructor-dashboard/learn-how/:section',
    Component: InstructorLearnHow,
    isInstructorRoute: true,
};

// export const instructorDashboardContentProducer = {
//     name: 'Manage',
//     icon: LocalMallIcon,
//     path: '/profile/instructor-dashboard/content',
//     Component: InstructorContentProducer,
//     //hideSideNav: true,
//     isInstructorRoute: true,
// };

export const instructorDashboardContentCreateSelectType = {
    name: 'Create',
    icon: LibraryAddIcon,
    path: '/profile/instructor-dashboard/content/create/select-type',
    Component: InstructorContentCreateSelectType,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardContentCreateAccessRestricted = {
    name: 'Manage',
    icon: LocalMallIcon,
    path: '/profile/instructor-dashboard/content/create/access-denied',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardContentProducerForm = {
    name: 'Content Producer From',
    icon: AssignmentIndIcon,
    path: '/profile/instructor-dashboard/content/create/:type',
    Component: InstructorContentProducerForm,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const lmsManageCourses = {
    name: 'Content Producer From',
    icon: AssignmentIndIcon,
    path: '/lms/manage/courses/:type',
    Component: LMSManageCourses,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardContentCurate = {
    name: 'Curate',
    icon: FilterListIcon,
    path: '/profile/instructor-dashboard/content/curate',
    Component: AccessRestricted,
    //hideSideNav: true,
    isInstructorRoute: true,
};

// export const instructorDashboardContentPersonas = {
//     name: 'Personas',
//     icon: FaceIcon,
//     path: '/profile/instructor-dashboard/content/personas',
//     Component: InstructorSupportedPersonas,
//     //hideSideNav: true,
//     isInstructorRoute: true,
// };

export const instructorDashboardContentBlogs = {
    name: 'Blogs',
    icon: CodeIcon,
    path: '/profile/instructor-dashboard/content/blogs',
    Component: InstructorSupportedBlogs,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorCourseOutline = {
    name: 'Course Outlines',
    icon: FileCopyIcon,
    path: '/profile/instructor-dashboard/course-outlines',
    Component: InstructorCourseOutlines,
    //hideSideNav: true,
    isInstructorRoute: true,
};

export const instructorDashboardContentProducerImport = {
    name: 'Import',
    icon: GetAppIcon,
    path: '/profile/instructor-dashboard/content/import',
    Component: UdemyUserCourses,
    //hideSideNav: true,
    isInstructorRoute: true,
};

// export const instructorDashboardSection = {
//     name: 'Instructor Dashboard Section',
//     path: '/profile/instructor-dashboard/:section',
//     Component: InstructorDashboard,
//     //hideSideNav: true,
//     isInstructorRoute: true,
// };

export const instructorSignUp = {
    name: 'instructor Sign up',
    path: '/instructor-signup',
    Component: InstructorSignUp,
    isPublic: true,
    // hideSideNav: true,
};

export const instructorLogin = {
    name: 'instructor Login In',
    path: '/instructor-login',
    Component: InstructorLogin,
    isPublic: true,
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const instructorOnboarding = {
    name: 'Teach with Starweaver',
    icon: AssignmentIndIcon,
    path: '/profile/instructor-onboarding',
    // Component: OnboardingContainer,
    Component: () => <Navigate to="/instructor-onboarding" replace />,
    isPublic: true,
    hideSideNav: true,
};

export const instructorOnboardingNew = {
    name: 'Teach with Starweaver',
    icon: AssignmentIndIcon,
    path: '/instructor-onboarding',
    Component: OnboardingContainerNew,
    // isPublic: true,
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const instructorSubmissionMessage = {
    name: 'submissionMessage',
    icon: '',
    path: '/profile/submission-message',
    Component: SubmissionMessage,
};

/* export const instructorOnboardingSignUp = {
    name: 'InstructorOnboardingSignUp',
    icon: '',
    path: '/instructor-onboarding-sign-up',
    isPublic: true,
    Component: InstructorOnboardingSignUp,
}; */

export const learnPressRoute = {
    name: 'learnPress',
    icon: AccountCircleIcon,
    path: '/lp-courses/:name',
    Component: LearnPress,
    isPublic: true,
};

export const jobRolesRoute = {
    // name: 'Careers',
    name: 'Career Paths',
    icon: FaceIcon,
    path: '/job-roles',
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const jobRolesDomainRoute = {
    name: 'Job roles Domain',
    icon: FaceIcon,
    path: [
        '/job-roles/data-science',
        '/job-roles/cloud',
        '/job-roles/full-stack',
        '/job-roles/cybersecurity',
        '/job-roles/devops-agility',
        //'/job-roles/devops',
        '/job-roles/networking',
        '/job-roles/ui-ux',
        '/job-roles/finance',
        '/job-roles/business',
    ],
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const jobRoleInfoRoute = {
    name: 'Job role info',
    path: '/job-roles/:slug',
    // Component: JobRoleInfoPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const journeysFormEditRoute = {
    name: 'Manage Journeys',
    icon: DirectionsWalkIcon,
    path: '/manage/journeys/form/:id',
    Component: JourneyForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const journeysFormRoute = {
    name: 'Manage Journeys',
    icon: DirectionsWalkIcon,
    path: '/manage/journeys/form',
    Component: JourneyForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const journeysManageRoute = {
    name: 'Journeys/Certifications',
    icon: DirectionsWalkIcon,
    path: '/manage/journeys',
    Component: JourneysManage,
    allowedRoleViews: [roleViews.ADMIN],
};

export const journeysManageSectionRoute = {
    name: 'Manage Journeys',
    icon: DirectionsWalkIcon,
    path: '/manage/journeys/:section',
    Component: JourneysManage,
    allowedRoleViews: [roleViews.ADMIN],
};

export const journeysRoute = {
    name: 'Journeys',
    icon: DirectionsWalkIcon,
    path: '/journeys',
    // Component: JourneysPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const jobRoleFormEditRoute = {
    name: 'Manage Job Role',
    icon: FaceIcon,
    path: '/manage/job-roles/form/:id',
    Component: JobRoleForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const jobRoleFormRoute = {
    name: 'Manage Job Role',
    icon: FaceIcon,
    path: '/manage/job-roles/form',
    Component: JobRoleForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const jobRolesManageRoute = {
    name: 'Job Roles',
    icon: FaceIcon,
    path: '/manage/job-roles',
    Component: JobRolesManage,
    allowedRoleViews: [roleViews.ADMIN],
};

export const jobRolesManageSectionRoute = {
    name: 'Manage Job Roles',
    icon: FaceIcon,
    path: '/manage/job-roles/:section',
    Component: JobRolesManage,
    allowedRoleViews: [roleViews.ADMIN],
};

// export const customJourneyLandingPage = {
//     name: 'Custom Journeys',
//     icon: DirectionsWalkIcon,
//     path: '/journey/c/:slug',
//     Component: CustomJourneyPage,
//     isPublic: true,
// };

export const journeyAllLandingRoute = {
    name: 'Journeys',
    icon: DirectionsWalkIcon,
    path: '/journeys/:categoryName/:contentTab',
    defaultPath: '/journeys/all/j',
    // Component: JourneysLandingPage,
    Component: () => {
        return <Navigate to="/journeys" replace />;
    },
    isPublic: true,
};

export const journeyConstellationSharePreviewRoute = {
    name: 'Journeys',
    icon: DirectionsWalkIcon,
    path: '/journeys/constellations/share/preview/:roadmapId',
    Component: JourneyConstallationPreviewPage,
    isPublic: true,
};

export const GuidancePreviewRoute = {
    name: 'Guidance',
    icon: SupportAgentIcon,
    path: ['/preview/guidance/:id', '/preview/guidance/:id/:section'],
    Component: GuidancePreviewPage,
    isPublic: true,
};

// export const journeyAllLandingRoute = {
//     name: 'Journeys',
//     icon: DirectionsWalkIcon,
//     path: '/journeys/all/j',
//     Component: JourneysLandingPage,
//     isPublic: true,
// };

// export const journeyBuilderRoute = {
//     name: 'First Time Users',
//     icon: AccessibilityNewIcon,
//     path: '/journeys/all/journey-builder',
//     Component: JourneysLandingPage,
//     isPublic: true,
// };

export const journeysDomainRoute = {
    name: 'Journeys Domain',
    icon: DirectionsWalkIcon,
    path: [
        '/journeys/data-science',
        '/journeys/cloud',
        '/journeys/full-stack',
        '/journeys/cybersecurity',
        '/journeys/agility',
        '/journeys/devops',
        '/journeys/networking',
        '/journeys/ui-ux',
        '/journeys/finance',
        '/journeys/business',
        '/journeys/coming-soon',
    ],
    // Component: JourneysPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const journeysDetailRoute = {
    name: 'Journeys Detail',
    path: '/journeys/:slug',
    // Component: JourneysDetailPage,
    // Component: NewJourneyDetailsPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const labRoute = {
    name: 'Lab',
    path: '/labs/:slug',
    Component: Lab,
    isPublic: true,
};

export const liveLabsRoute = {
    name: 'Channels',
    icon: ContactsIcon,
    path: '/live-labs',
    Component: LiveLabsPage,
    exact: true,
    isPublic: true,
};

export const LinkedInLogin = {
    name: 'LinkedInLogin',
    icon: ContactsIcon,
    path: '/auth/linkedin/redirect',
    Component: LinkedinRedirect,
    exact: true,
    isPublic: true,
};

export const liveLabsDomainRoute = {
    name: 'LiveLabs Domain',
    icon: LiveTvIcon,
    path: [
        '/live-labs/data-science',
        '/live-labs/cloud',
        '/live-labs/full-stack',
        '/live-labs/cybersecurity',
        '/live-labs/devops-agility',
        //'/live-labs/agility',
        //'/live-labs/devops',
        //'/live-labs/networking',
        //'/live-labs/ui-ux',
        '/live-labs/business',
        '/live-labs/finance',
        '/live-labs/coming-soon',
    ],
    Component: LiveLabsPage,
    isPublic: true,
};

export const landingPageRoute =
    window.location.hostname === process.env.REACT_APP_JOURNEYBUILDER_HOSTNAME
        ? {
              name: 'Landing Page',
              path: '/',
              // path: '/landing',
              Component: JourneyBuilderPage,
              exact: true,
              isPublic: true,
              hideSideNav: true,
              hideNavBar: true,
              isJourneyBuilderPath: true,
          }
        : {
              name: 'Landing Page',
              path: '/',
              // path: '/landing',
              Component: LandingPage,
              exact: true,
              isPublic: true,
          };

export const liveLabsDetailRoute = {
    name: 'LiveLabs Detail',
    path: '/live-labs/:courseSlug',
    Component: LiveLabsDetailPage,
    isPublic: true,
};

export const loginRoute = {
    name: 'Login',
    path: '/login',
    Component: Login,
    isPublic: true,
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const logoutRoute = {
    name: 'Logout',
    path: '/logout',
    Component: Logout,
    isPublic: true,
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const moonshotsRoute = {
    name: 'MoonShots',
    icon: Brightness4Icon,
    path: '/moonshots',
    releaseDate: 'Coming Soon',
    Component: HowItWorks,
};

export const myStandingRoute = {
    name: 'My Standing',
    icon: InsertChartIcon,
    path: '/my-standing',
    Component: HowItWorks,
};

export const onAirRoute = {
    name: 'On Air',
    icon: LiveTvIcon,
    path: '/on-air',
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    exact: true,
    isPublic: true,
};

export const onAirDomainRoute = {
    name: 'On Air Domain',
    icon: LiveTvIcon,
    path: [
        '/on-air/data-science',
        '/on-air/meet-the-gurus',
        '/on-air/cloud',
        '/on-air/full-stack',
        '/on-air/cybersecurity',
        '/on-air/devops-agility',
        //'/on-air/agility',
        //'/on-air/devops',
        //'/on-air/networking',
        //'/on-air/ui-ux',
        '/on-air/business',
        '/on-air/finance',
    ],
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const onAirDetailRoute = {
    name: 'On Air Detail',
    path: '/on-air/:courseSlug',
    // Component: OnAirDetailPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

// export const onAirTest = {
//     name: 'On Air Test',
//     path: '/on-air/test/:courseSlug',
//     Component: MainSectionTest,
//     isPublic: true,
// };

export const onAirMTGDetailRoute = {
    name: 'On Air MTG',
    path: '/on-air/meet-the-gurus/:courseSlug',
    Component: MtgDetailPage,
    isPublic: true,
};

export const onAirManageFormRoute = {
    name: 'Manage On Air',
    icon: LiveTvIcon,
    path: '/manage/on-air/form/:id',
    Component: OnAirForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const onAirManageFormCreateRoute = {
    name: 'Manage On Air',
    icon: LiveTvIcon,
    path: '/manage/on-air/form',
    Component: OnAirForm,
    allowedRoleViews: [roleViews.ADMIN],
};

export const onAirManageRoute = {
    name: 'On Air',
    icon: LiveTvIcon,
    path: '/manage/on-air',
    Component: OnAirManage,
    allowedRoleViews: [roleViews.ADMIN],
};

export const onAirMangeSectionRoute = {
    name: 'Manage On Air',
    icon: LiveTvIcon,
    path: '/manage/on-air/:section',
    Component: OnAirManage,
    allowedRoleViews: [roleViews.ADMIN],
};

// export const onboardingRoute = {
//     name: 'Onboarding Flow',
//     icon: FlightTakeoffIcon,
//     path: '/onboarding',
//     Component: OnboardingPage,
// };

export const collaboratorRoute = {
    // name: 'collaborator',
    name: 'Home',
    icon: FaceIcon,
    path: '/collaborator',
    Component: CollaboratorHome,
    role: COLLABORATOR,
};

export const collaboratorOpportunityManagementRoute = {
    name: 'Requirements',
    icon: NotListedLocationIcon,
    path: '/collaborator/opportunity-management',
    Component: () => (
        <OpportunityManagement
            basePath="/collaborator/opportunity-management"
            selectedRole={COLLABORATOR}
        />
    ),
    role: COLLABORATOR,
};

export const collaboratorOpportunityManagementAnalyticsRoute = {
    name: 'Insights',
    icon: BarChart,
    path: '/collaborator/opportunity-management/insights',
    Component: () => <OverallAnalytics selectedRole={COLLABORATOR} />,
    role: COLLABORATOR,
    exact: true,
};

export const collaboratorOpportunityManagementFeedRoute = {
    name: 'Streams',
    icon: ViewStream,
    path: '/collaborator/opportunity-management/streams',
    Component: () => (
        <Navigate
            to="/collaborator/opportunity-management/streams/feeds"
            replace
        />
    ),
    role: COLLABORATOR,
    exact: true,
};

export const collaboratorOpportunityManagementFeedAndActivityRoute = {
    name: 'Events',
    icon: ViewStream,
    path: '/collaborator/opportunity-management/streams/:section',
    Component: () => <FeedsAndActivity selectedRole={COLLABORATOR} />,
    role: COLLABORATOR,
    exact: true,
};

export const collaboratorOpportunityManagementSectionsRoute = {
    name: 'Requirements',
    icon: NotListedLocationIcon,
    path: '/collaborator/opportunity-management/:section',
    Component: () => (
        <OpportunityManagement
            basePath="/collaborator/opportunity-management"
            selectedRole={COLLABORATOR}
        />
    ),
    role: COLLABORATOR,
};

export const collaboratorOpportunityManagementDetailsRoute = {
    name: 'Requirement',
    icon: WhatshotIcon,
    path: [
        '/collaborator/opportunity-management/details/:requirementId',
        '/collaborator/opportunity-management/details/:requirementId/:section',
    ],
    Component: () => <RequirementDetails selectedRole={COLLABORATOR} />,
    exact: true,
    role: COLLABORATOR,
};

export const instructorNotificatonsRoute = {
    name: 'Notifications',
    icon: NotificationsActiveRounded,
    path: '/profile/instructor-dashboard/notifications',
    Component: AdminNotificationPage,
    isInstructorRoute: true,
};

export const plansRoute = {
    name: 'Plans Pricing',
    icon: PaymentIcon,
    path: '/pricing',
    isPublic: true,
    Component: AllPlans,
};

export const comparePlansRoute = {
    name: 'Compare Plans',
    icon: PaymentIcon,
    path: '/pricing/compare',
    isPublic: true,
    // new page
    Component: ComparePlans,
};

export const comparePlanTeamsRoute = {
    name: 'Compare Plan Teams',
    icon: PaymentIcon,
    path: '/pricing/teams/compare',
    isPublic: true,
    // new page
    Component: ComparePlansTeam,
};

export const podcastRoute = {
    name: 'Podcasts',
    icon: MicIcon,
    // path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/meet-the-gurus-podcast`,
    path: '/podcasts',
    // isExternalLink: true,
    // isNewTab: true,
    isPublic: true,
    Component: Podcasts,
};

export const productCheckoutRoute = {
    name: 'Checkout',
    icon: PaymentIcon,
    path: '/checkout',
    isPublic: true,
    Component: ProductCheckout,
};

export const creditsCheckoutRoute = {
    name: 'Credits Checkout',
    icon: PaymentIcon,
    isPublic: true,
    path: '/credits/checkout',
    Component: ProductCheckoutCredits,
};

export const profileRoute = {
    name: 'My Profile',
    icon: AccountCircleIcon,
    path: '/profile',
    Component: MainApp,
};

export const profileBillingRoute = {
    name: 'Account Billing',
    path: '/profile/billing',
    Component: Billing,
};

export const profileDataPrivacyRoute = {
    name: 'Data Privacy',
    path: '/profile/userdata',
    Component: DataPrivacy,
};

export const profileNotificationsRoute = {
    name: 'Account Billing',
    path: '/profile/notifications',
    Component: Notifications,
};

export const profilePersonalInfoRoute = {
    name: 'Personal Info',
    path: '/profile/information',
    Component: PersonalInfo,
};

export const profileSecurityRoute = {
    name: 'Account Billing',
    path: '/profile/security',
    Component: SignInInfo,
};

export const resetPasswordRoute = {
    name: 'Reset Password',
    path: '/reset-password',
    Component: ResetPassword,
    isPublic: true,
    hideSideNav: true,
};

export const setPasswordRoute = {
    name: 'Set Password',
    path: '/set-password',
    Component: SetPassword,
    isPublic: true,
    hideSideNav: true,
    hideFooter: true,
    hideNavBar: true,
};

export const leaderboardRoute = {
    name: 'Leaderboards',
    path: '/profile/dashboard/leaderboard',
    Component: Leaderboard,
    icon: PollIcon,
};

export const retroCoursesRoute = {
    name: 'Retro Learning',
    icon: ShopTwoIcon,
    path: '/browse/retro-learning',
    Component: RetroCoursePage,
    isPublic: true,
};

export const retroCourseRoute = {
    name: 'Retro Course',
    icon: TrendingUpIcon,
    path: '/retro-course/:name',
    Component: RetroCoursePage,
    isPublic: true,
};

export const myTrainingRoute = {
    name: 'My Training',
    path: '/profile/training',
    Component: MyTraining,
};

export const myTrainingSectionRoute = {
    name: 'My Training Section',
    path: '/profile/training/:section',
    Component: MyTraining,
};

export const searchRoute = {
    name: 'search field',
    icon: '',
    path: '/search',
    Component: SearchResultPage,
    isPublic: true,
};

// export const starLabsDetailsPage = {
//     name: 'Star Labs Details Page',
//     icon: '',
//     path: '/star-labs:courseId',
//     Component: StarLabsMain,
// };

export const sandboxesRoute = {
    name: 'Sandboxes',
    icon: InboxIcon,
    path: 'https://codesandbox.io/s/react-code-challenge-47ee0',
    isExternalLink: true,
    // path: '/sandboxes',
    // releaseDate: 'Coming Soon',
    // Component: HowItWorks,
};

export const signupRoute = {
    name: 'Sign up',
    path: '/signup',
    Component: SignUp,
    isPublic: true,
    // hideSideNav: true,
};

export const companySingupRoute = {
    name: 'Company sign up',
    path: '/signup/:id/c',
    Component: CompanySignUpPage,
    isPublic: true,
    // hideSideNav: true,
};

export const starSystemsRoute = {
    name: 'Star Systems',
    icon: StarBorderIcon,
    path: 'https://forums.starweaver.com/category/1/star-systems',
    releaseDate: 'Coming Soon',
    isExternalLink: true,
};

export const studentOnbaordingRoute = {
    name: 'Student Onboarding',
    path: '/student-onboarding',
    Component: StudentOnboardingPage,
    isPublic: true,
};

export const ssoRoute = {
    name: 'Starweaver SSO',
    path: '/sso',
    Component: SSOPage,
    isPublic: true,
    isNewTab: true,
    isExternalLink: true,
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const ssoAnalyticsRoute = {
    name: 'Starweaver Analytics',
    path: '/sso-analytics',
    Component: SSOAnalytics,
    isNewTab: true,
    isExternalLink: true,
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const testPrepsRoute = {
    name: 'Test Prep',
    icon: NoteIcon,
    path: '/test-prep',
    // Component: TestPrepsPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const testPrepAllLandingRoute = {
    name: 'Test Prep',
    icon: NoteIcon,
    path: '/test-prep/:categoryName/:contentTab',
    defaultPath: '/test-prep/all/tests',
    // Component: TestPrepCategoryPage,
    Component: () => <Navigate to="/test-prep" replace />,
    isPublic: true,
};

// export const testPrepAllLandingRoute = {
//     name: 'Test Prep',
//     icon: NoteIcon,
//     path: '/test-prep/all/tests',
//     Component: TestPrepCategoryPage,
//     isPublic: true,
// };

export const testPrepDomainRoute = {
    name: 'Test Prep Domain',
    icon: DirectionsWalkIcon,
    path: [
        '/test-prep/data-science',
        '/test-prep/cloud',
        '/test-prep/full-stack',
        '/test-prep/cybersecurity',
        '/test-prep/agility',
        '/test-prep/devops',
        '/test-prep/networking',
        '/test-prep/ui-ux',
        '/test-prep/finance',
        '/test-prep/business',
    ],
    // Component: TestPrepsPage,
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const checkoutSuccessRoute = {
    name: 'Checkout Success',
    path: '/checkout/success',
    Component: CheckoutSuccess,
    isPublic: true,
};

export const offerPageRoute = {
    name: 'Offer page',
    path: '/sale/:sale_name/',
    Component: OfferPage,
    isPublic: true,
};

export const checkoutCancledRoute = {
    name: 'Checkout Cacncled',
    path: '/checkout/canceled',
    Component: CheckoutCancled,
};

// export const teamsTestRoute = {
//     name: 'Teams test',
//     path: '/teams-test',
//     Component: CallWithChatExperience,
//     icon: SchoolIcon,
//     isPublic: true,
// };

export const techbytesRoute = {
    name: 'Techbytes',
    path: '/techbytes-online-technology-blog',
    icon: CodeIcon,

    // Component: TechBytes,
    Component: () => <Navigate to="/techbytes" replace />,
    isPublic: true,
};

export const techbytesCategoryRoute = {
    name: 'Techbytes',
    path: '/techbytes-online-technology-blog/category/:slug',
    icon: CodeIcon,
    // Component: TechBytes,
    Component: () => <Navigate to="/techbytes" replace />,
    isPublic: true,
};

export const techbytesRedirectRoute = {
    name: 'Techbytes',
    path: '/techbytes',
    icon: CodeIcon,
    // Component: () => (
    //     <Navigate to="/techbytes-online-technology-blog" replace />
    // ),
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const techbytesArticleEditRoute = {
    name: 'Techbytes Article',
    path: '/techbytes/form/:id',
    Component: TechByteEditPage,
    // isPublic: true,
    isInstructorRoute: true,
};

export const techbytesRedirectArticleRoute = {
    name: 'Techbytes Article',
    path: '/techbytes/:slug',
    Component: () => {
        const domain = process.env.REACT_APP_DO_DOMAIN;
        return <SwitchDomain domain={domain} />;
    },
    isPublic: true,
};

export const techbytesArticleRoute = {
    name: 'Techbytes Article',
    path: '/techbytes-online-technology-blog/:slug',
    // Component: TechbytesArticlePage,
    Component: () => <Navigate to="/techbytes" replace />,
    isPublic: true,
};

export const techbytesOldRoute = {
    // name: 'TechBytes',
    // icon: CodeIcon,
    // path: '/techbytes',
    // Component: TechBytes,
    // isPublic: true,
    // hideSideNav: true,
    name: 'Techbytes',
    icon: CodeIcon,
    path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/techbytes-online-technology-blog`,
    isExternalLink: true,
    isPublic: true,
};

export const trainingRoute = {
    name: 'Training',
    icon: TrendingUpIcon,
    path: '/training',
    Component: HowItWorks,
};

export const couponPopupInstSignup = {
    name: 'Access Coupon Signup',
    icon: TrendingUpIcon,
    path: '/couponsignup',
    Component: CouponSignUp,
    isExternalLink: true,
    isPublic: true,
};

export const verifyMobileRoute = {
    name: 'Verify Mobile',
    icon: TrendingUpIcon,
    path: '/verify-mobile',
    Component: MobileOtpVerificationPage,
    hideSideNav: true,
    hideNavBar: true,
    hideFooter: true,
    // isPublic: true,
};

export const universityLandingPageRoute = {
    name: 'Starweaver for Campus',
    path: '/for-campus/students',
    Component: UniversityStudentLandingPage,
    icon: SchoolIcon,
    exact: true,
    isPublic: true,
};

// export const voiceCallRoute = {
//     name: 'Voice call',
//     path: '/azure-teams-call-test',
//     Component: VoiceCall,
//     icon: SchoolIcon,
//     isPublic: true,
// };

export const zoomOauthRedirectRoute = {
    name: 'Zoom Oauth redirect',
    icon: AccountCircleIcon,
    path: '/zoom-redirect',
    Component: ZoomOauthRedirectPage,
    isInstructorRoute: true,
};

export const policyRoute = {
    name: 'Policies',
    icon: '',
    path: '/policies',
    Component: PoliciesPage,
    isPublic: true,
};

export const supportRoute = {
    name: 'Support',
    icon: '',
    path: '/support',
    Component: SupportPage,
    isPublic: true,
};

export const booksRoute = {
    name: 'Books and Publications',
    icon: '',
    path: '/books-and-publications',
    Component: BooksPage,
    isPublic: true,
};

export const enterpriseRoute = {
    name: 'Starweaver for Business',
    icon: BusinessIcon,
    path: '/free-enterprise-trial',
    Component: EnterprisePage,
    isPublic: true,
};

export const envRoute = {
    name: 'envRoute',
    icon: '',
    path: '/test/environment',
    Component: ServerInfo,
    isPublic: true,
    hideFooter: true,
    hideNavBar: true,
    hideSideNav: true,
};

export const ClientsPageRoute = {
    name: 'Clients',
    icon: AccountCircleIcon,
    path: '/clients',
    Component: ClientsPage,
    admin: true,
};

export const ClientsPageActiveRoute = {
    name: 'Clients',
    icon: AccountCircleIcon,
    path: '/clients/active',
    Component: ClientsPage,
    admin: true,
};

export const ClientsTabPageRoute = {
    name: 'Clients',
    icon: AccountCircleIcon,
    path: '/clients/:tabName',
    Component: ClientsPage,
    admin: true,
};

export const ClientActiveDetailsRoute = {
    name: 'Clients',
    icon: AccountCircleIcon,
    path: [
        '/clients/:tabName/:id',
        '/clients/:tabName/:id/:section',
        '/clients/:tabName/:id/:section/:status',
    ],
    Component: ClientDetailsPage,
    admin: true,
};

/* export const notfound = {
    name: 'Not Found',
    icon: TrendingUpIcon,
    path: '',
    Component: NotFound,
    hideSideNav: true,
    isPublic: true,
}; */
