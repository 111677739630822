import { Grid, Typography } from '@mui/material';

export const SingleOfferPage = ({
    description = [],
    terms = '',
    classes = {},
    condition = '',
}) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
                {description.map((paragraph) => (
                    <Typography
                        className={classes.description}
                        variant="h4"
                        gutterBottom
                    >
                        {paragraph}
                    </Typography>
                ))}
            </Grid>
            <Grid item xs={12} sm={5}>
                <Typography gutterBottom variant="subtitle2">
                    {terms}
                </Typography>
                <Typography component={'p'} variant="subtitle2">
                    {condition}
                </Typography>
            </Grid>
        </Grid>
    );
};
