import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    videoContainer: {
        width: '80vw',
        height: '100%',
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
}));

export const VideoPlayer = ({
    blobURL,
    title = 'starweaver',
    type = 'video/mp4',
}) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.videoContainer}>
            <video className={classes.video} title={title} controls>
                <source src={blobURL} type={type ? type : 'video/mp4'} />
                Your browser does not support the video tag
            </video>
        </Box>
    );
};
