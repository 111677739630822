import React, { useMemo } from 'react';
import { IconButton, LinearProgress, Box, Typography } from '@mui/material'; // Import LinearProgress and other necessary components
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from 'tss-react/mui';
import { AzureBlobStorage } from '../util/BlobStorage';

const useStyles = makeStyles()((theme) => {
    return {
        fileItem: {
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
            fontSize: '0.75rem',
        },
        fileItem__left: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
        },
        fileItem__info: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.6rem',
            flexWrap: 'wrap',
        },
        fileItem__name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '20ch',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '22ch',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '50',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '50',
            },
        },
        fileItem__right: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            marginLeft: 'auto',
        },
        fileItem__icon: {
            padding: '0.1rem 0.3rem',
            borderRadius: '0.25rem',
            border: `1px solid ${theme.palette.grey['300']}`,
            color: theme.palette.primary.light,
            fontSize: '0.75rem',
            fontWeight: '500',
            letterSpacing: '0.05rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fileItem__progress: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        filePreview: {
            width: 60,
            height: 60,
            objectFit: 'cover',
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(1),
        },
    };
});

function FileItem({
    id,
    file,
    index,
    handleRemoveSelectedFile,
    isUploaded,
    isUploading,
    percentageUploaded,
}) {
    const { classes } = useStyles();

    const mimeIconName = useMemo(() => {
        const fileName = file.name;
        return fileName.split('.').pop().toUpperCase();
    }, [file.name]);

    const fileSize = useMemo(() => {
        const size = file.size;
        if (size < 1000) {
            return `${size}B`;
        } else if (size > 1000 && size < 1000 * 1000) {
            return `${Math.floor(size / 1000)}KB`;
        } else {
            return `${Math.floor(size / 1000000)}MB`;
        }
    }, [file.size]);

    const isImage = file.type.startsWith('image/');

    return (
        <li key={file.name} className={classes.fileItem}>
            <span className={classes.fileItem__left}>
                <span className={classes.fileItem__num}>{index + 1}.</span>
                <span className={classes.fileItem__info}>
                    {isImage ? (
                        <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className={classes.filePreview}
                        />
                    ) : (
                        <span className={classes.fileItem__icon}>
                            {mimeIconName}
                        </span>
                    )}
                    <span className={classes.fileItem__name}>{file.name}</span>
                </span>
            </span>
            <span className={classes.fileItem__right}>
                <span>{fileSize}</span>
                <IconButton
                    size="small"
                    color="error"
                    onClick={(e) => handleRemoveSelectedFile(e, id)} // Pass the file ID instead of the index
                    aria-label="remove"
                >
                    <DeleteIcon />
                </IconButton>
            </span>
            {isUploading && (
                <Box className={classes.fileItem__progress}>
                    <LinearProgress
                        variant="determinate"
                        value={percentageUploaded}
                    />
                </Box>
            )}
            {/* {isUploaded && (
                <Typography variant="caption" color="primary">
                    Uploaded
                </Typography>
            )} */}
        </li>
    );
}

export default FileItem;
