import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles()((theme) => {
    return {
        gridContainer: {
            '& .MuiDataGrid-columnHeaders': {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                zIndex: 4,
                top: 75,
            },
            '& .MuiDataGrid-virtualScroller': {
                marginTop: '0 !important',
            },
            '& .MuiDataGrid-main': {
                overflow: 'visible',
            },
        },
    };
});

function WithStickyHeader({ children, ...props }) {
    const { classes } = useStyles();
    return (
        <Box className={classes.gridContainer} {...props}>
            {children}
        </Box>
    );
}

export default WithStickyHeader;
