import { styled } from '@mui/system';

const GridDiv = styled('div')(({ theme }) => {
    return {
        [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: 'calc(98% / 2) calc(98% / 2)',
            justifyContent: 'space-between',
        },
    };
});

// This component will divide the content into two cols on larger devices
function GridContainer({ children }) {
    return <GridDiv>{children}</GridDiv>;
}

export default GridContainer;
