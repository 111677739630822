const getBaseURL = (pathname) => {
    let baseURL = '';

    if (window.location.hostname === 'localhost') {
        baseURL = `http://localhost:3000${pathname}`;
    } else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        baseURL = `${process.env.REACT_APP_STAGE_DOMAIN}${pathname}`;
    } else {
        baseURL = `${process.env.REACT_APP_DOMAIN}${pathname}`;
    }

    return baseURL;
};

export { getBaseURL };
