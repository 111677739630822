import { Grid } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { IconButton, InputBase, Paper } from '../../ui';
import { SearchIcon } from '../../icons';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import HomeIcon from '@mui/icons-material/Home';

const CouponSearch = ({ onClick }) => {
    const [inputTxt, setInputTxt] = useState('');

    const searchCoupon = () => {
        if (!!inputTxt.length) {
            onClick(inputTxt);
        } else {
            onClick('');
        }
    };
    const goHome = () => {
        setInputTxt('');
        onClick('');
    };

    const onSearchBoxChange = (e) => {
        setInputTxt(e.target.value);
    };
    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            onSearchBoxChange();
        }
    };

    return (
        <Paper
            variant="outlined"
            alignItems="center"
            alignContent="center"
            style={{
                width: '50%',
                borderRadius: '7px',
                marginBottom: '20px',
                padding: '0px 8px',
            }}
        >
            <Grid
                container
                direction="row"
                xs={12}
                alignItems="center"
                alignContent="center"
            >
                <Grid
                    container
                    xs={1}
                    alignItems="center"
                    alignContent="center"
                >
                    <IconButton
                        aria-label="search"
                        style={{
                            width: '100%',
                        }}
                        onClick={goHome}
                        size="large">
                        <HomeIcon />
                    </IconButton>
                </Grid>

                <Grid
                    container
                    xs={10}
                    alignItems="center"
                    alignContent="center"
                >
                    <InputBase
                        fullWidth
                        onChange={onSearchBoxChange}
                        value={inputTxt}
                        placeholder="Search"
                        style={{ paddingLeft: '5px' }}
                    />
                </Grid>
                <Grid
                    container
                    xs={1}
                    alignItems="center"
                    alignContent="center"
                >
                    <IconButton
                        aria-label="search"
                        style={{
                            width: '100%',
                        }}
                        onClick={searchCoupon}
                        size="large">
                        <SearchIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CouponSearch;
