import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import axios from 'axios';

export const useEnrolledPrivateOnDemand = (user) => {
    const [loading, setLoading] = useState(false);

    const [privateOnDemand, setPrivateOnDemand] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (user) {
                if (
                    !user.user_private_course_ids ||
                    user.user_private_course_ids.length === 0
                ) {
                    setPrivateOnDemand([]);
                } else {
                    const enrolledCourseIds = user.user_private_course_ids;
                    const res = await axios.get(
                        `/api/content/private-courses?courseIds=${enrolledCourseIds.join(
                            ','
                        )}`
                    );

                    let userCourses = res.data;

                    // const resExtrasWordPress = await axios.get(
                    //     `${
                    //         process.env.REACT_APP_SW_WORDPRESS_DOMAIN
                    //     }/wp-json/headless/catalogue/dashboard?nowprocket=1&id=${userCourses
                    //         .map(({ productable_id }) => productable_id)
                    //         .join()}`
                    // );

                    const resExtrasWordPress = await axios.get(
                        `/api/courses?thinkificCourseIds=${userCourses
                            .map(({ productable_id }) => productable_id)
                            .join()}`
                    );

                    const extrasWP = new Map(
                        resExtrasWordPress.data?.courses?.map((c) => [
                            parseInt(c.thinkificCourseId),
                            c,
                        ])
                    );

                    //instructor, instructorSlug, hours, minutes
                    userCourses = userCourses.map((course) => ({
                        ...course,
                        ...extrasWP.get(course.productable_id),
                    }));

                    // console.log(
                    //     `/api/enrollments/thinkific-user?thinkificUserId=${
                    //         user.user_thinkific_id || 0
                    //     }&thinkificCourseIds=${userCourses
                    //         .map(({ productable_id }) => productable_id)
                    //         .join()}`
                    // );
                    const resExtrasThinkific = await axios.get(
                        `/api/enrollments/thinkific-user?thinkificUserId=${
                            user.user_thinkific_id || 0
                        }&thinkificCourseIds=${userCourses
                            .map(({ productable_id }) => productable_id)
                            .join()}`
                    );

                    //console.log(resExtrasThinkific.data);
                    const extrasThink = new Map(
                        resExtrasThinkific.data?.map((c) => [
                            parseInt(c.course_id),
                            c,
                        ])
                    );

                    //console.log(extrasThink);

                    //percentage_completed, updated_at
                    userCourses = userCourses.map((course) => ({
                        ...course,
                        ...extrasThink.get(course.productable_id),
                    }));

                    //console.log(userCourses);

                    setPrivateOnDemand(userCourses);
                }
            }

            setLoading(false);
        })();
    }, [/* privateOnDemand, setPrivateOnDemand,  */ user]);

    return { privateOnDemand, loading };
};
