import { useSetRecoilState, useRecoilValue } from 'recoil';
import { setOnAirValues, getOnAir } from './Recoil/Selector';
import { useCurrentUser } from '../my-account/useCurrentUser';
import axios from 'axios';
import { useEffect } from 'react';

export const useGetSavedRsvps = (user) => {
    const userOnAir = useRecoilValue(getOnAir);
    const setUserOnAir = useSetRecoilState(setOnAirValues);

    useEffect(() => {
        const getCourse = async () => {
            if (userOnAir === null) {
                try {
                    const result = await axios.get(
                        `/api/users/${user._id}/saved-rsvp`
                    );
                    setUserOnAir(result.data.rsvpDetails);
                } catch (e) {
                    console.log(e);
                }
            }
        };
        if (user) {
            getCourse();
        }
    }, [user]);

    return userOnAir;
};
