import { atom } from 'recoil';

export const roleViews = {
    STUDENT: 'student',
    ADMIN: 'admin',
    INSTRUCTOR: 'instructor',
    COLLABORATOR: 'collaborator',
};

export const roleViewState = atom({
    key: 'roleViewState',
    default: {
        selectedView: '',
    },
});
