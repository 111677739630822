import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Checkbox,
    FormControlLabel,
} from '../';
import { useState } from 'react';

export const UnEnrollDialog = ({
    onClose,
    handleRemove,
    open,
    course,
    title = '',
}) => {
    const [check, setCheck] = useState(false);

    return (
        <Dialog disableScrollLock open={open} onClose={onClose}>
            <DialogTitle>{'Do you really want to un-enroll?'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your current progress for
                    <strong>
                        &nbsp;{title ? title : course.name}&nbsp;
                    </strong>{' '}
                    is saved and you can continue watching it from where you
                    left.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={check}
                            onChange={() => setCheck(!check)}
                        />
                    }
                    label="Agree to continue"
                />
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Close
                </Button>
                <Button
                    onClick={async () => {
                        await handleRemove(course);
                        onClose();
                    }}
                    color="secondary"
                    variant="contained"
                    autoFocus
                    disabled={!check}
                >
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    );
};
