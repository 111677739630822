import { Box, CircularProgress, IconButton, TextField } from '../ui';
import { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { CheckIcon } from '../icons';
import { useProductTracker } from './useProductTracker';
import { isCreditCardRequiredState } from './recoil';
import { useSetRecoilState } from 'recoil';

export const EnterPromoCode = ({
    setPromoCodeResponse,
    customerId,
    priceId,
    checkCode,
    reset,
    defaultCoupon,
    user,
    paramsEmail,
    setReset = () => {},
}) => {
    const setIsCreditCardRequired = useSetRecoilState(
        isCreditCardRequiredState
    );
    const [checking, setChecking] = useState();
    const [promoCode, setPromoCode] = useState(defaultCoupon);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { classes } = useStyles();
    const { couponTried } = useProductTracker();

    const checkPromoCode = async (passedCode, defaultEmail = '') => {
        setChecking(true);
        setError('');
        setSuccess('');
        try {
            // make a function in parent component that return the response it should be async
            // here the price id we are using is stripe price id
            const data = await checkCode({
                customerId,
                stripePriceId: priceId,
                promoCode: promoCode ? promoCode : passedCode,
                defaultEmail: defaultEmail,
            });
            if (data.applicable) {
                setPromoCodeResponse({ ...data, name: promoCode });
                setSuccess(data.message);
                setIsCreditCardRequired(data.ccRequirement);
                if (promoCode || passedCode) {
                    couponTried({
                        value: promoCode ? promoCode : passedCode,
                        slug: 'coupon-success',
                        type: 'CLICK',
                    });
                }
            } else {
                if (promoCode || passedCode) {
                    couponTried({
                        value: promoCode ? promoCode : passedCode,
                        slug: 'coupon-failed',
                        type: 'CLICK',
                    });
                }
                console.log('change code error');
                setIsCreditCardRequired(true);
                setPromoCodeResponse(null);
                setError(data.message);
            }
        } catch (error) {
            console.log(error);
        }
        setChecking(false);
    };

    useEffect(() => {
        if (reset) {
            if (promoCode) {
                checkPromoCode(promoCode);
            } else {
                setPromoCodeResponse(null);
                setError('');
                setSuccess('');
            }
            setReset(false);
        }
        //eslint-disable-next-line
    }, [reset]);

    useEffect(() => {
        if (defaultCoupon) {
            setPromoCode(defaultCoupon);
            checkPromoCode(defaultCoupon, paramsEmail);
        }
        // eslint-disable-next-line
    }, [defaultCoupon, paramsEmail, user]);

    return (
        <Box
            style={{
                height: 95,
            }}
        >
            <Box paddingY={1} className={classes.couponBox}>
                <TextField
                    label="PROMO CODE"
                    value={promoCode}
                    color="secondary"
                    onChange={(e) => {
                        setPromoCode(e.target.value.toUpperCase());
                        setError('');
                        setSuccess('');
                    }}
                    variant="outlined"
                    disabled={checking}
                    style={{
                        marginRight: 20,
                    }}
                    helperText={
                        success || error
                            ? success
                                ? `${
                                      promoCode === 'VALENTINE2022'
                                          ? 'Coupon Applied'
                                          : success
                                  }`
                                : `${error}!`
                            : ''
                    }
                    FormHelperTextProps={{
                        classes: {
                            root: success
                                ? classes.successText
                                : classes.errorText,
                        },
                    }}
                />
                <IconButton
                    // disableElevation
                    color="secondary"
                    onClick={checkPromoCode}
                    disabled={checking}
                    size="large"
                >
                    {checking ? (
                        <CircularProgress size="1em" color="secondary" />
                    ) : (
                        <CheckIcon />
                    )}
                </IconButton>
            </Box>
        </Box>
    );
};
