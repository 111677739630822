import FindInPageIcon from '@mui/icons-material/FindInPage';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PageviewIcon from '@mui/icons-material/Pageview';
import SubjectIcon from '@mui/icons-material/Subject';
import BarChartIcon from '@mui/icons-material/BarChart';

export const analyticsContentExplorerRoute = {
    name: 'Content Explorer',
    path: '/sso-analytics?successPath=/content',
    icon: FindInPageIcon,
    isExternalLink: true,
    isNewTab: true,
};

export const analyticsContentTrackerRoute = {
    name: 'Content Tracker',
    path: '/sso-analytics?successPath=/content-tracker',
    icon: EditLocationIcon,
    isExternalLink: true,
    isNewTab: true,
};

export const analyticsKeywordExplorerRoute = {
    name: 'Keyword Explorer',
    path: '/sso-analytics?successPath=/keyword-explorer',
    icon: PageviewIcon,
    isExternalLink: true,
    isNewTab: true,
};

export const analyticsTopicAnalyzerRoute = {
    name: 'Topic Analyzer',
    path: '/sso-analytics?successPath=/topic-analyzer',
    icon: SubjectIcon,
    isExternalLink: true,
    isNewTab: true,
};

export const analyticsCompetitionExplorerRoute = {
    name: 'Competition Explorer',
    path: '/sso-analytics?successPath=/competition-explorer',
    icon: BarChartIcon,
    isExternalLink: true,
    isNewTab: true,
};
