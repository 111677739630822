import { createSlice } from '@reduxjs/toolkit';

export const curriculumSlice = createSlice({
    initialState: {
        addChapter: false,
        chapterTitle: 'Untitled Chapter',
        chapterDesc: '',
        chapters: [],
        chapterToEdit: null,
        isAddChItem: false,
        isEditChapter: false,
        showLessonTemplates: false,
        addLessonFor: null,
        isLoading: false,
        currCourseId: null,
    },
    name: 'curriculumBuilder',
    reducers: {
        setAddChapter: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                addChapter: payload.addChapter,
            };
        },
        setChapterTitle: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                chapterTitle: payload.chapterTitle,
            };
        },
        setChapterDesc: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                chapterDesc: payload.chapterDesc,
            };
        },
        setChapters: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                chapters: payload.chapters,
            };
        },
        setChapterToEdit: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                chapterToEdit: payload?.chapterToEdit ?? null,
            };
        },
        setIsAddChItem: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                isAddChItem: payload.isAddChItem,
            };
        },
        setIsEditChapter: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                isEditChapter: payload.isEditChapter,
            };
        },
        setShowLessonTemplates: (state, action) => {
            const { payload } = action;
            const { showLessonTemplates } = payload;
            return {
                ...state,
                addChapter: false,
                isEditChapter: false,
                showLessonTemplates: showLessonTemplates,
            };
        },
        setAddLessonFor: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                addLessonFor: payload?.chapterId ?? null,
            };
        },
        setLoading: (state, action) => {
            const { payload } = action;
            const { isLoading } = payload;
            
            return {
                ...state,
                isLoading,
            };
        },
        updateCurrCourseId: (state, action) => {
            const { payload } = action;
            const { courseId } = payload;

            return {
                ...state,
                currCourseId: courseId,
            };
        },
    },
});

export const {
    setAddChapter,
    setChapterDesc,
    setChapterTitle,
    setChapterToEdit,
    setChapters,
    setIsAddChItem,
    setIsEditChapter,
    setShowLessonTemplates,
    setAddLessonFor,
    setLoading,
    updateCurrCourseId,
} = curriculumSlice.actions;

export default curriculumSlice.reducer;
