import { useState, useRef, useCallback } from 'react';
import { Box, Button, Grid, Icon, Link as MuiLink, Typography } from '../ui';
import { FiberManualRecordIcon } from '../icons';
import { useStyles } from './styles';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export const InstructorContent = ({
    showLinks = true,
    showAchievementsInfo = false,
}) => {
    const { classes } = useStyles();

    return (
        <Box mt={5}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Typography
                        className={`${classes.sectionHeading} ${classes.colorBlue}`}
                        variant="h3"
                        gutterBottom
                        style={{
                            marginTop: 0,
                            fontWeight: 500,
                        }}
                    >
                        Get live education​ from top instructors
                    </Typography>
                    <Box pl={3} my={4}>
                        {bullets.map((content, index) => (
                            <Box
                                display="flex"
                                key={index}
                                alignItems="flex-start"
                                my={1}
                            >
                                <Box pt="4px" mr={1}>
                                    <Icon
                                        style={{
                                            fontSize: '0.8em',
                                        }}
                                    >
                                        <FiberManualRecordIcon
                                            style={{
                                                fontSize: '0.8em',
                                            }}
                                        />
                                    </Icon>
                                </Box>
                                <Typography
                                    className={classes.informationBullets}
                                    variant="subtitle2"
                                >
                                    {content}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    {showLinks && (
                        <>
                            <Box mt={2}>
                                <Typography variant="h6">
                                    Choose the&nbsp;
                                    <MuiLink color="secondary" href="/pricing">
                                        subscription plan
                                    </MuiLink>
                                    &nbsp;that suits your needs.​
                                </Typography>
                            </Box>
                            <Box mt={4}>
                                <Button
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                    href="/signup"
                                >
                                    START ABSOLUTELY FREE​
                                </Button>
                            </Box>
                        </>
                    )}
                    {showAchievementsInfo && (
                        <>
                            <Typography
                                //className={classes.h4Heading}
                                variant="h4"
                                gutterBottom
                                style={{
                                    marginTop: 0,
                                }}
                            >
                                Earn and share your achievements
                            </Typography>
                            <Box pl={3} my={4}>
                                {bullets2.map((content) => (
                                    <Box
                                        display="flex"
                                        alignItems="flex-start"
                                        my={1}
                                    >
                                        <Box pt="4px" mr={1}>
                                            <Icon
                                                style={{
                                                    fontSize: '0.8em',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    style={{
                                                        fontSize: '0.8em',
                                                    }}
                                                />
                                            </Icon>
                                        </Box>
                                        <Typography
                                            className={
                                                classes.informationBullets
                                            }
                                            variant="subtitle2"
                                        >
                                            {content}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box
                        width="100"
                        height="100%"
                        className={classes.videoContainer}
                    >
                        <LazyLoadComponent threshold={500}>
                            <iframe
                                src="https://player.vimeo.com/video/644584789?h=d85c3385ab&title=0&byline=0&portrait=0&autoplay=1&loop=1"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                                width="100%"
                                height="100%"
                                title="Learning by connecting"
                            ></iframe>
                        </LazyLoadComponent>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const bullets = [
    'Attend only the live training you need​',
    'Just-in-time personalized learning ​',
    'Real-world problem-solving​',
];

const bullets2 = [
    'Gain transfer credits from qualifying institutions',
    'Share your certificate of completion online',
    'Earn professional development units (continuing education credits) and more​',
];
