import axios from 'axios';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { setAllOnAirState, getAllOnAirState } from './Recoil/Selector';
import { useEffect, useState } from 'react';

export const useGetAllOnAir = () => {
    const [loading, setLoading] = useState(true);
    const allOnAir = useRecoilValue(getAllOnAirState);
    const setAllOnAir = useSetRecoilState(setAllOnAirState);

    // useEffect(() => {
    //     const getData = async () => {
    //         if (allOnAir === null) {
    //             try {
    //                 const { data } = await axios.get(
    //                     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/on-air?nowprocket=1`
    //                 );
    //                 setAllOnAir(data);
    //             } catch (error) {
    //                 console.log(error);
    //                 setAllOnAir(null);
    //             }
    //         }
    //         setLoading(false);
    //     };
    //     getData();
    // }, []);

    return { allOnAir, isLoading: loading };
};
