import { useEffect, useState } from 'react';
import axios from 'axios';

export const useInstructorProfile = (userDetails) => {
    // console.log(userDetails);
    const [isLoading, setIsLoading] = useState(true);
    const [tagLine, setTagline] = useState(
        userDetails?.instructor_details?.tag_line || ''
    );
    const [intro, setIntro] = useState(
        userDetails?.instructor_details?.intro || ''
    );
    const [name, setName] = useState(
        userDetails?.user_firstName + ' ' + userDetails?.user_lastName || ''
    );
    const [profilePic, setProfilePic] = useState(
        userDetails?.instructor_details?.channelProfilePic || null
    );
    const [bannerPic, setbannerPic] = useState(null);
    const [id, setId] = useState(userDetails?.instructorPostId || null);
    const [relatedArticles, setRelatedArticles] = useState([]);
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        if (!userDetails /* || !userDetails.is_instructor */) return;
        (async () => {
            try {
                // setIsLoading(true);
                // const res = await axios.get(
                //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/instructor/${userDetails.user_email}?status=any&nowprocket=1`
                // );
                // setId(res.data.id);
                // setTagline(res.data.tagLine);
                // setIntro(res.data.intro);
                // setProfilePic(res.data.profilePic);
                // setbannerPic(res.data.bannerPic);
                // setName(res.data.name);
                // setRelatedArticles(res.data.relatedArticles);
                // setIsLoading(false);

                setId(userDetails.instructorPostId);
                setTagline(userDetails.instructor_details.tag_line);
                setIntro(userDetails.instructor_details.intro);
                setDomains(userDetails.domains || []);
                setProfilePic(userDetails.instructor_details.channelProfilePic);
                setbannerPic(userDetails.instructor_details.channelBannerPic);
                setName(
                    userDetails.user_firstName + ' ' + userDetails.user_lastName
                );
                setRelatedArticles([]);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [userDetails]);

    return {
        userDetails,

        setIsLoading,
        id,
        name,
        tagLine,
        setTagline,
        intro,
        setIntro,
        domains,
        setDomains,
        profilePic,
        bannerPic,
        setProfilePic,
        relatedArticles,
        setRelatedArticles,
    };
};
