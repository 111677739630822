import withStyles from '@mui/styles/withStyles';
import { LinearProgress } from '@mui/material';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 12,
        borderRadius: 2,
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 2,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

export const LinearBar = (props) => {
    return <BorderLinearProgress {...props} />;
};
