import {
    features,
    pros,
    training,
    headingThree,
    Heading,
    Features,
} from '../forOrganisation';
import { Box, Divider, Link as MuiLink, Typography, Container } from '../ui';
import { Learning } from './Learning';
import { TopComments } from './TopComments';
import { makeStyles } from 'tss-react/mui';

export const SignUpContent = ({
    showLearning = true,
    showTestimony = true,
    showFeatures = true,
    showDomainFeatures = true,
}) => {
    const { classes } = useStyles();

    return (
        <Box mt={5} className={classes.mainContainer}>
            {showLearning && (
                <Box className={classes.fullWidthBox}>
                    <Container maxWidth="lg">
                        <Learning />
                    </Container>
                </Box>
            )}
            {/* <Box className={classes.fullWidthBoxOffWhite}>
                <Container maxWidth="lg">
                    <Doing />
                </Container>
            </Box> */}
            <Container maxWidth="lg">
                {showTestimony && (
                    <>
                        <Box my={5}>
                            <Typography className={classes.heading}>
                                What students are saying​
                            </Typography>
                            <TopComments />
                        </Box>
                        <Divider />
                    </>
                )}
                {showDomainFeatures && (
                    <>
                        <Heading
                            title={training.title}
                            subtitle={training.subtitle}
                            body={training.body}
                        />
                        <Divider />
                        <Features cards={features} />
                        <Divider />
                    </>
                )}
                {showFeatures && (
                    <>
                        <Heading
                            title={headingThree.title}
                            subtitle={headingThree.subtitle}
                            body={headingThree.body}
                        />
                        <Features cards={pros} />
                        <Divider />
                    </>
                )}
            </Container>
            <Box mt={5} className={classes.bottomDesc}>
                <MuiLink
                    style={{
                        textDecoration: 'none',
                    }}
                    href="/signup"
                >
                    <Typography variant="h4" className={classes.bottomTitle}>
                        GET STARTED TODAY
                    </Typography>
                </MuiLink>
                <Typography
                    variant="subtitle2"
                    className={classes.bottomSubTitle}
                >
                    IMMEDIATE ONBOARDING | NO CONTRACT | NO SET-UP COSTS | JUST
                    AWESOME WAY TO TRAIN YOUR TEAMS
                </Typography>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    mainContainer: {
        position: 'relative',
        paddingBottom: '200px',
    },
    heading: {
        width: 'fit-content',
        fontWeight: 700,
        margin: '10px auto 30px',
        fontSize: 'min(1.5em, 6vw)',
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            left: 0,
            bottom: '-3px',
            height: '3px',
            borderRadius: '5px',
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
        },
    },
    bottomDesc: {
        position: 'absolute',
        bottom: '-50px',
        width: '100%',
        background:
            'linear-gradient(90deg, rgba(35,78,100,1) 0%, rgba(45,67,85,1) 100%)',
        textAlign: 'center',
        padding: '35px 20px',
        color: '#eaeaea',
    },
    bottomTitle: {
        fontWeight: 900,
        marginBottom: 20,
        color: "#fff"
    },
    bottomSubTitle: {
        color: '#eae30d',
    },
    fullWidthBox: {
        margin: '50px 0',
        background:
            'linear-gradient(225deg,rgba(25,108,183,1)  0%, rgba(15,50,126,1) 100%)',
        position: 'relative',
        color: '#c5c5c5',
    },
    fullWidthBoxOffWhite: {
        marginTop: '100px',
        // paddingTop:'50px',
        backgroundColor: '#dadada',
        position: 'relative',
        color: '#333',
        [theme.breakpoints.down('md')]: {
            marginTop: '0px',
            paddingTop: '0px',
        },
    },
}));

export default SignUpContent;
