import React from 'react';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SearchIcon } from '../../icons';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
    searchBtn: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    searchBtnMobile: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    searchBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        width: '100%',
    },
}));

function PageSearchBox({
    searchValue,
    setSearchValue,
    handleKeyPress,
    handleSearch,
    handleClear,
    placeholder,
    disabled = false,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const isDesktopDevice = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box width={'100%'} className={classes.searchBox}>
            <TextField
                variant="outlined"
                color="secondary"
                name="searchValue"
                id="searchValue"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder={placeholder}
                disabled={disabled}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            size={isDesktopDevice ? 'medium' : 'small'}
                        >
                            <SearchIcon
                                fontSize={isDesktopDevice ? 'default' : 'small'}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="contained"
                                onClick={handleSearch}
                                disabled={searchValue.length === 0 || disabled}
                                className={classes.searchBtn}
                            >
                                Search
                            </Button>
                            &nbsp; &nbsp;
                            <IconButton
                                onClick={handleClear}
                                size={isDesktopDevice ? 'medium' : 'small'}
                                disabled={disabled}
                            >
                                <Close
                                    fontSize={
                                        isDesktopDevice ? 'default' : 'small'
                                    }
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                size={isDesktopDevice ? 'medium' : 'small'}
            />
            <Button
                variant="contained"
                onClick={handleSearch}
                disabled={searchValue.length === 0}
                className={classes.searchBtnMobile}
                size="small"
            >
                Search
            </Button>
        </Box>
    );
}

export default PageSearchBox;
