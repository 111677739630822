import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';

export const useCurrentLiveClasses = (isSmall = false) => {
    const [liveClasses, setLiveClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const allRandomCourses = useMemo(() => {
        if (liveClasses.length) {
            // const allRandomData = randomizeData(
            //     liveClasses,
            //     liveClasses.length
            // );
            const allRandomData = liveClasses;

            const totalClasses = allRandomData.length;
            const groupedClasses = [];
            let currentStart = 0;

            const numberOfGroups = isSmall ? 12 : 3;
            for (let i = 0; i < numberOfGroups; i++) {
                if (totalClasses > currentStart) {
                    const end = isSmall ? 1 : 4;
                    groupedClasses.push(
                        allRandomData.slice(currentStart, currentStart + end)
                    );
                    currentStart += end;
                }
            }
            return groupedClasses;
        } else {
            return [];
        }
    }, [liveClasses, isSmall]);

    useEffect(() => {
        (async () => {
            try {
                // if (!liveClasses.length) {
                setIsLoading(true);
                // const { data } = await axios.get(
                //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/on-air/landing-page?nowprocket=1`
                // );

                const { data } = await axios.get(`/api/on-air`);
                setLiveClasses(data);
                // }
            } catch (err) {
                console.log('Error in fetching live classes');
                setLiveClasses([]);
            }
            setIsLoading(false);
        })();
    }, []);

    return { groupedCourses: allRandomCourses, isLoading };
};
