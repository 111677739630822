import { Avatar, Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles()((theme) => {
    return {
        users__chip: {
            backgroundColor: 'rgba(0,0,0,0.08)',
            borderRadius: '100vmax',
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            paddingBlock: '1px',
            paddingLeft: '6px',
            gap: '4px',
            transition: 'all 150ms ease-in-out',
            marginBlock: '0.5%',
            flexShrink: 0,
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.125)',
            },
        },
        user__chipText: {
            fontSize: '0.8rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.95rem',
            },
        },
        users__removeIcon: {
            color: 'rgba(0,0,0,0.2)',
        },
    };
});

function Chip({
    _id,
    title,
    imgURL,
    handleCancel = () => console.log('handle cancel'),
}) {
    const { classes } = useStyles();
    return (
        <Box className={classes.users__chip}>
            <Box className={classes.users__avatar}>
                {imgURL?.length > 0 ? (
                    <Avatar
                        alt="test"
                        sx={{
                            width: 24,
                            height: 24,
                            fontSize: '13px',
                        }}
                        src={imgURL}
                    />
                ) : (
                    <Avatar
                        alt={title}
                        sx={{
                            width: 24,
                            height: 24,
                            fontSize: '13px',
                        }}
                    >
                        {title[0].toUpperCase()}
                    </Avatar>
                )}
            </Box>

            <Typography variant="body2" className={classes.user__chipText}>
                {title}
            </Typography>

            <IconButton size="small" onClick={() => handleCancel(_id)}>
                <CancelIcon
                    fontSize="small"
                    className={classes.users__removeIcon}
                />
            </IconButton>
        </Box>
    );
}

export { Chip };
