import { SvgIcon } from '@mui/material';
export const ClapsIcons = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.9 21.47a6.09 6.09 0 0 1-1.72-6.63l2.13-5.93a.765.765 0 0 1 1.38-.13l.31.55c.24.39.29.87.14 1.3l-.98 2.74l.43.38L15.55 7c.35-.4.95-.44 1.36-.09c.39.35.43.96.09 1.35l-4.45 5.03l.58.51l5.45-6.18c.35-.4.96-.44 1.35-.09c.4.35.44.97.07 1.36l-5.44 6.18l.58.51l4.69-5.32c.35-.4.96-.44 1.35-.09s.44.96.09 1.33l-4.69 5.34l.57.51l3.17-3.59c.35-.4.96-.44 1.36-.09s.43.96.08 1.33l-5.2 5.92a6.13 6.13 0 0 1-8.66.55m3.69-12.25L14.43 6c.24-.27.54-.5.87-.63l.38-.78a.947.947 0 0 0-.44-1.28a.964.964 0 0 0-1.28.44l-2.51 5.14c.05.11.11.22.14.33M11 8v.05l2.78-5.67a.965.965 0 0 0-.45-1.28a.951.951 0 0 0-1.28.44L9.41 6.95c.65.11 1.22.48 1.59 1.05m-6.23 6.33L6.9 8.4c.27-.75.9-1.26 1.65-1.43l2.14-4.39a.961.961 0 0 0-.44-1.28a.952.952 0 0 0-1.28.44L5 9.84l-.5-.25l.21-2.91c.04-.45-.14-.9-.46-1.22L3.79 5c-.47-.43-1.24-.14-1.29.5L2 11.79c-.13 2.04.77 3.99 2.35 5.21c-.02-.88.11-1.79.42-2.67z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
