import { Avatar, Badge, CircularProgress, Button, Tooltip } from '..';
import { EditIcon } from '../../icons';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    edit: {
        minWidth: '50px',
        borderRadius: '50%',
        height: '50px',
    },
}));

export const EditButton = ({ tooltip, onClick, input, isLoading }) => {
    const { classes } = useStyles();

    return (
        <Tooltip title={tooltip && !isLoading ? tooltip : ''}>
            <Button
                variant="contained"
                color="secondary"
                component="label"
                className={classes.edit}
                onClick={onClick ? onClick : () => {}}
                disabled={isLoading}
            >
                {isLoading ? (
                    <CircularProgress size="1.9em" />
                ) : (
                    <>
                        <EditIcon />
                        {input}
                    </>
                )}
            </Button>
        </Tooltip>
    );
};
