/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { f } from '../index';

import Main from '../Main';

const reducer = (state, action) => {
    let newState = { ...state };
    const { type, payload } = action;
    switch (type) {
        case 'rootBtn':
            const { btn } = payload;
            const newArray = f.toggleArray(payload, newState);
            newState[btn] = newArray;
            newState.apiString = ''; // this step ensures a useEffect in 'CouponLogic.js'. This means apiString has been cleared, need a make new, based on new state buttons
            break;
        case 'refetch-coupons':
            newState.apiString = payload;
            break;
        default:
            break;
    }
    return newState;
};

const initialState = {
    Ratings: [],
    Discount: [],
    Categories: [],
    'Sub Categories': [],
    // | 'Topics'
    Level: [],
    apiString: '',
};
export const State = React.createContext(null);
export const Dispatcher = React.createContext(null);

const CouponsPage = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    // const api = f.useCreateApi(state).apiString;

    // const { data, isLoading, error, refetch } = useQuery('getData', apiCall);

    // return (
    //     <State.Provider value={{ ...state }}>
    //         <Dispatcher.Provider value={dispatch}>
    //             <Main />
    //         </Dispatcher.Provider>
    //     </State.Provider>
    // );

    return <Main />;
};

const UseState = () => {
    const ctx = React.useContext(State);
    return ctx;
};
const UseDispatch = () => {
    const ctx = React.useContext(Dispatcher);
    return ctx;
};

export { CouponsPage, UseDispatch, UseState };
