import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@mui/material';

export const FilterAccordionRadio = ({
    title = '',
    value = '',
    handleChange = () => {},
    filterValue = '',
    filterLabels = [],
}) => {
    return (
        <>
            <Accordion expanded elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label={title}
                            name={title}
                            value={value}
                            onChange={handleChange}
                        >
                            {filterValue.map((filter, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={filter}
                                    control={<Radio />}
                                    label={filterLabels[index]}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
