import { useState, useEffect, Suspense } from 'react';
import { io } from 'socket.io-client';
//import { useSetRecoilState } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';
// import { useDispatch } from 'react-redux';
// import { setSocket } from './socketSlice';
// import { useRecoilState } from 'recoil';
// import { socketState } from './recoil';

import { DialogOfferPopup } from '../ui';
import axios from 'axios';
// import useZohoSalesIQChat from '../zoho/useZohoSalesIQChat';
//import { useUser } from '../auth/useUser';
import { coursesSlice } from '../courses';
import { AllRoutes } from '../navigation';
//import { searchOptionsSlice } from '../search-box';
import { Box, CircularProgress } from '../ui';
// import ScrollToTop from './ScrollToTop';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { userTrack } from '../util/tracking';
import SnackbarAlert from './SnackbarAlert';
// import { urlBase64ToUint8Array } from '../util/urlBase64ToUint8Array.js';
import { subscribeUser } from '../util/subscribeUser.js';
// import { unsubscribeUser } from '../util/unsubscribeUser.js';

import '../ckeditor/plugins.js';
import { useLoginLayer2 } from '../auth/useLoginLayer2.js';

axios.defaults.baseURL = process.env.REACT_APP_NODE_DOMAIN || '';
// process.env.REACT_APP_ENVIRONMENT !== 'development'
//     ? process.env.REACT_APP_NODE_DOMAIN
//     : '';

// const socket = io();

axios.interceptors.request.use(async (config) => {
    try {
        if (config.url.startsWith('/api')) {
            const token = await firebase.auth().currentUser?.getIdToken();
            if (token) config.headers.authtoken = token;
        }
    } catch (error) {
        console.log(error);
    }
    return config;
});

// axios.interceptors.response.use(
//     function (response) {
//         // throw new axios.Cancel('Operation canceled by the user.');
//         console.log('Operation canceled by the user.');
//     },
//     function (error) {
//         return Promise.reject(error);
//     }
// );

const { loadCourses } = coursesSlice;
//const { loadSearchOptions } = searchOptionsSlice;

export const App = () => {
    //const { user } = useUser();
    useLoginLayer2();
    const { user: mongoUser, isLoading: isUserLoading } = useCurrentUser();
    const [showDrawer, setShowDrawer] = useState(false);
    // used as a flag to run only 1 time
    const [firstTime, setFirstTime] = useState(true);

    //Tried storing socket both with redux and recoil, but didn't work. :-(
    //const [socket, setSocket] = useRecoilState(socketState);
    //const socket = useSelector((state) => state.socket);
    const [socket, setSocket] = useState(null);
    // console.log(socket);

    const [userFirstVisit, setUserFirstVisit] = useState(false);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     //if (!isLoading && user) {
    //     dispatch(loadCourses());
    //     //dispatch(loadSearchOptions());
    //     //}
    // }, [/* isLoading, user, */ dispatch]);

    useEffect(() => {
        if (
            mongoUser &&
            !socket &&
            (mongoUser.user_subscription_id !== '' ||
                mongoUser.plan_name === 'Voyager+' ||
                mongoUser.is_instructor ||
                mongoUser.isAdmin)
        ) {
            console.log('init socket');
            setSocket(
                io(process.env.REACT_APP_NODE_DOMAIN, {
                    withCredentials: true,
                })
            );
        }

        // //if (mongoUser && !socket) dispatch(setSocket(io()));

        /* function loadCSS(src) {
                var element = document.createElement('link');
                element.setAttribute('rel', 'stylesheet');
                element.setAttribute('type', 'text/css');
                element.setAttribute('href', src);
                document.getElementsByTagName('head')[0].appendChild(element);
            }

            loadCSS('https://fonts.googleapis.com/icon?family=Material+Icons');
            loadCSS(
                'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
            ); */
        if (
            window.location.hostname ===
            process.env.REACT_APP_JOURNEYBUILDER_HOSTNAME
        ) {
            document.documentElement.style.setProperty(
                '--font-family',
                'nasalization'
            );
        }
    }, [mongoUser?._id]);

    useEffect(() => {
        if (socket && mongoUser) {
            socket.on('connect', () => {
                //console.log(socket.id);

                //have to pass token for security
                socket.emit('socket:save', {
                    userId: mongoUser._id,
                    socketId: socket.id,
                });
            });

            socket.on('disconnect', () => {
                //console.log(socket.id);
            });

            socket.on('connect_error', (err) => {
                console.log(`connect_error due to ${err.message}`);
            });
        }
    }, [socket, mongoUser?._id]);

    useEffect(() => {
        if (mongoUser) {
            if (!(mongoUser.is_instructor || mongoUser.isAdmin)) return;
            const setupSubscription = async () => {
                if ('serviceWorker' in navigator && 'PushManager' in window) {
                    const permission = await Notification.requestPermission();
                    if (permission === 'granted') {
                        subscribeUser();
                    } else {
                        console.log('Push notifications permission denied');
                    }
                } else {
                    console.log(
                        'Service Worker or Push Notifications not supported'
                    );
                }
            };

            setupSubscription();
        }
    }, [mongoUser?._id]);

    const toggleDrawer = () => {
        setShowDrawer((prev) => !prev);
    };

    useEffect(() => {
        if (mongoUser) {
            if (firstTime) {
                setFirstTime(false);
                const trackingObject = {
                    user: mongoUser._id,
                    page_slug: window.location.pathname,
                    activity_type: 'USER_FIRST_VIST',
                    activity_value: 'User visits',
                };
                userTrack('first-visit-user', trackingObject);
            }
        }
    }, [firstTime, setFirstTime, mongoUser]);

    // useZohoSalesIQChat(mongoUser, isUserLoading);

    return (
        <>
            {/* SNACKBAR ALERT */}
            <SnackbarAlert />
            <Router>
                {/* <ScrollToTop> */}
                {/* <Suspense
                    fallback={
                        <Box
                            style={{
                                height: '100vh',
                                display: 'grid',
                                placeItems: 'center',
                            }}
                        >
                            <CircularProgress color="secondary" />
                        </Box>
                    }
                > */}
                {/* <Box> */}
                <AllRoutes
                    showDrawer={showDrawer}
                    toggleDrawer={toggleDrawer}
                    mongoUser={mongoUser}
                    socket={socket}
                    userFirstVisit={userFirstVisit}
                    setUserFirstVisit={setUserFirstVisit}
                />
                {/* <DialogOfferPopup
                                hasVisitedFirstTime={userFirstVisit}
                            /> */}
                {/* </Box> */}
                {/* </Suspense> */}
                {/* </ScrollToTop> */}
            </Router>
        </>
    );
};

export default App;
