import { useEffect, useState } from 'react';
import { clientDetailsState } from './recoil';
import { useRecoilState } from 'recoil';
import axios from 'axios';
// import firebase from 'firebase/app';

export const useClientDetails = (user, isUserLoading) => {
    const [isLoading, setIsLoading] = useState(true);
    const [client, setClient] = useRecoilState(clientDetailsState);

    useEffect(() => {
        const getClient = async () => {
            const {
                data: { client },
            } = await axios.get(`/api/admin/client`);
            setClient(client);

            setIsLoading(false);
        };

        if (!user) return;

        if (!client?._id && !isUserLoading && client?.userEmail != user.email) {
            getClient();
        } else {
            setIsLoading(false);
        }
    }, [isUserLoading, user]);

    return { isLoading, client };
};
