import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const ACTIVITY = ['LOG_IN'];
const REVIEW = ['REVIEW'];
const PROGRESS = ['COURSE_PROGRESS'];

export const useStardustReportDetails = ({ noReport = false }) => {
    const [allUserReports, setAllUserReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const formatData = useCallback((data = []) => {
        const newUsersFormattedPoints = [];
        data.forEach((user) => {
            // if we want to group them we can do it later but have to right a different function
            let activity = 0;
            let review = 0;
            let progress = 0;
            user.logs?.forEach((log) => {
                if (ACTIVITY.includes(log.type)) {
                    activity += log.points;
                }
                if (REVIEW.includes(log.type)) {
                    review += log.points;
                }
                if (PROGRESS.includes(log.type)) {
                    progress += log.points;
                }
            });
            const newUser = { ...user, activity, review, progress };
            newUsersFormattedPoints.push(newUser);
        });
        return newUsersFormattedPoints;
    }, []);

    const fetchDetails = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(
                `/api/admin/stardust-reports?noReport=${noReport}`
            );
            const formatterUserData = formatData(data.userLogs);
            setAllUserReports(formatterUserData);
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }, [formatData, noReport]);

    useEffect(() => {
        fetchDetails();
    }, [fetchDetails]);

    return { isLoading, allUserReports };
};
