import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    RedditShareButton,
} from 'react-share';
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Tooltip,
    IconButton,
} from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
//import InstagramIcon from '@mui/icons-material/Instagram';
//import YouTubeIcon from '@mui/icons-material/YouTube';
import RedditIcon from '@mui/icons-material/Reddit';

import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: 30,
    },
    heading: {
        textAlign: 'center',
    },
    info: {
        textAlign: 'center',
    },

    IconsBox: {
        display: 'flex',
        flexFlow: 'row',
        minHeight: '70px',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            alignItems: 'flex-start',
            flexFlow: 'column-reverse',
        },
    },

    styleContainer: {
        [theme.breakpoints.down('md')]: {
            width: '100px',
        },
    },
}));

export const ShareDialog = ({ open, setOpen }) => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //const [open, setOpen] = useRecoilState(freeCreditsPopUpOpenState);

    const handleClose = () => {
        setOpen(false);
    };
    return (
        //<></>
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            //disableBackdropClick={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>
                    <DialogTitle
                        id="alert-dialog-title"
                        className={classes.heading}
                    >
                        Share this page
                    </DialogTitle>
                </Grid>

                {/* <Grid item>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                            >
                                Go ahead and purchase courses that you want to
                                learn.
                            </DialogContentText>
                        </DialogContent>
                    </Grid> */}
                <Grid item>
                    <DialogActions>
                        <Box className={classes.IconsBox}>
                            <Box>
                                <FacebookShareButton
                                    url={window.location.href}
                                    //quote={name}
                                >
                                    <Tooltip title="Facebook">
                                        <IconButton
                                            /* onClick={goToFaceBookHandler} */ size="large"
                                        >
                                            <FacebookIcon />
                                        </IconButton>
                                    </Tooltip>
                                </FacebookShareButton>

                                <TwitterShareButton
                                    url={window.location.href}
                                    //title={name}
                                >
                                    <Tooltip title="Twitter">
                                        <IconButton
                                            /* onClick={goToTwitterHandler} */ size="large"
                                        >
                                            <TwitterIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TwitterShareButton>
                                <LinkedinShareButton
                                    url={window.location.href}
                                    //title={name}
                                >
                                    <Tooltip title="LinkedIn">
                                        <IconButton
                                            /* onClick={goToLinkedInHandler} */ size="large"
                                        >
                                            <LinkedInIcon />
                                        </IconButton>
                                    </Tooltip>
                                </LinkedinShareButton>

                                {/* <Tooltip title="YouTube">
                    <IconButton onClick={goYouTubeHandler}>
                        <YouTubeIcon />
                    </IconButton>
                </Tooltip> */}

                                {/* <PinterestShareButton
                                    url={window.location.href}
                                    //media={imageURL}
                                >
                                    <Tooltip title="Pinterest">
                                        <IconButton>
                                            <PinterestIcon />
                                        </IconButton>
                                    </Tooltip>
                                </PinterestShareButton> */}

                                {/* <Tooltip title="Instagram">
                    <IconButton onClick={goToInstagramHandler}>
                        <InstagramIcon />
                    </IconButton>
                </Tooltip> */}

                                <RedditShareButton
                                    url={window.location.href}
                                    //title={name}
                                >
                                    <Tooltip title="Reddit">
                                        <IconButton
                                            /* onClick={goToRedditHandler} */ size="large"
                                        >
                                            <RedditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </RedditShareButton>
                            </Box>
                        </Box>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
};
