import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import CookieConsent from 'react-cookie-consent';
import { Grid } from '@mui/material';

export const CookiePopUp = () => {
    function loadTrackers() {
        if (localStorage.getItem('cookie-consent')) {
            // Load your tracking scripts here
        } else {
            console.log('No Consent');
        }
    }

    useEffect(() => {
        const consent = localStorage.getItem('cookie-consent');
        if (!consent) {
            const userId = localStorage.getItem('userId') || null;
            if (!userId) {
                localStorage.setItem('nanoId', uuid());
            } else {
                localStorage.removeItem('nanoId');
            }
        }

        loadTrackers();
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookie-consent', 'true');
        loadTrackers();
    };

    return (
        <CookieConsent
            buttonText="Accept"
            location="bottom"
            style={{ background: '#2B373B', textalign: 'center' }}
            buttonStyle={{
                color: 'black',
                fontSize: '14px',
                height: '40px',
                width: '82.5px',
                background: 'white',
            }}
            expires={150}
            buttonWrapperClasses="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-4"
            onAccept={handleAccept}
        >
            <Grid container fixed justifyContent="center">
                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                        lineHeight: '1.5em',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}
                >
                    This website uses cookies, including third party ones, to
                    allow for analysis of how people use our website in order to
                    improve your experience and our services. By continuing to
                    use our website, you agree to the use of such cookies.{' '}
                    <a
                        href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/privacy-policies/`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline' }}
                    >
                        Click here for more information on our Privacy Policy
                    </a>
                </Grid>
            </Grid>
        </CookieConsent>
    );
};
