import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '../ui';
import {
    globalPopUpOpenState,
    globalPopUpTitleState,
    globalPopUpMessageState,
    globalPopUpActionBtnState,
    globalPopUpActionLinkState,
} from './recoil';

export const PopUpMessage = () => {
    let navigate = useNavigate();
    const [open, setOpen] = useRecoilState(globalPopUpOpenState);

    // const [title, setTitle] = useRecoilState(globalPopUpTitleState);
    // const [message, setMessage] = useRecoilState(globalPopUpMessageState);

    const title = useRecoilValue(globalPopUpTitleState);
    const message = useRecoilValue(globalPopUpMessageState);
    const actionBtn = useRecoilValue(globalPopUpActionBtnState);
    const actionLink = useRecoilValue(globalPopUpActionLinkState);

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    {actionBtn ? (
                        <Button
                            onClick={() => {
                                navigate(actionLink);
                                handleClose();
                            }}
                        >
                            {actionBtn}
                        </Button>
                    ) : (
                        <Button onClick={handleClose}>Ok</Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
