import { atom } from 'recoil';
export const chatStateAtom = atom({
    key: 'chatStateAtom',
    default: {
        chats: [],
        usersById: {},
        // selectedChatIndex: null,
        selectedChatId: null, //for undocked
        openedChats: [
            //for docked
            // { id: '664b6e24c7eeae779d87a4bf', minimized: true },
            // { id: '66508eca9dd77321f4abe087', minimized: true },
        ],
        isLoadingChats: true,
        minimized: true,
        showChatUsersOnMobile: true,
    },
});
