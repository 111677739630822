import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { allFeatureState } from './recoil';
import axios from 'axios';

const groups = [
    {
        label: 'Data Science',
        sections: ['Data Science'],
        redirect: '/category/data-science/courses',
    },
    {
        label: 'Cloud',
        sections: ['Cloud'],
        redirect: '/category/cloud/courses',
    },
    {
        label: 'Full Stack',
        sections: ['Full Stack'],
        redirect: '/category/full-stack/courses',
    },
    {
        label: 'Cybersecurity',
        sections: ['Cybersecurity'],
        redirect: '/category/cybersecurity/courses',
    },
    {
        label: 'DevOps/Agility',
        sections: ['DevOps', 'Agility'],
        redirect: '/category/devops-agility/courses',
    },
    {
        label: 'Finance',
        sections: ['Finance'],
        redirect: '/category/finance/courses',
    },
    {
        label: 'Business',
        sections: ['Business', 'UI/UX', 'Networking'],
        redirect: '/category/business/courses',
    },
];

export const useAllFeaturedCourses = () => {
    const [tabsState, setTabsState] = useRecoilState(allFeatureState);
    const [isLoading, setIsLoading] = useState(true);

    const groupData = useCallback((data = []) => {
        // group courses for all domain
        const groupedData = data.reduce((grouped, course) => {
            if (!grouped[`${course.domain}`]) {
                grouped[`${course.domain}`] = [];
                grouped[`${course.domain}`].push(course);
            } else {
                grouped[`${course.domain}`].push(course);
            }
            return grouped;
        }, {});
        // grouping course as we need in the tabs
        let groupedCourses = {};
        groups.forEach(({ label, sections, redirect }) => {
            const allCourseInSections = [];
            groupedCourses[label] = {};
            sections.forEach((domainName) => {
                if (groupedData[domainName]) {
                    allCourseInSections.push(...groupedData[domainName]);
                }
            });
            groupedCourses[label].courses = allCourseInSections;
            groupedCourses[label].redirect = redirect;
        });
        return groupedCourses;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const {
                //     data,
                // } = await axios.get(
                //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/on-demand/feature-courses?nowprocket=1`,
                //     { headers: { 'Accept-Encoding': 'gzip' } }
                // );

                const { data } = await axios.get(
                    `/api/courses?courseTagExists=1&thinkific=1&type=ON-DEMAND&limit=20`
                );

                const groupedCourses = groupData(data.courses);
                setTabsState(groupedCourses);
            } catch (error) {
                console.log(error);
            }
        };
        if (Object.values(tabsState).length) {
            setIsLoading(false);
        } else {
            fetchData();
            setIsLoading(false);
        }
    }, []);

    return { isLoading, tabsState };
};
