import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Box, Grid, Skeleton, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    rootStyle: {
        width: '100%',
        fontSize: '1.2em',
    },
    mainBoxWrap: {
        position: 'relative',
        minHeight: 300,
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            minHeight: 'auto',
        },
    },
    mainBox: {
        width: '94%',
        borderRadius: '8px',
        marginLeft: '20px',
        marginRight: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 13px 2px #AAA8A8',
        marginTop: '10px',
        position: 'relative',
        '&:hover': {
            position: 'absolute',
            zIndex: 1000,
            transition: 'all 0.3s ease-out',
            transform: 'scale(1.05)',
            '& $desc': {
                visibility: 'visible',
                display: 'block',
                backgroundColor: '#ffffff',
            },
        },
    },
    desc: {
        display: 'none',
        padding: 8,
        fontSize: '16px',
    },
    root: {
        backgroundColor: '#ddd',
        // backgroundPosition: 'center',
        backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        borderRadius: '6px 6px 0 0px',
        minHeight: '175px',
        width: '100%',
    },
    contentBox: {
        width: '100%',
        margin: '0 auto',
        padding: 16,
        transition: 'all 0.3s ease-in',
        minHeight: '70px',
    },
    btn: {
        position: 'absolute',
        top: '0px',
        right: '0px',
    },
}));

export const JourneysListItem = ({
    xs,
    md,
    lg,
    onClickJourney,
    slug,
    thumbnail,
    title,
    coursesIncluded,
    //instructorName,
    domain,
    description,
    duration,
}) => {
    const { classes } = useStyles();
    const [instructorCount, setInstructorCount] = useState(0);

    // useEffect(() => {
    //     getInstructorCount(coursesIncluded);
    // }, []);

    const getInstructorCount = (coursesIncluded) => {
        var instructorLength = [];
        {
            instructorLength = [
                ...new Set(coursesIncluded.map(({ instructor }) => instructor)),
            ].map((instructor) =>
                coursesIncluded.find((c) => c.instructor === instructor)
            );
        }
        return instructorLength.length;
    };

    return (
        <Grid style={{ cursor: 'pointer' }} item xs={xs} md={md} lg={lg}>
            <Box className={classes.mainBoxWrap}>
                <Box
                    onClick={() => onClickJourney(slug, title)}
                    className={classes.mainBox}
                >
                    <Box mx="auto">
                        <img
                            className={classes.root}
                            src={thumbnail}
                            alt="starweaver logo"
                        />
                    </Box>

                    <Box component="div" className={classes.contentBox}>
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.rootStyle}
                            >
                                {ReactHtmlParser(title)}
                            </Typography>

                            <Typography
                                style={{
                                    color: '#BCBCBC',
                                    fontWeight: 500,
                                }}
                                variant="body2"
                            >
                                {getInstructorCount(coursesIncluded) > 1
                                    ? coursesIncluded[0].instructor + ' &...'
                                    : coursesIncluded[0].instructor}{' '}
                                | {domain} | {duration}
                            </Typography>
                        </>
                        <Box component="div" className={classes.desc}>
                            {ReactHtmlParser(description)}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};
