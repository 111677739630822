import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    link: {
        fontWeight: 'bold',
        color: theme.palette.common.lightBlue,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const AdminAccessRestricted = () => {
    const { classes } = useStyles();
    return (
        <Container>
            <Box
                height={'70vh'}
                display={'grid'}
                style={{
                    placeItems: 'center',
                }}
            >
                <Box>
                    <Typography variant="h1" color="secondary" gutterBottom>
                        Access Restricted
                    </Typography>
                    <Typography align="center" variant="h6">
                        (To gain access, please contact your{' '}
                        <Link
                            className={classes.link}
                            to="/admin/program-management"
                        >
                            Program Manager
                        </Link>
                        )
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default AdminAccessRestricted;
