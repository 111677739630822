import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
    root: {
        color: '#999',
        width: '100%',
        margin: '20px auto',
    },
    textHead: {
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginBottom: '20px',
    },
    textStyle: {
        cursor: 'pointer',

        '&:hover': {
            color: '#337ab7',
        },
    },
}));
