import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    instructorDetails: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        flexWrap: 'wrap',
        paddingBottom: theme.spacing(2),
    },
    instructor: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        '&:hover > p': {
            textDecoration: 'underline',
        },
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '0.9rem',
    },
    instructorName: {
        margin: 0,
        color: 'hsl(0deg 0% 22.19%)',
    },
}));

export default function InstructoDetails({ instructors }) {
    const { classes } = useStyles();

    return (
        <div className={classes.instructorDetails}>
            {instructors?.map(({ instructor, instructorSlug }) => (
                <Link
                    key={instructor}
                    to={`/channels/${instructorSlug}/on-air`}
                    className={classes.instructor}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* AVATAR */}
                    <Avatar className={classes.avatar}>
                        {instructor?.substring(0, 1)?.toUpperCase()}
                    </Avatar>

                    {/* INSTRUCTOR NAME */}
                    <p className={classes.instructorName}>{instructor}</p>
                </Link>
            ))}
        </div>
    );
}
