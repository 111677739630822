import { useEffect, useState } from 'react';
import { useCurrentUser } from './useCurrentUser';
import { userCreditsPointsState } from './Recoil/State';
import { useRecoilState } from 'recoil';
import axios from 'axios';

export const useGetUserCredits = () => {
    const [credits, setCredits] = useRecoilState(userCreditsPointsState);
    const { user } = useCurrentUser();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!credits) {
            setLoading(true);
            const getCredits = async () => {
                const { data } = await axios.get(
                    `/api/users/${user._id}/credits`
                );
                if (data.credit) {
                    setCredits(data.credit.current_points);
                }
            };
            if (user) {
                getCredits();
            }
            setLoading(false);
        }
    }, [user]);

    return { credits, isLoading: loading };
};
