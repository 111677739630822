import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { CourseChapterAccordion } from './CourseChapterAccordion';
import { Typography, Button, Grid, Box } from '../ui';

const courseTitle = 'Python Foundations, Tools & Techniques';
const courseDescription =
    'This Python course prepares teaches the fundamentals of Python. It shows how to interact with Python, the common data structures, looping, classes, exceptions, file handling, environments and more.';
const coursePaywallImage =
    'https://s3.amazonaws.com/thinkific-import/163274/4vSc4l0lS3Sj61S4Mdbp_course-images-049.jpg';

const courseChapters = [
    {
        title: 'Welcome to the course!',
        lessons: [
            {
                title:
                    'About this course: Overview, Learning Outcomes, Who Should Enroll...',
                freePreview: true,
            },
            { title: 'Instructor bio - Matt Harrison', freePreview: true },
            { title: 'Key pointers for this program' },
            { title: 'Joining the Alumni Community' },
        ],
    },
    {
        title: 'Slack Channel (Discussion)',
        lessons: [
            { title: 'Slack Channel (Discussion Forum)' },
            { title: 'If (and when) you need help...', videoContent: true },
        ],
    },
    {
        title: 'Module 1',
        lessons: [
            {
                title:
                    'Module 1- Getting Started with Python – Installation, REPL, and Variables',
            },
            { title: 'Segment - 01', videoContent: true },
            { title: 'Segment - 02', videoContent: true },
            { title: 'Segment - 03', videoContent: true },
            { title: 'Segment - 04', videoContent: true },
        ],
    },
    {
        title: 'Final Exam',
        lessons: [
            { title: 'Final Exam: Overview and Instructions' },
            { title: 'Final Exam: Launch here' },
        ],
    },
];

const useStyles = makeStyles()((theme) => ({
    root: { marginBottom: 50 },
    heading: {
        color: 'white',
        fontWeight: 'bold',
    },
    subHeading: {
        color: 'white',
        fontSize: '1.8rem',
        fontWeight: 'bold',
    },
    subscribeButton: {
        minHeight: '60px',
    },
    courseCurriculum: {
        textAlign: 'center',
        margin: '70px 0px',
    },
    bannerContainer: {
        width: '100%',
        minHeight: 1000,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        margin: '50px 0px',
        padding: '150px',
    },
    chapterList: {
        width: '50%',
        margin: 'auto',
    },
}));

export const CoursePaywallPage = () => {
    const { classes } = useStyles();
    return (
        <div className={classes.root}>
            <Box
                className={classes.bannerContainer}
                style={{
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)),url(${coursePaywallImage})`,
                }}
            >
                <Grid container direction="column" spacing={6}>
                    <Grid item>
                        <Typography
                            variant="h1"
                            component="h1"
                            className={classes.heading}
                        >
                            {courseTitle}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.subHeading}
                        >
                            {courseDescription}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.subscribeButton}
                                >
                                    $14.95 / MONTH SITE SUBSCRIPTION (W/ 5 DAY
                                    FREE TRIAL)
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.subscribeButton}
                                >
                                    $149.95 / YEAR SITE SUBSCRIPTION (W/ 5 DAY
                                    FREE TRIAL)
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Typography
                    variant="h3"
                    component="h3"
                    className={classes.courseCurriculum}
                >
                    Course curriculum
                </Typography>
            </Box>
            <Box className={classes.chapterList}>
                {courseChapters.map((chapter, index) => (
                    <CourseChapterAccordion
                        title={chapter.title}
                        lessons={chapter.lessons}
                        chapterNumber={index + 1}
                        key={index}
                    />
                ))}
            </Box>
        </div>
    );
};
