import { useRef, useEffect, useState, useMemo } from 'react';
import blue from '../img/marker-1.svg';
import darkPurple from '../img/marker-2.svg';
import orange from '../img/marker-3.svg';
import green from '../img/marker-4.svg';
import plum from '../img/marker-5.svg';

// [top ---> bottom]
const markerImages = [plum, green, darkPurple, blue, orange];

const markerRatio = 0.6125;

export const useImageDimensions = () => {
    const containerRef = useRef(null);
    const [width, setWidth] = useState('100%');
    const [height, setHeight] = useState('100%');

    useEffect(() => {
        const observer = new ResizeObserver(([entity]) => {
            if (entity?.target?.clientWidth) {
                setWidth(entity.target.clientWidth);
                setHeight(entity.target.clientHeight);
            }
        });
        if (containerRef.current) {
            observer.observe(containerRef.current);
            setWidth(containerRef.current.clientWidth);
            setHeight(containerRef.current.clientHeight);
        }
        return () => {
            if (containerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(containerRef.current);
            }
        };
    }, [containerRef]);

    const imageHeight = useMemo(() => {
        let newArmHeight = width;
        if (width !== '100%') {
            return `${
                width === '100%' ? '100%' : `${(width * 73.1) / 100}px`
            } `;
        }
        return newArmHeight;
    }, [width]);

    const imageWidth = useMemo(() => {
        let newArmHeight = height;
        if (height !== '100%') {
            return `${
                height === '100%' ? '100%' : `${(height * 136.7) / 100}px`
            } `;
        }
        return newArmHeight;
    }, [height]);

    const positionFirstMarkerOnWidth = useMemo(() => {
        let markerObject = {
            width: '0px',
            height: '0px',
        };
        if (imageWidth !== '100%') {
            const imageWidthToNumber = parseInt(imageWidth.replace('px', ''));
            const calcWidth = imageWidthToNumber / 15;
            markerObject.height = `${calcWidth}px`;
            markerObject.width = `${calcWidth * markerRatio}px`;
            markerObject.top = `${imageWidthToNumber * 0.03}px`;
            markerObject.right = `${imageWidthToNumber * 0.5}px`;
        } else {
            return markerObject;
        }
        return markerObject;
    }, [imageWidth]);

    const positionMarkersOnWidth = useMemo(() => {
        const positions = [
            {
                top: 0.00009,
                right: 0.32,
                link: '/category/cybersecurity/course',
                tooltip: 'Hack like a cyber guru',
            },
            {
                top: 0.12,
                right: 0.48,
                link: '/category/finance/courses',
                tooltip: 'Dive Into Finance',
            },
            {
                top: 0.27,
                right: 0.21,
                link: '/search?q=agile',
                tooltip: 'Become Agile',
                hideSearch: true,
            },
            {
                top: 0.4,
                right: 0.62,
                link: '/certifications/cloud/c',
                tooltip: 'Get Cloud Certified',
            },
            {
                top: 0.6,
                right: 0.9,
                link: '/journeys/all/journey-builder/',
                tooltip: 'Find your journey',
            },
        ];
        const markerObject = {
            width: '0px',
            height: '0px',
        };
        const markers = [];
        const imageWidthToNumber = parseInt(imageWidth.replace('px', ''));
        const calcWidth = imageWidthToNumber / 15;
        if (imageWidth !== '100%') {
            positions.forEach((position, index) => {
                let markerPosition = { ...markerObject };
                if (position.top) {
                    markerPosition.height = `${calcWidth}px`;
                    markerPosition.width = `${calcWidth * markerRatio}px`;
                    markerPosition.top = `${
                        imageWidthToNumber * position.top
                    }px`;
                    markerPosition.right = `${
                        imageWidthToNumber * position.right
                    }px`;
                    markerPosition.backgroundImage = `url(${markerImages[index]})`;
                }
                const data = {
                    link: position.link,
                    tooltip: position.tooltip,
                    hideSearch: position?.hideSearch,
                };
                markers.push({ markerPosition, data });
            });
        }
        return markers;
    }, [imageWidth]);

    const positionThirdMarkerOnWidth = useMemo(() => {
        let markerObject = {
            width: '0px',
            height: '0px',
        };
        if (imageWidth !== '100%') {
            const imageWidthToNumber = parseInt(imageWidth.replace('px', ''));
            const calcWidth = imageWidthToNumber / 15;
            markerObject.height = `${calcWidth}px`;
            markerObject.width = `${calcWidth * markerRatio}px`;
            markerObject.top = `${imageWidthToNumber * 0.6}px`;
            markerObject.right = `${imageWidthToNumber * 0.9}px`;
        } else {
            return markerObject;
        }
        return markerObject;
    }, [imageWidth]);

    const positionSecondMarkerOnWidth = useMemo(() => {
        let markerObject = {
            width: '0px',
            height: '0px',
        };
        if (imageWidth !== '100%') {
            const imageWidthToNumber = parseInt(imageWidth.replace('px', ''));
            const calcWidth = imageWidthToNumber / 15;
            markerObject.height = `${calcWidth}px`;
            markerObject.width = `${calcWidth * markerRatio}px`;
            markerObject.top = `${imageWidthToNumber * 0.25}px`;
            markerObject.right = `${imageWidthToNumber * 0.2}px`;
        } else {
            return markerObject;
        }
        return markerObject;
    }, [imageWidth]);

    const positionFirstMarker = useMemo(() => {
        let markerObject = {
            width: '0px',
            height: '0px',
        };
        if (imageHeight !== '100%') {
            const imageHeightToNumber = parseInt(imageHeight.replace('px', ''));
            const calcHeight = imageHeightToNumber / 10;
            markerObject.height = `${calcHeight}px`;
            markerObject.width = `${calcHeight * markerRatio}px`;
            markerObject.top = `${imageHeightToNumber / 30}px`;
            markerObject.right = '50%';
        } else {
            return markerObject;
        }
        return markerObject;
    }, [imageHeight]);

    return {
        containerRef,
        imageHeight,
        imageWidth,
        positionFirstMarker,
        positionFirstMarkerOnWidth,
        positionThirdMarkerOnWidth,
        positionSecondMarkerOnWidth,
        positionMarkersOnWidth,
    };
};
