export const domainOptions = [
    { label: 'Data Science', value: 'data-science' },
    { label: 'Cybersecurity', value: 'cybersecurity' },
    { label: 'DevOps', value: 'devops' },
    { label: 'Networking', value: 'networking' },
    { label: 'UI/UX', value: 'ui-ux' },
    { label: 'Cloud', value: 'cloud' },
    { label: 'Full Stack', value: 'full-stack' },
    { label: 'Agility', value: 'agility' },
    { label: 'Business', value: 'business' },
    { label: 'Finance', value: 'finance' },
];
