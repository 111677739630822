import React, { useState } from 'react';

import {
    Box,
    Grid,
    TextField,
    Button,
    IconButton,
    Typography,
    CircularProgress,
} from '../ui';
import axios from 'axios';
// import MuiPhoneNumber from 'material-ui-phone-number';
import MuiPhoneNumber from 'mui-phone-number';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrowDropDownIcon } from '../icons';

import { Otp } from './Otp';

function isNumeric(n) {
    return !isNaN(parseInt(n)) && isFinite(n);
}

export const MobileOtpVerification = ({
    mobile_number,
    setMobileNumber,

    mobileVerificationStatus,
    setMobileVerificationStatus,
    setOnboardingData,
    savePhone,
    hideChangeBtn,
    hideHeading,
}) => {
    const [code, setCode] = useState('+1');
    const [pno, setPno] = useState('');
    const [otpShow, setOtpShow] = useState(false);
    const [otp, setOtp] = useState('');
    const [pendding, setPendding] = useState(false);
    /* const [status, setStatus] = useState(
        onBoardingData?.mobileVerificationStatus || ''
    ); */

    const verifyCode = async () => {
        setPendding(false);
        const e = code + pno;

        await axios
            .get('/api/users/code/verifycode', {
                params: {
                    phonenumber: e,
                    code: otp,
                },
            })
            .then((data) => {
                if (data.data.status === 'pending') {
                    alert('Entered OPT is Incorrect');
                    setMobileNumber('');
                    setMobileVerificationStatus(data.data.status);
                    setPendding(false);
                } else if (data.data.status === 'approved') {
                    setMobileNumber(code + pno);
                    setMobileVerificationStatus(data.data.status);
                    setPendding(false);

                    if (setOnboardingData)
                        setOnboardingData((prev) => {
                            return {
                                ...prev,
                                mobileVerificationStatus: 'approved',
                            };
                        });

                    if (savePhone) savePhone(code + pno);
                }
                // setNumVerified(data.data.status);

                console.log(data);
            })
            .catch((err) => console.log(err));
    };

    const getCode = async () => {
        const e = code + pno;
        console.log(e);
        await axios
            .get('/api/users/verify/getcode', {
                params: {
                    phonenumber: e,
                    channel: 'sms',
                },
            })
            .then((data) => {
                console.log(data);
            })
            .catch((err) => console.log(err));
    };

    const onPhoneValueChange = (value) => {
        console.log(value);
        setCode(value);
    };

    //  const height = this.state.height - 65;

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                width: '100%',
                minHeight: '5rem',
                // height: `${status !== 'approved'} ? 100vh : 25vh`,
            }}
        >
            {mobileVerificationStatus !== 'approved' /* && !mobile_number */ ? (
                <Box
                    style={{
                        padding: '0.5rem',
                        width: '100%',
                    }}
                >
                    {!otpShow ? (
                        <h3 style={{ color: '#9f9f9f' }}>{/* OTP */}</h3>
                    ) : (
                        <IconButton
                            onClick={() => {
                                setOtpShow(false);
                                setOtp('');
                            }}
                            size="small"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    {!otpShow ? (
                        <>
                            {!hideHeading && (
                                <Typography variant="h6">
                                    Enter your Phone Number
                                </Typography>
                            )}
                        </>
                    ) : (
                        <Typography variant="h6">Enter the OTP</Typography>
                    )}
                    {otpShow ? (
                        <p>
                            A One Time Password has been sent to your phone
                            number for verification puposes.
                        </p>
                    ) : null}
                    <div>
                        {!otpShow ? (
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                // style={{ border: '2px solid blue' }}
                            >
                                <Grid item xs={4}>
                                    <MuiPhoneNumber
                                        value={code}
                                        label="Country Code"
                                        deafultCountry={'us'}
                                        autoFormat={false}
                                        preferredCountries={['us', 'in', 'ca']}
                                        disableAreaCodes={true}
                                        //countryCodeEditable={false}
                                        onChange={onPhoneValueChange}
                                    />
                                </Grid>

                                <Grid item xs={8}>
                                    <TextField
                                        id="phone"
                                        label="Phone"
                                        color="secondary"
                                        value={pno}
                                        onChange={(e) => {
                                            if (
                                                (e.target.value[
                                                    e.target.value.length - 1
                                                ] >= '0' &&
                                                    e.target.value[
                                                        e.target.value.length -
                                                            1
                                                    ] <= '9') ||
                                                !e.target.value
                                            ) {
                                                setPno(e.target.value);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Otp otp={otp} setOtp={setOtp} />
                        )}
                        {otpShow ? (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    marginTop: 5,
                                }}
                            >
                                Didn't receive an OTP?{' '}
                                <Button
                                    color="primary"
                                    style={{
                                        textTransform: 'none',
                                        fontSize: 15,
                                        color: 'dodgerblue',
                                        cursor: 'pointer',
                                        // border: '2px solid',
                                        margin: '0 0 0 1rem',
                                    }}
                                    onClick={() => {
                                        setOtpShow(false);
                                        setOtp('');
                                    }}
                                >
                                    Resend OTP
                                </Button>
                            </div>
                        ) : null}
                        <div
                            style={{
                                // display: 'flex',
                                // flexDirection: 'row',
                                marginTop: 20,
                            }}
                        >
                            {pendding ? (
                                <CircularProgress
                                    style={{
                                        marginTop: '5px',
                                        marginLeft: '10px',
                                    }}
                                    color="secondary"
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    disabled={
                                        /* pno.length !== 10 || */
                                        code === null ||
                                        !isNumeric(pno) ||
                                        (otpShow && otp.length !== 6)
                                    }
                                    color="secondary"
                                    style={{
                                        color: 'white',
                                        marginLeft: 'auto',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        if (otpShow) {
                                            verifyCode();
                                        } else {
                                            getCode();
                                            setOtpShow(true);
                                        }
                                    }}
                                >
                                    Verify
                                </Button>
                            )}
                        </div>
                        {/* {!otpShow ? (
                            <p>
                                Standard sms and data charges may apply based
                                the country of your residence.
                            </p>
                        ) : null} */}
                        {/* <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginTop: 10,
                            }}
                        >
                            <a
                                href="#"
                                style={{ textDecoration: 'none', fontSize: 14 }}
                            >
                                Terms of service
                            </a>
                            <a
                                href="#"
                                style={{
                                    textDecoration: 'none',
                                    fontSize: 14,
                                    marginLeft: 10,
                                }}
                            >
                                User agreement
                            </a>
                        </div> */}
                    </div>
                </Box>
            ) : null}
            {mobileVerificationStatus === 'approved' ? (
                <Box
                    style={{
                        padding: '0.5rem',
                        width: '100%',
                    }}
                >
                    <h3>Your number is verified</h3>

                    <div>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            // style={{ border: '2px solid blue' }}
                        >
                            <Grid item xs={8}>
                                <TextField
                                    id="phone-disabled"
                                    label="Phone"
                                    color="secondary"
                                    disabled
                                    value={mobile_number}
                                    style={{
                                        width: 300,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {!hideChangeBtn && (
                            <div
                                style={{
                                    // display: 'flex',
                                    // flexDirection: 'row',
                                    marginTop: 20,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    /* color="secondary" */
                                    style={{
                                        color: 'rgba(255,255,255,1)',
                                        backgroundColor: 'rgba(0,0,0,0.75)',
                                        marginLeft: 'auto',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        setMobileVerificationStatus('');
                                        setOtp('');
                                        setOtpShow(false);
                                        setPendding(false);
                                    }}
                                >
                                    Change
                                </Button>
                            </div>
                        )}
                    </div>
                </Box>
            ) : null}
        </div>
    );
};
