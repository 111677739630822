export const MimeTypes = {
    Image: 'image',
    Video: 'video',
    Pdf: 'pdf',
    Doc: 'doc',
    Audio: 'audio',
};

export const videoFormats = ['avi', 'mp4', 'mpeg', 'ogv', 'ts', 'webm'];

export const imgFormats = [
    'png',
    'jpg',
    'gif',
    'jpeg',
    'webp',
    'avif',
    'bmp',
    'ico',
    'svg',
    'tif',
    'tiff',
];

export const pdfFormats = ['pdf'];

export const docFormats = [
    'doc',
    'docx',
    'odp',
    'ods',
    'odt',
    'ppt',
    'pptx',
    'xls',
    'xlsx',
    'csv',
];

export const audioFormats = [
    'mid',
    'midi',
    'mp3',
    'cda',
    'oga',
    'opus',
    'wav',
    'weba',
    '3gp',
    '3g2',
];

export function verifyMimeType(verifyWith, fileExtension) {
    switch (verifyWith) {
        case MimeTypes.Video:
            return videoFormats.includes(fileExtension);
        case MimeTypes.Image:
            return imgFormats.includes(fileExtension);
        case MimeTypes.Pdf:
            return pdfFormats.includes(fileExtension);
        case MimeTypes.Doc:
            return docFormats.includes(fileExtension);
        case MimeTypes.Audio:
            return audioFormats.includes(fileExtension);
        default:
            return true;
    }
}
