import { useMemo } from 'react';
import {
    Box,
    Button,
    Divider,
    IconButton,
    LinearBar,
    Link as MuiLink,
    Tooltip,
    Typography,
} from '../ui';
import { useCurrentUser } from './useCurrentUser';
import {
    AccessTimeIcon,
    AssessmentIcon,
    CloseIcon,
    HowToRegIcon,
    QuestionAnswerIcon,
    RateReviewIcon,
    TrendingUpIcon,
} from '../icons';
import { makeStyles } from 'tss-react/mui';
import four from '../img/four.png';
import five from '../img/five.png';
import six from '../img/six.png';
import seven from '../img/seven.png';
import eight from '../img/eight.png';
import nine from '../img/nine.png';
import ten from '../img/ten.png';
import { blue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const LEVEL_IMAGES = [four, five, six, seven, eight, nine, ten];

const TEXT_COLOR = '#888';

const EVENT_TYPES = {
    REVIEW: { name: 'Review', Icon: <RateReviewIcon fontSize="small" /> },
    COURSE_PROGRESS: {
        name: 'Progress',
        Icon: <AccessTimeIcon fontSize="small" />,
    },
    LOG_IN: { name: 'Activity', Icon: <AssessmentIcon fontSize="small" /> },
    REGISTRATION: {
        name: 'Registrations',
        Icon: <HowToRegIcon fontSize="small" />,
    },
    CHAT_DISCUSSION: {
        name: 'Chats/Discussions/Engage',
        Icon: <QuestionAnswerIcon fontSize="small" />,
    },
};

const LEVELS = [0, 15, 75, 250, 500, 1500, 5000, 15000, 50000, 100000];
// const LEVELS = [0, 5, 10, 50, 100, 1000];

const LevelsComponent = () => {
    return (
        <Box display={'flex'} flexDirection="column">
            {LEVELS.map((level, index) => (
                <Box display="flex" alignItems={'center'}>
                    <Typography variant="caption">
                        Level {index + 1} - {level} Points
                    </Typography>
                    {index > 2 && (
                        <Box ml={1}>
                            <img
                                width={12}
                                height={12}
                                alt="level_"
                                src={LEVEL_IMAGES[index - 3]}
                            />
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
};

export const StardustSection = ({
    onClose = () => console.log('close function'),
    name,
    avatar = <> </>,
    setTabValue = null,
}) => {
    const { stardustDetails } = useCurrentUser();
    const { classes } = useStyles();
    let navigate = useNavigate();

    const userLevel = useMemo(() => {
        const points = stardustDetails.points;
        let level = 1;
        LEVELS.forEach((totalPoints, index) => {
            if (points > totalPoints) {
                level = index + 1;
            }
        });
        return level;
    }, [stardustDetails]);

    const pointsPercent = useMemo(() => {
        const points = stardustDetails.points;
        if (points) {
            const levelDifference = LEVELS[userLevel] - LEVELS[userLevel - 1];
            const pointsDifference = points - LEVELS[userLevel - 1];
            return (pointsDifference * 100) / levelDifference;
        } else {
            return 0;
        }
    }, [userLevel, stardustDetails]);

    const groupedPointsEarned = useMemo(() => {
        if (stardustDetails?.logs) {
            const groupedLogs = stardustDetails?.logs.reduce((grouped, log) => {
                if (!grouped[`${log.type}`]) {
                    grouped[`${log.type}`] = [];
                }
                grouped[`${log.type}`].push(log);
                return grouped;
            }, {});
            return groupedLogs;
        } else {
            return {};
        }
    }, [stardustDetails]);

    const noPointsCategory = useMemo(() => {
        return Object.keys(EVENT_TYPES).filter((event) =>
            Object.keys(groupedPointsEarned).includes(event) ? false : true
        );
    }, [groupedPointsEarned]);

    const pointsSum = useMemo(() => {
        if (Object.keys(groupedPointsEarned)) {
            return Object.values(groupedPointsEarned).map((logs) =>
                logs.reduce((sum, log) => {
                    return (sum += log.points_used);
                }, 0)
            );
        }
        return [];
    }, [groupedPointsEarned]);

    const handleLeaderboardClick = () => {
        if (setTabValue) {
            const basePath = '/profile/dashboard';
            const section = 'leaderboard';

            navigate(`${basePath}/${section}`, {
                state: { noScrollToTop: true },
            });
            setTabValue(2);
            onClose();
        } else {
            window.open('/profile/dashboard/leaderboard/', '_blank');
        }
    };

    return (
        <Box
            style={{
                overflowX: 'hidden',
            }}
            minWidth={'600px'}
            maxWidth={'600px'}
            p={1}
        >
            <Box className={classes.titleHeading}>
                <Typography variant="h6">Stardust </Typography>
                <IconButton color="inherit" onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            </Box>
            {/* displayUser */}
            <Box className={classes.displayContainer}>
                <Box mb={1}>{avatar}</Box>
                <Typography
                    style={{
                        fontWeight: 600,
                    }}
                    color="inherit"
                    variant="h5"
                >
                    {name}
                </Typography>
                <Box>
                    <Tooltip placement="top" arrow title={<LevelsComponent />}>
                        <Box
                            style={{
                                cursor: 'default',
                                fontWeight: 600,
                            }}
                            display="flex"
                        >
                            <Typography color="inherit" variant="h6">
                                Level {userLevel}
                            </Typography>
                            {userLevel > 3 && (
                                <Box ml={1}>
                                    <img
                                        width={20}
                                        height={20}
                                        alt="level_"
                                        src={LEVEL_IMAGES[userLevel - 4]}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
            {/* displayUser */}
            <Box display="flex" justifyContent={'flex-end'}>
                <Button
                    // href="/profile/dashboard/leaderboard/"
                    color="secondary"
                    // target="_blank"
                    endIcon={<TrendingUpIcon />}
                    onClick={handleLeaderboardClick}
                >
                    Leaderboards
                </Button>
            </Box>
            <Box px={4}>
                {userLevel >= 3 ? (
                    <Box my={1}>
                        <Box mb={1} display={'flex'} justifyContent="flex-end">
                            <Typography variant="subtitle2">
                                {stardustDetails.points?.toLocaleString()}
                            </Typography>
                        </Box>
                        <Tooltip title={stardustDetails.points}>
                            <Box>
                                <LinearBar
                                    variant="determinate"
                                    value={pointsPercent}
                                />
                            </Box>
                        </Tooltip>
                        <Box
                            mt={1}
                            display={'flex'}
                            justifyContent="space-between"
                        >
                            <Box>{LEVELS[userLevel - 1].toLocaleString()}</Box>
                            <Box>{LEVELS[userLevel].toLocaleString()}</Box>
                        </Box>
                    </Box>
                ) : (
                    <Box my={1}>
                        <Box>
                            <Typography
                                style={{
                                    color: TEXT_COLOR,
                                }}
                                variant="subtitle2"
                            >
                                Your earn Stardust in may ways. When you reach
                                Level 3, you see your progress here.
                            </Typography>
                        </Box>
                        <Box>
                            <MuiLink
                                href="https://support.starweaver.com/portal/en/home"
                                className={classes.muiLink}
                            >
                                <Typography
                                    color="inherit"
                                    variant="subtitle2"
                                    gutterBottom
                                >
                                    Get started
                                </Typography>
                            </MuiLink>
                        </Box>
                    </Box>
                )}
                <Divider />
                <Box mt={2}>
                    {Object.keys(groupedPointsEarned).map((key, index) => (
                        <Box className={classes.categoryContainer}>
                            <Box className={classes.labelContainer}>
                                <Box className={classes.labelIcon}>
                                    {EVENT_TYPES[key].Icon}
                                </Box>
                                <Typography color="inherit" variant="subtitle2">
                                    {EVENT_TYPES[key].name}
                                </Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography color="inherit" variant="subtitle2">
                                    {pointsSum[index].toLocaleString()}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                    {noPointsCategory.map((event) => (
                        <Box className={classes.categoryContainer}>
                            <Box className={classes.labelContainer}>
                                <Box className={classes.labelIcon}>
                                    {EVENT_TYPES[event].Icon}
                                </Box>
                                <Typography color="inherit" variant="subtitle2">
                                    {EVENT_TYPES[event].name}
                                </Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography color="inherit" variant="subtitle2">
                                    0{' '}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
                {/* footer */}
                <Box mt={1} />
                <Divider />
                <Box my={1}>
                    <MuiLink
                        // href="https://support.starweaver.com/portal/en/home"
                        href="https://support.starweaver.com/portal/en/kb/articles/points-levels-and-badging"
                        className={classes.muiLink}
                        target="_blank"
                    >
                        <Typography
                            color="inherit"
                            variant="subtitle2"
                            gutterBottom
                        >
                            Learn more about Stardust
                        </Typography>
                    </MuiLink>
                    <Typography
                        style={{
                            color: TEXT_COLOR,
                        }}
                        variant="subtitle2"
                    >
                        You earn points as you complete your courses, Journeys,
                        Certifications, and more, interact with your
                        instructors, help others learn, and much more.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    titleHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        paddingBottom: '10px',
        zIndex: '1',
        '&::before': {
            position: 'absolute',
            bottom: '0%',
            left: '-20%',
            height: '1px',
            width: '150%',
            zIndex: '-1',
            content: '""',
            backgroundColor: '#c5c5c5',
        },
    },
    displayContainer: {
        margin: '20px auto ',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: '1',
        color: '#fafafa',
        '&::before': {
            position: 'absolute',
            top: '25%',
            left: '-20%',
            height: '80%',
            width: '150%',
            zIndex: '-1',
            content: '""',
            backgroundColor: blue[500],
        },
    },
    muiLink: {
        color: blue[600],

        ':hover': {
            textDecoration: 'underline',
            color: blue[800],
        },
    },
    categoryContainer: {
        marginTop: '10px',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '50%',
        color: TEXT_COLOR,
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    labelIcon: {
        marginTop: '8px',
        marginRight: '10px',
    },
}));
