import { userTrack } from '../util/tracking';
import { useCallback } from 'react';

export const useProductTracker = () => {
    const userRegistrationObject = useCallback(
        () => ({
            is_user_registered:
                localStorage.getItem('userId') !== 'null' ? true : false,
            user:
                localStorage.getItem('userId') !== 'null'
                    ? localStorage.getItem('userId')
                    : null,
        }),
        []
    );

    const couponTried = useCallback(
        ({ value, type, slug }) => {
            const userDetails = userRegistrationObject();
            const trackObject = {
                ...userDetails,
                page_slug: window.location.pathname,
                activity_type: type,
                activity_value: `${value}`,
            };
            userTrack(slug, trackObject);
        },
        [userRegistrationObject]
    );

    const planChanged = useCallback(
        ({ value, type }) => {
            const userDetails = userRegistrationObject();
            const trackObject = {
                ...userDetails,
                page_slug: window.location.pathname,
                activity_type: type,
                activity_value: `${value}`,
            };
            userTrack('plan-browsed', trackObject);
        },
        [userRegistrationObject]
    );

    const clickedCheckout = useCallback(
        ({ value, type }) => {
            const userDetails = userRegistrationObject();
            const trackObject = {
                ...userDetails,
                page_slug: window.location.pathname,
                activity_type: type,
                activity_value: `${value}`,
            };
            userTrack('clicked-checkout', trackObject);
        },
        [userRegistrationObject]
    );

    return { couponTried, planChanged, clickedCheckout };
};
