import { Box, Button, Divider, Paper, Typography } from '../ui';
import { Settings } from '@mui/icons-material';

export const NotificationBox = () => {
    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Typography variant="h6" align="center">
                        Notifications
                    </Typography>
                    <Typography
                        style={{
                            color: '#939393',
                        }}
                        variant="subtitle1"
                        align="center"
                    >
                        You have new notifications
                    </Typography>
                    <Divider />
                    <Box
                        display="flex"
                        flexDirection={'column'}
                        alignItems="center"
                        mt={2}
                    >
                        <Typography
                            style={{
                                color: '#737373',
                            }}
                        >
                            Improve your notifications
                        </Typography>
                        <Button
                            href="/profile/notifications"
                            color="secondary"
                            endIcon={<Settings />}
                        >
                            settings
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};
