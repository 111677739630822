window.CKEDITOR.plugins.add('mentionAtomic', {
    init: function (editor) {
        editor.on('key', function (event) {
            const keyCode = event.data.keyCode;

            const selection = editor.getSelection();
            const ranges = selection.getRanges();

            if (ranges.length === 1) {
                const range = ranges[0];
                const startNode = range.startContainer;
                const startOffset = range.startOffset;

                // Check if startNode or its parent elements have class "sw-ckeditor-name" and remove it
                let parentElement = startNode.getParent();
                while (
                    parentElement &&
                    !(
                        parentElement.hasClass('sw-ckeditor-name') ||
                        parentElement.hasClass('sw-ckeditor-task') ||
                        parentElement.hasClass('sw-ckeditor-team-name') ||
                        parentElement.hasClass('sw-ckeditor-requirement-name')
                    )
                ) {
                    parentElement = parentElement.getParent();
                }
                if (parentElement) {
                    // const parentRange = editor.createRange();
                    // parentRange.setStartBefore(parentElement);
                    // parentRange.setEndAfter(parentElement);
                    // editor.getSelection().selectRanges([parentRange]);
                    // editor.execCommand('delete');

                    if (
                        keyCode === 8 || // Backspace
                        keyCode === 46 // Delete
                    ) {
                        parentElement.remove();
                    } else if (
                        keyCode === 37 // Left
                    ) {
                        placeCursorBeforeElement(editor, parentElement);
                    } else if (
                        keyCode === 38 || // Up
                        keyCode === 39 || // Right
                        keyCode === 40 // Down
                    ) {
                    } else {
                        // event.cancel();
                        placeCursorAfterElement(editor, parentElement);
                    }
                }
            }
        });
    },
});

function placeCursorAfterElement(editor, element) {
    // Create a new range
    const range = editor.createRange();

    // Set the start and end of the range to be immediately after the element
    range.moveToPosition(element, window.CKEDITOR.POSITION_AFTER_END);

    // Get the selection object
    const selection = editor.getSelection();

    // Clear any existing ranges
    selection.removeAllRanges();

    // Add the new range to the selection
    selection.selectRanges([range]);

    // Ensure the editor is focused so the cursor appears in the right place
    editor.focus();
}
function placeCursorBeforeElement(editor, element) {
    // Create a new range
    const range = editor.createRange();

    // Set the start and end of the range to be immediately before the element
    range.moveToPosition(element, window.CKEDITOR.POSITION_BEFORE_START);

    // Get the selection object
    const selection = editor.getSelection();

    // Clear any existing ranges
    selection.removeAllRanges();

    // Add the new range to the selection
    selection.selectRanges([range]);

    // Ensure the editor is focused so the cursor appears in the right place
    editor.focus();
}
