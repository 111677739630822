import axios from 'axios';
export const wpUploadFeaturedImg = async (data, postId) => {
    if (!postId) return;
    // console.log(data);
    let config = {
        method: 'post',
        url: `${
            process.env.REACT_APP_SW_WORDPRESS_DOMAIN
        }/wp-json/wp/v2/media?title=${encodeURIComponent(
            data.name
        )}&featured=${postId}&status=publish`,
        headers: {
            'Content-Disposition': `form-data; filename='${encodeURIComponent(
                data.name
            )}'`,
            // Authorization: `Basic c3llZC5pbWFkdWRkaW5Ac3RhcndlYXZlci5jb206Q3dVcSB1T0VWIG9PTjcgazc5UCBsNjNmIGZIdjM=`,
            Authorization: `Basic ${process.env.REACT_APP_WP_API_KEY}`,
            'Content-Type': data.type,
        },
        data: data,
    };
    const res = await axios(config);

    return res.data;
};
