import axios from 'axios';
import firebase from 'firebase/app';
import { useUser } from '../auth';
import { useState, useEffect } from 'react';
import {
    userIsAdminState,
    userIsStarweaverAdminState,
} from '../my-account/Recoil/State';
import { useRecoilState } from 'recoil';
import { useClientDetails } from './useClientDetails';

export const useIsAdmin = () => {
    const [isAdmin, setIsAdmin] = useRecoilState(userIsAdminState);
    const [starweaverAdmin, setStarweaverAdmin] = useRecoilState(
        userIsStarweaverAdminState
    );
    const [isLoading, setIsLoading] = useState(true);

    // if (isAdmin === false && isLoading === false)
    //     console.log('Ooops! if you are an admin.');
    // console.log(
    //     isAdmin,
    //     isLoading,
    //     isAdmin === false && isLoading === false ? 'Ooops!' : ''
    // );
    const { isLoading: isUserLoading, user } = useUser();
    const { client, isLoading: isClientLoading } = useClientDetails(
        user,
        isUserLoading
    );

    useEffect(() => {
        const check = async () => {
            setIsLoading(true);
            try {
                // const token = awaapi/client-reqit firebase.auth().currentUser.getIdToken();
                // const {
                //     data: { isAdmin: userIsAdmin, isStarweaverAdmin },
                // } = await axios.get('/api/admin/', {
                //     headers: { authtoken: token },
                // });
                // setIsAdmin(userIsAdmin);
                // setStarweaverAdmin(isStarweaverAdmin);
                const authtokenResult = await firebase
                    .auth()
                    .currentUser.getIdTokenResult();
                // console.log(authtokenResult);

                if (authtokenResult?.claims?.admin) {
                    setIsAdmin(true);
                    if (
                        /@starweaver\.com$/i.test(
                            authtokenResult?.claims?.email
                        )
                    )
                        setStarweaverAdmin(true);
                    else setStarweaverAdmin(false);
                } else {
                    setIsAdmin(false);
                    setStarweaverAdmin(false);
                }
            } catch (error) {
                setIsAdmin(false);
                console.error(error);
            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                }, 200);
            }
        };
        if (user && !isUserLoading) {
            if (isAdmin) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 200);
            } else {
                check();
            }
        } else if (!user && !isUserLoading) {
            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        }

        // setIsLoading(false);
    }, [user, isUserLoading]);

    return {
        isAdmin,
        isLoading,
        client,
        isClientLoading,
        starweaverAdmin,
        user,
        isUserLoading,
        isCAD: isAdmin && !starweaverAdmin,
    };
};
