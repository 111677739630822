import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles()((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

export const BootstrapTooltip = (props) => {
    const { classes } = useStyles();

    return <Tooltip arrow classes={classes} {...props} />;
};
